import React, {createContext, Dispatch, useState} from "react";
import Toast, {ToastProps} from "../../../common/components/toast/Toast";

export const ToastContext = createContext<{
  toastProps: ToastProps | null,
  setToastProps: Dispatch<React.SetStateAction<ToastProps | null>>
} | null>(null);

const ToastContextProvider = (
  {
    children
  }: { children: React.ReactNode }
) => {
  const [
    toastProps,
    setToastProps
  ] = useState<ToastProps | null>(null);

  return (
    <ToastContext.Provider value={{toastProps, setToastProps}}>
      {children}
      {toastProps && <Toast {...toastProps} />}
    </ToastContext.Provider>
  )
};

export default ToastContextProvider;
