import {Id, isId} from "../../utils/types/Id";
import {isVariable, Variable} from "./Variable";
import {FlowModelV0, FlowModelV1} from "./FlowModel";
import {isUuid} from "../../utils/types/Uuid";


export type EnvironmentModel = {
  id: Id,
  variables: Variable[],
  flowId: FlowModelV1["id"],
  name: string,
};

export const isEnvironmentModelBase = (maybeEnvironmentModel: unknown): maybeEnvironmentModel is EnvironmentModelV1 | EnvironmentModelV0 =>
  typeof maybeEnvironmentModel === "object" && maybeEnvironmentModel !== null
  && ("variables" in maybeEnvironmentModel && Array.isArray(maybeEnvironmentModel.variables)
    && maybeEnvironmentModel.variables.every(isVariable))
  && "name" in maybeEnvironmentModel && typeof maybeEnvironmentModel.name === "string";

export const isEnvironmentModel = (maybeEnvironmentModel: unknown): maybeEnvironmentModel is EnvironmentModel =>
  isEnvironmentModelBase(maybeEnvironmentModel)
  && "flowId" in maybeEnvironmentModel && isUuid(maybeEnvironmentModel.flowId)

// versioning
export type EnvironmentModelV1 = EnvironmentModel;
export const isEnvironmentModelV1 = isEnvironmentModel;

// flowId became Uuid in v1
// id was introduced in v1
export type EnvironmentModelV0 = Omit<EnvironmentModelV1, "id" | "flowId"> & { flowId: FlowModelV0["id"] }
export const isEnvironmentModelV0 = (maybeEnvironmentModel: unknown): maybeEnvironmentModel is EnvironmentModelV0 =>
  isEnvironmentModelBase(maybeEnvironmentModel)
  && "flowId" in maybeEnvironmentModel && isId(maybeEnvironmentModel.flowId)
