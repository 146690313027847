//type IdChar = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "a" | "b" | "c" | "d" | "e" | "f";
export type Id = `id-${string}`;

const idFormatRegexp = /id-[0-9a-f]{6,}/;
const validate = (maybeId: string): boolean => {
  const regex = new RegExp(`^${idFormatRegexp.source}$`);
  return regex.test(maybeId);
}
export const isId = (maybeId: unknown): maybeId is Id => typeof maybeId === "string" && validate(maybeId);

export const extractIdFromString = (str: string): Id | undefined => {
  const extracted = str.match(idFormatRegexp)?.[0];
  return extracted && isId(extracted) ? extracted : undefined;
}
