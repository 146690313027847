import Editor from "../../../common/components/editor/Editor";
import TabbedContainer from "../../../common/components/tabbed-container/TabbedContainer";
import React, {useEffect, useState} from "react";
import Modal from "../../../common/components/modal/Modal";
import {UiColor} from "../../../utils/constants/UiColor";
import Container from "../../../common/components/container/Container";
import {InlineIconName} from "../../../common/components/inline-icon/InlineIconName";

type ModalValueAndInstructionEditorProps = {
  title: string;
  value: string;
  instruction: string;
  //
  onValueChange?: (newValueAsString: string) => void;
  onInstructionChange?: (newInstruction: string) => void;
  onCloseClick?: () => void;
}

export const ModalValueAndInstructionEditor = (
  {
    title,
    value,
    instruction,
    //
    onValueChange,
    onInstructionChange,
    onCloseClick
  }: ModalValueAndInstructionEditorProps
) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [lastValue, setLastValue] = useState(value);
  const [lastInstruction, setLastInstruction] = useState(instruction);

  useEffect(() => {
    setLastValue(value);
  }, [value]);

  useEffect(() => {
    setLastInstruction(instruction);
  }, [instruction]);

  const handleTabChange = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  };
  const handleValueChange = (newValueAsString: string) => {
    setLastValue(newValueAsString);
  }
  const handleInstructionChange = (newInstruction: string) => {
    setLastInstruction(newInstruction);
  }
  const headerProps = {
    title: title,
    rightSideButtonListProps: [
      {
        iconName: InlineIconName.CLOSE,
        onClick: () => onCloseClick && onCloseClick(),
      }
    ]
  };

  return (
    <Modal onOutsideClick={onCloseClick}>
      <div className="w-5/12 h-fit justify-self-center self-center">
        <Container headerProps={headerProps} backgroundTone={UiColor.BackgroundColor.WHITE}>
          <TabbedContainer
            tabs={[
              {
                title: "Value",
                children: <Editor
                  readOnly={false}
                  content={lastValue}
                  onChange={handleValueChange}
                  onBlur={() => onValueChange && onValueChange(lastValue)}
                />
              },
              {
                title: "Instruction/Example",
                children: <Editor
                  readOnly={false}
                  content={lastInstruction}
                  onChange={handleInstructionChange}
                  onBlur={() => onInstructionChange && onInstructionChange(lastInstruction)}
                />
              }
            ]}
            activeTabIndex={activeTabIndex}
            onTabChange={handleTabChange}
          />
        </Container>
      </div>
    </Modal>
  );
}
