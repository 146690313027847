import {useEffect} from "react";
import {CloseButtonProps, toast, ToastContainer, TypeOptions} from "react-toastify";
import {MESSAGE_TYPE, messageTypeColorMapper,} from "../../helper/messageTypeColorMapper";
import {messageTypeIconMapper} from "../../helper/messageTypeIconMapper";
import InlineIcon from "../inline-icon/InlineIcon";
import "react-toastify/dist/ReactToastify.css";
import {TailwindColors} from "../../../utils/constants/TailwindColors";
import {UiColor} from "../../../utils/constants/UiColor";
import {InlineIconName} from "../inline-icon/InlineIconName";


const toastifyColorMapperHelper = (color: string): string => {
  return TailwindColors[color] ?? color;
};

const hex2rgba = (hex: string, transparency?: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b, transparency ? transparency / 100 : 1];
};

const toastifyTypeMapperHelper = {
  [MESSAGE_TYPE.INFO]: "info" as TypeOptions,
  [MESSAGE_TYPE.SUCCESS]: "success" as TypeOptions,
  [MESSAGE_TYPE.WARNING]: "warning" as TypeOptions,
  [MESSAGE_TYPE.ERROR]: "error" as TypeOptions,
  [MESSAGE_TYPE.CUSTOM]: "info" as TypeOptions,
  [MESSAGE_TYPE.QUESTION]: "info" as TypeOptions
};

export enum TOAST_TIMEOUT {
  SHORT = 3000,
  LONG = 9000,
  NEVER = 0
}

export type ToastProps = {
  message: string,
  callToAction?: string,
  type?: MESSAGE_TYPE,
  toastId?: string,
  autoClose?: number | false,
  position?: "top-right" | "top-center" | "top-left" | "bottom-right" | "bottom-center" | "bottom-left", //fixme enum
  onToastClick?: () => void,
  onToastClose?: () => void
}

const Toast = (
  {
    message,
    type,
    toastId,
    position = "bottom-left",
    autoClose = type === MESSAGE_TYPE.ERROR ? false : TOAST_TIMEOUT.SHORT,
    onToastClick,
    callToAction = onToastClick ? "Click for details" : undefined,
  }: ToastProps) => {

  useEffect(() => {
    const content = (
      <div className={"h-full w-full absolute top-0 left-0 pl-8 flex flex-col justify-center"}
           onClick={onToastClick}>
        <span className={""}>{message}</span>
        {callToAction && <span className={"text-xs"}>{callToAction} &gt;</span>}
      </div>
    );
    const colorTheme = messageTypeColorMapper(type || MESSAGE_TYPE.INFO);
    const backgroundColorInHex = toastifyColorMapperHelper(colorTheme.backgroundTone.replace("bg-", ""));
    const rgbaBackgroundColor = `rgba(${hex2rgba(backgroundColorInHex, 10)})`;
    const iconName = messageTypeIconMapper(type || MESSAGE_TYPE.INFO);
    const CloseButton = ({closeToast}: CloseButtonProps) => (
      <div
        className={`absolute top-0 right-0 w-6 h-6 p-1 border rounded ${UiColor.BorderColor.TRANSPARENT} hover:${UiColor.BorderColor.GRAY} z-10 cursor-pointer opacity-70 hover:opacity-100`}
        onClick={closeToast}>
        <InlineIcon
          name={InlineIconName.CLOSE} primaryTone={UiColor.TextColor.BLACK}
        />
      </div>
    );
    if (!message) {
      toast.dismiss(toastId);
    } else {
      toast(content, {
        type: toastifyTypeMapperHelper[type || MESSAGE_TYPE.INFO],
        autoClose: autoClose ? autoClose : false,
        toastId: toastId,
        position: position,
        closeButton: CloseButton,
        style: {
          position: "relative",
          background: `linear-gradient(0deg, ${rgbaBackgroundColor}, ${rgbaBackgroundColor}), #FFFFFF`,
          border: `1px solid ${backgroundColorInHex}`,
          color: toastifyColorMapperHelper(UiColor.TextColor.BLACK.replace("text-", "")),
          borderRadius: "0px",
          boxShadow: "unset",
          padding: 0,
          margin: 0
        },
        icon: iconName && <InlineIcon
          name={iconName}
          primaryTone={colorTheme.textTone}/>,
        progressStyle: {
          backgroundColor: backgroundColorInHex
        }
      });
    }
  }, [toastId, message, type, position, autoClose, onToastClick, callToAction]);

  return <ToastContainer/>;
};

export default Toast;
