import {IdentityProvider} from "@cranq-gpt-lowcode/contracts";
import {InlineIconName} from "../../../../common/components/inline-icon/InlineIconName";
import InlineIcon from "../../../../common/components/inline-icon/InlineIcon";
import {classNames} from "../../../../utils/classNames";

export type LoginWithButtonProps = {
  provider: IdentityProvider;
  // events
  onClick?: () => void;
}
// Due to branding guidelines we use absolute colors not the UiColor constants
// Same applies to measures like width, height, padding, margin, etc.
const LoginWithButton = (
  {
    provider,
    onClick
  }: LoginWithButtonProps) => {

  let iconName: InlineIconName;
  let backgroundColor: string = "bg-black";
  let borderColor: string = "border-black";
  let hoverShadowColor: string = "hover:shadow-black/30";
  let iconBackground: string | undefined;
  let fontFamily: string = "font-sans";

  switch (provider) {
    case IdentityProvider.FACEBOOK:
      iconName = InlineIconName.LOGO_FACEBOOK;
      borderColor = "border-[#1877F2]";
      backgroundColor = "bg-[#1877F2]";
      hoverShadowColor = "hover:shadow-[#1877F2]/30";
      break;
    case IdentityProvider.GITHUB:
      iconName = InlineIconName.LOGO_GITHUB;
      break;
    case IdentityProvider.GOOGLE:
      iconName = InlineIconName.LOGO_GOOGLE;
      borderColor = "border-[#4285F4]";
      backgroundColor = "bg-[#4285F4]";
      hoverShadowColor = "hover:shadow-[#4285F4]/30";
      fontFamily = "font-[Roboto]";
      iconBackground = "bg-white";
      break;
    case IdentityProvider.MICROSOFT:
      iconName = InlineIconName.LOGO_MICROSOFT;
      break;
    case IdentityProvider.TWITTER:
      iconName = InlineIconName.LOGO_TWITTER;
      borderColor = "border-[#1DA1F2]";
      backgroundColor = "bg-[#1DA1F2]";
      hoverShadowColor = "hover:shadow-[#1DA1F2]/30";
      break;
    default:
      throw new Error("Unknown identity provider");
  }

  return (
    <button
      className={classNames(`
      w-full h-fit 
      flex flex-row gap-[8px] items-center 
      ${backgroundColor} 
      ${borderColor} border rounded-sm 
      shadow-sm
      hover:shadow-md ${hoverShadowColor}
      `)}
      onClick={() => onClick && onClick()}>
      <span className={`w-[34px] h-[34px] grow-0 shrink-0 rounded-sm ${iconBackground ?? backgroundColor}`}>
        <InlineIcon name={iconName}/>
      </span>
      <span className={`h-[34px] p-[8px] grow ${fontFamily} text-sm text-white text-left`}>
        Sign in with {provider}
      </span>
    </button>
  );
}

export default LoginWithButton;
