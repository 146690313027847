import {utfSafeBase64Decode} from "./utfSafeBase64Decode";
import {unWrapText} from "./unWrapText";
import {
  FlowExportModel,
  FlowExportModelV0,
  FlowExportModelV1,
  FlowExportModelV2,
  isFlowExportModelV0,
  isFlowExportModelV1,
  isFlowExportModelV2
} from "../../types/FlowExportModel";
import {
  migrateFlowModelV0ToV1,
  migrateGroupModelV0ToV1,
  migrateStepModelV0ToV1
} from "../../store-migrations/migrateToV1";
import {NilUuid} from "../../../utils/createNewUuid";
import {FlowModelV1} from "../../types/FlowModel";
import {migrateStepModelV1ToV2} from "../../store-migrations/migrateToV2";

const migrateFlowExportModelV1ToV2 = (
  flowExportModelV1: FlowExportModelV1,
): FlowExportModelV2 => {
  const {flow, groups, steps, parameters} = flowExportModelV1;
  return {
    flow,
    groups,
    steps: steps.map((step) => migrateStepModelV1ToV2(step)),
    parameters
  }
}
const migrateFlowExportModelV0ToV1 = (
  flowExportModelV0: FlowExportModelV0,
  flowId: FlowModelV1["id"],
  userId: FlowModelV1["userId"]
): FlowExportModelV1 => {
  const {flow, groups, steps, parameters} = flowExportModelV0;
  return {
    flow: migrateFlowModelV0ToV1(flow, flowId, userId),
    groups: groups.map((group) => migrateGroupModelV0ToV1(group, flowId)),
    steps: steps.map((step) => migrateStepModelV0ToV1(step, flowId)),
    parameters
  }
}
// !!! This function must support all previous versions of the flow model !!!
export const decodeFlow = (flowExport: string): FlowExportModel | undefined => {
  const data = flowExport.split(/^ *-+ *BEGIN *-+ *$/m)[1]?.split(/^ *-+ *END *-+ *$/m)[0]?.trim();
  const maybeJson = utfSafeBase64Decode(unWrapText(data ?? ""))
  try {
    const maybeFlowExport = JSON.parse(maybeJson);
    if (isFlowExportModelV2(maybeFlowExport)) {
      return maybeFlowExport;
    }
    if (isFlowExportModelV1(maybeFlowExport)) {
      return migrateFlowExportModelV1ToV2(maybeFlowExport);
    }
    if (isFlowExportModelV0(maybeFlowExport)) {
      // flowId is just a dummy flowId, the real flowId is not exported nor imported
      // same for userId
      return migrateFlowExportModelV1ToV2(
        migrateFlowExportModelV0ToV1(maybeFlowExport, NilUuid, NilUuid)
      );
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
}
