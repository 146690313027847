import {NavLink} from "react-router-dom";

const NotFoundPage = () => {

  return <div
    className={"h-screen flex justify-center items-center"}>
    <div className={"border-1 bg-cr-pink/50 border-cr-pink p-4"}>
      <h1 className={"text-2xl"}>Page not found</h1>
      <p>The page you are looking for does not exist.</p>
      <p>Return to <NavLink to={"/"}>Home</NavLink></p>
    </div>
  </div>;
}

export default NotFoundPage;
