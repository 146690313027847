import {StepModel} from "../../types/StepModel";
import {sanitizePortsBeforeSharing} from "./sanitizePortsBeforeSharing";
import {wrapText} from "./wrapText";
import {utfSafeBase64Encode} from "./utfSafeBase64Encode";
import {NilUuid} from "../../../utils/createNewUuid";

export const encodeStep = (step: StepModel): string => {
  const exportedStep: StepModel = {
    ...step,
    ...sanitizePortsBeforeSharing(step),
    flowId: NilUuid, // required by data model but irrelevant for step sharing
    groupId: undefined, // do not export group id
    id: "id-000000", // required by data model but irrelevant for step sharing
  };
  const exportedStepAsString = utfSafeBase64Encode(JSON.stringify(exportedStep));

  return `
--------------------------------------------------------------------------------
 subject: cranq.ai step export
 version: 1.0
   title: ${step.title}
------------------------------------ BEGIN -------------------------------------
${wrapText(exportedStepAsString, 80)}
------------------------------------- END --------------------------------------
`;
}
