import {InlineIconName} from "../inline-icon/InlineIconName";
import {Link} from "react-router-dom";
import {classNames} from "../../../utils/classNames";
import {UiColor} from "../../../utils/constants/UiColor";
import InlineIcon from "../inline-icon/InlineIcon";
import React from "react";

type LinkProps = {
  url: string;
  text: string;
  icon?: InlineIconName;
  disabled?: boolean;
}
export type LinkListProps = {
  links: LinkProps[];
}
const LinkList = (
  {
    links
  }: LinkListProps
) => {
  const isExternalLink = (link: string) => /^http(s)?:\/\//.test(link);

  return (
    <div className={"flex flex-col gap-4 flex-shrink"}>
      {links.map(({url, text, icon, disabled = false}, index) => (
        <Link
          key={`link-${index}`}
          to={url}
          className={classNames(`
                text-center text-sm 
                ${disabled ? UiColor.TextColor.DISABLED : UiColor.TextColor.GRAY} 
                ${disabled ? "cursor-not-allowed" : "cursor-pointer"}
                ${disabled ? "pointer-events-none" : ""}
              `)}
          target={isExternalLink(url) ? "_blank" : ""}
        >
          <div
            className={"flex gap-2"}>
            {icon && <span className={"flex-shrink"}>
                <InlineIcon
                  name={icon as InlineIconName}
                  primaryTone={disabled ? UiColor.TextColor.DISABLED : UiColor.TextColor.GRAY}/></span>}
            <span>{text}</span>
          </div>
        </Link>
      ))}
    </div>
  )
};

export default LinkList;
