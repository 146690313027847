import {Id, isId} from "../../utils/types/Id";
import {FlowModel, FlowModelV0} from "./FlowModel";
import {isUuid} from "../../utils/types/Uuid";

export type GroupModel = {
  id: Id,
  flowId: FlowModel["id"],
  title: string,
  description?: string,
}

export const isGroupModelBase = (maybeGroupModel: unknown): maybeGroupModel is GroupModelV1 | GroupModelV0 => {
  return typeof maybeGroupModel === "object" && maybeGroupModel !== null
    && "id" in maybeGroupModel && isId(maybeGroupModel.id)
    && "title" in maybeGroupModel && typeof maybeGroupModel.title === "string"
    && ("description" in maybeGroupModel ? typeof maybeGroupModel.description === "string" : true)
}
export const isGroupModel = (maybeGroupModel: unknown): maybeGroupModel is GroupModel => {
  return isGroupModelBase(maybeGroupModel)
    && "flowId" in maybeGroupModel && isUuid(maybeGroupModel.flowId)
}

// versioning
export type GroupModelV1 = GroupModel;
export const isGroupModelV1 = isGroupModel;

// flowId became Uuid in v1
export type GroupModelV0 = Omit<GroupModelV1, "flowId"> & { flowId: FlowModelV0["id"] }
export const isGroupModelV0 = (maybeGroupModel: unknown): maybeGroupModel is GroupModelV0 => {
  return isGroupModelBase(maybeGroupModel)
    && "flowId" in maybeGroupModel && isId(maybeGroupModel.flowId)
}
