import {FlowState, FlowStateV0} from "../../../types/FlowState";
import {ScheduledJobStatus} from "@cranq-gpt-lowcode/contracts";

export const calculateScheduledFlowStatus = (
  scheduledJobId: FlowState["scheduledJobId"],
  scheduledJobExpiryDate: FlowState["scheduledJobExpiryDate"],
  scheduledJobHash: FlowState["scheduledJobHash"],
  flowHash: FlowState["model"]["hash"],
  flowHashV0?: FlowStateV0["model"]["hash"]
) => {
  return scheduledJobId
    ? (
      (scheduledJobExpiryDate && scheduledJobExpiryDate > Date.now())
        ? ((scheduledJobHash === flowHash || scheduledJobHash === flowHashV0)
            ? ScheduledJobStatus.SCHEDULED
            : ScheduledJobStatus.OUTDATED
        )
        : ScheduledJobStatus.EXPIRED
    )
    : undefined;
}
