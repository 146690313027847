import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {GroupState, GroupStateV0} from "./types/GroupState";
import {Id, isId} from "../utils/types/Id";
import {GroupModel} from "./types/GroupModel";
import {createUniqueName} from "./utils/createUniqueName";
import {createDefaultGroupTitle} from "./utils/createDefaultGroupTitle";
import {FlowModel} from "./types/FlowModel";

export type GroupsState = Record<Id, GroupState>;
export type GroupsStateV1 = GroupsState;
export type GroupsStateV0 = Record<Id, GroupStateV0>

const initialState: GroupsState = {};


export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    createGroup: (state, action: PayloadAction<{
      id: GroupState["model"]["id"],
      flowId: GroupState["model"]["flowId"],
    }>) => {
      const {id, flowId} = action.payload;
      const existingGroupTitles = Object.values(state).map(group => group.model.title);
      const newGroupTitle = createUniqueName(createDefaultGroupTitle(), existingGroupTitles);
      state[id] = {
        model: {
          id,
          flowId,
          title: newGroupTitle,
          description: "",
        }
      }
    },
    deleteGroup: (state, action: PayloadAction<GroupModel["id"]>) => {
      delete state[action.payload];
    },
    deleteGroupsByFlowId: (state, action: PayloadAction<FlowModel["id"]>) => {
      Object.values(state)
        .filter((group) => group.model.flowId === action.payload)
        .forEach((group) => delete state[group.model.id]);
    },
    updateGroup: (state, action: PayloadAction<GroupState>) => {
      state[action.payload.model.id] = {
        ...action.payload
      };
    },
    updateGroupById: (state, action: PayloadAction<{
      groupId: GroupModel["id"],
      update: Partial<Omit<GroupState, "model">> & { model?: Partial<GroupModel> }
    }>) => {
      const group = state[action.payload.groupId];
      if (group) {
        state[action.payload.groupId] = {
          ...group,
          ...action.payload.update,
          model: {
            ...group.model,
            ...action.payload.update.model,
          }
        }
      }
    }
  }
});

export const groupsSliceRawReducers = groupsSlice.caseReducers;

export const {
  createGroup,
  deleteGroup,
  deleteGroupsByFlowId,
  updateGroup,
  updateGroupById,
} = groupsSlice.actions;


export const selectGroups = (state: RootState) => state.groups;
export const selectGroup = (id?: GroupModel["id"]) => (state: RootState) => (id && isId(id) && state.groups[id]) || undefined;
export const makeSelectGroupsByFlowId = () => createSelector(
  [
    selectGroups,
    (_: any, flowId?: FlowModel["id"]) => flowId
  ],
  (groups, flowId) => Object.values(groups).filter((group) => group.model.flowId === flowId) || undefined
);
export const groupsReducer = groupsSlice.reducer;
