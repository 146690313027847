import {updateFlowById} from "../../../flowsSlice";
import {RootState} from "../../../store";
import {ThunkAction} from "@reduxjs/toolkit";
import {Webhook} from "@cranq-gpt-lowcode/contracts";
import {FlowModel} from "../../../types/FlowModel";
import {useDeleteWebhookMutation} from "../../../webhookApi";

export const deleteWebhookAction = (
  flowId: FlowModel["id"],
  webhookId: Webhook["webhookId"],
  deleteWebhookTrigger: ReturnType<typeof useDeleteWebhookMutation>[0]
): ThunkAction<Promise<void>, RootState, any, any> => async (
  dispatch
) => {
  const result = await deleteWebhookTrigger(webhookId)
  if ("error" in result) {
    throw result.error;
  } else {
    dispatch(updateFlowById({
      flowId,
      update: {
        webhookId: undefined,
        webhookExpiryDate: undefined,
        webhookHash: undefined
      }
    }));
  }
}
