import {StepState} from "../../../types/StepState";
import {GroupedStepsState} from "../../../types/GroupedStepsState";
import {TimelineEntry} from "../types/TimelineEntry";
import {GroupState} from "../../../types/GroupState";

export const createTimelineEntries = (
  steps: StepState[],
  groups: GroupState[]
): TimelineEntry[] => {
  const [entries, lastGroup] = steps.reduce<[TimelineEntry[], GroupedStepsState | null]>(
    ([result, groupedSteps], step) => {
      if (!step.model.groupId) {
        return [
          [...result, ...(groupedSteps ? [groupedSteps] : []), step],
          null
        ];
      } else {
        const groupById = groups.find((group) => group.model.id === step.model.groupId);
        if (groupedSteps && groupedSteps.group.model.id !== step.model.groupId) {
          return [
            [...result, groupedSteps],
            {
              group: {
                model: {
                  id: step.model.groupId,
                  flowId: step.model.flowId,
                  title: groupById?.model.title ?? "Unknown group",
                }
              },
              steps: [step]
            }
          ];
        } else {
          return [
            result,
            groupedSteps
              ? {...groupedSteps, steps: [...groupedSteps.steps, step]}
              : {
                group: {
                  model: {
                    id: step.model.groupId,
                    flowId: step.model.flowId,
                    title: groupById?.model.title ?? "Unknown group",
                  }
                },
                steps: [step]
              }
          ];
        }
      }
    }, [[], null]);
  return lastGroup ? [...entries, lastGroup] : entries;
}
