import {UiColor} from "../../utils/constants/UiColor";

export enum MESSAGE_TYPE {
  INFO = "info",
  QUESTION = "question",
  WARNING = "warning",
  SUCCESS = "success",
  ERROR = "error",
  CUSTOM = "custom"
}


type MessageColorTheme = {
  textTone: UiColor.TextColor,
  backgroundTone: UiColor.BackgroundColor,
  borderTone?: UiColor.BorderColor
}
export const messageTypeColorMapper = (
  type: MESSAGE_TYPE,
): MessageColorTheme => {
  switch (type) {
    case MESSAGE_TYPE.INFO:
      return {
        textTone: UiColor.TextColor.BLACK,
        backgroundTone: UiColor.BackgroundColor.TRANSPARENT,
        borderTone: UiColor.BorderColor.GOLD
      };
    case MESSAGE_TYPE.WARNING:
      return {
        textTone: UiColor.TextColor.GOLD,
        backgroundTone: UiColor.BackgroundColor.GOLD,
        borderTone: UiColor.BorderColor.GOLD
      };
    case MESSAGE_TYPE.QUESTION:
      return {
        textTone: UiColor.TextColor.BLACK,
        backgroundTone: UiColor.BackgroundColor.TRANSPARENT,
        borderTone: UiColor.BorderColor.GOLD
      };
    case MESSAGE_TYPE.ERROR:
      return {
        textTone: UiColor.TextColor.RED,
        backgroundTone: UiColor.BackgroundColor.RED,
        borderTone: UiColor.BorderColor.RED
      };
    case MESSAGE_TYPE.SUCCESS:
      return {
        textTone: UiColor.TextColor.GREEN,
        backgroundTone: UiColor.BackgroundColor.GREEN,
        borderTone: UiColor.BorderColor.GREEN
      };
    default:
      return {
        textTone: UiColor.TextColor.BLACK,
        backgroundTone: UiColor.BackgroundColor.BLACK,
        borderTone: UiColor.BorderColor.GRAY
      };
  }
};
