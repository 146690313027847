//ref: https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
function base64ToBytes(base64: string) {
  try {
    const binString = atob(base64);
    return Uint8Array.from(binString, (m) => m.codePointAt(0) ?? 0 /* should not happen */);
  } catch (e) {
    return new Uint8Array();
  }
}

export const utfSafeBase64Decode = (str: string) => {
  return new TextDecoder().decode(base64ToBytes(str));
}
