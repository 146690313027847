import {useSearchParams} from "react-router-dom";
import {isId} from "../../../../utils/types/Id";
import {StepState} from "../../../types/StepState";
import {StepModel} from "../../../types/StepModel";
import {GroupModel} from "../../../types/GroupModel";
import {useCallback} from "react";

const isValidStepId = (stepId: string | undefined, steps: StepState[]): stepId is StepModel["id"] => {
  return isId(stepId) && !!steps.find((step) => step.model.id === stepId)
}
const isValidGroupId = (groupId: string | undefined, steps: StepState[]): groupId is GroupModel["id"] => {
  return isId(groupId) && !!steps.find((step) => step.model.groupId === groupId)
}

export type UseFocusedEntryResult = {
  focusOnFlowParams: true | undefined,
  focusedStepId: StepModel["id"] | undefined,
  focusedGroupId: GroupModel["id"] | undefined,
  setFocusOnFlowParams: () => void,
  setFocusedStepId: (stepId: string | undefined) => void,
  setFocusedGroupId: (groupId: string | undefined) => void
}
export const useFocusedEntry = (
  steps: StepState[]
): UseFocusedEntryResult => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setFocusOnFlowParams = useCallback(() => {
    setSearchParams("flowParameters")
  }, [setSearchParams]);
  const setFocusedStepId = useCallback((stepId: string | undefined) => {
    setSearchParams(stepId ? {stepId} : "");
  }, [setSearchParams])
  const setFocusedGroupId = useCallback((groupId: string | undefined) => {
    setSearchParams(groupId ? {groupId} : "");
  }, [setSearchParams])
  const focusOnFlowParams = searchParams.has("flowParameters")
  const stepIdFromUrl = searchParams.get("stepId") ?? undefined;
  const groupIdFromUrl = searchParams.get("groupId") ?? undefined;
  const focusedStepId = (!focusOnFlowParams && isValidStepId(stepIdFromUrl, steps)) ? stepIdFromUrl : undefined;
  const focusedGroupId = (!focusOnFlowParams && !focusedStepId && isValidGroupId(groupIdFromUrl, steps)) ? groupIdFromUrl : undefined;

  const firstFlowEntry = steps[0]
    ? (steps[0].model.groupId
      ? {
        id: steps[0].model.groupId,
        type: "group"
      }
      : {
        id: steps[0].model.id,
        type: "step"
      })
    : undefined
  const focusedEntry = focusOnFlowParams
    ? {
      id: undefined,
      type: "params"
    }
    : focusedGroupId
      ? {
        id: focusedGroupId,
        type: "group"
      }
      : (focusedStepId
        ? {
          id: focusedStepId ?? steps[0]?.model.id,
          type: "step"
        }
        : firstFlowEntry);
  return {
    focusOnFlowParams: focusedEntry?.type === "params" ? true : undefined,
    focusedStepId: focusedEntry?.type === "step" ? focusedEntry.id : undefined,
    focusedGroupId: focusedEntry?.type === "group" ? focusedEntry.id : undefined,
    setFocusOnFlowParams,
    setFocusedStepId,
    setFocusedGroupId
  }
}
