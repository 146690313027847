import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react";
import {SubscriptionWithPackage, User} from "@cranq-gpt-lowcode/contracts";
import {EndpointBuilder} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {ApiContext, getApiBaseUrl} from "./utils/getApiBaseUrl";
import {JsonApiResponse, MapApiResponse} from "./types/messages/utils/MapApiResponse";

export const subscriptionsApiRawEndpointBuilder = (
  builder: EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, "Subscriptions", "subscriptionsApi">
) => ({
  getUserSubscriptions: builder.query<MapApiResponse<SubscriptionWithPackage>[], User["id"]>({
    query: (userId) => ({
      url: `/${userId}`,
      method: "GET"
    }),
    providesTags: (result, error, arg) => [{type: "Subscriptions", id: arg}],
    keepUnusedDataFor: 5 * 60, // 5 minutes
    transformResponse: (subscriptions: JsonApiResponse<SubscriptionWithPackage>[]): MapApiResponse<SubscriptionWithPackage>[] => {
      return subscriptions.map((s) => {
        const {created, validFrom, validUntil, deleted, ...rest} = s;
        return ({
          ...rest,
          created: Date.parse(created),
          validFrom: Date.parse(validFrom),
          validUntil: Date.parse(validUntil),
          ...(deleted !== undefined ? {deleted: Date.parse(deleted)} : {})
        });
      });
    }
  })
});

const subscriptionsApi = createApi({
  reducerPath: "subscriptionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getApiBaseUrl(ApiContext.SUBSCRIPTIONS),
    credentials: "include",
    prepareHeaders: (headers) => {
      headers.append("Ga-Client-Id", window.__GLOBAL_VAR__.GA_ClIENT_ID || "unset");
    },
  }),
  endpoints: subscriptionsApiRawEndpointBuilder
});

export const {
  useGetUserSubscriptionsQuery
} = subscriptionsApi;
export default subscriptionsApi;
