import React from "react";
import {
  UiElementIndexedSelectors,
  UiElementSelectors
} from "../../components/lesson-context-provider/types/UiElementSelectors";
import Container from "../../../common/components/container/Container";
import {UiColor} from "../../../utils/constants/UiColor";

const UsableUiElements = () => {
  return (
    <ul className={"list-disc list-inside"}>
      {Object.keys(UiElementSelectors)
        .concat(Object.keys(UiElementIndexedSelectors))
        .map(
          (key, index) => <li key={`uiElement-${index}`}>{key}</li>
        )}
    </ul>
  );
}

const LessonExample = () => (
  <pre className={`p-2 ${UiColor.BackgroundColor.GRAY} border ${UiColor.BorderColor.BLACK} text-sm`}>{`
{
  "steps": [
    {
      "title": "Add a Blank First Step",
      "text": "Click on the button below",
      "element":"AddBlankFirstStep",
      "advanceOn": {
        "event": "click"
      }
    }
  ]
}
  `.trim()}</pre>);

const LessonStepProperties = () => (
  <dl className={"list-disc list-inside"}>
    <dt>title</dt>
    <dt><strong>text</strong></dt>
    <dd className={"text-sm"}>Hints:</dd>
    <dd className={"text-sm"}>you can use "\n" to insert new line into the text</dd>
    <dd className={"text-sm"}>you can wrap text in backticks (`text`) to highlight is as provided example</dd>
    <dt><strong>element</strong></dt>
    <dt>advanceOn</dt>
    <dd className={"text-sm"}>on event:
      <code className={"text-sm"}>{` {"event": "click"}`}</code>
    </dd>
    <dd className={"text-sm"}>on value:
      <code className={"text-sm"}>{` {"value": "some value"}`}</code>
    </dd>
    <dd className={"text-sm"}>on property change:
      <code className={"text-sm"}>{` {"property": "disabled"}`}</code>
    </dd>
    <dd className={"text-sm"}>on property value:
      <code className={"text-sm"}>{` {"property": "disabled", "value": false}`}</code>
    </dd>
    <dt>allowNext</dt>
    <dt>allowBack</dt>
  </dl>

);


export function LessonEditorNotes() {
  return (
    <Container headerProps={{title: "Lesson Editor Notes"}}>
      <h3>Lesson Step Properties</h3>
      <LessonStepProperties/>
      <h3>Usable UI element names</h3>
      <UsableUiElements/>
      <h3>Example</h3>
      <LessonExample/>
    </Container>
  )
}
