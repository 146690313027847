import {AppPermissions} from "../types/AppPermissions";
import {AppPermissionType} from "../types/AppPermissionType";

const readStoredAppPermissionsFromSessionStorage = (): AppPermissions =>
  Object.keys(AppPermissionType).reduce((acc, permissionName) => {
    const permissionValue = sessionStorage.getItem(`PERMISSION_${permissionName}`);
    return {
      ...acc,
      [permissionName]: {
        granted: permissionValue === "true",
        scope: "SESSION"
      }
    };
  }, {} as AppPermissions);
const readAppPermissionsFromUserProfile = (): Partial<AppPermissions> => ({
  //TODO: implement when we'll have permissions in user profile
  // SHARE_DATA_WITH_LLMS: {
  //     granted: true | false,
  //     scope: "USER"
  // }
});
export const readStoredAppPermissions = (): AppPermissions => ({
  ...readStoredAppPermissionsFromSessionStorage(),
  ...readAppPermissionsFromUserProfile(),
});
