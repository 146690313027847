"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapWeeklySchedulerEntryToUtc = exports.mapWeeklySchedulerEntryToTimezone = exports.Timezone = void 0;
const getOffsetOfTimezones_1 = require("./getOffsetOfTimezones");
const shiftWeekdayAndHourByOffset_1 = require("./shiftWeekdayAndHourByOffset");
var Timezone;
(function (Timezone) {
    Timezone["UTC"] = "Etc/UTC";
})(Timezone = exports.Timezone || (exports.Timezone = {}));
const mapWeeklySchedulerEntryToTimezone = (entry, timezone, now = new Date()) => {
    const { weekday, hour, timezone: entryTimezone } = entry;
    const offset = (0, getOffsetOfTimezones_1.getOffsetOfTimezones)(now, entryTimezone, timezone);
    if (isNaN(offset)) {
        return undefined;
    }
    const shifted = (0, shiftWeekdayAndHourByOffset_1.shiftWeekdayAndHourByOffset)(weekday, hour, -offset);
    return Object.assign(Object.assign(Object.assign({}, entry), shifted), { timezone });
};
exports.mapWeeklySchedulerEntryToTimezone = mapWeeklySchedulerEntryToTimezone;
const mapWeeklySchedulerEntryToUtc = (entry, now = new Date()) => (0, exports.mapWeeklySchedulerEntryToTimezone)(entry, Timezone.UTC, now);
exports.mapWeeklySchedulerEntryToUtc = mapWeeklySchedulerEntryToUtc;
