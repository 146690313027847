import Container from "../../../common/components/container/Container";
import LoginWithButton from "./login-with-button/LoginWithButton";
import {useCallback, useState} from "react";
import {login} from "../../userSlice";
import {UserModel} from "../../types/UserModel";
import {useAppDispatch} from "../../hooks";
import {IdentityProvider} from "@cranq-gpt-lowcode/contracts";
import {useAuthWindow} from "../../hooks/useAuthWindow";
import ReactGA from "react-ga4";

export type LoginProps = {
  anonymousUserId: UserModel["id"];
  identityProviders: IdentityProvider[];
  // events
  onLoginSuccess?: () => void;
  onLoginFailure?: (message: string) => void;
}

const Login = (
  {
    anonymousUserId,
    identityProviders,
    onLoginSuccess,
    onLoginFailure
  }: LoginProps
) => {
  const dispatch = useAppDispatch();

  const [currentIdentityProvider, setCurrentIdentityProvider] = useState<IdentityProvider | undefined>(undefined);
  const loginInProgress = currentIdentityProvider !== undefined;

  const handleLoginFailure = useCallback((error?: Error) => {
    setCurrentIdentityProvider(undefined);
    ReactGA.event({
      category: "auth",
      action: "LOGIN_FAILED",
      label: `Error: ${error}`,
      nonInteraction: true,
      transport: "xhr"
    });
    onLoginFailure && onLoginFailure(error?.message ?? "Login failed");
  }, [onLoginFailure]);

  const handleLoginSuccess = useCallback((user: UserModel, validUntil: number, isNewRegistration: boolean) => {
    setCurrentIdentityProvider(undefined);
    dispatch(login({user, validUntil}));
    ReactGA.event({
      category: "auth",
      action: isNewRegistration ? "REGISTER" : "LOGIN",
      label: `User: ${user.id}`,
      nonInteraction: true,
      transport: "xhr"
    });
    onLoginSuccess && onLoginSuccess();
  }, [dispatch, onLoginSuccess]);

  const {openAuthWindow} = useAuthWindow({
    onLoginSuccess: handleLoginSuccess,
    onLoginFailure: handleLoginFailure
  });
  const handleOnClick = (
    provider: IdentityProvider
  ) => () => {
    setCurrentIdentityProvider(provider);
    openAuthWindow(provider, anonymousUserId);
  }

  return <Container indented={false}>
    {loginInProgress
      ? <div>Waiting for authentication, please do not close...</div>
      : (
        <div className={"w-full flex flex-col gap-4 items-center"}>
          <div>
            <p>Please log in to use all features of the application, like:</p>
            <ul className={"list-disc list-inside"}>
              <li>Execute generated code</li>
              <li>Use 3rd party APIs from generated code</li>
              <li>Schedule workflow execution</li>
              <li>Webhook</li>
              <li className={"italic"}>... and many more coming...</li>
            </ul>
          </div>
          <div className={"w-fit grid gap-4 p-4"}>
            {identityProviders.map((provider) => (
              <LoginWithButton
                key={provider}
                provider={provider}
                onClick={handleOnClick(provider)}/>
            ))}
          </div>
        </div>
      )
    }
  </Container>
}

export default Login;
