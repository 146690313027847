import {useEffect, useRef, useState} from "react";
import {UserModel} from "../types/UserModel";
import {ApiContext, getApiBaseUrl} from "../utils/getApiBaseUrl";
import {isAuthMessage} from "@cranq-gpt-lowcode/contracts";
import {UserState} from "../types/UserState";
import {useAuthWindow} from "./useAuthWindow";

/** Custom error type for RefreshLogin */
export class RefreshLoginError extends Error {
  isSoftError: boolean;

  constructor(message: string, isSoftError: boolean = false) {
    super(message);
    this.name = "RefreshLoginError";
    this.isSoftError = isSoftError;
  }
}

export const useRefreshLogin = (
  userState: UserState,
  anonymousUserId: UserModel["id"],
  forceRefresh: boolean,
  onLoginRefreshSuccess: (user: UserModel, validUntil: number) => void,
  onLoginRefreshFailure: (error: any) => void,
  onLoginRefreshSkipped?: () => void,
  onAnonymousLoginSuccess?: (validUntil: number) => void
) => {
  const [isAuthWindowBlocked, setIsAuthWindowBlocked] = useState(false);
  const [isAuthWindowOpen, setIsAuthWindowOpen] = useState(false);
  const isSilentRefreshInProgress = useRef(false);

  const {openAuthWindow} = useAuthWindow({
    onLoginSuccess: (user, validUntil) => {
      setIsAuthWindowOpen(false);
      setIsAuthWindowBlocked(false);
      onLoginRefreshSuccess && onLoginRefreshSuccess(user, validUntil);
    },
    onLoginFailure: (error) => {
      setIsAuthWindowOpen(false);
      setIsAuthWindowBlocked(false);
      onLoginRefreshFailure && onLoginRefreshFailure(error?.message ?? "Login failed");
    },
    onAuthWindowOpenFailed: () => {
      setIsAuthWindowOpen(false);
      setIsAuthWindowBlocked(true);
    }
  });
  const silentLoginRefreshTimeoutId = useRef<number | null>(null);
  useEffect(() => {
    const silentLoginRefresh = (
      userId: UserModel["id"],
      anonymousUserId: UserModel["id"],
      onFailure: (error: Error) => void
    ) => {
      if (isSilentRefreshInProgress.current) {
        return;
      }
      const refreshUrl = new URL(getApiBaseUrl(ApiContext.AUTH) + "/refresh"); //FIXME: move URL to contracts?
      isSilentRefreshInProgress.current = true;
      refreshUrl.searchParams.append("userId", userId);
      refreshUrl.searchParams.append("anonymousUserId", anonymousUserId);
      fetch(refreshUrl, {
        method: "POST",
        credentials: "include"
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new RefreshLoginError(
            `Login refresh failed with status ${response.status}`,
            ![400, 401, 403].includes(response.status)
          );
        }, (error) => {
          throw new RefreshLoginError(`Login refresh failed: ${error.message}`, true);
        })
        .then((data) => {
          if (isAuthMessage(data)) {
            if (data.result === "success") {
              onLoginRefreshSuccess(data.user, data.validUntil);
            } else {
              throw new Error("Authentication refresh failed: " + data.details);
            }
          } else {
            throw new Error("Authentication refresh failed: invalid message");
          }
        })
        .catch((error) => {
          onFailure(error);
        })
        .finally(() => {
          isSilentRefreshInProgress.current = false;
        });
    }

    const handleSilentRefreshFailure = (
      user: UserState,
      anonymousUserId: UserModel["id"],
      error: Error
    ) => {
      console.debug("silent login refresh failed", error);
      if (!forceRefresh && error instanceof RefreshLoginError && error.isSoftError) {
        console.debug("soft error, retrying...");
        scheduleSilentRefresh(user, anonymousUserId);
        return;
      }
      if (user.model) {
        // user is authenticated reopen auth window
        setIsAuthWindowOpen(true);
        openAuthWindow(user.model.identityProvider, anonymousUserId);
      } else {
        // user is anonymous can't open auth window, call failure callback
        onLoginRefreshFailure && onLoginRefreshFailure(error);
      }
    }

    const scheduleSilentRefresh = (
      user: UserState,
      anonymousUserId: UserModel["id"],
      immediateRefresh: boolean = false
    ) => {
      const minTimeoutMs = 10 * 1000;
      const halfTimeUntilExpiration = Math.floor((user.validUntil - Date.now()) / 2);
      const normalTimeoutMs = Math.max(halfTimeUntilExpiration, minTimeoutMs);
      const timeoutMs = immediateRefresh ? 0 : normalTimeoutMs;

      silentLoginRefreshTimeoutId.current && clearTimeout(silentLoginRefreshTimeoutId.current);
      console.debug(`scheduled login refresh in ${timeoutMs}ms`)
      silentLoginRefreshTimeoutId.current = window.setTimeout(() => {
        silentLoginRefresh(
          user.model?.id ?? anonymousUserId,
          anonymousUserId,
          (error) => handleSilentRefreshFailure(user, anonymousUserId, error));
      }, timeoutMs);
    }

    if (userState.validUntil) {
      // scheduled refresh
      scheduleSilentRefresh(userState, anonymousUserId, forceRefresh)
    } else {
      // get token for anonymous user
      const loginUrl = new URL(getApiBaseUrl(ApiContext.AUTH) + "/anonymous/login");
      fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({anonymousUserId}),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error(`Anonymous login failed with status ${response.status}`)
        }, (error) => {
          throw new Error(`Anonymous login failed: ${error.message}`);
        })
        .then((data) => {
          if (isAuthMessage(data)) {
            if (data.result === "success") {
              onAnonymousLoginSuccess && onAnonymousLoginSuccess(data.validUntil);
              scheduleSilentRefresh({validUntil: data.validUntil}, anonymousUserId)
            } else {
              throw new Error("Anonymous login failed: " + data.details);
            }
          } else {
            throw new Error("Anonymous login failed: invalid message");
          }
        });
    }

    return () => {
      if (silentLoginRefreshTimeoutId.current) {
        clearTimeout(silentLoginRefreshTimeoutId.current);
      }
    }
  }, [onLoginRefreshSuccess, openAuthWindow, forceRefresh, anonymousUserId, userState, onAnonymousLoginSuccess, onLoginRefreshFailure]);

  return {
    openAuthWindow,
    isAuthWindowBlocked,
    isAuthWindowOpen
  }
}
