import Button, {ButtonType} from "../../../common/components/button/Button";
import {UiColor} from "../../../utils/constants/UiColor";
import React, {useContext} from "react";
import Container from "../../../common/components/container/Container";
import {AuthContext} from "../auth-context-provider/AuthContextProvider";
import {InlineIconName} from "../../../common/components/inline-icon/InlineIconName";

type CreateStepsPanelProps = {
  isStepGenerationInProgress?: boolean,
  isFlowRunning?: boolean,
  // events
  onCreateBlankFirstStep?: () => void,
  onImport?: () => void,
  onBreakdownChatStart?: () => void
}

export const CreateFirstStepsPanel = (
  {
    isStepGenerationInProgress,
    isFlowRunning,
    onCreateBlankFirstStep,
    onImport,
    onBreakdownChatStart
  }: CreateStepsPanelProps) => {
  const {permissions} = useContext(AuthContext);
  const canCreateSteps = !isFlowRunning && !isStepGenerationInProgress;

  return (
    <Container
      headerProps={{title: "Workflow", level: 4, titleTone: UiColor.TextColor.LIGHT_GRAY}}
      indented={false}
      paddingY={false}>
      <div className={"pt-4 flex flex-row flex-wrap gap-4 justify-evenly"}>
        <Button
          title={"Add a blank first step"}
          type={ButtonType.PRIMARY}
          shadowTone={UiColor.ShadowColor.BLACK_MEDIUM}
          disabled={!canCreateSteps}
          onClick={() => onCreateBlankFirstStep && onCreateBlankFirstStep()}
        />
        <Button
          title={"Import a step or a group"}
          type={ButtonType.PRIMARY}
          shadowTone={UiColor.ShadowColor.BLACK_MEDIUM}
          disabled={!canCreateSteps}
          onClick={() => onImport && onImport()}
        />
        <Button
          title={`Launch AI Wizard${permissions.SHARE_DATA_WITH_LLMS.granted ? "" : " (*)"}`}
          iconName={InlineIconName.MAGICK}
          type={ButtonType.HIGHLIGHTED}
          shadowTone={UiColor.ShadowColor.BLACK_MEDIUM}
          disabled={!canCreateSteps}
          onClick={() => onBreakdownChatStart && onBreakdownChatStart()}
        />
      </div>
    </Container>
  );
}
