import {Id, isId} from "../../utils/types/Id";
import {isUuid, Uuid} from "../../utils/types/Uuid";
import {UserModel} from "./UserModel";

export type FlowModel = {
  id: Uuid,
  idV0?: Id,
  userId: UserModel["id"],
  title: string,
  description: string,
  lessonDescriptor?: string,
  hints?: string[],
  createdAt?: number,
  updatedAt?: number,
  accessedAt?: number,
  hash?: string
}

const isFlowModelBase = (maybeFlowModel: unknown): maybeFlowModel is FlowModelV1 | FlowModelV0 => {
  return typeof maybeFlowModel === "object" && maybeFlowModel !== null
    && "title" in maybeFlowModel && typeof maybeFlowModel.title === "string"
    && "description" in maybeFlowModel && typeof maybeFlowModel.description === "string"
    && (
      ("lessonDescriptor" in maybeFlowModel && typeof maybeFlowModel.lessonDescriptor === "string")
      || true
    )
    && (
      ("hints" in maybeFlowModel && Array.isArray(maybeFlowModel.hints) && maybeFlowModel.hints.every((hint) => typeof hint === "string"))
      || true
    )
    && (
      ("createdAt" in maybeFlowModel && typeof maybeFlowModel.createdAt === "number")
      || true
    )
    && (
      ("updatedAt" in maybeFlowModel && typeof maybeFlowModel.updatedAt === "number")
      || true
    )
    && (
      ("accessedAt" in maybeFlowModel && typeof maybeFlowModel.accessedAt === "number")
      || true
    )
    && (
      ("hash" in maybeFlowModel && typeof maybeFlowModel.hash === "string")
      || true
    )
}
export const isFlowModel = (maybeFlowModel: unknown): maybeFlowModel is FlowModel => {
  return isFlowModelBase(maybeFlowModel)
    && "id" in maybeFlowModel && isUuid(maybeFlowModel.id)
    && ("idV0" in maybeFlowModel ? isId(maybeFlowModel.idV0) : true)
    && "userId" in maybeFlowModel && isUuid(maybeFlowModel.userId)
}

// versioning
export type FlowModelV1 = FlowModel;
export const isFlowModelV1 = isFlowModel

// id became Uuid in v1
// previous Id was renamed to idV0 in v1
// userId got introduced in v1
export type FlowModelV0 = Omit<FlowModelV1, "id" | "idV0" | "userId"> & {
  id: Id
}
export const isFlowModelV0 = (maybeFlowModel: unknown): maybeFlowModel is FlowModelV0 => {
  return isFlowModelBase(maybeFlowModel)
    && "id" in maybeFlowModel && isId(maybeFlowModel.id)
}
