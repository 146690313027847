"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isExecutionContext = void 0;
const isExecutionContext = (value) => typeof value === "object" && value !== null
    && "steps" in value && typeof value.steps === "object" && value.steps !== null
    && Object.values(value.steps).every((step) => step === null || isExecutionContextStep(step))
    && "log" in value && Array.isArray(value.log) && value.log.every((entry) => typeof entry === "string");
exports.isExecutionContext = isExecutionContext;
const isExecutionContextStep = (value) => typeof value === "object" && value !== null
    && (("error" in value && typeof value.error === "string") || true);
