import {StepModelV1, StepModelV2} from "../types/StepModel";
import {DEFAULT_AI_MODEL_ID, DEFAULT_AI_MODEL_TYPE} from "@cranq-gpt-lowcode/contracts";
import {PersistedRootStateV1, PersistedRootStateV2} from "../types/PersistedRootState";

export const migrateStepModelV1ToV2 = (
  stepModelV1: StepModelV1,
): StepModelV2 => {
  return {
    ...stepModelV1,
    description: stepModelV1.description ?? "",
    aiModelId: DEFAULT_AI_MODEL_ID,
    aiModelType: DEFAULT_AI_MODEL_TYPE
  };
}
export const migrateToV2 = (state: PersistedRootStateV1): PersistedRootStateV2 => {
  return ({
    ...state,
    steps: Object.fromEntries(
      Object.entries(state.steps)
        .map(([id, step]) => [
          id,
          {
            ...step,
            model: migrateStepModelV1ToV2(step.model)
          }
        ])
    )
  });
}
