import {LocalStorageKeys} from "./constants/LocalStorageKeys";

export const getOrCreateValueFromLocalStorage = (key: LocalStorageKeys, creator: () => string) => {
  const value = localStorage.getItem(key);
  if (value) {
    return value;
  }
  const newValue = creator();
  localStorage.setItem(key, newValue);
  return newValue;
};
