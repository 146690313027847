"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOffsetOfTimezones = void 0;
const date_fns_tz_1 = require("date-fns-tz");
const getOffsetOfTimezones = (now, timezone, timezoneBase = "Etc/UTC") => {
    const timezoneOffsetToUtc = (0, date_fns_tz_1.getTimezoneOffset)(timezone, now) / 1000 / 60 / 60;
    const timezoneOffsetFromUtc = (0, date_fns_tz_1.getTimezoneOffset)(timezoneBase, now) / 1000 / 60 / 60;
    return timezoneOffsetToUtc - timezoneOffsetFromUtc;
};
exports.getOffsetOfTimezones = getOffsetOfTimezones;
