import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {StepModel} from "../../../types/StepModel";
import {RootState} from "../../../store";
import {GroupExportModel} from "../../../types/GroupExportModel";
import {createGroup, updateGroup} from "../../../groupsSlice";
import {batch} from "react-redux";
import {GroupModel} from "../../../types/GroupModel";
import createNewId from "../../../../utils/createNewId";
import {importStepAction} from "./importStepAction";
import {createUniqueName} from "../../../utils/createUniqueName";

const createNewGroupId = (existingGroupIds: GroupModel["id"][]) => {
  let retry = existingGroupIds.length + 1;
  while (retry > 0) {
    const newId = createNewId();
    if (!existingGroupIds.includes(newId)) {
      return newId;
    }
    retry--;
  }
  throw new Error("Failed to create new group id");
};

const createNewStepId = (existingStepIds: StepModel["id"][]) => {
  let retry = existingStepIds.length + 1;
  while (retry > 0) {
    const newId = createNewId();
    if (!existingStepIds.includes(newId)) {
      return newId;
    }
    retry--;
  }
  throw new Error("Failed to create new step id");
};


export const importGroupAction = (
  index: StepModel["index"],
  flowId: StepModel["flowId"],
  groupToImport: GroupExportModel
): ThunkAction<Promise<GroupModel["id"]>, RootState, any, AnyAction> => async (
  dispatch,
  getState
) => {
  if (groupToImport.steps.length === 0) {
    throw new Error(`Group with id ${groupToImport.group.id} does not contain any steps.`);
  }
  const state = getState();
  const existingGroupIds = Object.keys(state.groups) as GroupModel["id"][];
  const groupIdReplacement: Record<GroupModel["id"], GroupModel["id"]> = existingGroupIds.includes(groupToImport.group.id)
    ? {[groupToImport.group.id]: createNewGroupId(existingGroupIds)}
    : {};
  const existingStepIds = Object.keys(state.steps) as StepModel["id"][];
  const stepIdReplacement = groupToImport.steps.reduce(
    (replacements, step) => existingStepIds.includes(step.id)
      ? {...replacements, [step.id]: createNewStepId(existingStepIds)}
      : replacements,
    {} as Record<StepModel["id"], StepModel["id"]>);

  const groupId = groupIdReplacement[groupToImport.group.id] ?? groupToImport.group.id;
  batch(() => {
    dispatch(createGroup({id: groupId, flowId}));
    const otherGroupsInFlow = Object.values(state.groups)
      .filter((group) => group.model.flowId === flowId)
      .filter((group) => group.model.id !== groupId);
    const otherGroupTitlesInFlow = otherGroupsInFlow.map((group) => group.model.title);
    const uniqueTitle = createUniqueName(groupToImport.group.title, otherGroupTitlesInFlow);
    dispatch(updateGroup({
      model: {
        ...groupToImport.group,
        id: groupId,
        title: uniqueTitle,
        flowId
      }
    }))

    groupToImport.steps.forEach((stepModel, idx) => {
      const stepId = stepIdReplacement[stepModel.id] ?? stepModel.id;
      const stepIndexToInsertTo = index + idx;
      dispatch(importStepAction(
        stepIndexToInsertTo,
        groupId,
        flowId,
        {
          ...stepModel,
          id: stepId,
          groupId,
          flowId,
          index: stepIndexToInsertTo,
          inputs: Object.values(stepModel.inputs).reduce(
            (inputs, input) => ({
              ...inputs,
              [input.id]: {
                ...input,
                source: input.source.type === "output"
                  ? {
                    ...input.source,
                    stepId: stepIdReplacement[input.source.stepId] ?? input.source.stepId,
                  }
                  : input.source
              }
            }),
            {}),
        },
        stepId
      ))
    });
  });
  return groupId;
}
