import {forwardRef, ReactElement, ReactNode, useState} from "react";
import Header, {HeaderProps} from "../header/Header";
import {InlineIconName} from "../inline-icon/InlineIconName";
import {classNames} from "../../../utils/classNames";
import {UiColor} from "../../../utils/constants/UiColor";


const ContainerRoundingMap = {
  none: "rounded-none",
  small: "rounded",
  medium: "rounded-lg",
  large: "rounded-2xl",
}
export type ContainerProps = {
  id?: string,
  //leftSideIconButtonProps is reserved for Collapse button
  headerProps?: Omit<HeaderProps, "leftSideIconButtonProps"> & {
    leftSideIconButtonProps?: never
  },
  children: ReactNode,
  collapsed?: boolean,
  indented?: boolean,
  paddingY?: boolean,
  dropShadow?: boolean,
  rounded?: keyof typeof ContainerRoundingMap,
  backgroundTone?: string,
  borderTone?: UiColor.BorderColor,
  height?: "full",
  overflowY?: "auto" | "hidden" | "unset",
  shrinkOrGrow?: "shrink" | "no-shrink" | "grow" | "no-grow",

  //events
  onCollapseChange?: (isCollapsed: boolean) => void,
  onHeaderButtonClick?: (index: number) => void,
}
const Container = forwardRef<HTMLElement, ContainerProps>((props, ref) => {
  const {
    id,
    collapsed,
    children,
    headerProps,
    indented = !!headerProps, // no header => no default indent (to make container unobtrusive)
    paddingY = !!headerProps,
    dropShadow = false,
    rounded = "small",
    backgroundTone = "bg-transparent",
    borderTone = UiColor.BorderColor.TRANSPARENT,
    height = "auto",
    overflowY = "unset",
    shrinkOrGrow,
    onCollapseChange,
    onHeaderButtonClick
  } = props;
  const collapsible = collapsed !== undefined;

  const [isCollapsed, setIsCollapsed] = useState(collapsed || false);

  const paddingToHeaderLevelMap = {
    1: "py-6",
    2: "py-5",
    3: "py-4",
    4: "py-3",
    5: "py-2",
    6: "py-1",
  }

  const overFlowYMap: Record<Required<ContainerProps>["overflowY"], string> = {
    auto: "overflow-y-auto",
    hidden: "overflow-y-hidden",
    unset: "",
  }

  const toggleCollapse = () => {
    setIsCollapsed((previousIsCollapsed) => {
      onCollapseChange && onCollapseChange(!previousIsCollapsed);
      return !previousIsCollapsed;
    });
  };

  //FIXME: general utility type
  type OptionalElement<T> = ReactElement<T> | undefined;

  const headerIfCollapsible: OptionalElement<typeof Header> = headerProps &&
    <Header  {...{
      ...headerProps,
      leftSideIconButtonProps: {
        iconName: isCollapsed ? InlineIconName.RIGHT : InlineIconName.DOWN,
        onClick: toggleCollapse
      },
      onRightSideButtonClick: (index) => onHeaderButtonClick && onHeaderButtonClick(index)
    }} />;
  const headerIfNotCollapsible: OptionalElement<typeof Header> = headerProps &&
    <Header {...{
      ...headerProps,
      onRightSideButtonClick: (index) => onHeaderButtonClick && onHeaderButtonClick(index)
    }} />;

  type ShrinkOrGrow = Required<ContainerProps>["shrinkOrGrow"];
  const shrinkOrGrowMap: Record<ShrinkOrGrow, string> = {
    shrink: "shrink",
    "no-shrink": "shrink-0",
    grow: "grow",
    "no-grow": "grow-0",
  }
  return (
    <section
    id={id}
    ref={ref}
    className={classNames(`
      container 
      flex flex-col ${shrinkOrGrow ? shrinkOrGrowMap[shrinkOrGrow] : ""}
      ${height === "full" ? "h-full" : ""}
      ${overFlowYMap[overflowY]}
      ${backgroundTone} 
      border ${borderTone}
      ${rounded ? ContainerRoundingMap[rounded] : ""}
      ${dropShadow ? UiColor.ShadowColor.BLACK_LIGHT : ""}
    `)}>
      <div
        className={`flex flex-col gap-4 ${height === "full" ? "h-full" : ""} ${indented ? "px-6" : ""} ${paddingY ? paddingToHeaderLevelMap[headerProps?.level ?? 1] : ""}`}>
        {headerProps && (collapsible ? headerIfCollapsible : headerIfNotCollapsible)}
        {!isCollapsed && children}
      </div>
    </section>
  );
});

export default Container;
