"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateInputs = void 0;
const resolveStepInputValue_1 = require("../utils/resolveStepInputValue");
const resolveOutput_1 = require("../utils/resolveOutput");
function validateInputs(inputs, stepsToExecute, flow, env) {
    return inputs.reduce((prev, input, index) => {
        let sourceError;
        if (!input.source) {
            sourceError = {
                property: "source",
                constraints: { "sourceShouldBeSpecified": "No source specified" }
            };
        }
        else {
            switch (input.source.type) {
                case "output":
                    const { stepId, outputName } = input.source;
                    if ((0, resolveStepInputValue_1.resolveStepInputValue)(input, flow, env) === undefined &&
                        !(0, resolveOutput_1.resolveOutput)(stepsToExecute, stepId, outputName)) {
                        sourceError = {
                            property: "source",
                            constraints: { "outputRefShouldBeValid": `Referenced output value "#${input.source.stepId}.${input.source.outputName}" could not be resolved` }
                        };
                    }
                    break;
                case "env":
                    if ((0, resolveStepInputValue_1.resolveStepInputValue)(input, flow, env) === undefined) {
                        sourceError = {
                            property: "source",
                            constraints: { "envRefShouldBeValid": `Environment variable "${input.source.envVariableName}" could not be resolved` }
                        };
                    }
                    break;
                case "value":
                    if ((0, resolveStepInputValue_1.resolveStepInputValue)(input, flow, env) === undefined) {
                        sourceError = {
                            property: "source",
                            constraints: { "valueShouldBeSpecified": `Source type is "value", but no value is specified` }
                        };
                    }
                    break;
                default:
                    sourceError = {
                        property: "source",
                        constraints: { "sourceShouldBeValid": `Invalid source` }
                    };
            }
        }
        if (sourceError) {
            return [
                ...prev,
                {
                    property: `${index}`,
                    children: [sourceError]
                }
            ];
        }
        else {
            return prev;
        }
    }, []);
}
exports.validateInputs = validateInputs;
