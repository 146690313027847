import {useMinimalScreenSize} from "../../../hooks/useMinimalScreenSize";
import {ReactNode, useEffect} from "react";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import {useDialog} from "../../../common/components/dialog/hook/useDialog";
import Dialog from "../../../common/components/dialog/Dialog";

type ScreenSizeGuardProps = {
  size: {
    width: number;
    height: number;
  };
  children: ReactNode;
}
const ScreenSizeGuard = (
  {
    children,
    size
  }: ScreenSizeGuardProps
) => {
  const {dialogProps, openDialog, closeDialog} = useDialog();

  const isScreenSizeSufficient = useMinimalScreenSize(size);
  useEffect(() => {
      if (!isScreenSizeSufficient) {
        openDialog({
          title: "Screen size too small",
          content: `Please resize your screen to at least ${size.width}x${size.height} pixels to use this app.`,
          type: MESSAGE_TYPE.WARNING,
        });
      } else {
        closeDialog();
      }
    }, [closeDialog, isScreenSizeSufficient, openDialog, size]
  );

  return (
    <>
      {children}
      {dialogProps && <Dialog {...dialogProps}/>}
    </>
  )


}

export default ScreenSizeGuard;
