"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftWeekdayAndHourByOffset = void 0;
const shiftWeekdayAndHourByOffset = (weekday, hour, offset) => {
    const adjustedHour = hour + offset;
    const dayOffset = Math.floor(adjustedHour / 24);
    const validAdjustedHour = (adjustedHour - (dayOffset * 24)) % 24;
    const adjustedWeekday = weekday + dayOffset;
    const weekOffset = Math.floor(adjustedWeekday / 7);
    const validAdjustedWeekday = (adjustedWeekday - (weekOffset * 7)) % 7;
    return {
        weekday: validAdjustedWeekday,
        hour: validAdjustedHour
    };
};
exports.shiftWeekdayAndHourByOffset = shiftWeekdayAndHourByOffset;
