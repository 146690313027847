import {useCallback, useContext} from "react";
import {MESSAGE_TYPE} from "../../../helper/messageTypeColorMapper";
import {ToastProps} from "../Toast";
import {ToastContext} from "../../../../app/components/toast-context-provider/ToastContextProvider";

type ToastHookReturn = {
  toastProps: ToastProps | null,
  showToast: (props: ToastProps) => void,
  clearToast: (toastId: NonNullable<ToastProps["toastId"]>) => void
}

export const useToast = (): ToastHookReturn => {
  const toaster = useContext(ToastContext);
  const {toastProps = null, setToastProps} = toaster ?? {};

  const showToast: ToastHookReturn["showToast"] = useCallback((props) => {
    setToastProps && setToastProps({
      ...props,
      toastId: props.toastId ?? `${props.type ?? MESSAGE_TYPE.INFO}-${Date.now()}`
    });
  }, [setToastProps]);

  const clearToast: ToastHookReturn["clearToast"] = useCallback((toastId) => {
    setToastProps && setToastProps({
      toastId,
      message: "",
    });
  }, [setToastProps]);

  return {
    toastProps,
    showToast,
    clearToast
  };
};
