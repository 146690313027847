import {ReactNode} from "react";

type ClickableProps = {
  children: ReactNode;
  onClick?: () => void;
}
const Clickable = (
  {
    onClick,
    children
  }: ClickableProps
) => (
  <span
    className={"underline px-1 cursor-pointer"}
    onClick={onClick}
  >{children}</span>
)

export default Clickable;
