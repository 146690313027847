import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {UserState} from "./types/UserState";
import {UserModel} from "./types/UserModel";

const getLoggedOutUserState = (): UserState => ({validUntil: 0});
const initialState: UserState = getLoggedOutUserState();

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (_state, action: PayloadAction<{
      user: UserModel,
      validUntil: number
    }>) => {
      return {
        model: action.payload.user,
        loggedInAt: Date.now(),
        validUntil: action.payload.validUntil
      }
    },
    loginAnonymous: (_state, action: PayloadAction<{
      validUntil: number
    }>) => {
      return {
        validUntil: action.payload.validUntil
      }
    },
    logout: () => {
      return getLoggedOutUserState();
    }
  }
});

export const userSliceRawReducers = userSlice.caseReducers;

export const {
  login,
  loginAnonymous,
  logout
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user;
export const userReducer = userSlice.reducer;
