import {EnvironmentModel, EnvironmentModelV0, isEnvironmentModelV0, isEnvironmentModelV1} from "./EnvironmentModel";

export type EnvironmentState = {
  model: EnvironmentModel,
  selectedVariableIndexes?: number[],
};
export const isEnvironmentStateBase = (value: unknown): value is EnvironmentStateV1 | EnvironmentStateV0 => {
  return typeof value === "object" && value !== null
    && ("selectedVariableIndexes" in value
      ? (Array.isArray(value.selectedVariableIndexes) && value.selectedVariableIndexes.every((i: unknown) => typeof i === "number"))
      : true);
}

export const isEnvironmentState = (value: unknown): value is EnvironmentStateV1 => {
  return isEnvironmentStateBase(value)
    && "model" in value && isEnvironmentModelV1(value.model)
}


// versioning
export type EnvironmentStateV2 = EnvironmentState;
export const isEnvironmentStateV2 = isEnvironmentState;

// no change from v1 to v2
export type EnvironmentStateV1 = EnvironmentStateV2;
export const isEnvironmentStateV1 = isEnvironmentStateV2;

export type EnvironmentStateV0 = {
  model: EnvironmentModelV0,
  selectedVariableIndexes?: number[],
};
export const isEnvironmentStateV0 = (value: unknown): value is EnvironmentStateV0 => {
  return isEnvironmentStateBase(value)
    && "model" in value && isEnvironmentModelV0(value.model)
}
