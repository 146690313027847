import Layout from "../../../common/components/layout/Layout";
import {useParams} from "react-router-dom";
import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Notification from "../../../common/components/notification/Notification";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import {LessonEditor} from "./LessonEditor";
import {LessonEditorNotes} from "./LessonEditorNotes";
import {isUuid} from "../../../utils/types/Uuid";

const LessonEditorPage = () => {
  const {flowId} = useParams();
  return (
    <Layout
      sideBarContent={<Sidebar showFlowList={false}/>}
      leftPanelContent={
        isUuid(flowId)
          ? <LessonEditor flowId={flowId}/>
          : <Notification text={"Flow not found"} type={MESSAGE_TYPE.ERROR}/>
      }
      rightPanelContent={<LessonEditorNotes/>}
    />
  );
};

export default LessonEditorPage;
