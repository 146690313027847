import Container from "../../../../common/components/container/Container";
import {ChatMessage as ChatMessageProps} from "./types/ChatMessage";
import {ChatMessage} from "./ChatMessage";
import MultilineInput from "../../../../common/components/multiline-input/MultilineInput";
import Button from "../../../../common/components/button/Button";
import {KeyboardEvent, useEffect, useLayoutEffect, useRef, useState} from "react";
import {UiColor} from "../../../../utils/constants/UiColor";
import {ChatMessageActor} from "./types/ChatMessageActor";

type AiChatProps = {
  messages?: ChatMessageProps[];
  initialMessage?: ChatMessageProps["message"];
  statusMessage?: string;
  waitingForResponse: boolean;
  onMessageSend?: (message: string) => Promise<boolean>;
};

const AiChat = (
  {
    messages,
    initialMessage,
    statusMessage,
    waitingForResponse = false,
    onMessageSend
  }: AiChatProps
) => {
  const [messageToSend, setMessageToSend] = useState("");
  const isTypingInitialMessage = useRef(false);
  const setIsTypingInitialMessage = (value: boolean) => {
    isTypingInitialMessage.current = value;
  }
  const scrollableContainer = useRef<HTMLElement>(null);

  useEffect(() => {
    // visually typing in the initial message
    const userMessages = messages?.filter((message) => message.role === ChatMessageActor.User)
    if (initialMessage && userMessages?.length === 0 && !isTypingInitialMessage.current) {
      setIsTypingInitialMessage(true);
      const typeMessage = async () => {
        for (let i = 0; i < initialMessage.length; i++) {
          setMessageToSend((prev) => prev + initialMessage[i]);
          await new Promise((resolve) => setTimeout(resolve, 25));
        }
        setIsTypingInitialMessage(false);
        setMessageToSend(`${initialMessage}\n`); // trigger rerender
      }
      typeMessage().then(() => undefined)
    }
  }, [initialMessage, isTypingInitialMessage, messages])

  useEffect(() => {
    if (waitingForResponse) {
      setMessageToSend("");
    }
  }, [waitingForResponse]);

  useLayoutEffect(() => {
    if (scrollableContainer.current) {
      // scroll to the bottom
      scrollableContainer.current.scrollTop = scrollableContainer.current.scrollHeight;
    }
  }, [messages]);

  const handledSendMessage = async (message: string) => {
    const messageBeingSent = message;
    setMessageToSend("");
    const success = onMessageSend ? await onMessageSend(message) : false;
    if (!success) {
      setMessageToSend(messageBeingSent);
    }
  }

  const handledEnterKeyDown = async (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      await handledSendMessage(messageToSend);
    }
  }

  const sendMessageEnabled = messageToSend.trim() !== "" && !waitingForResponse && !isTypingInitialMessage.current;

  return (
    <Container height={"full"} overflowY={"hidden"} shrinkOrGrow={"grow"}>
      <Container shrinkOrGrow={"grow"} overflowY={"auto"} ref={scrollableContainer}>
        {statusMessage
          ? <div>{statusMessage}</div>
          : (
            <>
              {(messages ?? [])
                .filter((message) => !message.isSummary)
                .map(
                  (message, index) => (
                    <ChatMessage {...message} key={`msg-${index}`}/>
                  ))}
            </>
          )}
      </Container>
      <Container shrinkOrGrow={"no-shrink"}>
        <div className={"flex flex-col gap-4"}>
          <MultilineInput
            name={"message"}
            value={messageToSend}
            readonly={isTypingInitialMessage.current}
            placeholder={"Type your message here"}
            disabled={waitingForResponse}
            rows={1}
            autoGrowUntil={10}
            focus={!waitingForResponse}
            onValueChange={setMessageToSend}
            onEnter={handledEnterKeyDown}
          />
          <div className={"flex justify-end gap-2 items-center"}>
            <span
              className={`text-xs italic ${sendMessageEnabled ? UiColor.TextColor.GRAY : UiColor.TextColor.LIGHT_GRAY}`}>
              Shift + Enter or &gt;&gt;
            </span>
            <Button
              title={"Send"}
              disabled={!sendMessageEnabled}
              onClick={() => handledSendMessage(messageToSend)}/>
          </div>
        </div>
      </Container>
    </Container>
  )

};

export default AiChat;
