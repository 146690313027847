"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isUser = exports.isUserAnalytics = exports.isUserProfile = void 0;
const isUserProfile = (maybeUserProfile) => {
    return typeof maybeUserProfile === "object"
        && maybeUserProfile !== null
        && "id" in maybeUserProfile && typeof maybeUserProfile.id === "string"
        && "identityProvider" in maybeUserProfile && typeof maybeUserProfile.identityProvider === "string"
        && "identityProviderId" in maybeUserProfile && typeof maybeUserProfile.identityProviderId === "string"
        && "displayName" in maybeUserProfile && typeof maybeUserProfile.displayName === "string"
        && "email" in maybeUserProfile && typeof maybeUserProfile.email === "string"
        && ("firstname" in maybeUserProfile ? typeof maybeUserProfile.firstname === "string" : true)
        && ("lastname" in maybeUserProfile ? typeof maybeUserProfile.lastname === "string" : true)
        && ("avatarUrl" in maybeUserProfile ? typeof maybeUserProfile.avatarUrl === "string" : true)
        && ("disabled" in maybeUserProfile ? typeof maybeUserProfile.disabled === "boolean" : true);
};
exports.isUserProfile = isUserProfile;
const isUserAnalytics = (maybeUserAnalytics) => {
    return typeof maybeUserAnalytics === "object"
        && maybeUserAnalytics !== null
        && ("registrationDate" in maybeUserAnalytics ? maybeUserAnalytics.registrationDate instanceof Date : true)
        && ("lastRefreshTokenIssuedDate" in maybeUserAnalytics ? maybeUserAnalytics.lastRefreshTokenIssuedDate instanceof Date : true)
        && ("anonymousUserIds" in maybeUserAnalytics
            ? (Array.isArray(maybeUserAnalytics.anonymousUserIds) && maybeUserAnalytics.anonymousUserIds.every((id) => typeof id === "string"))
            : true);
};
exports.isUserAnalytics = isUserAnalytics;
const isUser = (maybeUser) => (0, exports.isUserProfile)(maybeUser)
    && (0, exports.isUserAnalytics)(maybeUser);
exports.isUser = isUser;
