import {isUiElementSelectorExpression, UiElementSelectorExpression,} from "./UiElementSelectors";

export type AdvanceOnEventOption = {
  selector?: UiElementSelectorExpression;
  event: string;
}
export const isAdvanceOnEventOption = (maybeAdvanceOn: unknown): maybeAdvanceOn is AdvanceOnEventOption => {
  return typeof maybeAdvanceOn === "object" && maybeAdvanceOn !== null
    && ("selector" in maybeAdvanceOn ? isUiElementSelectorExpression(maybeAdvanceOn.selector) : true)
    && "event" in maybeAdvanceOn && typeof maybeAdvanceOn.event === "string"
}

export type AdvanceOnValueOption = {
  selector?: UiElementSelectorExpression;
  property?: never;
  value: string;

}
export const isAdvanceOnValueOption = (maybeAdvanceOn: unknown): maybeAdvanceOn is AdvanceOnValueOption => {
  return typeof maybeAdvanceOn === "object" && maybeAdvanceOn !== null
    && ("selector" in maybeAdvanceOn ? isUiElementSelectorExpression(maybeAdvanceOn.selector) : true)
    && "value" in maybeAdvanceOn && typeof maybeAdvanceOn.value === "string"
}

export type AdvanceOnPropertyChangeOption = {
  selector?: UiElementSelectorExpression;
  property: string;
  value?: never;
}
export const isAdvanceOnPropertyChangeOption = (maybeAdvanceOn: unknown): maybeAdvanceOn is AdvanceOnPropertyChangeOption => {
  return typeof maybeAdvanceOn === "object" && maybeAdvanceOn !== null
    && ("selector" in maybeAdvanceOn ? isUiElementSelectorExpression(maybeAdvanceOn.selector) : true)
    && "property" in maybeAdvanceOn && typeof maybeAdvanceOn.property === "string"
    && !("value" in maybeAdvanceOn)
}

export type AdvanceOnPropertyValueOption = {
  selector?: UiElementSelectorExpression;
  property: string;
  value: string;
}
export const isAdvanceOnPropertyValueOption = (maybeAdvanceOn: unknown): maybeAdvanceOn is AdvanceOnPropertyValueOption => {
  return typeof maybeAdvanceOn === "object" && maybeAdvanceOn !== null
    && ("selector" in maybeAdvanceOn ? isUiElementSelectorExpression(maybeAdvanceOn.selector) : true)
    && "property" in maybeAdvanceOn && typeof maybeAdvanceOn.property === "string"
    && "value" in maybeAdvanceOn && typeof maybeAdvanceOn.value === "string"
}

export type AdvanceOnOption =
  AdvanceOnEventOption
  | AdvanceOnValueOption
  | AdvanceOnPropertyChangeOption
  | AdvanceOnPropertyValueOption;
const isAdvanceOnOption = (maybeAdvanceOn: unknown): maybeAdvanceOn is AdvanceOnOption => {
  return isAdvanceOnEventOption(maybeAdvanceOn)
    || isAdvanceOnValueOption(maybeAdvanceOn)
    || isAdvanceOnPropertyChangeOption(maybeAdvanceOn)
    || isAdvanceOnPropertyValueOption(maybeAdvanceOn)
}

export type LessonsStep = {
  title?: string;
  text: string;
  element: UiElementSelectorExpression;
  advanceOn?: AdvanceOnOption;
  allowNext?: boolean;
  allowBack?: boolean;
}

export const isLessonStep = (maybeLessonStep: unknown): maybeLessonStep is LessonsStep => {
  return typeof maybeLessonStep === "object" && maybeLessonStep !== null
    && ("title" in maybeLessonStep ? typeof maybeLessonStep.title === "string" : true)
    && ("text" in maybeLessonStep && typeof maybeLessonStep.text === "string")
    && "element" in maybeLessonStep && isUiElementSelectorExpression(maybeLessonStep.element)
    && ("advanceOn" in maybeLessonStep ? isAdvanceOnOption(maybeLessonStep.advanceOn) : true)
    && ("allowNext" in maybeLessonStep ? typeof maybeLessonStep.allowNext === "boolean" : true)
    && ("allowBack" in maybeLessonStep ? typeof maybeLessonStep.allowBack === "boolean" : true)
}
