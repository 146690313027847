import {FeatureFlags, isFeatureFlagSet} from "../../utils/isFeatureFlagSet";
import React from "react";
import Container from "../../../common/components/container/Container";
import {UiColor} from "../../../utils/constants/UiColor";

const getFeatureFlagStates = (): Record<FeatureFlags, boolean> => {
  return Object.values(FeatureFlags)
    .reduce((acc, flag) => ({...acc, [flag]: isFeatureFlagSet(flag)}), {} as Record<FeatureFlags, boolean>);
}
export const ManageFeatureFlags = () => {
  const [
    featureFlagStates,
    setFeatureFlagStates
  ] = React.useState<Record<FeatureFlags, boolean>>(getFeatureFlagStates());

  const toggleFeatureFlag = (flag: FeatureFlags) => {
    if (isFeatureFlagSet(flag)) {
      localStorage.removeItem(flag);
    } else {
      localStorage.setItem(flag, "true");
    }
    setFeatureFlagStates(getFeatureFlagStates());
  }

  return (
    <Container headerProps={{title: "Feature flags"}}>
      <ul className={"flex flex-col gap-2"}>
        {Object.values(FeatureFlags).map((flag) => (
          <li key={flag}>
            <label>
              <input type={"checkbox"} checked={featureFlagStates[flag]}
                     onClick={() => toggleFeatureFlag(flag)}/>
              <span
                className={`${featureFlagStates[flag] ? UiColor.TextColor.GREEN : UiColor.TextColor.RED} px-2`}>{flag}</span>
            </label>
          </li>
        ))}
      </ul>
    </Container>
  );
}
