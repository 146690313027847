import {ReactNode} from "react";
import {UiColor} from "../../../utils/constants/UiColor";
import Container from "../container/Container";
import {classNames} from "../../../utils/classNames";

type TabbedContainerItemBase = {
  title: string,
  children: ReactNode,
}

// meant to attire user's attention
type PromotedTabbedContainerItem = (TabbedContainerItemBase & { promoted?: true })
// meant no to attract user's attention though still visible
type DemotedTabbedContainerItem = (TabbedContainerItemBase & { demoted?: true })
export type TabbedContainerItem = TabbedContainerItemBase | PromotedTabbedContainerItem | DemotedTabbedContainerItem;

export type TabbedContainerProps = {
  tabs: TabbedContainerItem[],
  activeTabIndex?: number
  // events
  onTabChange?: (tabIndex: number) => void
}
const TabbedContainer = (
  {
    tabs,
    activeTabIndex = 0,
    onTabChange
  }: TabbedContainerProps
) => {

  const tabButtons = tabs.map((tab, index) => (
    <button
      key={`tab-button-${index}`}
      className={classNames(`
        relative
        px-1 py-2 
        border-b-2 ${index === activeTabIndex ? UiColor.BorderColor.GOLD : UiColor.BorderColor.TRANSPARENT}
        ${"demoted" in tab && tab.demoted ? UiColor.TextColor.LIGHT_GRAY : UiColor.TextColor.BLACK}
        ${"promoted" in tab && tab.promoted ? "animate-bounce" : ""}
      `)}
      onClick={() => onTabChange && onTabChange(index)}>
      {/*"promoted" in tab && tab.promoted ? <ButtonPinger/> : null*/ ""}
      {tab.title}
    </button>
  ));

  const activeTab = tabs[activeTabIndex]?.children ?? null;
  return (
    <section className={"flex flex-col gap-4 min-h-0"}>
      <div className={`flex flex-row gap-4 px-5 pt-4 border-b ${UiColor.BorderColor.GRAY}`}>
        {tabButtons}
      </div>
      <Container indented={true}>
        {activeTab}
      </Container>
    </section>
  )
};

export default TabbedContainer;
