import {StepState} from "../../../types/StepState";
import {StepModel} from "../../../types/StepModel";

export const isStepMarkable = (
  steps: StepState[],
  markedStepIds: StepModel["id"][],
  stepId: StepModel["id"]
) => {
  if (!steps.some((step) => markedStepIds.includes(step.model.id))) {
    return true; // no marked steps
  }
  if (markedStepIds.includes(stepId)) {
    return true; // step is marked
  }

  const stepIndex = steps.findIndex(step => step.model.id === stepId);
  const step = steps[stepIndex];
  if (!step || stepIndex < 0) {
    return false; // step not found
  }

  const previousStep = steps[stepIndex - 1];
  const nextStep = steps[stepIndex + 1];
  return (
    (previousStep && markedStepIds.includes(previousStep.model.id) && previousStep.model.groupId === step.model.groupId)
    || (nextStep && markedStepIds.includes(nextStep.model.id) && nextStep.model.groupId === step.model.groupId)
  );
}
