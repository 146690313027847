import {useAppDispatch} from "../../hooks";
import {logout} from "../../userSlice";
import {useDialog} from "../../../common/components/dialog/hook/useDialog";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import React, {useContext, useEffect} from "react";
import Dialog from "../../../common/components/dialog/Dialog";
import {LoggedInUserState} from "../../types/UserState";
import Button, {ButtonType} from "../../../common/components/button/Button";
import {ApiContext, getApiBaseUrl} from "../../utils/getApiBaseUrl";
import {useToast} from "../../../common/components/toast/hook/useToast";
import ReactGA from "react-ga4";
import {AuthContext} from "../auth-context-provider/AuthContextProvider";
import {openLoginDialog} from "../login/utils/openLoginDialog";
import {UserAccount} from "./UserAccount";
import {UserAvatar} from "./UserAvatar";
import {useGetUserSubscriptionsQuery} from "../../subscriptionsApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {SubscriptionWithTimestamps} from "../../types/SubscriptionWithTimestamps";


const openLogoutDialog = (
  user: LoggedInUserState,
  subscriptions: SubscriptionWithTimestamps[] | undefined,
  openDialog: ReturnType<typeof useDialog>["openDialog"],
  closeDialog: ReturnType<typeof useDialog>["closeDialog"],
  handleLogout: () => void
) => {
  openDialog({
    title: "Your Account",
    content: <UserAccount user={user} subscriptions={subscriptions}/>,
    type: MESSAGE_TYPE.CUSTOM,
    controlButtonList: [
      {
        title: "Logout",
        onClick: handleLogout
      },
      {
        title: "Close",
        onClick: () => {
          closeDialog();
        }
      }
    ]
  })
};

type UserStatusProps = {
  forceLogin?: boolean
}

const UserStatus = (
  {
    forceLogin = false
  }: UserStatusProps
) => {
  const dispatch = useAppDispatch();
  const {user, anonymousUserId} = useContext(AuthContext);
  const {data: subscriptions} = useGetUserSubscriptionsQuery(
    user.model?.id ?? skipToken,
    {pollingInterval: 5 * 60 * 1000, refetchOnMountOrArgChange: 60}
  );
  const {dialogProps, openDialog, closeDialog} = useDialog()
  const {showToast} = useToast();

  useEffect(() => {
    if (forceLogin && !user.loggedInAt) {
      openLoginDialog(anonymousUserId, openDialog, closeDialog, showToast);
    }
  }, [anonymousUserId, closeDialog, forceLogin, openDialog, showToast, user.loggedInAt]);

  const handleLogout = () => {
    const userId = user.model?.id;
    if (!userId) {
      return
    }
    const logoutUrl = new URL(getApiBaseUrl(ApiContext.AUTH) + "/logout");
    logoutUrl.searchParams.append("userId", userId);
    fetch(logoutUrl, {
      method: "POST",
      credentials: "include"
    })
      .then((response) => {
        if (response.ok) {
          dispatch(logout());
          ReactGA.event({
            category: "auth",
            action: "LOGOUT",
            label: `User: ${userId}`,
            nonInteraction: true,
            transport: "xhr"
          });
          return;
        }
        throw new Error("logout failed");
      })
      .catch(() => {
        showToast({
          message: "Logout failed",
          type: MESSAGE_TYPE.ERROR,
        });
      })
      .finally(() => {
        closeDialog();
      })
  }

  return (
    <>
      {user.loggedInAt !== undefined
        ? (
          <div className={"flex gap-2 items-center cursor-pointer"}
               onClick={() => openLogoutDialog(user, subscriptions, openDialog, closeDialog, handleLogout)}>
            <UserAvatar user={user} subscriptions={subscriptions}/>
            <span className={"truncate text-cr-gray"}>{user.model.displayName}</span>
          </div>
        )
        : <Button
          title={"Login"}
          type={ButtonType.PRIMARY}
          onClick={() => openLoginDialog(anonymousUserId, openDialog, closeDialog, showToast)}/>
      }
      {dialogProps && <Dialog {...dialogProps}/>}
    </>
  );
}

export default UserStatus;
