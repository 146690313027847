import React, {useEffect, useMemo, useRef, useState} from "react";
import "ace-builds"; // must be before react-ace
import AceEditor from "react-ace";
import ReactAce from "react-ace";

import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-typescript";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-handlebars";
import "ace-builds/src-noconflict/theme-dawn";
import "ace-builds/src-noconflict/theme-idle_fingers";
import "ace-builds/src-noconflict/ext-language_tools";

import Container from "../container/Container";
import {UiColor} from "../../../utils/constants/UiColor";
import {Ace, Range} from "ace-builds";

export enum EditorMode {
  JSON = "json",
  JAVASCRIPT = "javascript",
  TYPESCRIPT = "typescript",
  TEXT = "text",
  HANDLEBARS = "handlebars"
}

type EditorTheme = "light" | "dark";

const editorThemeMap: Record<EditorTheme, string> = {
  light: "dawn",
  dark: "idle_fingers" // theme's background must match Container's backgroundTone
}
const editorContainerBackgroundToneMap: Record<EditorTheme, UiColor.BackgroundColor> = {
  light: UiColor.BackgroundColor.INPUT,
  dark: UiColor.BackgroundColor.BLACK
}
const editorContainerBorderToneMap: Record<EditorTheme, UiColor.BorderColor> = {
  light: UiColor.BorderColor.GRAY,
  dark: UiColor.BorderColor.BLACK
}
type EditorProps = {
  content?: string;
  placeholder?: string;
  mode?: EditorMode;
  theme?: EditorTheme
  lines?: number
  readOnly?: boolean;
  showGutter?: boolean;
  wrapEnabled?: boolean;
  autoCompletionStrings?: string[];
  autoCompletionTriggerCharacters?: string[];
  autoCompletionMeta?: string;
  //
  onChange?: (changedContent: string) => void;
  onBlur?: (lastContent: string) => void;
}

const Editor = (props: EditorProps) => {
  const {
    content = "",
    placeholder,
    mode = EditorMode.TYPESCRIPT,
    theme = "light",
    lines,
    readOnly = true,
    showGutter = false,
    wrapEnabled = false,
    autoCompletionStrings = [],
    autoCompletionTriggerCharacters = ["{"],
    autoCompletionMeta,
    //
    onChange,
    onBlur
  } = props;
  const [lastContent, setLastContent] = useState<string>(content);

  useEffect(() => {
    setLastContent(content);
  }, [content]);

  const handleOnChange = (changedContent: string) => {
    onChange && onChange(changedContent);
    setLastContent(changedContent);
  }
  const handleOnBlur = () => {
    onBlur && onBlur(lastContent);
    // reset local state to prop value upon editing finished
    // otherwise an eventual rejected value change would not be reflected in the input
    setLastContent(content);
  }
  const completer: Ace.Completer = useMemo(() => ({
    getCompletions: function (editor, session, pos, prefix, callback) {
      const completions = autoCompletionStrings.map((word) => ({
        value: word,
        score: 1000,
        meta: autoCompletionMeta ?? "autoComplete",
        completer: completer,
      }));
      callback(
        null,
        completions
      );
    },
    triggerCharacters: autoCompletionTriggerCharacters,
    onInsert: function (editor: any, completion: any) {
      const session = editor.getSession();
      const {row, column} = editor.getCursorPosition();
      const columnBeforeInsert = column - completion.value.length - 1;
      const characterBeforeInsert = session.getTextRange(new Range(
        row,
        columnBeforeInsert,
        row,
        columnBeforeInsert + 1
      ));
      if (autoCompletionTriggerCharacters.includes(characterBeforeInsert)) {
        session.getDocument().removeInLine(row, columnBeforeInsert, columnBeforeInsert + 1);
      }
    },
  }), [autoCompletionStrings, autoCompletionMeta, autoCompletionTriggerCharacters]);

  const aceEditor = useRef<ReactAce>(null);
  // hack to force update completer
  useEffect(() => {
    if (aceEditor.current && "editor" in aceEditor.current) {
      aceEditor.current.editor.completers = [completer];
    }
  });

  return <Container
    backgroundTone={editorContainerBackgroundToneMap[theme]}
    borderTone={editorContainerBorderToneMap[theme]}
    indented={true}
    paddingY={true}
    rounded={"medium"}>
    <AceEditor
      ref={aceEditor}
      readOnly={readOnly}
      mode={mode}
      theme={editorThemeMap[theme]}
      showGutter={showGutter}
      highlightActiveLine={false}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      name="codeEditor"
      editorProps={{$blockScrolling: true}}
      placeholder={placeholder}
      value={lastContent}
      width={"100%"}
      setOptions={{
        showLineNumbers: false,
        showPrintMargin: false,
        //@ts-ignore: wrong typings
        enableLiveAutocompletion: [completer],
      }}

      wrapEnabled={wrapEnabled}
      {...lines && {height: `${lines}rem`}}
    />
  </Container>
};

export default Editor;
