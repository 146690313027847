import {StepConversationSummary} from "../types/StepConversationSummary";
import Container from "../../../../common/components/container/Container";
import MultilineInput from "../../../../common/components/multiline-input/MultilineInput";

const emptySummary: StepConversationSummary = {
  title: "",
  description: "",
  inputs: [],
  outputs: []
};


type StepChatSummaryProps = {
  summary: StepConversationSummary | undefined;
}
export const StepChatSummary = (
  {
    summary = emptySummary,
  }: StepChatSummaryProps
) => {
  return (
    <Container height={"full"} overflowY={"auto"}>
      <label>Step description</label>
      <MultilineInput name={"description"}
                      value={summary.description.trim()}
                      readonly={true}
                      placeholder={"Generating description..."}
                      rows={3}
                      autoGrowUntil={10}
      />
      <label>Inputs</label>
      <MultilineInput name={"inputs"}
                      value={summary.inputs.map((input) => `${input.name}: ${input.type}`).join("\n")}
                      readonly={true}
                      placeholder={"Generating inputs..."}
                      rows={3}
                      autoGrowUntil={10}
      />
      <label>Outputs</label>
      <MultilineInput name={"outputs"}
                      value={summary.outputs.map((output) => `${output.name}: ${output.type}`).join("\n")}
                      readonly={true}
                      placeholder={"Generating outputs..."}
                      rows={3}
                      autoGrowUntil={10}
      />
    </Container>

  )
}
