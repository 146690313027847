import {ChangeEvent, KeyboardEvent, useEffect, useLayoutEffect, useRef, useState} from "react";
import {UiColor} from "../../../utils/constants/UiColor";
import {classNames} from "../../../utils/classNames";

const shouldGrow = (element: HTMLTextAreaElement) => element.scrollHeight > element.clientHeight;
const autoResize = (element: HTMLTextAreaElement, minRows: number, maxRows: number) => {
  while (element.rows > Math.max(minRows, 1) && !shouldGrow(element)) {
    element.rows--;
  }
  while (element.rows < maxRows && shouldGrow(element)) {
    element.rows++;
  }
}

type MultilineInputProps = {
  name: string,
  id?: string,
  value?: string,
  placeholder?: string,
  backgroundTone?: string,
  textTone?: string,
  textSize?: string,
  borderTone?: string,
  rows?: number,
  autoGrowUntil?: number,
  readonly?: boolean,
  disabled?: boolean,
  focus?: boolean,
  onValueChange?: (value: string) => void,
  onBlur?: (lastValue: string) => void
  onEnter?: (event: KeyboardEvent<HTMLTextAreaElement>) => void
}

const MultilineInput = (
  {
    name,
    id,
    value = "",
    placeholder = "What does the flow do?",
    rows = 3,
    autoGrowUntil,
    backgroundTone = UiColor.BackgroundColor.INPUT,
    textTone = UiColor.TextColor.BLACK,
    textSize = "text-sm",
    borderTone = UiColor.BorderColor.INPUT_GRAY,
    readonly = false,
    disabled = false,
    focus = false,
    onValueChange,
    onBlur,
    onEnter,
  }: MultilineInputProps) => {
  const [lastValue, setLastValue] = useState<string>(value);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    // update state upon prop change
    setLastValue(value);
  }, [value]);

  useLayoutEffect(() => {
    const element = textareaRef.current;
    if (element && autoGrowUntil) {
      autoResize(element, rows, autoGrowUntil);
    }
  }, [autoGrowUntil, rows, lastValue]);

  useLayoutEffect(() => {
    const element = textareaRef.current;
    if (element && focus) {
      element.focus();
    }
  }, [focus]);

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const element = event.target;
    onValueChange && onValueChange(element.value);
    setLastValue(element.value);
  }
  const handleOnBlur = () => {
    onBlur && onBlur(lastValue);
    // reset local state to prop value upon editing finished
    // otherwise an eventual rejected value change would not be reflected in the input
    setLastValue(value);
  }
  const handleOnKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      onEnter && onEnter(event);
    }
  }

  return <textarea
    name={name}
    id={id}
    ref={textareaRef}
    className={classNames(`
      w-full p-2 resize-none
      ${disabled ? UiColor.BackgroundColor.DISABLED : backgroundTone}
      ${disabled ? UiColor.TextColor.DISABLED : textTone}
      ${textSize}
      ${borderTone ? `border ${borderTone}` : ""} border-solid rounded
      focus:outline-none
      ring-transparent
      flex-shrink-0
    `)}
    rows={rows}
    value={lastValue}
    placeholder={placeholder}
    disabled={disabled}
    readOnly={readonly}
    onChange={handleOnChange}
    onBlur={handleOnBlur}
    onKeyDown={handleOnKeyDown}
  />
};

export default MultilineInput;
