// utils
import {PersistedState} from "redux-persist/es/types";

export const isObject = (value: unknown): value is Record<string, unknown> =>
  typeof value === "object" && value !== null;
type Defined<T> = Exclude<T, undefined>;
const isDefined = <T>(value: T | undefined): value is T => value !== undefined;
const isPersistedState = (state: unknown): state is Defined<PersistedState> =>
  state === undefined || (
    isObject(state)
    && "_persist" in state && isObject(state._persist)
    && "version" in state._persist
  )
// PersistedState can be undefined, but from migration POV we only care about defined PersistedState
export const isDefinedPersistedState = (state: unknown): state is Defined<PersistedState> =>
  isDefined(state) && isPersistedState(state);
