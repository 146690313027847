"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapGeneratorTaskToQueryResult = void 0;
const GeneratorError_1 = require("../domain/GeneratorError");
function mapGeneratorTaskToQueryResult(task) {
    var _a, _b, _c, _d;
    return {
        state: task.status,
        result: (_a = task.result) !== null && _a !== void 0 ? _a : undefined,
        error: task.error ? {
            reason: (_b = task.error.reason) !== null && _b !== void 0 ? _b : GeneratorError_1.GeneratorErrorReason.OTHER,
            message: (_c = task.error.message) !== null && _c !== void 0 ? _c : "Generator function failed",
            canRetry: (_d = task.error.can_retry) !== null && _d !== void 0 ? _d : false
        } : undefined
    };
}
exports.mapGeneratorTaskToQueryResult = mapGeneratorTaskToQueryResult;
