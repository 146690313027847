import {isStepModelPriorV0, isStepModelV0, isStepModelV1, isStepModelV2, StepModel} from "../../types/StepModel";
import {utfSafeBase64Decode} from "./utfSafeBase64Decode";
import {unWrapText} from "./unWrapText";
import {migrateStepModelPriorV0ToV0} from "../../store-migrations/migrateToV0";
import {migrateStepModelV0ToV1} from "../../store-migrations/migrateToV1";
import {NilUuid} from "../../../utils/createNewUuid";
import {migrateStepModelV1ToV2} from "../../store-migrations/migrateToV2";

// !!! This function must support all previous versions of the step model !!!
export const decodeStep = (stepExport: string): StepModel | undefined => {
  const data = stepExport.split(/^ *-+ *BEGIN *-+ *$/m)[1]?.split(/^ *-+ *END *-+ *$/m)[0]?.trim();
  const maybeJson = utfSafeBase64Decode(unWrapText(data ?? ""))
  try {
    const maybeStep = JSON.parse(maybeJson);
    if (isStepModelV2(maybeStep)) {
      return maybeStep;
    }
    if (isStepModelV1(maybeStep)) {
      return migrateStepModelV1ToV2(maybeStep);
    }
    if (isStepModelV0(maybeStep)) {
      return migrateStepModelV1ToV2(
        migrateStepModelV0ToV1(maybeStep, NilUuid)
      );
    }
    if (isStepModelPriorV0(maybeStep)) {
      return migrateStepModelV1ToV2(
        migrateStepModelV0ToV1(
          migrateStepModelPriorV0ToV0(maybeStep),
          NilUuid // real flowId is not exported nor imported, is replaced by a dummy id during the process
        )
      );
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
}
