import {QueryResponse} from "@cranq-gpt-lowcode/contracts";
import {useRef} from "react";

type PollingStartTimes = Record<QueryResponse["queryId"], number>
type Reducer<T> = (prev: T) => T
export const usePollingTimeout = (maxPollingTime: number = 120000) => {
  const pollingStartTimes = useRef<PollingStartTimes>({});
  const setPollingStartTimes = (reducer: Reducer<PollingStartTimes>) => pollingStartTimes.current = reducer(pollingStartTimes.current)
  const addPollingStartTime = (queryId: QueryResponse["queryId"]) => {
    const startTime = Date.now();
    setPollingStartTimes((prev) => ({
      ...prev,
      [queryId]: startTime
    }));
    return startTime;
  };
  const deletePollingStartTime = (queryId: QueryResponse["queryId"]) => setPollingStartTimes((prev) => Object.fromEntries(Object.entries(prev).filter(([key]) => key !== queryId)));
  const getPollingStartTime = (queryId: QueryResponse["queryId"]) => pollingStartTimes.current[queryId];

  // public interface
  const hasTimedOut = (queryId: QueryResponse["queryId"]) => {
    const startTime = getPollingStartTime(queryId) ?? addPollingStartTime(queryId);
    return Date.now() - startTime > maxPollingTime;
  }
  const register = (queryId: QueryResponse["queryId"]) => getPollingStartTime(queryId) ?? addPollingStartTime(queryId);
  const unregister = deletePollingStartTime;

  return {
    register,
    unregister,
    hasTimedOut,
  };
}
