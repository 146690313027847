import {ThunkAction} from "@reduxjs/toolkit";
import {FlowModel} from "../../../types/FlowModel";
import {batch} from "react-redux";
import {deleteStepsByFlowId} from "../../../stepsSlice";
import {RootState} from "../../../store";
import {deleteFlow} from "../../../flowsSlice";
import {deleteGroupsByFlowId} from "../../../groupsSlice";
import {deleteEnvironmentsByFlowId} from "../../../environmentsSlice";

export const deleteFlowAction = (
  flowId: FlowModel["id"]
): ThunkAction<Error | undefined, RootState, any, any> => (
  dispatch
) => {
  try {
    batch(() => {
      dispatch(deleteStepsByFlowId(flowId));
      dispatch(deleteGroupsByFlowId(flowId));
      dispatch(deleteEnvironmentsByFlowId(flowId));
      dispatch(deleteFlow(flowId));
    });
    return undefined;
  } catch (e: unknown) {
    return (e instanceof Error) ? e : new Error(`Unknown error while deleting flow: ${e}`);
  }
}
