import {StepStatus} from "../../../types/StepStatus";
import {ReactNode} from "react";
import StatusTag from "../../../../common/components/status-tag/StatusTag";
import {UiColor} from "../../../../utils/constants/UiColor";

export const createStatusTag = (stepState?: keyof typeof StepStatus): ReactNode => {
  switch (stepState) {
    case "run":
      return <StatusTag title={"Success"} color={UiColor.TextColor.GREEN}/>
    case "failed":
      return <StatusTag title={"Failed"} color={UiColor.TextColor.RED}/>
    default:
      return <StatusTag title={"Draft"} color={UiColor.TextColor.GRAY}/>
  }
}
