import objectHashFunc from "object-hash";

export const FAILED_HASH = "FAILED_HASH";

export const objectHash = (
  obj: unknown,
  options?: objectHashFunc.NormalOption
): string => {
  if (obj === undefined) {
    return "";
  }
  try {
    return objectHashFunc(obj, options);
  } catch (e) {
    console.error(`Failed to calculate hash for object`, obj, e);
    return FAILED_HASH;
  }
}
