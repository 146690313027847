import Input, {InputProps} from "../input/Input";
import React, {useLayoutEffect} from "react";
import IconButton, {IconButtonProps} from "../icon-button/IconButton";
import {classNames} from "../../../utils/classNames";
import {UiColor} from "../../../utils/constants/UiColor";
import {InlineIconName} from "../inline-icon/InlineIconName";

type InputWithActionButtonsProps = InputProps & {
  editActionTooltip?: string,
  inspectActionTooltip?: string,
  linkActionTooltip?: string,
  unlinkActionTooltip?: string,
  copyActionTooltip?: string,
  //
  onEditAction?: () => void,
  onInspectAction?: () => void,
  onLinkAction?: () => void,
  onUnlinkAction?: () => void,
  onCopyAction?: () => void,
}
export const InputWithActionButtons = (props: InputWithActionButtonsProps) => {
  const {
    editActionTooltip = "Open value editor",
    inspectActionTooltip = "Open value viewer",
    linkActionTooltip = "Link to output or flow parameter",
    unlinkActionTooltip = "Unlink",
    copyActionTooltip = "Copy",
    //
    onEditAction,
    onInspectAction,
    onLinkAction,
    onUnlinkAction,
    onCopyAction,
    value,
    onValueChange,
    ...inputProps
  } = props;

  const buttonsDivRef = React.useRef<HTMLDivElement>(null);
  const componentElementRef = React.useRef<HTMLDivElement>(null);
  const [showActionButtons, setShowActionButtons] = React.useState<boolean>(false);
  // we need to update the input value upon each change to be able to adapt action icons
  const [inputValue, setInputValue] = React.useState<string | undefined>(value);

  useLayoutEffect(() => {
    // update state upon prop change
    setInputValue(value);
  }, [value]);

  const actionButtonsProps: (IconButtonProps | undefined)[] = [
    onEditAction && {
      iconName: InlineIconName.EDIT,
      tooltip: editActionTooltip,
      onClick: onEditAction
    },
    onInspectAction && {
      iconName: InlineIconName.INSPECT,
      tooltip: inspectActionTooltip,
      onClick: onInspectAction
    },
    onLinkAction && {
      iconName: InlineIconName.LINK,
      tooltip: linkActionTooltip,
      onClick: onLinkAction
    },
    onUnlinkAction && {
      iconName: InlineIconName.UNLINK,
      tooltip: unlinkActionTooltip,
      onClick: onUnlinkAction
    },
    onCopyAction && inputValue !== undefined && inputValue !== ""
      ? {
        iconName: InlineIconName.COPY,
        tooltip: copyActionTooltip,
        onClick: onCopyAction
      }
      : undefined
  ]

  const actionButtons = actionButtonsProps
    .filter((props): props is IconButtonProps => props !== undefined)
    .map((props: IconButtonProps) => (
      <IconButton
        {...props}
        key={props.iconName}
        size={"small"}
        disabled={inputProps.disabled}
        disableTabindex={true}
        iconTone={UiColor.TextColor.LIGHT_GRAY}
      />
    ));

  const sizeClassMapping: Record<Required<InputProps>["size"], string> = {
    "small": "w-fit",
    "medium": "w-fit",
    "large": "w-fit",
    "flexible": "flex",
    "full": "w-full"
  };
  const sizeClass = sizeClassMapping[inputProps.size ?? "flexible"];

  useLayoutEffect(() => {
    const showActionButtons = () => {
      setShowActionButtons(true)
    }
    const hideActionButtons = () => {
      setShowActionButtons(false)
    }

    if (componentElementRef.current) {
      const targetElement = componentElementRef.current;
      targetElement.addEventListener("mouseenter", showActionButtons);
      targetElement.addEventListener("mouseleave", hideActionButtons);

      return () => {
        targetElement.removeEventListener("mouseenter", showActionButtons);
        targetElement.removeEventListener("mouseleave", hideActionButtons);
      }
    }
  }, [actionButtons]);

  return <div ref={componentElementRef} className={`relative ${sizeClass} group`}>
    <Input {...{
      ...inputProps,
      value: inputValue,
      onValueChange: (newValue) => {
        setInputValue(newValue);
        onValueChange && onValueChange(newValue)
      },
      // paddingRight: showActionButtons ? actionButtonGroupWidth : undefined
    }} />
    <div
      ref={buttonsDivRef}
      className={classNames(`
        absolute right-1 top-1
        flex flex-row gap-0
        ${showActionButtons ? "visible" : "invisible"}
      `)}>
      <div className={`pl-2 bg-gradient-to-l from-cr-gray5 ${/* just gradient fading */""}`}></div>
      <div className={`p-1 grow flex flex-row items-center justify-end ${UiColor.BackgroundColor.INPUT}`}>
        {actionButtons}
      </div>
    </div>
  </div>
};
