import {RootState, store} from "../store";
import {detailedDiff} from "deep-object-diff";
import {unique} from "../../utils/unique";
import {isId} from "../../utils/types/Id";
import {updateFlowHashAction} from "../actions/updateFlowHashAction";
import {isDefined} from "@cranq-gpt-lowcode/contracts";

export const updateFlowHashUponEnvironmentsChanges = (
  state: RootState["environments"],
  previousState: RootState["environments"]
) => {
  const diff = detailedDiff(previousState, state)
  // we don't care about added environments since they surely have no variables linked to any input
  // we don't care about deleted ones since upon deletion linked inputs are unlinked, and we'll capture that change
  const {updated} = diff;
  const flowsToUpdate = unique(
    Object.keys(updated)
      .filter(isId)
      .map((environmentId) => state[environmentId])
      .filter(isDefined)
      .map((environment) => environment?.model.flowId)
  );
  flowsToUpdate.forEach((flowId) => store.dispatch(updateFlowHashAction(flowId)))
};
