import {StepModel} from "../../types/StepModel";
import {GroupModel} from "../../types/GroupModel";
import {sanitizePortsBeforeSharing} from "./sanitizePortsBeforeSharing";
import {wrapText} from "./wrapText";
import {utfSafeBase64Encode} from "./utfSafeBase64Encode";
import {NilUuid} from "../../../utils/createNewUuid";
import {GroupExportModel} from "../../types/GroupExportModel";

export const encodeGroup = (
  group: GroupModel,
  steps: StepModel[]
): string => {

  const sanitizedSteps: StepModel[] = steps.map(
    (step, idx) => ({
      ...sanitizePortsBeforeSharing(
        step,
        steps.map((step) => step.id
        )),
      index: idx + 1, // reset index to start from 1
      flowId: NilUuid, // required by data model but irrelevant for step sharing
    }));
  const sanitizedGroup: GroupModel = {
    ...group,
    flowId: NilUuid
  };
  const exportedGroup: GroupExportModel = {
    group: sanitizedGroup,
    steps: sanitizedSteps
  }
  const exportedGroupAsString = utfSafeBase64Encode(JSON.stringify(exportedGroup));

  return `
--------------------------------------------------------------------------------
 subject: cranq.ai group export
 version: 1.0
   title: ${group.title}
------------------------------------ BEGIN -------------------------------------
${wrapText(exportedGroupAsString, 80)}
------------------------------------- END --------------------------------------
`;
}
