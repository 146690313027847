import {ReactNode} from "react";
import {MESSAGE_TYPE, messageTypeColorMapper} from "../../helper/messageTypeColorMapper";
import {messageTypeIconMapper} from "../../helper/messageTypeIconMapper";
import Button, {ButtonProps} from "../button/Button";
import Container from "../container/Container";
import InlineIcon from "../inline-icon/InlineIcon";
import Modal from "../modal/Modal";
import {InlineIconName} from "../inline-icon/InlineIconName";
import {UiColor} from "../../../utils/constants/UiColor";

export type DialogProps = {
  content: ReactNode | string,
  type: MESSAGE_TYPE,
  title?: string,
  iconName?: InlineIconName,
  controlButtonList?: ButtonProps[],
  width?: "full" | "fit",
  height?: "full" | "fit",
  onCloseClick?: () => void
}
const Dialog = (
  {
    content,
    iconName,
    title = "",
    controlButtonList,
    type,
    width = "fit",
    height = "fit",
    onCloseClick
  }: DialogProps) => {

  const headerProps = title || onCloseClick ? {
    title,
    backgroundTone: `${messageTypeColorMapper(type).backgroundTone}/10`,
    rightSideButtonListProps: [
      ...(onCloseClick ? [{
        iconName: InlineIconName.CLOSE,
        onClick: () => onCloseClick && onCloseClick(),
        fillTone: "none",
        strokeTone: "cr-dark"
      }] : [])
    ]
  } : undefined;

  const Buttons = controlButtonList
    && controlButtonList.map((buttonProps, index) => {
      return <Button key={index} {...buttonProps} />;
    });

  const iconNameToUse = iconName ?? messageTypeIconMapper(type);
  const icon = iconNameToUse &&
    <InlineIcon name={iconNameToUse} primaryTone={messageTypeColorMapper(type).textTone}/>
  const widthClass = width === "full" ? "w-full px-4" : "w-fit max-w-screen-md";
  const heightClass = height === "full" ? "h-full py-4" : "h-fit max-h-screen-md";

  const dialog: ReactNode = (
    <div className={`${widthClass} ${heightClass} max-w justify-self-center self-center`}>
      <Container headerProps={headerProps} backgroundTone={UiColor.BackgroundColor.OFF_WHITE}
                 height={height ? "full" : undefined}>
        <div className="flex flex-row gap-4 grow w-full">
          {icon && <div className="flex-grow-0 flex align-middle min-w-fit">{icon}</div>}
          {typeof content === "string"
            ? <div className=" flex items-center break-words">{content}</div>
            : content}
        </div>
        {controlButtonList &&
          <div className="flex flex-row no-shrink gap-1 justify-end">{Buttons}</div>}
      </Container>
    </div>
  );

  return (
    <Modal>
      {dialog}
    </Modal>
  );
};

export default Dialog;
