import {LocalStorageKeys} from "../../utils/constants/LocalStorageKeys";
import {getOrCreateValueFromLocalStorage} from "../../utils/getOrCreateValueFromLocalStorage";
import createNewUuid from "../../utils/createNewUuid";

const storage = window.localStorage;
const key = LocalStorageKeys.ANONYMOUS_USER_ID
const anonymousUserId = getOrCreateValueFromLocalStorage(key, () => createNewUuid())

const restoreAnonymousUserId = (event: StorageEvent) => {
  if (event.storageArea === storage) {
    storage.setItem(key, anonymousUserId);
    console.debug("restored anonymousUserId after storage event");
  } else {
    console.debug("other storage event", event)
  }
};
export const ensureAnonymousUserId = () => {
  window.removeEventListener("storage", restoreAnonymousUserId);
  window.addEventListener("storage", restoreAnonymousUserId);
  return anonymousUserId;
};
