import {
  Branch,
  Branches,
  IntermediateBranch,
  isIntermediateBranch
} from "../../common/components/tree-selector/TreeSelector";
import {Id} from "../../utils/types/Id";
import {EnvironmentState} from "../types/EnvironmentState";
import {StepState} from "../types/StepState";
import {Variable} from "../types/Variable";
import {safeJsonStringify} from "./safeJsonStringify";
import {GroupState} from "../types/GroupState";
import {GlobalParametersState} from "../globalParametersSlice";
import {
  createGlobalParameterReferenceResolver,
  GlobalParameterReferencePrefix
} from "../components/environment-editor/utils/createGlobalParameterReferenceResolver";

export const createTreeSelectorBranchesFromStepsAndEnvs = (
  steps: StepState[],
  groups: GroupState[],
  environment: EnvironmentState,
  globalParameters: GlobalParametersState,
  stepIndex?: number | undefined
): { [key: Id]: Branch, "environment"?: Branch } => {
  const branches: Branches = {};

  Object.values(steps).forEach((step) => {
    const group = groups.find((group) => group.model.id === step.model.groupId);
    if (!stepIndex || step.model.index < stepIndex) {
      const newBranch: Branches[string] = {
        branchName: step.model.title,
        leaves: {}
      };
      Object.values(step.model.outputs)
        .filter((output) => output.name.trim() !== "")
        .forEach((output) => {
          newBranch.leaves = {
            ...newBranch.leaves,
            [output.id]: {
              name: output.description ?? output.name,
              value: safeJsonStringify(output.value)
            }
          };
        });
      if (Object.keys(newBranch.leaves).length > 0) {
        if (group) {
          const newGroupBranch: IntermediateBranch = {
            branchName: group.model.title,
            branches: {}
          };
          const existingGroupBranch = branches[group.model.id];
          const groupBranch: IntermediateBranch = (existingGroupBranch && isIntermediateBranch(existingGroupBranch))
            ? existingGroupBranch
            : newGroupBranch;
          groupBranch.branches = {
            ...groupBranch.branches,
            [step.model.id]: newBranch
          };
          branches[group.model.id] = groupBranch;
        } else {
          branches[step.model.id] = newBranch;
        }
      }
    }
  });

  const resolveGlobalParameterReferences = createGlobalParameterReferenceResolver(globalParameters, GlobalParameterReferencePrefix);
  const environmentVariables: Variable[] = environment.model.variables;
  const environmentBranch: Branch | undefined = (environmentVariables.length > 0)
    ? {
      branchName: "Workflow parameters",
      leaves: environmentVariables
        .filter((variable) => variable.key.trim() !== "")
        .reduce((variables, variable) => {
          const resolvedValue = resolveGlobalParameterReferences(variable.value);
          return {
            ...variables,
            [variable.key]: {
              name: variable.key,
              value: safeJsonStringify(resolvedValue)
            }
          };
        }, {})
    }
    : undefined;
  return {
    ...(environmentBranch ? {environment: environmentBranch} : {}), // Should always be first if not empty
    ...branches
  };
};
