import React, {useState} from "react";
import {FlowModel} from "../../types/FlowModel";
import {useAppSelector} from "../../hooks";
import {selectFlow, updateFlowById} from "../../flowsSlice";
import Container from "../../../common/components/container/Container";
import Editor, {EditorMode} from "../../../common/components/editor/Editor";
import MultilineInput from "../../../common/components/multiline-input/MultilineInput";
import {useDispatch} from "react-redux";
import Button, {ButtonType} from "../../../common/components/button/Button";
import {safeJsonParse} from "../../utils/safeJsonParse";
import {isLesson} from "../../components/lesson-context-provider/types/Lesson";

const isValidLesson = (content: string | undefined) => {
  return isLesson(safeJsonParse(content ?? "") ?? {});
};

const formatLessonDescriptor = (content: string | undefined): string => {
  return JSON.stringify(safeJsonParse(content ?? "") ?? {}, null, 4);
}
const defaultLessonDescriptor: FlowModel["lessonDescriptor"] = JSON.stringify({
  "steps": [
    {
      "title": "Add a Blank First Step",
      "text": "Click on the button below",
      "element": "AddBlankFirstStep",
      "advanceOn": {
        "event": "click"
      }
    }
  ]
});

type LessonEditorProps = {
  flowId: FlowModel["id"]
}
export const LessonEditor = (
  {
    flowId
  }: LessonEditorProps) => {

  const flow = useAppSelector(selectFlow(flowId));
  const dispatch = useDispatch();

  const {
    title,
    description,
    lessonDescriptor: initialLessonDescriptor = defaultLessonDescriptor
  } = flow?.model ?? {};
  const [
    lessonDescriptor,
    setLessonDescriptor
  ] = useState<FlowModel["lessonDescriptor"]>(formatLessonDescriptor(initialLessonDescriptor));
  const [
    valid,
    setValid
  ] = useState(isValidLesson(lessonDescriptor));

  const handleChange = (content: string) => {
    setValid(isValidLesson(content));
    setLessonDescriptor(content);
  }
  const handleSave = () => {
    const formattedLessonDescriptor = formatLessonDescriptor(lessonDescriptor)
    dispatch(updateFlowById({
      flowId: flowId,
      update: {
        model: {
          lessonDescriptor: formattedLessonDescriptor
        }
      }
    }));
    setLessonDescriptor(formattedLessonDescriptor);
  };

  const handleReset = () => {
    setLessonDescriptor(initialLessonDescriptor);
  }

  const saved = lessonDescriptor === initialLessonDescriptor;
  return (
    <Container>
      {flow ? (
        <>
          <h1>{title}</h1>
          <MultilineInput name={"description"} value={description} rows={10} disabled={true}/>
          <Editor
            placeholder={"Type lesson descriptor JSON here..."}
            content={lessonDescriptor}
            mode={EditorMode.JSON}
            onChange={handleChange}
            readOnly={false}
            showGutter={true}
            wrapEnabled={true}
          />
          <div className={"flex gap-4 justify-end"}>
            <Button type={ButtonType.SECONDARY} title={"Reset"} onClick={handleReset} disabled={saved}/>
            <Button type={ButtonType.PRIMARY} title={`${saved ? "Saved" : "Save (& format)"}`} onClick={handleSave}
                    disabled={!valid || saved}/>
          </div>
        </>
      ) : <>Flow not found</>}
    </Container>
  );
}
