import {ThunkDispatch} from "@reduxjs/toolkit";
import {RootState} from "../../../store";
import {Flow, isDefined, validateExecuteRequest, ValidationError} from "@cranq-gpt-lowcode/contracts";
import {isId} from "../../../../utils/types/Id";
import {updateStepInput} from "../../../stepsSlice";
import {StepModel} from "../../../types/StepModel";
import {calculateStepGroupedIndex} from "../../../utils/calculateStepGroupedIndex";

export type StepValidationError = {
  stepId: StepModel["id"];
  inputIndex?: number;
  messages: string[];
}

function mapStepInputsValidationErrorsToStep(
  stepId: StepModel["id"],
  error: ValidationError
) {
  return (error.children ?? []).reduce((errors, error) => {
    const inputIndex = Number(error.property);
    return (error.children ?? []).reduce((errors, error) => {
      if (error.property === "source") {
        return [
          ...errors,
          {
            stepId,
            inputIndex,
            messages: Object.values(error.constraints ?? {})
          }
        ];
      }
      return errors;
    }, errors);
  }, [] as StepValidationError[]);
}

const mapStepInterfaceValidationErrorsToStep = (
  stepId: StepModel["id"],
  error: ValidationError
): StepValidationError[] => error.children?.reduce((errors, error) => {
  if (error.property === "inputs") {
    return [
      ...errors,
      ...mapStepInputsValidationErrorsToStep(stepId, error)
    ];
  }
  return errors;
}, [] as StepValidationError[]) ?? [];

const mapStepImplementationValidationErrorsToStep = (
  stepId: StepModel["id"],
  error: ValidationError
): StepValidationError[] => [{
  stepId,
  messages: Object.values(error.constraints ?? {})
}]

const mapStepValidationErrorsToStep = (
  stepId: StepModel["id"],
  error: ValidationError
): StepValidationError[] => {
  return (error.children ?? []).reduce((errors, error) => {
    if (error.property === "interface") {
      return [
        ...errors,
        ...mapStepInterfaceValidationErrorsToStep(stepId, error)
      ];
    }
    if (error.property === "implementation") {
      return [
        ...errors,
        ...mapStepImplementationValidationErrorsToStep(stepId, error)
      ];
    }
    return errors;
  }, [] as StepValidationError[]);
}

const mapFlowValidationErrorsToStep = (
  error: ValidationError,
): StepValidationError[] => (error.children ?? []).reduce((errors, error) => {
  const stepId = error.property;
  if (isId(stepId)) {
    return [
      ...errors,
      ...mapStepValidationErrorsToStep(stepId, error)
    ];
  } else {
    return [];
  }
}, [] as StepValidationError[]);

const mapValidationErrorsToStepInput = (
  validationErrors: ValidationError[],
): StepValidationError[] => {
  return validationErrors.reduce((errors, error) => {
    if (error.property === "flow") {
      return mapFlowValidationErrorsToStep(error);
    }
    return errors;
  }, [] as StepValidationError[]);

}
/**
 *  Format error messages for the UI
 *  Set validation errors on inputs
 */
export const processValidationErrors = (
  dispatch: ThunkDispatch<RootState, any, any>,
  validatedFlow: Flow,
  steps: StepModel[],
  validationErrors: ReturnType<typeof validateExecuteRequest>
): string[] => {
  const getStepById = (stepId: StepModel["id"]) => steps.find((step) => step.id === stepId);
  const getStepInputByIndex = (stepId: StepModel["id"], inputIndex: number, validatedFlow: Flow) => {
    const step = getStepById(stepId);
    if (step) {
      // Not all inputs are sent for execution, only those that are bound to code!
      const inputsSentToExecution = validatedFlow.find(
        (step) => step.id === stepId
      )?.interface?.inputs ?? [];
      return Object.values(step.inputs).find(
        (input) => inputsSentToExecution[inputIndex]?.description === input.description
          || inputsSentToExecution[inputIndex]?.name === input.name
      );
    }
  }

  const stepInputValidationErrors = mapValidationErrorsToStepInput(validationErrors);
  stepInputValidationErrors.forEach(({stepId, inputIndex, messages}) => {
    if (inputIndex === undefined) {
      return; //error is not related to input
    }
    const input = getStepInputByIndex(stepId, inputIndex, validatedFlow);
    if (input) {
      dispatch(updateStepInput({
        stepId,
        input: {
          ...input,
          validationError: messages.join(", ")
        }
      }));
    }
  });
  return stepInputValidationErrors.map(({stepId, inputIndex, messages}) => {
    const step = getStepById(stepId);
    if (!step) {
      return undefined;
    }
    const stepIndex = calculateStepGroupedIndex(stepId, steps) ?? step.index;
    const stepName = step.title ?? "Unnamed step";
    if (inputIndex !== undefined) {
      const input = getStepInputByIndex(stepId, inputIndex, validatedFlow);
      if (input) {
        const inputIndexOnUi = Object.values(step.inputs ?? {}).findIndex((i) => i === input)
        const inputName = input.description ?? input.name ?? `input #${inputIndexOnUi}`;
        return messages.map((message) => `Step <[${stepIndex}] ${stepName}>, input <${inputName}>: ${message}`);
      }
    }
    return messages.map((message) => `Step <[${stepIndex}] ${stepName}>: ${message}`);
  })
    .filter(isDefined)
    .flat();
};
