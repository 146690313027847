import {EnvironmentModel} from "../types/EnvironmentModel";
import {convertInputSourceForEditorApi} from "./convertInputSourceForEditorApi";
import {AiModelType, Output, Step} from "@cranq-gpt-lowcode/contracts";
import {StepModel} from "../types/StepModel";

export const mapStepModelToApiStepModel = (
  step: StepModel,
  steps: StepModel[],
  environmentVariables: EnvironmentModel["variables"],
  // at the moment we don't have any use case when the api should know about unbound ports
  ignoreUnboundPorts: boolean = true
): Step => ({
  id: step.id,
  name: step.title,
  aiModelId: step.aiModelId,
  aiModelType: step.aiModelType,
  description: step.description ?? "",
  interface: {
    inputs: Object.values(step.inputs)
      // due to a bug the isBoundToCode property was not set (aka undefined) for new inputs created by generator
      // so we need to filter explicitly on false and treat undefined as true
      .filter((input) => ignoreUnboundPorts ? input.isBoundToCode !== false : true)
      .map((input) => ({
        name: input.name,
        type: input.type ?? "",
        description: input.description ?? "",
        source: convertInputSourceForEditorApi(input.source, steps, environmentVariables)
      })),
    outputs: Object.values(step.outputs)
      .filter((output) => ignoreUnboundPorts ? output.isBoundToCode !== false : true)
      .map<Output>((output) => {
        const {
          name,
          type,
          description,
          value
        } = output;
        return {
          name,
          type: type ?? "",
          description: description ?? "",
          lastValue: value
        };
      })
  },
  implementation: (step.aiModelType === AiModelType.CODE_WRITER && step.implementation) ? {
    ...step.implementation,
    summary: step.implementation.summary ?? ""
  } : undefined
});
