"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityProvider = void 0;
var IdentityProvider;
(function (IdentityProvider) {
    IdentityProvider["ANONYMOUS"] = "Anonymous";
    IdentityProvider["GOOGLE"] = "Google";
    IdentityProvider["FACEBOOK"] = "Facebook";
    IdentityProvider["TWITTER"] = "Twitter";
    IdentityProvider["GITHUB"] = "GitHub";
    IdentityProvider["MICROSOFT"] = "Microsoft";
})(IdentityProvider = exports.IdentityProvider || (exports.IdentityProvider = {}));
