import React from "react";
import logo from "../../assets/cranq-ai-logo.svg";
import {Link} from "react-router-dom";
import {classNames} from "../../../utils/classNames";
import {UiColor} from "../../../utils/constants/UiColor";
import {LayoutValues} from "../../../utils/constants/LayoutValues";

type PageProps = {
  sideBarContent: React.ReactNode,
  leftPanelContent?: React.ReactNode,
  rightPanelContent?: React.ReactNode,
}
const Layout: React.FC<PageProps> = (props: PageProps) => {
  const {
    sideBarContent,
    leftPanelContent,
    rightPanelContent
  } = props;

  return (
    <section className={"flex flex-row h-screen overflow-y-hidden"}>
      {/* Left sidebar - Navigation */}
      <LayoutLeftPanel>
        <div className={"grow-0 w-max"}>
          <Link to={"/"}>
            <img src={logo} alt="logo" className={"h-5"}/>
            <p className={`text-right text-sm ${UiColor.TextColor.BLACK} leading-tight tracking-tight`}>Beta</p>
          </Link>
        </div>
        <div className={"grow"}>
          {sideBarContent}
        </div>
      </LayoutLeftPanel>
      {/* Flow details */}
      <LayoutMiddlePanel>
        {leftPanelContent}
      </LayoutMiddlePanel>
      {/* Step details */}
      <LayoutRightPanel>
        {rightPanelContent}
      </LayoutRightPanel>
    </section>
  )
};

export default Layout;

export const LayoutLeftPanel = (
  {
    children
  }: { children: React.ReactNode }
) => {
  return (
    <div className={classNames(
      `
        w-60 grow-0
        flex flex-col gap-9 justify-start
        ${LayoutValues.COLUMN_PADDING_X}
        ${LayoutValues.COLUMN_PADDING_Y}
        border-r-1 
        ${UiColor.BorderColor.GRAY}
        ${UiColor.BackgroundColor.OFF_WHITE}
        `)}>
      {children}
    </div>
  )
}
export const LayoutMiddlePanel = (
  {
    children
  }: { children: React.ReactNode }
) => {
  return (
    <div className={classNames(
      `
        w-60 grow 
        flex justify-center
        ${LayoutValues.COLUMN_PADDING_X}
        ${LayoutValues.COLUMN_PADDING_Y}
        overflow-x-hidden
        ${UiColor.BackgroundColor.OFF_WHITE}
        `)}>
      {children}
    </div>
  )
}

export const LayoutRightPanel = (
  {
    children
  }: { children: React.ReactNode }
) => {
  return (
    <div className={classNames(
      `
        w-60 grow 
        flex justify-center
        ${LayoutValues.COLUMN_PADDING_X}
        ${LayoutValues.COLUMN_PADDING_Y}
        overflow-x-hidden
        ${UiColor.BackgroundColor.GOLD}
        `)}>
      {children}
    </div>
  )
}
