import {UiColor} from "../../../utils/constants/UiColor";
import Button, {ButtonType} from "../../../common/components/button/Button";
import {InlineIconName} from "../../../common/components/inline-icon/InlineIconName";
import {ReactNode} from "react";
import Clickable from "../../../common/components/clickable/Clickable";

const ItemNumber = ({number}: { number: number }) => (
  <dt className={"flex justify-center items-start py-4"}>
    <div
      className={`w-6 h-6 ${UiColor.BackgroundColor.GOLD} ${UiColor.TextColor.WHITE} text-lg text-center leading-snug border-0 rounded-full`}>{number}</div>
  </dt>
);

const Item = ({children}: { children: ReactNode }) => (
  <dd className={"grow"}>{children}</dd>
);

const List = ({children}: { children: ReactNode }) => (
  <dl className={`grid grid-cols-2`} style={{gridTemplateColumns: "1.5rem 1fr"}}>{children}</dl>
);

type WelcomeProps = {
  permittedToStartMentor?: boolean;
  onStartNewWorkflow?: (withMentor: boolean) => void
}

const Welcome = (
  {
    permittedToStartMentor = false,
    onStartNewWorkflow
  }: WelcomeProps
) => {
  return (
    <div>
      <h2 className={"pb-6"}>Let’s build an automation</h2>
      <p className={`text-sm ${UiColor.TextColor.GRAY}`}>You can either:</p>
      <List>
        <ItemNumber number={1}/>
        <Item>
          <p>Create an automation via chat with our AI Wizard<br/>
            (Need an idea? Try the Example Automation Seeds 👉)</p>
          <p className={"py-4"}>
            <Button
              title={`AI Wizard${permittedToStartMentor ? "" : " (*)"}`}
              disabled={!permittedToStartMentor}
              type={ButtonType.PRIMARY}
              backgroundTone={UiColor.BackgroundColor.GOLD}
              titleTone={UiColor.TextColor.BLACK}
              shadowTone={UiColor.ShadowColor.BLACK_MEDIUM}
              iconName={InlineIconName.MAGICK}
              padding={true}
              onClick={() => onStartNewWorkflow && onStartNewWorkflow(true)}
            />
          </p>
        </Item>
        <ItemNumber number={2}/>
        <Item>
          <p>or Build your own automation step-by-step, just click
            <Clickable
              onClick={() => onStartNewWorkflow && onStartNewWorkflow(false)}
            >New Workflow</Clickable><br/>
            (CRANQ 101👇 will help you to get started)</p>
        </Item>
      </List>
    </div>

  );
};

export default Welcome;
