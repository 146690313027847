import {isUserModel, UserModel} from "./UserModel";

type RefreshTokenData = {
  validUntil: number;
}
export type LoggedInUserState = {
  model: UserModel,
  loggedInAt: number
} & RefreshTokenData

export type AnonymousUserState = {
  model?: never
  loggedInAt?: never
} & RefreshTokenData

export type UserState = LoggedInUserState | AnonymousUserState
export const isLoggedInUserState = (value: unknown): value is LoggedInUserState => {
  return typeof value === "object" && value !== null
    && "model" in value && isUserModel(value.model)
    && "loggedInAt" in value && typeof value.loggedInAt === "number"
    && "validUntil" in value && typeof value.validUntil === "number";
}
export const isAnonymousUserState = (value: unknown): value is AnonymousUserState => {
  return typeof value === "object" && value !== null
    && "validUntil" in value && typeof value.validUntil === "number";
}
export const isUserState = (value: unknown): value is UserState => {
  return isLoggedInUserState(value) || isAnonymousUserState(value);
}


// versioning
export type UserStateV2 = UserState;
export const isUserStateV2 = isUserState;

// no change from v1 to v2
export type UserStateV1 = UserStateV2;
export const isUserStateV1 = isUserStateV2;

// AnonymousUserState got introduced in v1
export type UserStateV0 = Exclude<UserStateV1, AnonymousUserState> | null
export const isUserStateV0 = (value: unknown): value is UserStateV0 => {
  return value === null || isLoggedInUserState(value);
}
