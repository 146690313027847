import {
  isStepModel,
  isStepModelPriorV0,
  isStepModelV0,
  isStepModelV1,
  StepModel,
  StepModelPriorV0,
  StepModelV0,
  StepModelV1
} from "./StepModel";
import {isRequestId, RequestId} from "../../utils/types/RequestId";
import {GenerateExecutionErrorHintsResponse} from "@cranq-gpt-lowcode/contracts";

export type StepState = {
  model: StepModel,
  codeGenerationRequestId?: RequestId,
  executionErrorHintsGenerationRequestId?: RequestId,
  lastExecutionError?: string | null // null means successful execution, undefined means no execution yet
  lastExecutionErrorHints?: GenerateExecutionErrorHintsResponse,
  aiHintsUpdatedTimestamp?: number,
  aiHintsViewedTimestamp?: number,
  lastChatUpdate?: number

}
export const isStepState = (value: unknown): value is StepState => {
  return typeof value === "object" && value !== null
    && "model" in value && isStepModel(value.model)
    && ("codeGenerationRequestId" in value ? isRequestId(value.codeGenerationRequestId) : true)
    && ("lastExecutionError" in value ? (typeof value.lastExecutionError === "string" || value.lastExecutionError === null) : true)
    && ("aiHintsUpdatedTimestamp" in value ? (typeof value.aiHintsUpdatedTimestamp === "number") : true)
    && ("aiHintsViewedTimestamp" in value ? (typeof value.aiHintsViewedTimestamp === "number") : true)
    && ("lastChatUpdate" in value ? (typeof value.lastChatUpdate === "number") : true)
}

// versioning
export type StepStateV2 = StepState;
export const isStepStateV2 = isStepState;

export type StepStateV1 = Omit<StepStateV2, "model"> & {
  model: StepModelV1
}
export const isStepStateV1 = (value: unknown): value is StepStateV1 => {
  return typeof value === "object" && value !== null
    && "model" in value && isStepModelV1(value.model)
    && ("codeGenerationRequestId" in value ? isRequestId(value.codeGenerationRequestId) : true)
    && ("lastExecutionError" in value ? (typeof value.lastExecutionError === "string" || value.lastExecutionError === null) : true)
    && ("aiHintsUpdatedTimestamp" in value ? (typeof value.aiHintsUpdatedTimestamp === "number") : true)
    && ("aiHintsViewedTimestamp" in value ? (typeof value.aiHintsViewedTimestamp === "number") : true)
    && ("lastChatUpdate" in value ? (typeof value.lastChatUpdate === "number") : true)
}
export type StepStateV0 = Omit<StepStateV1, "model"> & {
  model: StepModelV0
}
export const isStepStateV0 = (value: unknown): value is StepStateV0 => {
  return typeof value === "object" && value !== null
    && "model" in value && isStepModelV0(value.model)
    && ("codeGenerationRequestId" in value ? isRequestId(value.codeGenerationRequestId) : true)
    && ("lastExecutionError" in value ? (typeof value.lastExecutionError === "string" || value.lastExecutionError === null) : true);
}

export type StepStatePriorV0 = Omit<StepStateV0, "model"> & {
  model: StepModelPriorV0
} & {}
export const isStepStatePriorV0 = (value: unknown): value is StepStatePriorV0 =>
  typeof value === "object" && value !== null
  && "model" in value && isStepModelPriorV0(value.model)
  && ("codeGenerationRequestId" in value ? isRequestId(value.codeGenerationRequestId) : true)
