import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {StepModel} from "../../../types/StepModel";
import {RootState} from "../../../store";
import {createGroup, updateGroup} from "../../../groupsSlice";
import {batch} from "react-redux";
import {GroupModel} from "../../../types/GroupModel";
import createNewId from "../../../../utils/createNewId";
import {importStepAction} from "../../import-entity-editor/actions/importStepAction";
import {createUniqueName} from "../../../utils/createUniqueName";
import {createFlow, updateFlowById} from "../../../flowsSlice";
import {FlowModel} from "../../../types/FlowModel";
import {createEnvironment} from "../../../environmentsSlice";
import {FlowExportModel} from "../../../types/FlowExportModel";
import createNewUuid from "../../../../utils/createNewUuid";
import {GlobalParameterReferencePrefix} from "../../environment-editor/utils/createGlobalParameterReferenceResolver";
import {createGlobalParameter} from "../../../globalParametersSlice";
import {isLinkedToGlobalParameter} from "../../environment-editor/utils/isLinkedToGlobalParameter";

const createNewGroupId = (existingGroupIds: GroupModel["id"][]) => {
  let retry = existingGroupIds.length + 1;
  while (retry > 0) {
    const newId = createNewId();
    if (!existingGroupIds.includes(newId)) {
      return newId;
    }
    retry--;
  }
  throw new Error("Failed to create new group id");
};

const createNewStepId = (existingStepIds: StepModel["id"][]) => {
  let retry = existingStepIds.length + 1;
  while (retry > 0) {
    const newId = createNewId();
    if (!existingStepIds.includes(newId)) {
      return newId;
    }
    retry--;
  }
  throw new Error("Failed to create new step id");
};


export const importFlowAction = (
  exportedFlow: FlowExportModel,
  userId: FlowModel["userId"]
): ThunkAction<Promise<FlowModel["id"]>, RootState, any, AnyAction> => async (
  dispatch,
  getState
) => {
  const {
    flow: flowToImport,
    groups: groupsToImport,
    steps: stepsToImport,
    parameters = []
  } = exportedFlow;
  const state = getState();

  const existingGroupIds = Object.keys(state.groups) as GroupModel["id"][];
  const groupIdReplacement = groupsToImport.reduce(
    (replacements, group) => existingGroupIds.includes(group.id)
      ? {...replacements, [group.id]: createNewGroupId(existingGroupIds)}
      : replacements,
    {} as Record<GroupModel["id"], GroupModel["id"]>);

  const existingStepIds = Object.keys(state.steps) as StepModel["id"][];
  const stepIdReplacement = stepsToImport.reduce(
    (replacements, step) => existingStepIds.includes(step.id)
      ? {...replacements, [step.id]: createNewStepId(existingStepIds)}
      : replacements,
    {} as Record<StepModel["id"], StepModel["id"]>);

  const globalParametersToCreate = parameters
    .filter(isLinkedToGlobalParameter)
    .map((p) => p.value.replace(GlobalParameterReferencePrefix, ""))
    .filter((key) => !state.globalParameters.model.find((param) => param.key === key));

  const flowId = createNewUuid();

  batch(() => {
    const otherFlowTitles = Object.values(state.flows).map((flow) => flow.model.title);
    const uniqueFlowTitle = createUniqueName(flowToImport.title, otherFlowTitles);
    dispatch(createFlow({flowId, userId}));
    dispatch(createEnvironment({flowId, variables: parameters}));

    dispatch(updateFlowById({
      flowId,
      update: {
        model: {
          ...flowToImport,
          id: flowId,
          title: uniqueFlowTitle,
          userId
        },
      }
    }));

    groupsToImport.forEach((group) => {
      const groupId = groupIdReplacement[group.id] ?? group.id;
      dispatch(createGroup({id: groupId, flowId}));
      const otherGroupsInFlow = Object.values(state.groups)
        .filter((group) => group.model.flowId === flowId)
        .filter((group) => group.model.id !== groupId);
      const otherGroupTitlesInFlow = otherGroupsInFlow.map((group) => group.model.title);
      const uniqueGroupTitle = createUniqueName(group.title, otherGroupTitlesInFlow);
      dispatch(updateGroup({
        model: {
          ...group,
          id: groupId,
          title: uniqueGroupTitle,
          flowId
        }
      }))
    });

    stepsToImport.forEach((stepModel, idx) => {
      const stepId = stepIdReplacement[stepModel.id] ?? stepModel.id;
      const groupId = stepModel.groupId ? (groupIdReplacement[stepModel.groupId] ?? stepModel.groupId) : undefined;
      const stepIndexToInsertTo = idx + 1;
      dispatch(importStepAction(
        stepIndexToInsertTo,
        groupId,
        flowId,
        { //FIXME: reuse from importGroupAction
          ...stepModel,
          id: stepId,
          groupId,
          flowId,
          index: stepIndexToInsertTo,
          inputs: Object.values(stepModel.inputs).reduce(
            (inputs, input) => ({
              ...inputs,
              [input.id]: {
                ...input,
                source: input.source.type === "output"
                  ? {
                    ...input.source,
                    stepId: stepIdReplacement[input.source.stepId] ?? input.source.stepId,
                  }
                  : input.source
              }
            }),
            {}),
        },
        stepId
      ))
    });
  });

  globalParametersToCreate.forEach((key) => {
    dispatch(createGlobalParameter({key, value: undefined}));
  });
  return flowId;
}
