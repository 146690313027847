import Welcome from "../welcome/Welcome";
import Cranq101 from "../cranq-101/Cranq101";
import {useContext} from "react";
import {AuthContext} from "../auth-context-provider/AuthContextProvider";

type HomeProps = {
  onStartNewWorkflow?: (withMentor: boolean) => void;
}
const Home = (
  {
    onStartNewWorkflow
  }: HomeProps
) => {
  const {permissions} = useContext(AuthContext);

  return (
    <section className={`flex flex-col w-full h-full`}>
      <Welcome
        permittedToStartMentor={permissions.SHARE_DATA_WITH_LLMS.granted}
        onStartNewWorkflow={onStartNewWorkflow}
      />
      <div className={"w-full flex-grow flex flex-col py-4 gap-8"}>
        <Cranq101/>
      </div>
    </section>
  );
};

export default Home;
