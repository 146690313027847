"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isImplementedStep = exports.isLlmStep = exports.isCodeStep = exports.Step = void 0;
const swagger_1 = require("@nestjs/swagger");
const class_validator_1 = require("class-validator");
const Interface_1 = require("./Interface");
const Implementation_1 = require("./Implementation");
const AiModel_1 = require("./AiModel");
const class_transformer_1 = require("class-transformer");
class Step {
}
__decorate([
    (0, swagger_1.ApiProperty)({ type: String }),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], Step.prototype, "id", void 0);
__decorate([
    (0, swagger_1.ApiProperty)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], Step.prototype, "name", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: String }),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(255),
    __metadata("design:type", String)
], Step.prototype, "description", void 0);
__decorate([
    (0, swagger_1.ApiPropertyOptional)({ type: String }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], Step.prototype, "aiModelId", void 0);
__decorate([
    (0, swagger_1.ApiPropertyOptional)({ enum: AiModel_1.AiModelType }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], Step.prototype, "aiModelType", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: Interface_1.Interface }),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => Interface_1.Interface),
    __metadata("design:type", Interface_1.Interface)
], Step.prototype, "interface", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: Implementation_1.Implementation }),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => Implementation_1.Implementation),
    __metadata("design:type", Implementation_1.Implementation)
], Step.prototype, "implementation", void 0);
exports.Step = Step;
const isCodeStep = (step) => {
    var _a;
    return ("aiModelId" in step ? typeof step.aiModelId === "string" : true)
        && ((_a = step.aiModelType) !== null && _a !== void 0 ? _a : AiModel_1.DEFAULT_AI_MODEL_TYPE) === AiModel_1.AiModelType.CODE_WRITER;
};
exports.isCodeStep = isCodeStep;
const isLlmStep = (step) => typeof step.aiModelId === "string"
    && typeof step.aiModelType === "string"
    && step.aiModelType === AiModel_1.AiModelType.TEXT_WRITER;
exports.isLlmStep = isLlmStep;
const isImplementedStep = (step) => {
    return step.implementation !== undefined;
};
exports.isImplementedStep = isImplementedStep;
