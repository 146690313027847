export enum ExternalLinks {
  LATEST_SEO_WORKFLOWS = "https://cranq.ai/seo/workflows",
  PRO_WORKFLOWS = "https://cranq.ai/pro-flows",
  CRANQ_DISCORD_CHANNEL = "https://discord.gg/UgsjNtZW65",
  STEP_SET_A_GOOGLE_TASK = "https://cranq.ai/seo-workflows/set-a-google-task",
  STEP_POST_A_TWEET = "https://cranq.ai/seo-workflows/post-a-tweet",
  STEP_GET_GOOGLE_ANALYTICS_REPORT = "https://cranq.ai/seo-workflows/get-google-analytics-report-for-your-website",
  STEP_INSERT_ROWS_INTO_A_GOOGLE_SHEET = "https://cranq.ai/seo-workflows/insert-rows-into-a-google-sheet"

}
