import {IdentityProvider, isAuthMessage} from "@cranq-gpt-lowcode/contracts";
import {ApiContext, getApiBaseUrl} from "../utils/getApiBaseUrl";
import {useCallback, useRef} from "react";
import {usePopupWindow} from "../../common/hooks/usePopupWindow";
import {UserModel} from "../types/UserModel";

export const createAuthUrl = (
  provider: IdentityProvider,
  anonymousUserId: UserModel["id"]
) =>
  new URL(`${getApiBaseUrl(ApiContext.AUTH)}/${provider.toLowerCase()}/login?anonymousUserId=${anonymousUserId}`);

type useAuthWindowParameters = {
  onLoginSuccess?: (user: UserModel, validUntil: number, isNewRegistration: boolean) => void;
  onLoginFailure?: (error?: Error) => void;
  onAuthWindowOpenFailed?: (error?: Error) => void;
}
type useAuthWindowResult = {
  openAuthWindow: (identityProvider: IdentityProvider, anonymousUserId: UserModel["id"]) => void;
}
export const useAuthWindow = (
  {
    onLoginSuccess,
    onLoginFailure,
    onAuthWindowOpenFailed
  }: useAuthWindowParameters
): useAuthWindowResult => {
  const closeAuthWindowRef = useRef<() => void>(() => { /* noop */
  });
  const closeAuthWindow = () => closeAuthWindowRef.current();

  const handleAuthWindowPostMessage = useCallback((event: MessageEvent) => {
    const {data: message} = event;
    if (message && isAuthMessage(message)) {
      const {user, result, details, validUntil, isNewRegistration} = message;
      if (result === "success") {
        onLoginSuccess && onLoginSuccess(user, validUntil, isNewRegistration);
        closeAuthWindow();
      } else {
        onLoginFailure && onLoginFailure(new Error(details));
        closeAuthWindow();
      }
    }
  }, [onLoginFailure, onLoginSuccess]);
  const {openWindow} = usePopupWindow({
    onPopupWindowClose: () => {
      onLoginFailure && onLoginFailure(new Error("Authentication window is closed"));
    },
    onPopupWindowMessage: handleAuthWindowPostMessage,
    onPopupWindowOpenFailed: () => {
      onAuthWindowOpenFailed && onAuthWindowOpenFailed(new Error("Failed to open authentication window"));
    }
  });

  return {
    openAuthWindow: (
      identityProvider,
      anonymousUserId) => {
      closeAuthWindowRef.current = openWindow(createAuthUrl(identityProvider, anonymousUserId));
    }
  }
}
