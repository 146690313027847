import {useToast} from "../../../../common/components/toast/hook/useToast";
import {useDialog} from "../../../../common/components/dialog/hook/useDialog";
import {MESSAGE_TYPE} from "../../../../common/helper/messageTypeColorMapper";
import React from "react";
import {TOAST_TIMEOUT} from "../../../../common/components/toast/Toast";

export const pollingErrorCallback = (
  showToast: ReturnType<typeof useToast>["showToast"],
  openDialog: ReturnType<typeof useDialog>["openDialog"]
) => (
  title: string,
  errorMessage?: string,
  callToAction?: string,
  type: MESSAGE_TYPE = MESSAGE_TYPE.ERROR
) => {
  showToast({
    type,
    message: title,
    callToAction,
    autoClose: type === MESSAGE_TYPE.ERROR ? false : TOAST_TIMEOUT.LONG,
    onToastClick: errorMessage
      ? () => {
        openDialog({
          type,
          title,
          content:
            <div className={"overflow-auto"}>
              {errorMessage.split("\n").map((line, index) =>
                <pre className={"text-sm"} key={index}>{line}</pre>
              )}
            </div>
        });
      }
      : undefined
  });
};
export const pollingSuccessCallback = (
  showToast: ReturnType<typeof useToast>["showToast"]
) => (
  title: string
) => {
  showToast({
    type: MESSAGE_TYPE.SUCCESS,
    message: title,
  });
};
