import React from "react";
import {ExecuteRequest, validateExecuteRequest} from "@cranq-gpt-lowcode/contracts";
import Container from "../../../common/components/container/Container";
import MultilineInput from "../../../common/components/multiline-input/MultilineInput";
import Button from "../../../common/components/button/Button";
import {FlowModel} from "../../types/FlowModel";
import {NilUuid} from "../../../utils/createNewUuid";
import {EnvironmentModel} from "../../types/EnvironmentModel";
import {StepState} from "../../types/StepState";
import {mapApiStepToStepState} from "../flow-editor-page/utils/mapApiStepToStepState";
import {encodeFlow} from "../../utils/sharing/encodeFlow";

const recoverExportedFlowFromExecuteRequest = (executeRequest: ExecuteRequest): string => {
  const flowModel: FlowModel = {
    id: NilUuid,
    userId: NilUuid,
    title: "Recovered flow",
    description: "Recovered from executor task",
  }
  const environment: EnvironmentModel = {
    id: "id-000000",
    flowId: NilUuid,
    name: "Recovered environment",
    variables: Object.entries(executeRequest.env).map(([key, value]) => ({key, value}))
  }
  const steps: StepState[] = mapApiStepToStepState(executeRequest.flow, {model: flowModel}, environment.variables);
  const stepModels = steps.map(({model}) => model);
  return encodeFlow(flowModel, [], stepModels, environment);
};


export const RecoverFlowFromExecutorTask = () => {
  const [executorTask, setExecutorTask] = React.useState<string>("");
  const [flowRecoveryResult, setFlowRecoveryResult] = React.useState<string>("");

  const handleRecoverFlow = (executorTaskAsString: string) => {
    try {
      const executorTask = JSON.parse(executorTaskAsString);
      const executionRequest = "request" in executorTask ? executorTask.request : undefined;
      if (executionRequest) {
        const validationErrors = validateExecuteRequest(executionRequest);
        if (validationErrors.length === 0) {
          setFlowRecoveryResult(`Failed to validate execution request in task. Errors: ${validationErrors.join(", ")}`);
        }
        const flow = recoverExportedFlowFromExecuteRequest(executionRequest);
        setFlowRecoveryResult(flow);
      } else {
        setFlowRecoveryResult("Failed to find execution request in task")
      }
    } catch (e) {
      console.error(e);
      setFlowRecoveryResult("Failed to parse executor task");
    }
  }

  return (
    <Container headerProps={{title: "Recover flow from executor task"}}>
      <MultilineInput
        name={"executorTask"}
        placeholder={"Paste executor task here"}
        value={executorTask}
        autoGrowUntil={10}
        onValueChange={(value) => {
          setExecutorTask(value);
          setFlowRecoveryResult("")
        }}/>
      <Button title={"Recover flow"} onClick={() => handleRecoverFlow(executorTask)}/>
      {flowRecoveryResult &&
        <MultilineInput
          name={"flowRecoveryResult"}
          value={flowRecoveryResult}
          autoGrowUntil={10}
          readonly={true}
        />}
    </Container>
  );
};
