import Header from "../../../common/components/header/Header";
import MultilineInput from "../../../common/components/multiline-input/MultilineInput";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {makeSelectStepsByFlowId, makeSelectStepsByGroupId, updateStepInput, updateStepOutput} from "../../stepsSlice";
import React, {Fragment, useContext, useMemo} from "react";
import {UiColor} from "../../../utils/constants/UiColor";
import {classNames} from "../../../utils/classNames";
import {LayoutValues} from "../../../utils/constants/LayoutValues";
import {InlineIconName} from "../../../common/components/inline-icon/InlineIconName";
import Button, {ButtonProps, ButtonType} from "../../../common/components/button/Button";
import {Timeouts} from "../../../utils/constants/Timeouts";
import {useToast} from "../../../common/components/toast/hook/useToast";
import {useDialog} from "../../../common/components/dialog/hook/useDialog";
import Dialog from "../../../common/components/dialog/Dialog";
import {createUniqueName} from "../../utils/createUniqueName";
import {AuthContext} from "../auth-context-provider/AuthContextProvider";
import {GroupModel} from "../../types/GroupModel";
import {makeSelectGroupsByFlowId, selectGroup, updateGroupById} from "../../groupsSlice";
import {createDefaultGroupTitle} from "../../utils/createDefaultGroupTitle";
import {FlowModel} from "../../types/FlowModel";
import Container from "../../../common/components/container/Container";
import StepInputEditor from "../step-input-editor/StepInputEditor";
import {getLinkedPropertyDisplay} from "../step-input-editor/utils/getLinkedPropertyDisplay";
import {selectEnvironmentByFlowId} from "../../environmentsSlice";
import {createNewEnvironment} from "../../utils/createNewEnvironment";
import {Branches} from "../../../common/components/tree-selector/TreeSelector";
import {createTreeSelectorBranchesFromStepsAndEnvs} from "../../utils/createTreeSelectorBranchesFromStepsAndEnvs";
import {StepModel} from "../../types/StepModel";
import {StepInputModel} from "../../types/StepInputModel";
import StepOutputEditor from "../step-output-editor/StepOutputEditor";
import {isOutputLinked} from "../step/utils/isOutputLinked";
import {StepOutputModel} from "../../types/StepOutputModel";
import {generateStatusLine} from "../step/utils/generateStatusLine";
import {calculateImplementationParametersHash} from "../../utils/calculateImplementationParametersHash";
import {calculateGroupStatus} from "./utils/calculateGroupStatus";
import OAuthConnectButtonList from "../oauth-connect-button-list/OAuthConnectButtonList";
import {unique} from "../../../utils/unique";
import {useFocusedEntry} from "../../pages/flow-editor-page/hooks/useFocusedEntry";
import {copyToClipboard} from "../../utils/copyToClipboard";
import {encodeGroup} from "../../utils/sharing/encodeGroup";
import {isOutputLinkedInsideGroup} from "./utils/isOutputLinkedInsideGroup";
import {isInputLinkedInsideGroup} from "./utils/isInputLinkedInsideGroup";
import {selectGlobalParameters} from "../../globalParametersSlice";

export type GroupProps = {
  groupId: GroupModel["id"],
  flowId: FlowModel["id"],
  isFlowRunning: boolean,
  isGroupRunning: boolean,
  //events
  onStepExecutionRequested?: (stepIds: StepModel["id"][]) => void,
}
const Group = (
  {
    groupId,
    flowId,
    isFlowRunning,
    isGroupRunning,
    //
    onStepExecutionRequested,
  }: GroupProps
) => {
  const dispatch = useAppDispatch();
  const group = useAppSelector(selectGroup(groupId));
  if (!group) {
    throw new ReferenceError("Failed to find group with id: " + groupId);
  }
  const selectStepsByGroupId = useMemo(makeSelectStepsByGroupId, []);
  const stepsInGroup = useAppSelector((state) => selectStepsByGroupId(state, groupId));
  if (!stepsInGroup) {
    throw new ReferenceError("Failed to find steps for group with id: " + groupId);
  }
  const selectStepsByFlowId = useMemo(makeSelectStepsByFlowId, []);
  const steps = useAppSelector((state) => selectStepsByFlowId(state, flowId));
  const selectGroupsByFlowId = useMemo(makeSelectGroupsByFlowId, []);
  const groups = useAppSelector((state) => selectGroupsByFlowId(state, flowId));
  if (!steps || !groups) {
    throw new ReferenceError("Failed to find steps and/or groups for flow with id: " + flowId);
  }
  const globalParameters = useAppSelector(selectGlobalParameters);
  const environment = useAppSelector(selectEnvironmentByFlowId(flowId)) ?? createNewEnvironment(flowId);
  const createBranches: (stepIndex: StepModel["index"]) => Branches = createTreeSelectorBranchesFromStepsAndEnvs.bind(
    null,
    steps,
    groups,
    environment,
    globalParameters);
  const {permissions} = useContext(AuthContext);
  const {setFocusedStepId} = useFocusedEntry(steps);

  const {dialogProps} = useDialog();
  const {showToast} = useToast();

  const {
    model: {
      title = "",
      description = "",
    },
  } = group;

  const resolveLinkedInput = useMemo(
    () => getLinkedPropertyDisplay.bind(null, steps, groups, environment, globalParameters),
    [steps, groups, environment, globalParameters]
  );

  const handleTitleChange = (newTitle: string) => {
    const defaultTitle = createDefaultGroupTitle();
    const newValidTitle = newTitle.trim() || defaultTitle;
    const otherGroupTitles = groups
      .filter((group) => group.model.id !== groupId)
      .map((group) => group.model.title);
    const newValidUniqueTitle = createUniqueName(newValidTitle, otherGroupTitles);
    dispatch(updateGroupById({
      groupId,
      update: {
        model: {
          title: newValidUniqueTitle
        }
      }
    }));
  };
  const handleDescriptionIsEmptyChange = (newDescription: string) => {
    if ((newDescription.trim() === "") !== (description.trim() === "")) {
      dispatch(updateGroupById({
        groupId,
        update: {
          model: {
            description: newDescription
          }
        }
      }));
    }
  };
  const handleDescriptionChange = (newDescription: string) => {
    dispatch(updateGroupById({
      groupId,
      update: {
        model: {
          description: newDescription
        }
      }
    }));
  };
  const rightSideButtonListProps: ButtonProps[] = [
    {
      title: "Export group to clipboard",
      type: ButtonType.SECONDARY,
      expanding: true,
      iconName: InlineIconName.COPY,
      onClick: () => copyToClipboard(encodeGroup(group.model, stepsInGroup.map((step) => step.model)), showToast, "Group exported to clipboard"),
    }
  ];

  const handleRunGroupRequest = () => {
    onStepExecutionRequested && onStepExecutionRequested(stepsInGroup.map((step) => step.model.id));
  };

  const handleChangeInput = (stepId: StepModel["id"], newInput: StepInputModel) => {
    dispatch(updateStepInput({
      stepId: stepId,
      input: newInput,
    }));
  };

  const handleChangeOutput = (stepId: StepModel["id"], newOutput: StepOutputModel) => {
    dispatch(updateStepOutput({
      stepId: stepId,
      output: newOutput,
    }));
  };

  const isCodeGenerationPending = stepsInGroup.some((step) => step.codeGenerationRequestId !== undefined);
  const apis = unique(stepsInGroup.flatMap((step) => step.model.implementation?.apis ?? []));
  const inputsCol = (
    <Container
      headerProps={{
        title: "Inputs",
        level: 3,
      }}
      indented={false}>
      <div className={"grid gap-1 items-center"}
           style={{gridTemplateColumns: "33% 1fr"}}>
        {stepsInGroup.flatMap(
          (step) => Object.entries(step.model.inputs)
            .filter(([, input]) => !isInputLinkedInsideGroup(stepsInGroup, input))
            .map(([key, input]) => {
              return (
                <StepInputEditor
                  key={key}
                  model={input}
                  allowValueModificationOnly={true}
                  linkables={createBranches(step.model.index)}
                  linkedInputResolver={resolveLinkedInput}
                  disabled={isGroupRunning || isCodeGenerationPending}
                  onChange={(newInput) => handleChangeInput(step.model.id, newInput)}
                />);
            })
        )}
      </div>
    </Container>
  );

  const outputsCol = (
    <Container
      headerProps={{
        title: "Outputs",
        level: 3
      }}
      indented={false}>
      <div className={"grid gap-1 items-center"}
           style={{gridTemplateColumns: "33% 1fr"}}>
        {stepsInGroup.flatMap(
          (step) => Object.entries(step.model.outputs)
            .filter(([, output]) => !isOutputLinkedInsideGroup(stepsInGroup, step, output))
            .map(([key, output]) => {
              return (
                <StepOutputEditor
                  key={key}
                  model={output}
                  allowValueModificationOnly={true}
                  disabled={isGroupRunning || isCodeGenerationPending}
                  isLinked={isOutputLinked(stepsInGroup, output)}
                  onChange={(newOutput) => handleChangeOutput(step.model.id, newOutput)}
                />);
            }))}
      </div>
    </Container>
  );

  const statusLine = generateStatusLine(
    "group",
    description,
    calculateGroupStatus(stepsInGroup),
    stepsInGroup.every((step) => step.model.implementation?.code) ? "code" : "",
    stepsInGroup
      .flatMap((step) => Object.values(step.model.inputs))
      .reduce((inputs, input) => ({...inputs, [input.id]: input}), {}),
    stepsInGroup
      .flatMap((step) => Object.values(step.model.outputs))
      .reduce((outputs, output) => ({...outputs, [output.id]: output}), {}),
    undefined,
    isCodeGenerationPending,
    isGroupRunning,
    stepsInGroup.every((step) => step.model.implementation?.parametersHash !== calculateImplementationParametersHash(step.model)),
    () => {
      const firstFailedStep = stepsInGroup.find((step) => step.lastExecutionError);
      if (firstFailedStep) {
        setFocusedStepId(firstFailedStep.model.id);
      }
    }
  )
  return (
    <Fragment>
      <section className="container w-full h-full flex flex-col justify-between">
        <div className={"w-full flex-shrink-0 pb-6"}>
          <Header
            title={title}
            onTitleChange={handleTitleChange}
            titleTone={UiColor.TextColor.WHITE}
            rightSideButtonListProps={rightSideButtonListProps}
          />
        </div>
        <div className={classNames(`
          w-full ${LayoutValues.COLUMN_PADDING_X}
          flex-grow flex-shrink
          flex flex-col
          overflow-y-hidden
          rounded-2xl
          ${UiColor.BackgroundColor.WHITE}
        `)}>
          <div className={classNames(`
            flex-grow flex-shrink 
            ${LayoutValues.COLUMN_PADDING_X_INVERSE} ${LayoutValues.COLUMN_PADDING_X} pt-6
            flex flex-col gap-4
            border-b-[1px] ${UiColor.BorderColor.GRAY}
            overflow-y-auto
          `)}>
            <MultilineInput
              name={"description"}
              disabled={isGroupRunning}
              placeholder={"Type in the group's description here..."}
              rows={3}
              autoGrowUntil={10}
              value={description}
              onValueChange={handleDescriptionIsEmptyChange}
              onBlur={handleDescriptionChange}/>
            <OAuthConnectButtonList apis={apis}/>
            {inputsCol}
            {outputsCol}
          </div>
          <div className={"w-full flex-shrink-0 flex justify-between py-6 gap-2"}>
            {statusLine}
            <Button
              title={`Proceed${permissions["SHARE_DATA_WITH_LLMS"]?.granted ? "" : " (*)"}`}
              type={ButtonType.PRIMARY}
              disabled={isFlowRunning || !permissions["SHARE_DATA_WITH_LLMS"]?.granted}
              isInProgress={isGroupRunning}
              progressTimeout={isGroupRunning ? Timeouts.EXECUTION : Timeouts.STEP_IMPLEMENTATION_GENERATION}
              shadowTone={UiColor.ShadowColor.BLACK_LIGHT}
              onClick={handleRunGroupRequest}
            />
          </div>
        </div>
      </section>
      {dialogProps && <Dialog {...dialogProps}/>}
    </Fragment>
  );
};

export default Group;
