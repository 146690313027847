import Editor from "../../../common/components/editor/Editor";
import React from "react";
import {formatExecutionError} from "../step-drawer/utils/ExecutionError";
import Notification from "../../../common/components/notification/Notification";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";

type StepCodeAndErrorProps = {
  code?: string,
  error?: string,
  isImplementationObsolete?: boolean
}
const StepCodeAndError = (
  {
    code,
    error,
    isImplementationObsolete = false
  }: StepCodeAndErrorProps) => {
  const codeEditor =
    <div>
      <Editor content={code ? code : "No code generated yet."} theme={"dark"} readOnly={true}/>
    </div>

  const errorViewer = error
    ? (
      <div>
        <Notification text={"The above code's last run resulted in the following error:"} type={MESSAGE_TYPE.ERROR}/>
        <Editor content={formatExecutionError(error)} readOnly={true}/>
      </div>
    )
    : null;

  const obsoleteMessage = isImplementationObsolete
    ? <Notification
      text={"Code may be obsolete"}
      secondaryText={"Parameters was changed since this code was generated and/or run. You may need to proceed the step again."}
      type={MESSAGE_TYPE.WARNING}/>
    : null;

  return (
    <div className={"flex flex-col gap-2"}>
      {obsoleteMessage}
      {codeEditor}
      {errorViewer}
    </div>
  );
}

export default StepCodeAndError;
