import createNewUuid from "../../utils/createNewUuid";
import {FlowStateV1} from "../types/FlowState";
import {ensureAnonymousUserId} from "../utils/ensureAnonymousUserId";

import {PersistedRootStateV0, PersistedRootStateV1} from "../types/PersistedRootState";
import {FlowModelV0, FlowModelV1} from "../types/FlowModel";
import {FlowsStateV1} from "../flowsSlice";
import {EnvironmentsStateV1} from "../environmentsSlice";
import {StepsStateV1} from "../stepsSlice";
import {StepStateV1} from "../types/StepState";
import {EnvironmentStateV1} from "../types/EnvironmentState";
import {Id} from "../../utils/types/Id";
import {GroupsStateV1} from "../groupsSlice";
import {GroupStateV1} from "../types/GroupState";
import {StepModelV0, StepModelV1} from "../types/StepModel";
import {GroupModelV0, GroupModelV1} from "../types/GroupModel";


const anonymousUserId = ensureAnonymousUserId();
const createIdMapper = <T, U>(
  newIdCreator: () => U,
  cache: Map<T, U> = new Map()
) => (oldId: T): U => {
  const newId = cache.get(oldId) ?? newIdCreator();
  cache.set(oldId, newId);
  return newId;
};

// migration functions used by import process
export const migrateStepModelV0ToV1 = (
  stepModelV0: StepModelV0,
  flowId: FlowModelV1["id"]
): StepModelV1 => {
  return {
    ...stepModelV0,
    flowId
  }
}

export const migrateGroupModelV0ToV1 = (
  groupModelV0: GroupModelV0,
  flowId: FlowModelV1["id"]
): GroupModelV1 => ({
  ...groupModelV0,
  flowId
});

export const migrateFlowModelV0ToV1 = (
  flowModelV0: FlowModelV0,
  flowId: FlowModelV1["id"],
  userId: FlowModelV1["userId"]
): FlowModelV1 => {
  return {
    ...flowModelV0,
    id: flowId,
    idV0: flowModelV0.id,
    userId,
  };
};

// migration functions
// version 1:
// - FlowModel[id] became Uuid
// - previous FlowModel[id] is stored in idV0 (for a transitional period)
// - FlowModel got a new property: userId
// - Environment id should be updated to the new flowId
// - flowId in steps should be updated
export const migrateToV1 = (state: PersistedRootStateV0): PersistedRootStateV1 => {
  const mapFlowIdV0ToV1 = createIdMapper<FlowModelV0["id"], FlowModelV1["id"]>(createNewUuid);
  return ({
    ...state,
    flows: {
      ...Object.values(state.flows)
        .reduce<FlowsStateV1>((flows, flow) => {
          const flowId = mapFlowIdV0ToV1(flow.model.id)
          const migratedFlowModel: FlowModelV1 = migrateFlowModelV0ToV1(flow.model, flowId, anonymousUserId);
          const migratedFlow: FlowStateV1 = {
            ...flow,
            model: migratedFlowModel,
          };
          return ({
            ...flows,
            [flowId as string]: migratedFlow
          });
        }, {})
    },
    groups: {
      ...Object.values(state.groups)
        .reduce<GroupsStateV1>((groups, group) => {
          const flowIdV0 = group.model.flowId;
          const flowId = mapFlowIdV0ToV1(flowIdV0)
          const migratedGroupModel: GroupModelV1 = migrateGroupModelV0ToV1(group.model, flowId);
          const migratedGroup: GroupStateV1 = {
            ...group,
            model: migratedGroupModel
          };
          return ({
            ...groups,
            [group.model.id]: migratedGroup
          });
        }, {})
    },
    steps: {
      ...Object.values(state.steps)
        .reduce<StepsStateV1>((steps, step) => {
          const stepId = step.model.id;
          const flowIdV0 = step.model.flowId;
          const flowId = mapFlowIdV0ToV1(flowIdV0)
          const migratedStepModel = migrateStepModelV0ToV1(step.model, flowId);
          const migratedStep: StepStateV1 = {
            ...step,
            model: migratedStepModel
          };
          return ({
            ...steps,
            [stepId]: migratedStep
          });
        }, {})
    },
    environments: {
      ...Object.entries(state.environments)
        .reduce<EnvironmentsStateV1>((environments, [environmentId, environment]) => {
          const flowIdV0 = environment.model.flowId;
          const flowId = mapFlowIdV0ToV1(flowIdV0)
          const migratedEnvironment: EnvironmentStateV1 = {
            ...environment,
            model: {
              ...environment.model,
              flowId,
              id: environmentId as Id
            }
          };
          return ({
            ...environments,
            [environmentId]: migratedEnvironment
          });
        }, {})
    },
    user: state.user === null
      ? {validUntil: 0}
      : state.user
  });
}
