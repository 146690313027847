import {isAdvanceOnEventOption, LessonsStep} from "../types/LessonsStep";
import {ShepherdOptionsWithType, Step as ShepherdStep, Tour} from "react-shepherd";
import {LessonFloatingUiPlacementMap} from "../types/LessonFloatingUiPlacementMap";
import {
  isUiElementIndexedSelectorKey,
  isUiElementSelectorKey,
  normalizeUiElementIndexedSelectorKey,
  UiElementIndexedSelectors,
  UiElementSelectorExpression,
  UiElementSelectors
} from "../types/UiElementSelectors";
import {LessonFloatingUiPlacement} from "../types/LessonFloatingUiPlacements";

const toTargetSelector = (selectorExpression: UiElementSelectorExpression): string | undefined => {
  const normalizedIndexedSelectorKey = normalizeUiElementIndexedSelectorKey(selectorExpression)
  if (isUiElementIndexedSelectorKey(normalizedIndexedSelectorKey)) {
    const [, index] = selectorExpression.split("#");
    if (!isNaN(Number(index))) {
      return UiElementIndexedSelectors[normalizedIndexedSelectorKey].replace("{INDEX}", `${index}`);
    }
  }
  if (isUiElementSelectorKey(selectorExpression)) {
    return UiElementSelectors[selectorExpression];
  }
  console.debug("Failed to resolve target selector for", selectorExpression);
  return undefined;
}

const getPlacement = (selectorExpression: UiElementSelectorExpression): LessonFloatingUiPlacement | undefined => {
  const normalizedIndexedSelectorKey = normalizeUiElementIndexedSelectorKey(selectorExpression)
  if (isUiElementIndexedSelectorKey(normalizedIndexedSelectorKey)) {
    return LessonFloatingUiPlacementMap[UiElementIndexedSelectors[normalizedIndexedSelectorKey]];
  }
  if (isUiElementSelectorKey(selectorExpression)) {
    return LessonFloatingUiPlacementMap[UiElementSelectors[selectorExpression]];
  }
  return undefined;
}

function addProgressIndicator(currentStep: ShepherdStep) {
  const currentStepElement = currentStep?.getElement();
  if (currentStepElement) {
    const tour = currentStep.getTour();
    const header = currentStepElement?.querySelector('.shepherd-header');
    const progress = document.createElement('span');
    progress.className = 'shepherd-progress';
    progress.innerText = `[${tour.steps.indexOf(currentStep) + 1}/${tour.steps.length}]`;
    header?.insertBefore(progress, currentStepElement.querySelector('.shepherd-cancel-icon'));
  }
}

// transforms text between backticks to code blocks
function processText(text: string): HTMLElement {
  const textParts = text.split('`');
  const container = document.createElement('p');
  textParts.forEach((part, index) => {
    const element = index % 2 === 0 ? document.createTextNode(part) : document.createElement('code');
    if ("innerText" in element) {
      element.innerText = part;
      element.title = "Double click to set text to clipboard";
      element.addEventListener("dblclick", (event) => {
        event.preventDefault();
        event.stopPropagation();
        //set selection to the whole element
        const range = document.createRange();
        range.selectNodeContents(element);
        const selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
        //copy to clipboard
        navigator.clipboard.writeText(part);
      });
    }
    container.appendChild(element);
  })
  return container
}

export const toShepherdStep = (
  step: LessonsStep,
  index: number
): ShepherdOptionsWithType | undefined => {
  const {
    title,
    text,
    element,
    advanceOn,
    allowBack,
    allowNext,
    ...rest
  } = step;
  const stepId = `step-${index}`;
  const targetSelector = toTargetSelector(element);
  const placement = getPlacement(element)

  return targetSelector ? ({
      ...rest,
      title,
      text: processText(text),
      // automatic step id
      id: stepId,
      // transform to lazy evaluator
      attachTo: {
        element: targetSelector ? () => {
          return document.querySelector<HTMLElement>(targetSelector);
        } : undefined,
        on: placement
      },
      ...(advanceOn && isAdvanceOnEventOption(advanceOn) ? {
        advanceOn: {
          ...advanceOn,
          selector: toTargetSelector(advanceOn?.selector ?? element) ?? targetSelector
        }
      } : {}),
      beforeShowPromise: () => {
        return new Promise((resolve) => {
          setTimeout(resolve, 300); // FIXME: This is a hack to ensure the element is in the DOM before the step is shown
        });
      },
      ...(allowBack || allowNext ? {
        buttons: [
          ...(allowBack ? [{
            text: "Previous",
            action: function (this: Tour) {
              this.back();
            }
          }] : []),
          ...(allowNext ? [{
            text: "Next",
            action: function (this: Tour) {
              this.next();
            }
          }] : [])
        ]
      } : {}),
      when: {
        show: function (this: ShepherdStep) {
          addProgressIndicator(this);
          // handle advanceOn option (except for advanceOnEvent)
          if (advanceOn && !isAdvanceOnEventOption(advanceOn)) {
            const {
              selector = step.element,
              property = "value",
              value
            } = advanceOn;
            const elementSelector = toTargetSelector(selector);
            const element = document.querySelector<HTMLElement>(elementSelector ?? targetSelector) as HTMLElement;
            if (element && property) {
              if (property === "value") {
                const listener = () => {
                  if ((element as HTMLInputElement).value === value) {
                    element.removeEventListener("keyup", listener);
                    element.removeEventListener("paste", listener);
                    this.getTour()?.getCurrentStep()?.id === stepId && this.getTour().next();
                  }
                }
                element.addEventListener("keyup", listener);
                element.addEventListener("paste", listener);
              } else {
                const observer = new MutationObserver((mutations) => {
                  mutations.forEach((mutation) => {
                    if (mutation.target === element && mutation.type === "attributes" && mutation.attributeName === property) {
                      // if value is not defined any change of the property will trigger the next step
                      if (value ? (mutation.target as HTMLElement).getAttribute(property) === value : true) {
                        observer.disconnect();
                        this.getTour().next();
                      } else {
                        console.debug("MutationObserver unhandled mutation", mutation);
                      }
                    }
                  });
                });
                observer.observe(element.parentNode ?? element, {subtree: true, childList: true, attributes: true});
              }
            }
          }
        }
      }
    }
  ) : undefined;
};
