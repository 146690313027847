import {StepModel} from "../../../types/StepModel";
import {Variable} from "../../../types/Variable";
import {convertInputSourceForEditorApi} from "../../../utils/convertInputSourceForEditorApi";
import {GenerateCodeForStepRequest, Step} from "@cranq-gpt-lowcode/contracts";

export const createGenerateCodeForStepRequest = (
  stepId: StepModel["id"],
  step: StepModel,
  steps: StepModel[],
  variables: Variable[],
  flowDescription: string
): GenerateCodeForStepRequest => ({
  flow: steps
    .sort((a, b) => Math.sign(a.index - b.index))
    .map<Step>((step) => ({
      id: step.id,
      name: step.title,
      aiModelId: step.aiModelId,
      aiModelType: step.aiModelType,
      description: step.description ?? "",
      interface: {
        inputs: Object.values(step.inputs)
          .map((input) => ({
            name: input.name,
            type: input.type ?? "",
            description: input.description ?? "",
            source: convertInputSourceForEditorApi(input.source, steps, variables)
          })),
        outputs: Object.values(step.outputs)
          .map((output) => ({
            name: output.name,
            type: output.type ?? "",
            description: output.description ?? "",
            lastValue: (output.value ?? "")
          }))
      },
      // do not send previous implementation for code generation request as it influences the generated code
      implementation: undefined
    })),
  env: Object.fromEntries(variables.map((variable) => [variable.key, variable.value])),
  flowDescription: flowDescription,
  stepId
});
