import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../../../store";
import {FlowState} from "../../../types/FlowState";
import {updateFlowById} from "../../../flowsSlice";
import {GenerateFlowByInstructionHintsResponse} from "@cranq-gpt-lowcode/contracts";

export const processGenerateHintsForStepsResult = (
  flow: FlowState | undefined,
  result: GenerateFlowByInstructionHintsResponse
): ThunkAction<string | void, RootState, undefined, AnyAction> => (
  dispatch
): string | void => {
  if (flow) {
    const {hints} = result;
    dispatch(updateFlowById({
        flowId: flow.model.id,
        update: {
          model: {
            hints
          }
        }
      }
    ));
  }
};
