import ReactGA from "react-ga4";

export const getGaClientId = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      ReactGA.ga((tracker: { get: (arg0: string) => any; }) => {
        const clientId = tracker.get("clientId");
        resolve(clientId);
      });
    } catch (e) {
      reject(e);
    }
  })
}
