import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react";
import {GetOAuthConnectionsRequest, GetOAuthConnectionsResponse} from "@cranq-gpt-lowcode/contracts";
import {EndpointBuilder} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {ApiContext, getApiBaseUrl} from "./utils/getApiBaseUrl";

export const oAuthApiRawEndpointBuilder = (
  builder: EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, never, "oAuthApi">
) => ({
  getConnections: builder.query<GetOAuthConnectionsResponse, GetOAuthConnectionsRequest["userId"]>({
    query: (userId) => ({
      url: `/connections/${userId}`,
      method: "GET"
    })
  })
});

const oAuthApi = createApi({
  reducerPath: "oAuthApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getApiBaseUrl(ApiContext.OAUTH),
    credentials: "include",
    prepareHeaders: (headers) => {
      headers.append("Ga-Client-Id", window.__GLOBAL_VAR__.GA_ClIENT_ID || "unset");
    }
  }),
  endpoints: oAuthApiRawEndpointBuilder
});

export const {
  useGetConnectionsQuery
} = oAuthApi;
export default oAuthApi;
