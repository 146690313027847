import {ThunkAction} from "@reduxjs/toolkit";
import editorApi from "../../../editorApi";
import {RootState} from "../../../store";
import {FlowState} from "../../../types/FlowState";
import {updateFlowById} from "../../../flowsSlice";
import {isUuid} from "../../../../utils/types/Uuid";
import {PendingRequestId} from "../../../../utils/types/RequestId";


export const generateHintsForStepsAsyncAction = (
  flow: FlowState,
  generateHintsForStepsAsyncTrigger: ReturnType<typeof editorApi["useGenerateHintsForStepsAsyncMutation"]>[0],
  errorCallback: (title: string, errorMessage?: string) => void = () => {
  }
): ThunkAction<void, RootState, any, any> => (dispatch) => {
  const {model: {id: flowId, description}} = flow;

  dispatch(updateFlowById({
    flowId: flowId,
    update: {
      generateHintsForStepsRequestId: PendingRequestId
    }
  }));

  generateHintsForStepsAsyncTrigger({instruction: description})
    .then((response) => {
      if ("data" in response) {
        const queryId = response.data.queryId;
        if (isUuid(queryId)) {
          dispatch(updateFlowById({
            flowId: flowId,
            update: {
              generateHintsForStepsRequestId: queryId
            }
          }));
        } else {
          console.error("Received invalid queryId in response of step hints generation request");
        }
      } else {
        errorCallback(
          "Step hints generation failed unexpectedly!",
          "Raw message:\n" + JSON.stringify(response.error, null, 2)
        );
      }
    });
};
