import Container from "../../../common/components/container/Container";
import MultilineInput from "../../../common/components/multiline-input/MultilineInput";
import Button, {ButtonProps, ButtonType} from "../../../common/components/button/Button";
import {InlineIconName} from "../../../common/components/inline-icon/InlineIconName";
import {UiColor} from "../../../utils/constants/UiColor";
import {Timestamp} from "../../../utils/types/Timestamp";
import ScheduledFlowStatusTag from "../scheduled-flow-status-tag/ScheduledFlowStatusTag";
import {ScheduledJobStatus} from "@cranq-gpt-lowcode/contracts";
import Notification from "../../../common/components/notification/Notification";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import Clickable from "../../../common/components/clickable/Clickable";
import {useLayoutEffect, useState} from "react";
import WebhookStatusTag from "../webhook-status-tag/WebhookStatusTag";
import {WebhookStatus} from "@cranq-gpt-lowcode/contracts/dist/webhook/domain/WebhookStatus";
import IconButton from "../../../common/components/icon-button/IconButton";
import {FeatureFlags, isFeatureFlagSet} from "../../utils/isFeatureFlagSet";

const formatTimestamp = (timestamp: Timestamp) => {
  const dateToFormat = new Date(timestamp);
  const dayString = `0${dateToFormat.getDate()}`.slice(-2);
  const monthString = `0${dateToFormat.getMonth() + 1}`.slice(-2);
  const yearString = dateToFormat.getFullYear();
  return `${dayString}/${monthString}/${yearString}`
};

const formatScheduledFlowStatusMessage = (scheduledFlowStatus: ScheduledJobStatus, scheduledJobExpiryTime?: Timestamp) => {
  const formattedExpiryDate = scheduledJobExpiryTime ? formatTimestamp(scheduledJobExpiryTime) : "unknown date";
  switch (scheduledFlowStatus) {
    case ScheduledJobStatus.OUTDATED:
      return `A previous copy of this flow is scheduled until ${formattedExpiryDate}`;
    case ScheduledJobStatus.EXPIRED:
      return `This flow was scheduled until ${formattedExpiryDate}`;
    case ScheduledJobStatus.SCHEDULED:
      return `This flow is scheduled until ${formattedExpiryDate}`;
    default:
      return null;
  }
}

const formatWebhookStatusMessage = (webhookStatus: WebhookStatus, webhookExpiryTime?: Timestamp) => {
  const formattedExpiryDate = webhookExpiryTime ? formatTimestamp(webhookExpiryTime) : "unknown date";
  switch (webhookStatus) {
    case WebhookStatus.OUTDATED:
      return `A previous copy of this flow is available as webhook until ${formattedExpiryDate}`;
    case WebhookStatus.EXPIRED:
      return `This flow was scheduled to run once a day until ${formattedExpiryDate}`;
    case WebhookStatus.ACTIVE:
      return `This flow is available as webhook until ${formattedExpiryDate}`;
    default:
      return null;
  }
}


type ScheduledFlowProps = {
  scheduledFlowStatus?: ScheduledJobStatus,
  scheduledJobExpiryDate?: Timestamp
}
type WebhookProps = {
  webhookStatus?: WebhookStatus,
  webhookExpiryDate?: Timestamp,
}
export type SynopsisProps = {
  title?: string,
  description?: string,
  hints?: string[],
  isStepGenerationInProgress?: boolean,
  isFlowRunning?: boolean,
  schedulerDisabledReason?: string,
  isFlowScheduleOperationInProgress?: boolean,
  webhookDisabledReason?: string,
  isWebhookOperationInProgress?: boolean,
  // events
  onDeleteFlowClick?: () => void,
  onTitleChange?: (title: string) => void,
  onDescriptionChange?: (description: string) => void,
  onScheduleFlowClick?: () => void,
  onDescheduleFlowClick?: () => void,
  onCreateWebhookClick?: () => void,
  onDeleteWebhookClick?: () => void,
  onWebhookInfoClick?: () => void,
  onExportFlow?: () => void,
  onStartLesson?: () => void,
} & ScheduledFlowProps
  & WebhookProps

const Synopsis = (props: SynopsisProps) => {
  const {
    title = "",
    description = "",
    scheduledJobExpiryDate,
    scheduledFlowStatus,
    webhookExpiryDate,
    webhookStatus,
    hints = [],
    isStepGenerationInProgress = false,
    isFlowRunning = false,
    schedulerDisabledReason,
    isFlowScheduleOperationInProgress = false,
    webhookDisabledReason,
    isWebhookOperationInProgress = false,
    onDeleteFlowClick,
    onTitleChange,
    onDescriptionChange,
    onScheduleFlowClick,
    onDescheduleFlowClick,
    onCreateWebhookClick,
    onDeleteWebhookClick,
    onWebhookInfoClick,
    onExportFlow,
    onStartLesson
  } = props;
  const [showDescription, setShowDescription] = useState<boolean>(description.trim() !== "");

  useLayoutEffect(() => {
    setShowDescription(description.trim() !== "");
  }, [description, title]);

  const rightSideButtonListProps: ButtonProps[] = [
    {
      title: "Export Flow",
      iconName: InlineIconName.COPY,
      type: ButtonType.SECONDARY,
      borderTone: UiColor.BorderColor.GRAY,
      expanding: true,
      disabled: isStepGenerationInProgress || isFlowRunning,
      onClick: () => onExportFlow && onExportFlow()
    },
    scheduledFlowStatus
      ? {
        title: "Change Schedule",
        type: ButtonType.SECONDARY,
        borderTone: UiColor.BorderColor.GRAY,
        titleTone: UiColor.TextColor.GOLD,
        expanding: true,
        disabled: schedulerDisabledReason !== undefined || isFlowRunning || isStepGenerationInProgress,
        isInProgress: isFlowScheduleOperationInProgress,
        tooltip: schedulerDisabledReason,
        iconName: InlineIconName.SCHEDULE,
        onClick: () => onDescheduleFlowClick && onDescheduleFlowClick()
      }
      : {
        title: "Set Schedule",
        type: ButtonType.SECONDARY,
        borderTone: UiColor.BorderColor.GRAY,
        expanding: true,
        disabled: schedulerDisabledReason !== undefined || isFlowRunning || isStepGenerationInProgress,
        tooltip: schedulerDisabledReason,
        isInProgress: isFlowScheduleOperationInProgress,
        iconName: InlineIconName.SCHEDULE,
        onClick: () => onScheduleFlowClick && onScheduleFlowClick()
      },
    ...(isFeatureFlagSet(FeatureFlags.EnableWebhook) ? ([
        webhookStatus
          ? {
            title: "Delete Webhook",
            type: ButtonType.SECONDARY,
            titleTone: UiColor.TextColor.RED,
            borderTone: UiColor.BorderColor.GRAY,
            expanding: true,
            disabled: webhookDisabledReason !== undefined || isFlowRunning || isStepGenerationInProgress,
            isInProgress: isWebhookOperationInProgress,
            tooltip: webhookDisabledReason,
            iconName: InlineIconName.DELETE_WEBHOOK,
            onClick: () => onDeleteWebhookClick && onDeleteWebhookClick()
          }
          : {
            title: "Create Webhook",
            type: ButtonType.SECONDARY,
            borderTone: UiColor.BorderColor.GRAY,
            expanding: true,
            disabled: webhookDisabledReason !== undefined || isWebhookOperationInProgress || isFlowRunning || isStepGenerationInProgress,
            isInProgress: isWebhookOperationInProgress,
            tooltip: webhookDisabledReason,
            iconName: InlineIconName.WEBHOOK,
            onClick: () => onCreateWebhookClick && onCreateWebhookClick()
          }])
      : []),
    {
      title: "Delete Flow",
      iconName: InlineIconName.DELETE,
      titleTone: UiColor.TextColor.RED,
      backgroundTone: UiColor.BackgroundColor.TRANSPARENT,
      borderTone: UiColor.BorderColor.GRAY,
      expanding: true,
      disabled: isStepGenerationInProgress || isFlowRunning,
      onClick: () => onDeleteFlowClick && onDeleteFlowClick()
    }
  ];

  const handleDescriptionIsEmptyChange = (newDescription: string) => {
    if ((newDescription.trim() === "") !== (description.trim() === "")) {
      onDescriptionChange && onDescriptionChange(newDescription);
    }
  }

  return (
    <Container headerProps={{title, onTitleChange, rightSideButtonListProps}} dropShadow={true}>
      {showDescription
        ? <MultilineInput
          name={"description"}
          disabled={isStepGenerationInProgress}
          value={description}
          placeholder={"Write a general description of the whole workflow here.\n (e.g. Get a Google Analytics report, analyse it, and post the analysis to Slack)"}
          rows={5}
          onValueChange={handleDescriptionIsEmptyChange}
          onBlur={(newDescription) => onDescriptionChange && onDescriptionChange(newDescription)}/>
        : <Clickable onClick={() => setShowDescription(true)}>Add description</Clickable>
      }
      {onStartLesson ? (
        <div className={"flex justify-between"}>
          <Notification text={"This workflow contains an interactive lesson >>"} type={MESSAGE_TYPE.INFO}/>
          <Button title={"Start Lesson"}
                  type={ButtonType.PRIMARY}
                  iconName={InlineIconName.PLAY}
                  shadowTone={UiColor.ShadowColor.BLACK_MEDIUM}
                  disabled={isStepGenerationInProgress || isFlowRunning}
                  onClick={onStartLesson}/>
        </div>
      ) : null}
      {scheduledFlowStatus ? (
        <div className={"flex items-center gap-2 text-sm"}>
          <ScheduledFlowStatusTag scheduledFlowStatus={scheduledFlowStatus}/>
          <p>{formatScheduledFlowStatusMessage(scheduledFlowStatus, scheduledJobExpiryDate)}</p>
        </div>
      ) : null}
      {webhookStatus ? (
        <div className={"flex items-center gap-2 text-sm"}>
          <WebhookStatusTag webhookStatus={webhookStatus}/>
          <p>
            {formatWebhookStatusMessage(webhookStatus, webhookExpiryDate)}
          </p>
          <IconButton iconName={InlineIconName.HINT} size={"small"} onClick={onWebhookInfoClick}
                      tooltip={"Webhook Info"}/>
        </div>
      ) : null}
      {hints.length > 0 &&
        <Container
          headerProps={{
            title: `Hints${isStepGenerationInProgress ? " (about steps being generated)" : ""}`,
            level: 3,
          }}
          collapsed={false}
          indented={false}
        >
          <ul className={"list-disc list-inside"}>
            {hints.map((hint, index) => <li key={index}>{hint}</li>)}
          </ul>
        </Container>
      }
    </Container>
  )
}

export default Synopsis;
