import {FlowState, FlowStateV0} from "../../../types/FlowState";
import {WebhookStatus} from "@cranq-gpt-lowcode/contracts/dist/webhook/domain/WebhookStatus";

export const calculateWebhookStatus = (
  webhookId: FlowState["webhookId"],
  webhookExpiryDate: FlowState["webhookExpiryDate"],
  webhookHash: FlowState["webhookHash"],
  flowHash: FlowState["model"]["hash"],
  flowHashV0?: FlowStateV0["model"]["hash"]
) => {
  return webhookId
    ? (
      (webhookExpiryDate && webhookExpiryDate > Date.now())
        ? ((webhookHash === flowHash || webhookHash === flowHashV0)
            ? WebhookStatus.ACTIVE
            : WebhookStatus.OUTDATED
        )
        : WebhookStatus.EXPIRED
    )
    : undefined;
}
