import {UiColor} from "../../../utils/constants/UiColor";
import {classNames} from "../../../utils/classNames";

export type StatusTagProps = {
  title: string,
  color?: UiColor.TextColor
}

const StatusTag = (
  {
    title,
    color = UiColor.TextColor.GRAY
  }: StatusTagProps
) => {
  return <div className={classNames(
    `${UiColor.BackgroundColor.GRAY}
    ${color} 
    px-2 py-1 
    text-xs
    uppercase
    rounded-md`
  )}>{title}</div>
}

export default StatusTag;
