import {UiColor} from "../../../utils/constants/UiColor";
import {ReactNode, useState} from "react";
import InlineIcon from "../inline-icon/InlineIcon";
import {InlineIconName} from "../inline-icon/InlineIconName";


// Sunday is the first day of the week in JavaScript
const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"] as const;
// We show Monday as the first day
const weekdayDisplayIndexesStartingWithMonday = [1, 2, 3, 4, 5, 6, 0] as const;
const weekdayDisplayIndexesStartingWithSunday = [0, 1, 2, 3, 4, 5, 6] as const;
const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] as const;

const HeaderCell = ({children, clickable = false}: { children: ReactNode, clickable?: boolean }) => {
  return (
    <th
      className={`text-xs leading-[0] tracking-tighter h-0 min-h-0 px-0 py-0 m-0 whitespace-nowrap font-normal ${UiColor.TextColor.TRANSPARENT} ${clickable ? "" : "pointer-events-none"}`}>
      {children}
    </th>
  );
}
const WeekDayCell = ({dayName}: { dayName: string }) => {
  return (
    <HeaderCell>
      <span className={`absolute top-3 ${UiColor.BackgroundColor.WHITE} ${UiColor.TextColor.BLACK}`}>{dayName}</span>
      {dayName}
    </HeaderCell>
  );
}

const HourCell = ({hour}: { hour: number }) => <th className={"border-r font-normal"}>{`${hour}`.padStart(2, "0")}</th>;

type HourSlotCellProps = {
  reserved?: boolean;
  allowReservation?: boolean;
  onClick?: () => void;
}
const HourSlotCell = (
  {
    reserved = false,
    allowReservation = true,
    onClick
  }: HourSlotCellProps
) => {

  const clickable = reserved || allowReservation;
  const bgTone = reserved ? UiColor.BackgroundColor.GOLD : UiColor.BackgroundColor.TRANSPARENT;
  return (
    <td
      className={`border-r ${bgTone} ${clickable ? "cursor-pointer" : "cursor-no-drop"}`}
      onClick={() => onClick && onClick()}
    >{" "}
    </td>
  );
}

type WeeklyCalendarProps = {
  startDay?: "Sunday" | "Monday";
  view?: "normal" | "compact";
  reservations?: { weekday: number, hour: number }[];
  allowNewReservation?: boolean;
  onHourSlotClick?: (weekday: number, hour: number) => void;
};
const WeeklyCalendar = (
  {
    startDay = "Sunday",
    view = "compact",
    reservations = [],
    allowNewReservation = true,
    onHourSlotClick
  }: WeeklyCalendarProps
) => {
  const [currentView, setCurrentView] = useState<WeeklyCalendarProps["view"]>(view);
  const toggleView = () => setCurrentView((currentView) => currentView === "normal" ? "compact" : "normal");
  const weekdayDisplayIndexes = startDay === "Sunday" ? weekdayDisplayIndexesStartingWithSunday : weekdayDisplayIndexesStartingWithMonday;

  const isReserved = (weekday: number, hour: number) => {
    return reservations.some(reservation => reservation.weekday === weekday && reservation.hour === hour);
  }

  return (
    <div className={`w-full h-full overflow-y-hidden ${UiColor.BackgroundColor.WHITE} pt-6 relative`}>
      <div
        className={`${UiColor.BackgroundColor.WHITE} absolute top-0 left-0 w-full h-6`}>{/* Header background */}</div>
      <div className={`w-full h-full overflow-y-auto`}>
        <table className={"border"}>
          <colgroup>
            <col className={"w-4"}/>
            <col className={"w-16"}/>
            <col className={"w-16"}/>
            <col className={"w-16"}/>
            <col className={"w-16"}/>
            <col className={"w-16"}/>
            <col className={"w-16"}/>
            <col className={"w-16"}/>
          </colgroup>
          <thead>
          <tr>
            <HeaderCell clickable={true}>
              <span
                className={`block w-3 h-3 absolute top-1 ${UiColor.BackgroundColor.WHITE} ${UiColor.TextColor.BLACK} cursor-pointer`}
                title={`Switch to ${currentView === "compact" ? "normal" : "compact"} view`}
                onClick={toggleView}
              ><InlineIcon name={InlineIconName.INSPECT}/></span>
            </HeaderCell>
            {weekdayDisplayIndexes.map(dayIndex => <WeekDayCell key={dayIndex} dayName={weekdays[dayIndex]}/>)}
          </tr>
          </thead>
          <tbody>
          {hours.map((hour, hourIndex) => {
            const bgTone = hourIndex % 2 ? UiColor.BackgroundColor.WHITE : UiColor.BackgroundColor.GRAY;
            const textSize = currentView === "normal" ? "text-xs" : "text-[7px] leading-[7px]";
            return (
              <tr key={hour}
                  className={`${bgTone} ${textSize}`}>
                <HourCell hour={hour}/>
                {weekdayDisplayIndexes.map((dayIndex) => {
                  return (
                    <HourSlotCell
                      key={dayIndex}
                      reserved={isReserved(dayIndex, hour)}
                      allowReservation={allowNewReservation}
                      onClick={() => onHourSlotClick && onHourSlotClick(dayIndex, hour)}
                    />
                  );
                })}
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    </div>
  )
};

export default WeeklyCalendar;
