import {SubscriptionWithTimestamps} from "../types/SubscriptionWithTimestamps";
import {Api} from "@cranq-gpt-lowcode/contracts";

export const hasRequiredSubscription = (
  subscriptions: SubscriptionWithTimestamps[] | undefined = [],
  requiredSubscriptions: Api["requiredSubscriptions"] | undefined = []) => {
  return requiredSubscriptions.length > 0
    ? subscriptions.some((sub) => requiredSubscriptions.includes(sub.packageId))
    : true;
}
