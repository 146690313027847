import {UiColor} from "../../../utils/constants/UiColor";
import React from "react";

type StickerProps = {
  text: string;
  bgColor?: UiColor.BackgroundColor;
  textColor?: UiColor.TextColor;
}
export const Sticker = (
  {
    text,
    bgColor = UiColor.BackgroundColor.GOLD,
    textColor = UiColor.TextColor.GRAY,
  }: StickerProps
) => (
  <div className={`absolute -top-2 flex items-center`}>
    <span className={`-ml-[75%] ${bgColor} rounded rounded-br-none ${textColor} px-1 text-xs`}>{text}</span>
  </div>
);
