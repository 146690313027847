import {useCreateNewFlow} from "../../hooks/useCreateNewFlow";
import Button, {ButtonType} from "../../../common/components/button/Button";
import {UiColor} from "../../../utils/constants/UiColor";
import React from "react";

export const StartNewFlowButton = () => {
  const createNewFlow = useCreateNewFlow();
  return (
    <Button
      title={"New workflow"}
      type={ButtonType.PRIMARY}
      shadowTone={UiColor.ShadowColor.BLACK_MEDIUM}
      onClick={() => createNewFlow()}/>
  );
};
