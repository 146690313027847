import {StepInputModel} from "../types/StepInputModel";
import {Variable} from "../types/Variable";
import {EnvSource, OutputSource, ValueSource} from "@cranq-gpt-lowcode/contracts";
import {StepModel} from "../types/StepModel";

export const convertInputSourceForEditorApi = (
  source: StepInputModel["source"],
  steps: StepModel[],
  variables: Variable[]
): ValueSource | OutputSource | EnvSource => {
  const fallbackSource: ValueSource = {
    type: "value",
    value: undefined
  }
  switch (source.type) {
    case "value":
      return {
        type: "value",
        value: source.value
      }
    case "output":
      const step = steps.find((s) => s.id === source.stepId);
      const output = step?.outputs[source.outputId];
      if (step && output) {
        return {
          type: "output",
          stepId: step.id,
          outputName: output.name
        }
      }
      break;
    case "env":
      const variable = variables.find((v) => v.key === source.name);
      if (variable) {
        return {
          type: "env",
          envVariableName: variable.key
        }
      }
      break;
  }
  return fallbackSource;
};
