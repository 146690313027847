import {FlowModel} from "../../../types/FlowModel";
import {UserModel} from "../../../types/UserModel";
import {NilUuid} from "../../../../utils/createNewUuid";
import {updateFlowById} from "../../../flowsSlice";
import {useAppDispatch} from "../../../hooks";

/**
 * Fixes a bug where the imported flow was assigned to an invalid user with ID NilUuid
 */
export const useFixInvalidUserId = (
  flow?: FlowModel,
  userId?: UserModel["id"]
) => {
  const dispatch = useAppDispatch();

  if (!flow || !userId) return;
  if (flow.userId === NilUuid) {
    dispatch(updateFlowById({
      flowId: flow.id,
      update: {
        model: {
          userId
        }
      }
    }));
  }


}
