import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react";
import {EndpointBuilder} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {ApiContext, getApiBaseUrl} from "./utils/getApiBaseUrl";
import {FlowModel} from "./types/FlowModel";
import {safeJsonParse} from "./utils/safeJsonParse";
import {
  FlowConversationMessageRequest,
  FlowConversationMessageResponse,
  FlowConversationRequest,
  FlowConversationResponse,
  FlowConversationSummaryRequest,
  FlowConversationSummaryResponse,
  StepConversationMessageRequest,
  StepConversationMessageResponse,
  StepConversationRequest,
  StepConversationResponse,
  StepConversationSummaryRequest,
  StepConversationSummaryResponse,
  UpdateFlowSnapshotRequest
} from "@cranq-gpt-lowcode/contracts";
import {StepModel} from "./types/StepModel";

type WithFlowId<T> = T & { flowId: FlowModel["id"] };
type WithFlowAndStepId<T> = WithFlowId<T> & { stepId: StepModel["id"] };
type SseQuery<T, U> = T & { onEvent?: (data: U) => void };
type ErrorResponse = {
  reason: string;
  message?: string;
  can_retry?: boolean;
}
const isErrorResponse = (value: unknown): value is ErrorResponse => {
  return typeof value === "object" && value !== null
    && "reason" in value && typeof value.reason === "string"
    && ("can_retry" in value ? typeof value.can_retry === "boolean" : true)
    && ("message" in value ? typeof value.message === "string" : true);
}

const getDataFromSseLines = (lines: string[]): string => {
  const dataFieldString = "data:"
  return lines
    .filter((line) => line.startsWith(dataFieldString))
    .map((line) => line.slice(dataFieldString.length).trimStart())
    .join("\n");
}

function sseResponseHandler<T>(onEvent?: (data: T) => void) {
  return async (response: Response) => {
    if (!response.ok) {
      return response.json(); //FIXME: handle non JSON error message?
    }
    if (response.body) {
      const reader = response.body.getReader();
      let done = false;
      let value: Uint8Array | undefined = undefined;
      let lastResult: T | undefined = undefined
      while (!done) {
        const read = await reader.read();
        done = read.done;
        value = read.value;
        if (value) {
          const text = new TextDecoder().decode(value);
          const chunks = text.split(/\r?\n\r?\n/).slice(0, -1); // last element is empty
          const events = chunks.filter((chunk) => chunk.startsWith("event: ")); // ignore pings
          if (events.length > 0) {
            const lines = events.slice(-1)[0]?.split(/\r?\n/) ?? [];
            const eventType = lines.find((line) => line.startsWith("event: "))?.slice("event: ".length);
            const data = getDataFromSseLines(lines);
            switch (eventType) {
              case "replace":
                //FIXME type guard? Base RTK-query doesn't support type guard either
                lastResult = safeJsonParse(data) as T;
                onEvent && lastResult && onEvent(lastResult);
                break;
              case "error":
                const error = safeJsonParse(data);
                throw new Error(isErrorResponse(error) ? error.reason : "Unknown error");
              default:
                throw new Error("Unhandled SSE event type: $`{eventType}`")
            }
          }
        }
      }
      return onEvent ? JSON.stringify({result: true}) : JSON.stringify(lastResult ?? ""); // if onEvent is not provided, return the last result only
    }
  };
}

export const mentorApiRawEndpointBuilder = (
  builder: EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, "chat", "mentorApi">
) => ({
  getFlowConversation: builder.query<FlowConversationResponse, FlowConversationRequest>({
    query: ({flowId}) => ({
      url: `/flow/${flowId}/conversation`,
      method: "GET"
    }),
    providesTags: (_result, _error, {flowId}) => ([{type: "chat", id: flowId}])
  }),
  postFlowMessage: builder.mutation<FlowConversationMessageResponse, SseQuery<WithFlowId<FlowConversationMessageRequest>, FlowConversationMessageResponse>>({
    query: ({flowId, message, onEvent}) => ({
      url: `flow/${flowId}/conversation/message`,
      method: "POST",
      body: {message},
      responseHandler: sseResponseHandler<FlowConversationMessageResponse>(onEvent)
    }),
    invalidatesTags: (_result, _error, {flowId}) => [{type: "chat", id: flowId}]
  }),
  generateFlowBreakdown: builder.mutation<FlowConversationSummaryResponse, SseQuery<WithFlowId<FlowConversationSummaryRequest>, FlowConversationSummaryResponse>>({
    query: ({flowId, onEvent}) => ({
      url: `flow/${flowId}/conversation/summary`,
      method: "POST",
      responseHandler: sseResponseHandler<FlowConversationSummaryResponse>(onEvent)
    }),
    invalidatesTags: (_result, _error, {flowId}) => [{type: "chat", id: flowId}]
  }),
  createStepConversation: builder.mutation<StepConversationMessageResponse, SseQuery<WithFlowAndStepId<StepConversationRequest>, StepConversationMessageResponse>>({
    query: ({flowId, stepId, onEvent}) => ({
      url: `flow/${flowId}/steps/${stepId}/conversation/create`, //FIXME: suffix create is not necessary in REST nomenclature!
      method: "POST",
      body: {},
      responseHandler: sseResponseHandler<StepConversationMessageResponse>(onEvent)
    }),
    invalidatesTags: (_result, _error, {flowId, stepId}) => [{type: "chat", id: `${flowId}/${stepId}`}]
  }),
  getStepConversation: builder.query<StepConversationResponse, WithFlowAndStepId<StepConversationRequest>>({
    query: ({flowId, stepId}) => ({
      url: `/flow/${flowId}/steps/${stepId}/conversation`,
      method: "GET"
    }),
    providesTags: (_result, _error, {flowId, stepId}) => ([{type: "chat", id: `${flowId}/${stepId}`}])
  }),
  postStepMessage: builder.mutation<StepConversationMessageResponse, SseQuery<WithFlowAndStepId<StepConversationMessageRequest>, StepConversationMessageResponse>>({
    query: ({flowId, stepId, message, onEvent}) => ({
      url: `flow/${flowId}/steps/${stepId}/conversation/message`,
      method: "POST",
      body: {message},
      responseHandler: sseResponseHandler<StepConversationMessageResponse>(onEvent)
    }),
    invalidatesTags: (_result, _error, {flowId, stepId}) => [{type: "chat", id: `${flowId}/${stepId}`}]
  }),
  generateStepSummary: builder.mutation<StepConversationSummaryResponse, SseQuery<WithFlowAndStepId<StepConversationSummaryRequest>, StepConversationSummaryResponse>>({
    query: ({flowId, stepId, onEvent}) => ({
      url: `flow/${flowId}/steps/${stepId}/conversation/summary`,
      method: "POST",
      responseHandler: sseResponseHandler<StepConversationSummaryResponse>(onEvent)
    }),
    invalidatesTags: (_result, _error, {flowId, stepId}) => [{type: "chat", id: `${flowId}/${stepId}`}]
  }),
  updateFlowSnapshot: builder.mutation<void, UpdateFlowSnapshotRequest>({
    query: (request) => ({
      url: `flow/${request.snapshot.flowId}/snapshot`,
      method: "PUT",
      body: request,
    }),
  }),
});

const mentorApi = createApi({
  reducerPath: "mentorApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getApiBaseUrl(ApiContext.MENTOR),
    credentials: "include",
    prepareHeaders: (headers) => {
      headers.append("Ga-Client-Id", window.__GLOBAL_VAR__.GA_ClIENT_ID || "unset");
    }
  }),
  endpoints: mentorApiRawEndpointBuilder
});

export const {
  useGetFlowConversationQuery,
  usePostFlowMessageMutation,
  useGenerateFlowBreakdownMutation,
  useCreateStepConversationMutation,
  useGetStepConversationQuery,
  usePostStepMessageMutation,
  useGenerateStepSummaryMutation,
  useUpdateFlowSnapshotMutation
} = mentorApi;
export default mentorApi;
