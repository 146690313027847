import {makeSelectFlowsOrdered} from "../../flowsSlice";
import {useAppSelector} from "../../hooks";
import {FlowModel} from "../../types/FlowModel";
import {selectSteps} from "../../stepsSlice";
import FlowListItem from "../flow-list-item/FlowListItem";
import {calculateScheduledFlowStatus} from "../scheduled-flow-status-tag/utils/calculateScheduledFlowStatus";
import {useMemo} from "react";
import {FlowState} from "../../types/FlowState";
import {getFlowState} from "./utils/getFlowState";


export type FlowListProps = {
  currentFlowId?: FlowModel["id"],
  limit?: number,
  orderBy?: "createdAt" | "accessedAt" | "updatedAt",
  orderDirection?: "asc" | "desc",
  filterText?: string,
  showDescription?: boolean,
  flavour?: "navBarPanel" | "pageComponent",
} & ({
  includeLessonsOnly?: true;
  includeUserFlowsOnly?: never;
} | {
  includeLessonsOnly?: never;
  includeUserFlowsOnly?: true;

})

const FlowList = (
  {
    currentFlowId,
    orderBy = "createdAt",
    orderDirection = "asc",
    limit,
    filterText,
    includeLessonsOnly,
    includeUserFlowsOnly,
  }: FlowListProps
) => {
  const selectFlowsOrdered = useMemo(makeSelectFlowsOrdered, []);
  const filterPredicateByFilterText = (flow: FlowState) => {
    const filterTextLowercase = filterText?.trim().toLowerCase();
    return filterTextLowercase
      ? [flow.model.title, flow.model.description].some((text) => text.toLowerCase().includes(filterTextLowercase))
      : true;
  }
  const filterPredicateByInclude = includeLessonsOnly
    ? (flowState: FlowState) => flowState.model.lessonDescriptor !== undefined
    : (includeUserFlowsOnly
        ? (flowState: FlowState) => flowState.model.lessonDescriptor === undefined
        : () => true
    );
  const filterPredicate = (flowState: FlowState) => {
    return filterPredicateByInclude(flowState) && filterPredicateByFilterText(flowState)
  }
  const flows = useAppSelector((state) => selectFlowsOrdered(
    state,
    orderBy,
    orderDirection,
    limit,
    filterPredicate
  ));
  const steps = useAppSelector(selectSteps);

  return Object.values(flows).length > 0
    ? (
      <div>
        <div className={`flex flex-col gap-3`}>
          {Object.values(flows).map((flow) => {
            const flowState = getFlowState(flow.model.id, steps)
            const scheduledFlowStatus = calculateScheduledFlowStatus(
              flow.scheduledJobId,
              flow.scheduledJobExpiryDate,
              flow.scheduledJobHash,
              flow.model.hash
            );
            return <FlowListItem
              isCurrent={flow.model.id === currentFlowId}
              key={flow.model.id}
              title={flow.model.title}
              flowId={flow.model.id}
              flowState={flowState}
              scheduledFlowStatus={scheduledFlowStatus}
            />
          })}
        </div>
      </div>
    )
    : null
}

export default FlowList;
