import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query/react";
import {SerializedError} from "@reduxjs/toolkit";

export const getErrorReason = (error: FetchBaseQueryError | SerializedError) => {
  return (
    "data" in error
    && typeof error.data === "object" && error.data !== null
    && "reason" in error.data
  ) ? error.data.reason : undefined;
};
