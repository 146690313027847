import {createUniqueName} from "./createUniqueName";

export const MaxVariableNameLength = 50;
export const fixVariableName = (
  otherVariableNames: string[],
  defaultName: string,
  variableName: string
) => {
  const correctedName = (variableName
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9_ ]/g, "") // filter non-allowed characters
      .replace(/ [^ ]/g, (substring, args) => substring.toUpperCase().trim())  // generate camelCase
    || defaultName).slice(0, MaxVariableNameLength); // limit to 30 characters
  return createUniqueName(
    correctedName,
    otherVariableNames,
    (i) => `_${i}`
  );
};
