import {StepState} from "../../../types/StepState";
import {StepModel} from "../../../types/StepModel";

export const shouldDeleteGroupWithDeletedStep = (
  steps: StepState[],
  groupId: Required<StepModel>["groupId"],
  deletedStepId: StepModel["id"]
) => steps.filter(
  (step) => step.model.id !== deletedStepId && step.model.groupId === groupId
).length === 0
