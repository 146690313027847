import React from "react";
import {UiColor} from "../../../utils/constants/UiColor";
import {classNames} from "../../../utils/classNames";

export type SelectProps = {
  name: string,
  id?: string,
  value?: string,
  options: Record<string, string>,
  title?: string,
  backgroundTone?: UiColor.BackgroundColor,
  textTone?: UiColor.TextColor,
  textSize?: string,
  borderTone?: string,
  height?: number,
  size?: "small" | "medium" | "large" | "flexible" | "full",
  padding?: boolean,
  paddingRight?: number,
  readonly?: boolean,
  disabled?: boolean,
  invalid?: boolean,
  onValueChange?: (newValue: string) => void
}

const Select = (
  {
    name,
    id,
    value = "",
    options,
    title,
    backgroundTone = UiColor.BackgroundColor.INPUT,
    textTone = UiColor.TextColor.BLACK,
    textSize = "text-sm",
    borderTone = UiColor.BorderColor.INPUT_GRAY,
    size = "flexible",
    height = 8,
    padding = true,
    paddingRight,
    readonly = false,
    disabled = false,
    invalid = false,
    onValueChange,
  }: SelectProps) => {
  const sizeClassMapping: Record<Required<SelectProps>["size"], string> = {
    "small": "w-16",
    "medium": "w-32",
    "large": "w-48",
    "flexible": "flex-grow flex-shrink",
    "full": "w-full"
  };

  return <select
    name={name}
    id={id}
    size={1 /* reset HTML size to be able to set size from css without a minimal limit */}
    className={classNames(`
      peer ${"" /* to be able to style siblings according to input's state */}
      ${(disabled || readonly) ? UiColor.BackgroundColor.DISABLED : backgroundTone}
      ${(disabled || readonly) ? UiColor.TextColor.DISABLED : textTone}
      ${invalid ? `${UiColor.BorderColor.RED} ${UiColor.TextColor.RED}` : ""}
      ${borderTone ? `border ${borderTone}` : ""}
      border-solid
      rounded
      ${textSize} 
      ${padding ? "p-1" : ""}
      h-${height}
      ${sizeClassMapping[size]}
      focus:outline-none
      ${!(disabled || readonly) ? `hover:${UiColor.BackgroundColor.INPUT}` : ""}
      ${!(disabled || readonly) ? `hover:${UiColor.BorderColor.INPUT_GRAY}` : ""}
      ${!(disabled || readonly) ? `focus:${UiColor.BackgroundColor.INPUT}` : ""}
      ${!(disabled || readonly) ? `focus:${UiColor.BorderColor.INPUT_GRAY}` : ""}
      ${!(disabled || readonly) ? `hover:${UiColor.TextColor.BLACK}` : ""}
      ${!(disabled || readonly) ? `focus:${UiColor.TextColor.BLACK}` : ""}
    `)}
    style={{
      ...(paddingRight ? {paddingRight: `${paddingRight}px`} : {})
    }}
    value={value}
    title={title ?? value}
    disabled={disabled}
    onChange={(event) => {
      onValueChange && onValueChange(event.target.value);
    }}
  >
    {Object.entries(options).map(([value, label]) => (
      <option key={value} value={value}>{label}</option>
    ))}
  </select>;

};

export default Select;
