import {batch} from "react-redux";
import {
  createEnvironmentVariable,
  deleteEnvironmentVariable,
  selectEnvironment,
  updateEnvironmentVariable
} from "../../environmentsSlice";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
  makeSelectStepsByFlowId,
  resetInputValidationErrorUponEnvironmentVariableValueChange,
  unlinkStepInputsFromEnvironmentVariable,
  updateEnvironmentVariableNameChangeOnStepInputs
} from "../../stepsSlice";
import React, {useMemo} from "react";
import {Variable} from "../../types/Variable";
import {UiColor} from "../../../utils/constants/UiColor";
import Container from "../../../common/components/container/Container";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import {useDialog} from "../../../common/components/dialog/hook/useDialog";
import {StepState} from "../../types/StepState";
import Dialog from "../../../common/components/dialog/Dialog";
import {EnvironmentModel} from "../../types/EnvironmentModel";
import {Branches} from "../../../common/components/tree-selector/TreeSelector";
import {selectGlobalParameters} from "../../globalParametersSlice";
import {EnvironmentVariableEditor} from "./EnvironmentVariableEditor";
import {
  createGlobalParameterReferenceResolver,
  GlobalParameterReferencePrefix
} from "./utils/createGlobalParameterReferenceResolver";

export const GlobalParametersBranchKey = "globalParameters";
export type EnvironmentEditorProps = {
  environmentId: EnvironmentModel["id"]
}

const isLinkedToInput = (variable: Variable, steps: StepState[]) => steps.some(step =>
  Object.values(step.model.inputs)
    .some(input => input.source.type === "env" && input.source.name === variable.key));

const EnvironmentEditor = (
    {
      environmentId
    }: EnvironmentEditorProps
  ) => {
    const dispatch = useAppDispatch();
    const {openDialog, closeDialog, dialogProps} = useDialog();

    const environment = useAppSelector(selectEnvironment(environmentId));
    if (!environment) {
      throw new ReferenceError(`Environment with id ${environmentId} not found`);
    }
    const {
      model: {
        flowId,
        variables: environmentVariables = []
      }
    } = environment;
    const selectStepsByFlowId = useMemo(makeSelectStepsByFlowId, []);
    const steps = useAppSelector((state) => selectStepsByFlowId(state, flowId)) ?? [];


  const globalParameters = useAppSelector(selectGlobalParameters);
    const linkables: Branches = {
      [GlobalParametersBranchKey]: {
        branchName: "Global parameters",
        leaves: globalParameters.model.reduce((acc, param) => ({
          ...acc,
          [param.key]: {name: param.key, value: param.value}
        }), {})
      }
    };

  const resolveGlobalParameterReference = useMemo(
    () => createGlobalParameterReferenceResolver(globalParameters, GlobalParameterReferencePrefix),
    [globalParameters]
  );

    const handleAddClick = () => {
      dispatch(createEnvironmentVariable({environmentId}));
    };

    const handleDelete = (index: number, deleteConfirmed = false) => {
      const variable = environmentVariables[index];
      if (variable) {
        if (isLinkedToInput(variable, steps) && !deleteConfirmed) {
          openDialog({
            title: "Warning",
            type: MESSAGE_TYPE.WARNING,
            content: "This parameter is linked to one or more steps' input(s). Deleting it will unlink these inputs. Are you sure you want to proceed?",
            controlButtonList: [
              {
                title: "Cancel",
                onClick: closeDialog
              },
              {
                title: "Proceed",
                onClick: () => {
                  closeDialog();
                  handleDelete(index, true)
                }
              }
            ]
          });
          return;
        }
        batch(() => {
          dispatch(unlinkStepInputsFromEnvironmentVariable({
            flowId: flowId,
            variableName: variable.key
          }));
          dispatch(deleteEnvironmentVariable({
            environmentId,
            variableIndex: index
          }));
        });
      }
    };

    const handleVariableChange = (
      event: { variable: Variable, index: number }
    ) => {
      const {variable: changedVariable, index} = event;
      const orig = environmentVariables[index];
      if (orig) {
        batch(() => {
          dispatch(updateEnvironmentVariable({
            environmentId,
            variableIndex: index,
            key: changedVariable.key,
            value: changedVariable.value,
            instruction: changedVariable.instruction
          }));
          if (orig.key !== changedVariable.key) {
            dispatch(updateEnvironmentVariableNameChangeOnStepInputs({
              flowId: flowId,
              oldVariableName: orig.key,
              newVariableName: changedVariable.key
            }));
          }
          if (orig.value !== changedVariable.value) {
            dispatch(resetInputValidationErrorUponEnvironmentVariableValueChange({
              flowId: flowId,
              variableName: changedVariable.key
            }));
          }
        });
      }
    }

    return (
      <Container
        id="workflowParameters"
        headerProps={{
          title: "",
          level: 3,
          rightSideButtonListProps: [
            {
              title: "+ Add new parameter",
              backgroundTone: UiColor.BackgroundColor.TRANSPARENT,
              titleTone: UiColor.TextColor.GOLD,
              onClick: handleAddClick,
            }
          ]
        }}
        indented={false}>
        <div className={"grid gap-1 py-1"}
             style={{gridTemplateColumns: "33% 1fr"}}>
          {environmentVariables
            && environmentVariables.map((variable, index) => (
              <EnvironmentVariableEditor
                key={`${variable.key}-${index}`}
                variable={variable}
                linkables={linkables}
                globalParameterReferenceResolver={resolveGlobalParameterReference}
                onChange={({variable}) => handleVariableChange({variable, index})}
                onDelete={() => handleDelete(index)}
              />
            ))}
        </div>
        {dialogProps && <Dialog {...dialogProps}/>}
      </Container>
    );
  }
;

export default EnvironmentEditor;
