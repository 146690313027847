"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateExecuteRequest = void 0;
const validateFlow_1 = require("../../common/validators/validateFlow");
const uuid_1 = require("uuid");
function validateExecuteRequest(request) {
    const output = [];
    const { action, userId, flow, env } = request;
    let stepsToValidate = [];
    if (userId !== undefined && !(0, uuid_1.validate)(userId)) {
        output.push({
            property: "userId",
            constraints: { "userIdShouldBeValid": "Invalid userId" }
        });
    }
    let actionErrors = [];
    if (action.type === "runSteps") {
        let invalidSteps = [];
        stepsToValidate = action.stepIds.reduce((prev, id, index) => {
            const step = flow.find((s) => s.id === id);
            if (!step) {
                invalidSteps.push({
                    property: `${index}`,
                    value: id,
                    constraints: { "stepIdShouldExist": `Requested step #${id} is not specified in the flow` }
                });
                return prev;
            }
            else {
                return [...prev, step];
            }
        }, []);
        if (invalidSteps.length > 0) {
            actionErrors.push({
                property: "stepIds",
                children: invalidSteps
            });
        }
    }
    else {
        stepsToValidate = flow;
    }
    if (actionErrors.length > 0) {
        output.push({
            property: "action",
            children: actionErrors
        });
    }
    const flowErrors = (0, validateFlow_1.validateFlow)(stepsToValidate, flow, env);
    if (flowErrors.length > 0) {
        output.push({
            property: "flow",
            children: flowErrors
        });
    }
    return output;
}
exports.validateExecuteRequest = validateExecuteRequest;
