import {GroupModel, GroupModelV0, isGroupModel, isGroupModelV0} from "./GroupModel";

export type GroupState = {
  model: GroupModel;
}

export const isGroupState = (maybeGroupState: unknown): maybeGroupState is GroupState => {
  return typeof maybeGroupState === "object" && maybeGroupState !== null
    && "model" in maybeGroupState && isGroupModel(maybeGroupState.model)
}
// versioning
export type GroupStateV2 = GroupState;
export const isGroupStateV2 = isGroupState;

// no change from v1 to v2
export type GroupStateV1 = GroupStateV2;
export const isGroupStateV1 = isGroupStateV2;

// model changed in v1
export type GroupStateV0 = Omit<GroupState, "model"> & { model: GroupModelV0 }
export const isGroupStateV0 = (maybeGroupState: unknown): maybeGroupState is GroupStateV0 => {
  return typeof maybeGroupState === "object" && maybeGroupState !== null
    && "model" in maybeGroupState && isGroupModelV0(maybeGroupState.model)
}
