import React from "react";
import {UiColor} from "../../../utils/constants/UiColor";

const maxTimeoutSeconds = 600;
const ButtonSpinner = (
  {
    timeout = 0,
    backgroundTone = UiColor.BackgroundColor.GRAY,
  }) => {

  const timeoutSeconds = timeout > 1000 ? Math.floor(timeout / 1000) : timeout;
  const validTimeoutSeconds = timeoutSeconds > maxTimeoutSeconds
    ? maxTimeoutSeconds
    : (timeoutSeconds < 0 ? 0 : timeoutSeconds);
  const validSubTimeoutMilliseconds = Math.floor((validTimeoutSeconds * 1000) / 12);

  return (
    <span className={`absolute top-0 left-0 w-full h-full ${backgroundTone}/90`}>
      <span className={"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"}>
        <svg width="20" height="20" viewBox="0 0 200 200" stroke="currentColor" fill="transparent">
          <circle className={`animate-circleTimerOuter`}
                  style={{
                    animationDuration: `${validTimeoutSeconds || 3}s`,
                    animationIterationCount: validTimeoutSeconds === 0 ? "infinite" : 1
                  }} cx="100" cy="100" r="80" strokeWidth="40"/>
          <circle
            className={`opacity-50 animate-circleTimerInner ${timeout === 0 ? "hidden" : ""}`}
            style={{
              animationDuration: `${validSubTimeoutMilliseconds}ms`,
              animationIterationCount: validTimeoutSeconds === 0 ? 0 : 12
            }} cx="100" cy="100" r="30"
            strokeWidth="60"/>
          <circle
            className={`opacity-0 animate-pulse ${timeout === 0 ? "hidden" : ""}`}
            style={{
              animationIterationCount: validTimeoutSeconds === 0 ? 0 : 3,
              animationDelay: `${validTimeoutSeconds}s`
            }} cx="100" cy="100" r="30"
            strokeWidth="60"/>
          <circle className="circle-bg opacity-10" cx="100" cy="100" r="80" strokeWidth="40"/>
        </svg>
      </span>
    </span>
  )
}

export default ButtonSpinner;
