import {useAppDispatch} from "../../hooks";
import Container from "../../../common/components/container/Container";
import Button from "../../../common/components/button/Button";
import React from "react";
import {rtkQueryApis} from "../../store";
import {Api} from "@reduxjs/toolkit/query";

const apis: Record<string, Api<any, any, any, any, any>> = rtkQueryApis
  .reduce((acc, api) => ({...acc, [api.reducerPath]: api}), {});
export const ResetApiState = () => {
  const dispatch = useAppDispatch();

  const handleResetApiState = (api: typeof apis[keyof typeof apis]) => {
    const action = api.util.resetApiState()
    dispatch(action);
  }
  return (
    <Container headerProps={{title: "Reset Apis"}}>
      <div className={"w-fit flex flex-col gap-2"}>
        {Object.entries(apis).map(([apiName, api]) => (
          <Button key={apiName} title={`Reset ${apiName}`} onClick={() => handleResetApiState(api)}/>
        ))}
      </div>
    </Container>
  );
}
