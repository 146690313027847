import {FlowModel} from "../../../types/FlowModel";
import Container from "../../../../common/components/container/Container";
import {ChatMessageActor} from "../ai-chat/types/ChatMessageActor";
import AiChat from "../ai-chat/AiChat";
import Button, {ButtonType} from "../../../../common/components/button/Button";
import {UiColor} from "../../../../utils/constants/UiColor";
import {useEffect, useState} from "react";
import {classNames} from "../../../../utils/classNames";
import {
  useCreateStepConversationMutation,
  useGenerateStepSummaryMutation,
  useGetStepConversationQuery,
  usePostStepMessageMutation
} from "../../../mentorApi";
import {ChatMessage} from "../ai-chat/types/ChatMessage";
import {skipToken} from "@reduxjs/toolkit/query";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {selectStep, updateStepById, updateStepPorts} from "../../../stepsSlice";
import {selectFlow} from "../../../flowsSlice";
import {FlowConversationMessageResponse, StepConversationSummaryResponse} from "@cranq-gpt-lowcode/contracts";
import {StepModel} from "../../../types/StepModel";
import ReactGA from "react-ga4";
import {StepConversation} from "../types/StepConversation";
import {getFormattedError} from "../utils/getFormattedError";
import {getErrorReason} from "../utils/GetErrorReason";
import {StepChatSummary} from "./StepChatSummary";
import {useUpdateFlowSnapshot} from "../utils/useUpdateFlowSnapshot";

/**
 * Helper function until the summary messages will be marked with a flag
 */
const isSummaryMessage = (message: string) => {
  const score = (message.includes("[Summary]") ? 1 : 0)
    + (message.includes("[Explanation]") ? 1 : 0)
    + (message.includes("[Inputs]") ? 1 : 0)
    + (message.includes("[Outputs]") ? 1 : 0);
  return score >= 3;
};

type StepChatProps = {
  flowId: FlowModel["id"];
  stepId: StepModel["id"];
  onChatCompleted?: () => void;
};

/**
 * This component is responsible for:
 *  - manage the state of the chat
 *  - provide data to AiChat
 */
const StepChat = (
  {
    flowId,
    stepId,
    onChatCompleted
  }: StepChatProps) => {
  const dispatch = useAppDispatch();
  const flow = useAppSelector(selectFlow(flowId));
  if (!flow) {
    throw new Error(`Flow with id ${flowId} not found`);
  }
  const step = useAppSelector(selectStep(stepId));
  if (!step) {
    throw new Error(`Step with id ${stepId} not found`);
  }
  if (step.model.flowId !== flowId) {
    throw new Error(`Step with id ${stepId} does not belong to flow with id ${flowId}`);
  }

  // local state init
  const [showSummary, setShowSummary] = useState(false);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [conversation, setConversation] = useState<StepConversation>({
    statusMessage: "Loading...",
    lastUpdated: -1
  });
  const [initialized, setInitialized] = useState(false);
  const [completed, setCompleted] = useState(false);

  // update flow snapshot upon initialization
  const updateFlowSnapshotWithReason = useUpdateFlowSnapshot(flowId, stepId);
  useEffect(() => {
    if (!initialized) {
      setConversation((conversation) => ({
        ...conversation,
        statusMessage: "Updating workflow data...",
        lastUpdated: -1
      }));
      updateFlowSnapshotWithReason("conversation_start")
        .then((result) => {
          if ("error" in result && result.error) {
            console.error(`Failed to update flow snapshot: ${result.error}`);
            setConversation((conversation) => ({
              ...conversation,
              statusMessage: "Failed to update workflow data, chat cannot be initialized. Please reload the page and retry."
            }));
          } else {
            setInitialized(true);
          }
        });
    }
  }, [initialized, updateFlowSnapshotWithReason])

  // update flow snapshot upon completion
  useEffect(() => {
    if (completed) {
      setConversation((conversation) => ({
        ...conversation,
        statusMessage: "Updating workflow data...",
        lastUpdated: -1
      }));
      updateFlowSnapshotWithReason("summary_accepted")
        .then((result) => {
          if ("error" in result && result.error) {
            console.error(`Failed to update flow snapshot: ${result.error}`);
            setConversation((conversation) => ({
              ...conversation,
              statusMessage: "Failed to update workflow data, chat cannot be initialized. Please reload the page and retry."
            }));
          }
          // independently of the eventual error we consider the chat as completed
          onChatCompleted && onChatCompleted()
        });
    }
  }, [completed, onChatCompleted, updateFlowSnapshotWithReason])

  // api hooks
  const shouldSyncConversation = initialized && conversation.lastUpdated < (step.lastChatUpdate ?? 0);
  const {
    currentData: conversationData,
    isFetching: conversationIsFetching,
    isSuccess: conversationIsSuccess,
    isError: conversationIsError,
    error: conversationError,
  } = useGetStepConversationQuery(shouldSyncConversation ? {flowId, stepId} : skipToken);
  const [createConversation] = useCreateStepConversationMutation();
  const [generateSummary] = useGenerateStepSummaryMutation();
  const [postMessage] = usePostStepMessageMutation();

  // load chat messages from api
  useEffect(() => {
    if (shouldSyncConversation) {
      if (conversationIsSuccess && conversationData) {
        const messages: ChatMessage[] = conversationData.messages
          .map((message) => ({
            message: message.message,
            role: message.role === "assistant" ? ChatMessageActor.Assitant : ChatMessageActor.User,
            ...(isSummaryMessage(message.message) ? {isSummary: true} : {})
          }));
        setConversation((conversation) =>
          ({
            ...conversation,
            statusMessage: undefined,
            messages,
            ...(conversationData.summary
              ? {
                summary: {
                  description: conversationData.summary.summary,
                  inputs: conversationData.summary.step?.interface?.inputs ?? [],
                  outputs: conversationData.summary.step?.interface?.outputs ?? []
                }
              }
              : {}),
            lastUpdated: step.lastChatUpdate ?? -1
          }));
      }
      // handle expected 404 error if step conversation was not initialized yet
      if (conversationIsError) {
        if ("status" in conversationError
          && conversationError.status === 404
          && getErrorReason(conversationError) === "INVALID_STEP_ID"
        ) {
          setConversation((conversation) => ({
            ...conversation,
            statusMessage: "Initiating conversation about step...",
            lastUpdated: Date.now()
          }));
          setWaitingForResponse(true);
          createConversation({flowId, stepId})
            .then((response) => {
              if ("error" in response) {
                const reason = getErrorReason(response.error);
                if (reason === "UNSUPPORTED") {
                  setConversation((conversation) => ({
                    ...conversation,
                    statusMessage: "This step was not created by AI Wizard, so it cannot be discussed (yet...)",
                    lastUpdated: Date.now()
                  }));
                } else {
                  setConversation((conversation) => ({
                    ...conversation,
                    statusMessage: "Failed to create conversation",
                    lastUpdated: Date.now()
                  }));
                }
                return;
              } else {
                setWaitingForResponse(false);
                // update StepState to rerender and refetch conversation
                dispatch(updateStepById({stepId, update: {lastChatUpdate: Date.now()}}));
              }
            });
        } else {
          console.error("Failed to load conversation", conversationError);
          setConversation((conversation) => ({
            ...conversation,
            statusMessage: "Failed to load conversation"
          }));
        }
      }
    }
  }, [conversation.lastUpdated, conversationData, conversationError, conversationIsError, conversationIsSuccess, createConversation, dispatch, flowId, shouldSyncConversation, step.lastChatUpdate, stepId]);

  const handleSendMessage = async (message: string) => {
    setWaitingForResponse(true);
    setConversation((conversation) => ({
      ...conversation,
      messages: [
        ...(conversation.messages ?? []),
        {message, role: ChatMessageActor.User},
        {message: "...", role: ChatMessageActor.Assitant}
      ]
    }));
    const onEvent = (chunk: FlowConversationMessageResponse) => {
      setConversation((conversation) => ({
        ...conversation,
        messages: [
          ...(conversation.messages ?? []).slice(0, -1),
          {message: `${chunk.message}...`, role: ChatMessageActor.Assitant}
        ]
      }));
    }
    return postMessage({flowId, stepId, message, onEvent})
      .then((response) => {
        setWaitingForResponse(false);
        if ("error" in response) {
          console.error("Failed to send message", response.error);
          setConversation((conversation) => ({
            ...conversation,
            messages: [
              ...(conversation.messages ?? []).slice(0, -2)
            ]
          }));
          dispatch(updateStepById({stepId, update: {lastChatUpdate: Date.now()}}));
          return false;
        }
        dispatch(updateStepById({stepId, update: {lastChatUpdate: Date.now()}}));
        return true;
      });
  }

  const handleSummaryRequest = () => {
    setShowSummary(true);
    if (!isLastMessageSummary) {
      ReactGA.event({
        category: "chat",
        action: "REQUEST_STEP_CHAT_SUMMARY",
        label: `Flow: ${flowId}, Step: ${stepId}`
      });
      setWaitingForResponse(true);
      setConversation((conversation) => {
        return ({
          ...conversation,
          summary: {
            ...conversation.summary,
            description: "",
            inputs: [],
            outputs: [],
          }
        });
      });
      const onEvent = (chunk: StepConversationSummaryResponse) => {
        setConversation((conversation) => ({
          ...conversation,
          summary: {
            ...conversation.summary,
            description: chunk.summary,
            inputs: chunk.step?.interface?.inputs ? chunk.step.interface.inputs : [],
            outputs: chunk.step?.interface?.outputs ? chunk.step.interface.outputs : [],
          }
        }));
      }
      generateSummary({flowId, stepId, onEvent})
        .then((response) => {
          if ("error" in response) {
            console.error("Failed to generate breakdown", response.error);
            const reason = getFormattedError(response.error);
            setConversation((conversation) => ({
              ...conversation,
              statusMessage: "Failed to generate summary",
              error: new Error(reason)
            }));
          }
          dispatch(updateStepById({stepId, update: {lastChatUpdate: Date.now()}}));
          setWaitingForResponse(false);
          return;
        });
    }
  }
  const handleModifyStep = () => {
    ReactGA.event({
      category: "chat",
      action: "REQUEST_STEP_CHAT_MODIFIED_STEP",
      label: `Flow: ${flowId}, Step: ${stepId}`,
    });
    const {summary} = conversation;
    if (summary) {
      dispatch(updateStepById({
        stepId,
        update: {
          model: {
            description: `${summary.description?.trim()}`,
          },
          lastChatUpdate: undefined
        }
      }));
      dispatch(updateStepPorts({
        stepId,
        inputs: summary.inputs
          .map((input) => {
            const {source: _, ...rest} = input;
            return rest;
          }),
        outputs: summary.outputs
      }));
      setCompleted(true);
    }
  };

  // derived variables
  const isLastMessageSummary = (conversation.messages ?? []).slice(-1)[0]?.isSummary;
  const isProceedEnabled = !waitingForResponse
    && !conversationIsFetching
    && (conversation.messages ?? [])
      .filter((message) => message.role === ChatMessageActor.User)
      .length > 0;

  return (
    <div className={"h-full w-screen max-w-screen-md flex flex-row overflow-hidden relative"}>
      <div className={classNames(`
        h-full w-full overflow-hidden 
        flex flex-col gap-4
        absolute top-0 ${showSummary ? "-left-full" : "left-0"}
        transition-all duration-500
      `)}>
        {conversationIsError
          ? <div>Failed to load chat, please try again! <pre>{getFormattedError(conversationError)}</pre></div>
          : (<>
              <AiChat statusMessage={conversation.statusMessage}
                      messages={conversation.messages}
                      waitingForResponse={waitingForResponse}
                      onMessageSend={handleSendMessage}/>
              <Container shrinkOrGrow={"no-shrink"}>
                <div className={`flex justify-center items-center pt-4 border-t-2 ${UiColor.BorderColor.GOLD}`}>
                  <Button title={`${isLastMessageSummary ? "View Summary >>" : "Proceed >>"}`}
                          disabled={!isProceedEnabled} onClick={handleSummaryRequest}/>
                </div>
              </Container>
            </>
          )}
      </div>
      <div className={classNames(`
        h-full w-full overflow-hidden
        flex flex-col gap-4
        absolute top-0 ${showSummary ? "left-0" : "left-full"}
        transition-all duration-500
      `)}>{conversation.error
        ? <div>Error while trying the generate summary: <pre>{conversation.error.message}</pre></div>
        : (
          <>
            <StepChatSummary
              summary={conversation.summary}
            />
            <Container shrinkOrGrow={"no-shrink"}>
              <div className={`flex justify-center items-center gap-4 pt-4 border-t-2 ${UiColor.BorderColor.GOLD}`}>
                <Button title={"<< I'd like to refine, let's continue discussion"} disabled={waitingForResponse}
                        type={ButtonType.SECONDARY}
                        shadowTone={UiColor.ShadowColor.BLACK_MEDIUM}
                        onClick={() => setShowSummary(false)}/>
                <Button title={"Looks fine, let's update the Step!"}
                        type={ButtonType.PRIMARY}
                        shadowTone={UiColor.ShadowColor.BLACK_MEDIUM}
                        disabled={waitingForResponse}
                        onClick={handleModifyStep}/>
              </div>
            </Container>
          </>
        )}
      </div>
    </div>
  );
};

export default StepChat;
