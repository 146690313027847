import {FunctionComponent, SVGProps} from "react";
import {ReactComponent as AddIcon} from "../../assets/add.svg";
import {ReactComponent as PlayIcon} from "../../assets/play.svg";
import {ReactComponent as DeleteIcon} from "../../assets/delete.svg";
import {ReactComponent as DownloadIcon} from "../../assets/download.svg";
import {ReactComponent as EditIcon} from "../../assets/edit.svg";
import {ReactComponent as InspectIcon} from "../../assets/inspect.svg";
import {ReactComponent as CopyIcon} from "../../assets/copy.svg";
import {ReactComponent as PasteIcon} from "../../assets/paste.svg";
import {ReactComponent as LinkIcon} from "../../assets/link.svg";
import {ReactComponent as UnlinkIcon} from "../../assets/unlink.svg";
import {ReactComponent as ShareIcon} from "../../assets/share.svg";
import {ReactComponent as ScheduleIcon} from "../../assets/schedule.svg";
import {ReactComponent as DescheduleIcon} from "../../assets/deschedule.svg";
import {ReactComponent as Webhook} from "../../assets/webhook.svg";
import {ReactComponent as DeleteWebhook} from "../../assets/delete_webhook.svg";
import {ReactComponent as Workflow} from "../../assets/workflow.svg";
import {ReactComponent as LabIcon} from "../../assets/lab.svg";
import {ReactComponent as UpIcon} from "../../assets/up.svg";
import {ReactComponent as RightIcon} from "../../assets/right.svg";
import {ReactComponent as DownIcon} from "../../assets/down.svg";
import {ReactComponent as BackIcon} from "../../assets/back.svg";
import {ReactComponent as VerticalListIcon} from "../../assets/vertical_list.svg";
import {ReactComponent as HorizontalListIcon} from "../../assets/horizontal_list.svg";
import {ReactComponent as Close} from "../../assets/close.svg";
import {ReactComponent as InfoBig} from "../../assets/info_big.svg";
import {ReactComponent as Code} from "../../assets/code.svg";
import {ReactComponent as Hint} from "../../assets/hint.svg";
import {ReactComponent as Magick} from "../../assets/magick-wand.svg";
import {ReactComponent as Check} from "../../assets/check.svg";
import {ReactComponent as CheckBig} from "../../assets/check_big.svg";
import {ReactComponent as WarningBig} from "../../assets/warning_big.svg";
import {ReactComponent as ErrorBig} from "../../assets/error_big.svg";
import {ReactComponent as QuestionBig} from "../../assets/question_big.svg";
import {ReactComponent as LogoGoogle} from "../../assets/Google.svg";
import {ReactComponent as LogoFacebook} from "../../assets/Facebook.svg";
import {ReactComponent as LogoApple} from "../../assets/Apple.svg";
import {ReactComponent as LogoGithub} from "../../assets/Github.svg";
import {ReactComponent as LogoMicrosoft} from "../../assets/Microsoft.svg";
import {ReactComponent as LogoTwitter} from "../../assets/Twitter.svg";
import {ReactComponent as RefreshIcon} from "../../assets/refresh.svg";
import {ReactComponent as SettingsIcon} from "../../assets/settings.svg";
import {ReactComponent as LogoDiscord} from "../../assets/Discord.svg";
import {InlineIconName} from "./InlineIconName";


export const inlineIconsMapping: Record<
  InlineIconName,
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  [InlineIconName.ADD]: AddIcon,
  [InlineIconName.PLAY]: PlayIcon,
  [InlineIconName.DELETE]: DeleteIcon,
  [InlineIconName.DOWNLOAD]: DownloadIcon,
  [InlineIconName.EDIT]: EditIcon,
  [InlineIconName.INSPECT]: InspectIcon,
  [InlineIconName.COPY]: CopyIcon,
  [InlineIconName.PASTE]: PasteIcon,
  [InlineIconName.REFRESH]: RefreshIcon,
  [InlineIconName.SETTINGS]: SettingsIcon,
  [InlineIconName.LAB]: LabIcon,
  [InlineIconName.LINK]: LinkIcon,
  [InlineIconName.UNLINK]: UnlinkIcon,
  [InlineIconName.SHARE]: ShareIcon,
  [InlineIconName.SCHEDULE]: ScheduleIcon,
  [InlineIconName.DESCHEDULE]: DescheduleIcon,
  [InlineIconName.WEBHOOK]: Webhook,
  [InlineIconName.DELETE_WEBHOOK]: DeleteWebhook,
  [InlineIconName.WROKFLOW]: Workflow,
  [InlineIconName.UP]: UpIcon,
  [InlineIconName.RIGHT]: RightIcon,
  [InlineIconName.DOWN]: DownIcon,
  [InlineIconName.BACK]: BackIcon,
  [InlineIconName.VERTICAL_LIST]: VerticalListIcon,
  [InlineIconName.HORIZONTAL_LIST]: HorizontalListIcon,
  [InlineIconName.CLOSE]: Close,
  [InlineIconName.CODE]: Code,
  [InlineIconName.HINT]: Hint,
  [InlineIconName.MAGICK]: Magick,
  [InlineIconName.INFO_BIG]: InfoBig,
  [InlineIconName.CHECK_BIG]: CheckBig,
  [InlineIconName.CHECK]: Check,
  [InlineIconName.WARNING_BIG]: WarningBig,
  [InlineIconName.ERROR_BIG]: ErrorBig,
  [InlineIconName.QUESTION_BIG]: QuestionBig,
  [InlineIconName.LOGO_GOOGLE]: LogoGoogle,
  [InlineIconName.LOGO_APPLE]: LogoApple,
  [InlineIconName.LOGO_FACEBOOK]: LogoFacebook,
  [InlineIconName.LOGO_GITHUB]: LogoGithub,
  [InlineIconName.LOGO_MICROSOFT]: LogoMicrosoft,
  [InlineIconName.LOGO_TWITTER]: LogoTwitter,
  [InlineIconName.LOGO_DISCORD]: LogoDiscord,
};

export const inlineIconNames = [...Object.values(InlineIconName)]
  .sort((a, b) => a.localeCompare(b));
