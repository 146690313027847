import {detailedDiff} from "deep-object-diff";
import {StepModel} from "../types/StepModel";
import {unique} from "../../utils/unique";
import {isId} from "../../utils/types/Id";
import {StepState} from "../types/StepState";
import {updateFlowHashAction} from "../actions/updateFlowHashAction";
import {RootState, store} from "../store";

export const updateFlowHashUponStepsChanges = (
  state: RootState["steps"],
  previousState: RootState["steps"]
) => {
  const diff = detailedDiff(previousState, state)
  const {added, deleted, updated} = diff;
  const getStepByStepId = (state: RootState["steps"]) => (stepId: StepModel["id"]) => state[stepId];

  const flowsToUpdate = unique(
    [added, updated]
      .flatMap((i) => Object.keys(i))
      .filter(isId)
      .map(getStepByStepId(state))
      .concat(
        Object.keys(deleted)
          .filter(isId)
          .map(getStepByStepId(previousState)) //deleted steps must be found in previousState
      )
      .filter((i): i is StepState => i !== undefined)
      .map((step) => step.model.flowId)
  );
  flowsToUpdate.forEach((flowId) => store.dispatch(updateFlowHashAction(flowId)))
}
