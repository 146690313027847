import IconButton, {IconButtonProps} from "../icon-button/IconButton";
import {InlineIconName} from "../inline-icon/InlineIconName";
import {UiColor} from "../../../utils/constants/UiColor";
import React from "react";

type DeleteItemActionButtonProps = Omit<IconButtonProps, "iconName" | "iconTone" | "size" | "disableTabindex">;
const DeleteItemActionButton = (props: DeleteItemActionButtonProps) =>
  <div className={"p-1 absolute -right-6 top-1 opacity-0 group-hover:opacity-100"}>
    <IconButton
      iconName={InlineIconName.DELETE}
      iconTone={UiColor.TextColor.RED}
      size={"small"}
      disableTabindex={true}
      {...props} />
  </div>

export default DeleteItemActionButton;
