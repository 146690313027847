import {StepState} from "../../../types/StepState";
import {EnvironmentState} from "../../../types/EnvironmentState";
import {StepInputModel} from "../../../types/StepInputModel";
import {safeJsonStringify} from "../../../utils/safeJsonStringify";
import {GroupState} from "../../../types/GroupState";
import {GlobalParametersState} from "../../../globalParametersSlice";
import {
  createGlobalParameterReferenceResolver,
  GlobalParameterReferencePrefix
} from "../../environment-editor/utils/createGlobalParameterReferenceResolver";

export const getLinkedPropertyDisplay = (
  steps: StepState[],
  groups: GroupState[],
  environment: EnvironmentState,
  globalParameters: GlobalParametersState,
  source: StepInputModel["source"]
): { label: string, value: string } | undefined => {
  const resolveGlobalParameterReference = createGlobalParameterReferenceResolver(
    globalParameters, GlobalParameterReferencePrefix);

  switch (source.type) {

    case "output":
      const step = steps.find((step) => step.model.id === source.stepId);
      const group = groups.find((group) => group.model.id === step?.model.groupId);
      const output = step?.model.outputs[source.outputId];
      if (step && output) {
        return {
          label: `value of output ${group ? `<${group.model.title}>/` : ""}<${step.model.title}>/<${output.description}>`,
          value: safeJsonStringify(output.value) ?? `future value of output <${step.model.title}>/<${output.description}>`
        };
      }
      break;
    case "env":
      const envVariable = environment.model.variables.find((variable) => variable.key === source.name);
      if (envVariable) {
        const resolvedValue = resolveGlobalParameterReference(envVariable.value);
        return {
          label: `value of flow parameter <${envVariable.key}>`,
          value: safeJsonStringify(resolvedValue) ?? `future value of flow parameter <${envVariable.key}>`
        };
      } else {
        return {
          label: `value of flow parameter <${source.name}>`,
          value: `missing flow parameter <${source.name}>`
        };
      }
  }
  return undefined;
};
