import Layout from "../../../common/components/layout/Layout";
import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import MyFlows from "../../components/my-flows/MyFlows";
import Container from "../../../common/components/container/Container";
import GlobalParams from "../../components/global-params/GlobalParams";

const MyFlowsPage = () => {
  return (
    <Layout
      sideBarContent={<Sidebar showFlowList={false}/>}
      leftPanelContent={(
        <Container
          headerProps={{title: "My workflows"}}
          overflowY={"hidden"}
          height={"full"}
          paddingY={false}
          indented={false}>
          <MyFlows/>
        </Container>
      )}
      rightPanelContent={(
        <GlobalParams/>
      )}
    />
  );
};

export default MyFlowsPage;
