import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react";
import {ExecuteRequest, ExecuteResponse, ExecutionStatusResponse} from "@cranq-gpt-lowcode/contracts";
import {EndpointBuilder} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {ApiContext, getApiBaseUrl} from "./utils/getApiBaseUrl";

export const runnerApiRawEndpointBuilder = (
  builder: EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, never, "runnerApi">
) => ({
  execute: builder.mutation<ExecuteResponse, ExecuteRequest>({
    query: (request) => ({
      url: "execute",
      method: "POST",
      body: request
    })
  }),
  getExecutionStatus: builder.query<ExecutionStatusResponse, string>({
    query: (executionId) => ({
      url: `execution/${executionId}`,
      method: "GET"
    }),
    keepUnusedDataFor: 0 // no caching
  })
});

const runnerApi = createApi({
  reducerPath: "runnerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getApiBaseUrl(ApiContext.RUNNER),
    credentials: "include",
    prepareHeaders: (headers) => {
      headers.append("Ga-Client-Id", window.__GLOBAL_VAR__.GA_ClIENT_ID || "unset");
    }
  }),
  endpoints: runnerApiRawEndpointBuilder
});

export const {
  useExecuteMutation,
  useGetExecutionStatusQuery
} = runnerApi;
export default runnerApi;
