import {useEffect, useState} from "react";
import IconButton, {IconButtonProps} from "../icon-button/IconButton";
import {InlineIconName} from "../inline-icon/InlineIconName";
import {UiColor} from "../../../utils/constants/UiColor";
import {Branch, isTerminalBranch} from "../tree-selector/TreeSelector";

export type BranchOutputs = Record<string, { name: string, value: string }>;

export type BranchContainerProps = {
  containerTitle: string,
  branch: Branch,
  backgroundTone?: string,
  titleTone?: UiColor.TextColor,
  collapsed?: boolean,
  //events
  onCollapseChange?: (isCollapsed: boolean) => void,
  onItemClick?: (event: {
    key: string,
    value: string,
  }) => void,
  onBranchClick?: (event: { branchKeys: string[], key: string, value: string }) => void
}
const BranchContainer = (props: BranchContainerProps) => {
  const {
    containerTitle,
    collapsed,
    branch,
    backgroundTone = UiColor.BackgroundColor.TRANSPARENT,
    titleTone = UiColor.TextColor.BLACK,
    onCollapseChange,
    onItemClick,
    onBranchClick
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect((() => {
    onCollapseChange && onCollapseChange(isCollapsed);
  }), [isCollapsed, onCollapseChange]);
  useEffect((() => {
    setIsCollapsed(collapsed || true);
  }), [collapsed]);

  const toggleCollapse = () => {
    setIsCollapsed((previousIsCollapsed) =>
      !previousIsCollapsed);
  };

  const collapseButtonProps: IconButtonProps = {
    iconName: isCollapsed ? InlineIconName.RIGHT : InlineIconName.UP,
    iconTone: titleTone,
    size: "small",
    onClick: toggleCollapse
  };

  return (
    <article className="w-fit">
      <section className={`${backgroundTone} grid gap-1 items-center px-4`}
               style={{gridTemplateColumns: "min-content 1fr min-content 1fr"}}>
        <IconButton {...collapseButtonProps} />
        <h3 className={`${titleTone} col-span-3 cursor-pointer`}
            onClick={toggleCollapse}>{containerTitle}</h3>
        <div className={`${isCollapsed ? "hidden" : ""} ${backgroundTone} contents`}>
          {
            isTerminalBranch(branch) ?
              // show leaves
              Object.entries(branch.leaves).map(([key, output]) => {
                return (
                  <div className="contents cursor-pointer"
                       key={key}
                       title={`${output.name} = ${output.value}`}
                       onClick={() => {
                         onItemClick && onItemClick({key, value: output.value});
                       }}>
                <span className={`
                  ${titleTone} 
                  text-xs
                  col-start-2 
                  max-w-[10rem] 
                  overflow-hidden 
                  overflow-ellipsis 
                  whitespace-nowrap`}
                >{output.name}</span>
                    <span className={`${titleTone} text-xs`}>=</span>
                    <span
                      className={`${titleTone} text-xs max-w-[10rem] overflow-hidden overflow-ellipsis whitespace-nowrap`}
                    >{output.value}</span>
                  </div>);
              })
              : Object.entries(branch.branches).map(([subBranchKey, subBranch]) => (
                <div className={"col-span-3 col-start-2"}>
                  <BranchContainer
                    containerTitle={subBranch.branchName}
                    titleTone={titleTone}
                    backgroundTone={backgroundTone}
                    branch={subBranch}
                    onItemClick={({key, value}) => {
                      onBranchClick && onBranchClick({branchKeys: [subBranchKey], key, value});
                    }}
                    onBranchClick={({branchKeys, key, value}) => {
                      onBranchClick && onBranchClick({branchKeys: [subBranchKey, ...branchKeys], key, value});
                    }}
                  />
                </div>
              ))
          }
        </div>
      </section>
    </article>
  );
};

export default BranchContainer;
