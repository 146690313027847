import {FlowModel} from "../../../types/FlowModel";
import {StepsState} from "../../../stepsSlice";
import {StepStatus} from "../../../types/StepStatus";
import {calculateStepStatus} from "../../step/utils/calculateStepStatus";

export const getFlowState = (flowId: FlowModel["id"], steps: StepsState): keyof typeof StepStatus => {
  return Object.values(steps)
    .filter((step) => step.model.flowId === flowId)
    .map((step) => calculateStepStatus(step))
    .sort((statusA, statusB) => Math.sign(StepStatus[statusA] - StepStatus[statusB]))[0] ?? "blank";
}
