import {FlowModel, FlowModelV0, isFlowModelV0, isFlowModelV1} from "./FlowModel";
import {StepModel} from "./StepModel";
import {RequestId} from "../../utils/types/RequestId";
import {Uuid} from "../../utils/types/Uuid";
import {Timestamp} from "../../utils/types/Timestamp";

export type StepId = StepModel["id"]
export type FlowState = {
  model: FlowModel
  timelineOrientation?: "horizontal" | "vertical",
  executionId?: RequestId,
  executedStepIds?: StepId[],
  //DEPRECATED:
  generateStepsRequestId?: RequestId,
  //DEPRECATED:
  generateStepsAutoStart?: boolean,
  //DEPRECATED:
  generateHintsForStepsRequestId?: RequestId,
  scheduledJobId?: Uuid,
  scheduledJobExpiryDate?: Timestamp,
  scheduledJobHash?: string
  webhookId?: Uuid,
  webhookExpiryDate?: Timestamp,
  webhookHash?: string
  lastChatUpdate?: number,
}

export const isFlowStateBase = (maybeFlowState: unknown): maybeFlowState is FlowStateV1 | FlowStateV0 => {
  return typeof maybeFlowState === "object" && maybeFlowState !== null
    && ("timelineOrientation" in maybeFlowState ? maybeFlowState.timelineOrientation === "horizontal" || maybeFlowState.timelineOrientation === "vertical" : true)
    && ("executionId" in maybeFlowState ? typeof maybeFlowState.executionId === "string" : true)
    && ("executedStepIds" in maybeFlowState ? Array.isArray(maybeFlowState.executedStepIds) && maybeFlowState.executedStepIds.every((id) => typeof id === "string") : true)
    && ("generateStepsRequestId" in maybeFlowState ? typeof maybeFlowState.generateStepsRequestId === "string" : true)
    && ("generateStepsAutoStart" in maybeFlowState ? typeof maybeFlowState.generateStepsAutoStart === "boolean" : true)
    && ("generateHintsForStepsRequestId" in maybeFlowState ? typeof maybeFlowState.generateHintsForStepsRequestId === "string" : true)
    && ("scheduledJobId" in maybeFlowState ? typeof maybeFlowState.scheduledJobId === "string" : true)
    && ("scheduledJobExpiryDate" in maybeFlowState ? typeof maybeFlowState.scheduledJobExpiryDate === "number" : true)
    && ("scheduledJobHash" in maybeFlowState ? typeof maybeFlowState.scheduledJobHash === "string" : true)
    && ("webhookId" in maybeFlowState ? typeof maybeFlowState.webhookId === "string" : true)
    && ("webhookExpiryDate" in maybeFlowState ? typeof maybeFlowState.webhookExpiryDate === "number" : true)
    && ("webhookHash" in maybeFlowState ? typeof maybeFlowState.webhookHash === "string" : true)
    && ("lastChatUpdate" in maybeFlowState ? typeof maybeFlowState.lastChatUpdate === "number" : true)
}
export const isFlowState = (maybeFlowState: unknown): maybeFlowState is FlowState => {
  return isFlowStateBase(maybeFlowState)
    && "model" in maybeFlowState && maybeFlowState.model !== null && typeof maybeFlowState.model === "object" && isFlowModelV1(maybeFlowState.model)
}

// versioning
export type FlowStateV2 = FlowState;
export const isFlowStateV2 = isFlowState;

// no change from v1 to v2
export type FlowStateV1 = FlowStateV2;
export const isFlowStateV1 = isFlowStateV2;

// model changed in v1
export type FlowStateV0 = Omit<FlowState, "model"> & {
  model: FlowModelV0
}
export const isFlowStateV0 = (maybeFlowState: unknown): maybeFlowState is FlowStateV0 => {
  return typeof maybeFlowState === "object" && maybeFlowState !== null
    && "model" in maybeFlowState && maybeFlowState.model !== null && typeof maybeFlowState.model === "object" && isFlowModelV0(maybeFlowState.model)
    && ("timelineOrientation" in maybeFlowState ? maybeFlowState.timelineOrientation === "horizontal" || maybeFlowState.timelineOrientation === "vertical" : true)
    && ("executionId" in maybeFlowState ? typeof maybeFlowState.executionId === "string" : true)
    && ("executedStepIds" in maybeFlowState ? Array.isArray(maybeFlowState.executedStepIds) && maybeFlowState.executedStepIds.every((id) => typeof id === "string") : true)
    && ("generateStepsRequestId" in maybeFlowState ? typeof maybeFlowState.generateStepsRequestId === "string" : true)
    && ("generateStepsAutoStart" in maybeFlowState ? typeof maybeFlowState.generateStepsAutoStart === "boolean" : true)
    && ("generateHintsForStepsRequestId" in maybeFlowState ? typeof maybeFlowState.generateHintsForStepsRequestId === "string" : true)
    && ("scheduledJobId" in maybeFlowState ? typeof maybeFlowState.scheduledJobId === "string" : true)
    && ("scheduledJobExpiryDate" in maybeFlowState ? typeof maybeFlowState.scheduledJobExpiryDate === "number" : true)
    && ("scheduledJobHash" in maybeFlowState ? typeof maybeFlowState.scheduledJobHash === "string" : true)
    && ("lastChatUpdate" in maybeFlowState ? typeof maybeFlowState.lastChatUpdate === "number" : true)
}
