"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateFlow = void 0;
const validateStep_1 = require("./validateStep");
function validateFlow(stepsToExecute, flow, env) {
    return stepsToExecute.reduce((prev, step, index) => {
        const stepErrors = (0, validateStep_1.validateStep)(step, stepsToExecute, flow, env);
        if (stepErrors.length > 0) {
            return [...prev, ({
                    property: `${step.id}`,
                    children: stepErrors
                })];
        }
        else {
            return prev;
        }
    }, []);
}
exports.validateFlow = validateFlow;
