import {FlowState} from "../../../types/FlowState";
import {Variable} from "../../../types/Variable";
import {Step} from "@cranq-gpt-lowcode/contracts";
import {mapApiStepToStepModel} from "./mapApiStepToStepModel";

export const mapApiStepToStepState = (
  apiSteps: Step[],
  flow: FlowState,
  variables: undefined | Variable[]
) => mapApiStepToStepModel(apiSteps, flow, variables).map((model) => ({model}));
