import {EnvironmentModel} from "../types/EnvironmentModel";
import {GlobalParametersModel} from "../types/GlobalParametersModel";
import {Environment} from "@cranq-gpt-lowcode/contracts";
import {
  createGlobalParameterReferenceResolver,
  GlobalParameterReferencePrefix
} from "../components/environment-editor/utils/createGlobalParameterReferenceResolver";

export const createResolvedEnvironment = (
  environmentVariables: EnvironmentModel["variables"],
  globalParameters: GlobalParametersModel,
): Environment => {
  const resolveGlobalParameterReference = createGlobalParameterReferenceResolver(globalParameters, GlobalParameterReferencePrefix);
  return environmentVariables.reduce((env, variable) => {
    const resolvedValue = resolveGlobalParameterReference(variable.value);
    return {
      ...env,
      [variable.key]: resolvedValue
    }
  }, {});
}
