import React, {useEffect, useMemo, useRef, useState} from "react";
import {useModalEditor} from "../../../common/components/modal-editor/hook/useModalEditor";
import ModalEditor from "../../../common/components/modal-editor/ModalEditor";
import TreeSelector, {Branches, TreeSelectorProps} from "../../../common/components/tree-selector/TreeSelector";
import {isId} from "../../../utils/types/Id";
import {StepInputModel} from "../../types/StepInputModel";
import {safeJsonParse} from "../../utils/safeJsonParse";
import {safeJsonStringify} from "../../utils/safeJsonStringify";
import {InputWithActionButtons} from "../../../common/components/input-with-action-buttons/InputWithActionButtons";
import {useToast} from "../../../common/components/toast/hook/useToast";
import {copyToClipboard} from "../../utils/copyToClipboard";
import DeleteItemActionButton from "../../../common/components/delete-item-action-button/DeleteItemActionButton";
import {ModalValueAndInstructionEditor} from "../modal-value-and-instruction-editor/ModalValueAndInstructionEditor";

type StepInputEditorPropsBase = {
  model: StepInputModel,
  linkedInputResolver: (source: StepInputModel["source"]) => { label: string, value: string } | undefined,
  linkables: Branches,
  disabled?: boolean,
  // events
  onChange?: (newInput: StepInputModel) => void,
  onDelete?: () => void,
}
export type StepInputEditorProps = StepInputEditorPropsBase & {
  allowValueModificationOnly?: false,
  inputNameFixer: (name: string) => string,
}
  | StepInputEditorPropsBase & {
  allowValueModificationOnly: true,
  inputNameFixer?: never
}
const StepInputEditor = (
  {
    model,
    linkedInputResolver,
    inputNameFixer,
    allowValueModificationOnly,
    linkables,
    disabled = false,
    onChange,
    onDelete
  }: StepInputEditorProps
) => {
  //hooks
  const [
    treeSelectorIsOpen,
    setTreeSelectorIsOpen
  ] = useState(false);

  const {openModalEditor, modalEditorProps} = useModalEditor();
  const {showToast} = useToast();
  const [showModalStepInputValueEditor, setShowModalStepInputValueEditor] = useState(false);
  const treeSelectorRef = useRef<HTMLDivElement>(null);
  const linkedPropertyDisplay = linkedInputResolver(model.source);
  const inputValue = useMemo(
    () => model.source.type === "value" ? safeJsonStringify(model.source.value) : linkedPropertyDisplay?.value ?? "",
    [model.source, linkedPropertyDisplay]
  );

  //FIXME: move to a reusable hook
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (treeSelectorRef.current && !treeSelectorRef.current.contains(event.target as Node)) {
        setTreeSelectorIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [setTreeSelectorIsOpen, treeSelectorRef]);

  // event handlers
  const handleLinkClick = (isLinked: boolean) => {
    const {validationError, ...modelWithoutValidationError} = model;
    if (isLinked) {
      onChange && onChange({
        ...modelWithoutValidationError,
        source: {
          type: "value",
          value: undefined
        }
      });
    } else {
      setTreeSelectorIsOpen((prev) => !prev);
    }
  };

  const handleBranchClick: TreeSelectorProps["onBranchClick"] = (
    {
      branchKeys,
      leafKey
    }) => {
    const {validationError, ...modelWithoutValidationError} = model;
    const topBranchKey = branchKeys[0];
    const terminalBranchKey = branchKeys.slice(-1)[0];
    if (topBranchKey === "environment") {
      onChange && onChange({
          ...modelWithoutValidationError,
          source: {
            type: "env",
            name: leafKey
          }
        }
      );
    } else {
      if (!topBranchKey || !isId(terminalBranchKey) || !isId(leafKey)) {
        console.error(`Invalid key received by onBranchClick: ${branchKeys}, ${leafKey}`);
        return;
      }
      onChange && onChange({
        ...modelWithoutValidationError,
        source: {
          type: "output",
          stepId: terminalBranchKey,
          outputId: leafKey
        },
      });
    }
    setTreeSelectorIsOpen(false);
  };

  const handleDescriptionChange = (
    newDescription: StepInputModel["description"]
  ) => {
    if (!allowValueModificationOnly) {
      //do not run if description has not changed as it changes the name of the input
      return newDescription !== model.description && onChange && onChange({
        ...model,
        name: inputNameFixer(newDescription ?? ""),
        description: newDescription,
      });
    }
  };

  const handleValueChange = (
    changedValueAsString: string
  ) => {
    if (model.source.type !== "value") { //should not happen, but required for type narrowing
      return;
    }
    const newValue = safeJsonParse(changedValueAsString);
    if (newValue === model.source.value) {
      return; //no change
    }
    onChange && onChange({
      ...model,
      source: {
        ...model.source,
        value: newValue
      },
      validationError: undefined
    });
  };

  const handleInstructionChange = (
    newInstruction: string
  ) => {
    if (model.source.type !== "value") {
      return;
    }
    if (newInstruction === model.source.instruction) {
      return; //no change
    }
    onChange && onChange({
      ...model,
      source: {
        ...model.source,
        instruction: newInstruction.trim() === "" ? undefined : newInstruction.trim()
      },
    });
  };

  const handleDelete = () => {
    !allowValueModificationOnly && onDelete && onDelete();
  };

  const handleEditInputDescription = () => {
    !allowValueModificationOnly && openModalEditor({
      title: model.name,
      content: model.description ?? "",
      handleOnClose: (newDescription) => handleDescriptionChange(newDescription)
    })
  }
  const createModalEditActionForValueInput = (model: StepInputModel) => model.source.type === "value"
    ? () => {
      setShowModalStepInputValueEditor(true);
    }
    : undefined;

  const createModalInspectActionForLinkedInput = (model: StepInputModel) => model.source.type === "value"
    ? undefined
    : () => {
      openModalEditor({
        title: model.description ?? model.name,
        content: inputValue,
      })
    };

  const hasLinkables = Object.keys(linkables).length > 0;

  return (
    <div className="contents group relative">
      <InputWithActionButtons
        name={"description"}
        size={"full"}
        placeholder={"Describe the input"}
        value={model.description}
        disabled={disabled}
        readonly={allowValueModificationOnly}
        onEditAction={allowValueModificationOnly ? undefined : handleEditInputDescription}
        onBlur={allowValueModificationOnly ? undefined : handleDescriptionChange}/>
      <div className={"relative"}>
        <InputWithActionButtons
          name={"value"}
          value={model.source.type === "value" ? safeJsonStringify(model.source.value) : linkedPropertyDisplay?.value}
          title={model.source.type === "value" ? model.source.instruction ?? "" : linkedPropertyDisplay?.label}
          placeholder={model.source.type === "value" ? `${model.source.instruction ?? "Type input value"}` : `Linked to ${linkedPropertyDisplay?.label}`}
          size={"full"}
          disabled={disabled}
          invalid={model.validationError !== undefined}
          readonly={model.source.type !== "value"}
          onBlur={model.source.type === "value" ? handleValueChange : undefined}
          editActionTooltip={"Open value & instruction editor"}
          onEditAction={createModalEditActionForValueInput(model)}
          onInspectAction={createModalInspectActionForLinkedInput(model)}
          onLinkAction={model.source.type === "value" && hasLinkables ? () => handleLinkClick(false) : undefined}
          onUnlinkAction={model.source.type === "value" ? undefined : () => handleLinkClick(true)}
          onCopyAction={() => copyToClipboard(inputValue, showToast)}
        />
        {!allowValueModificationOnly && <DeleteItemActionButton onClick={handleDelete} disabled={disabled}/>}
        {
          treeSelectorIsOpen &&
          <div className="absolute top-2 right-2 z-10 min-w-96"
               ref={treeSelectorRef}>
            <TreeSelector
              tree={linkables}
              onBranchClick={handleBranchClick}/>
          </div>
        }
      </div>
      {modalEditorProps && <ModalEditor {...modalEditorProps}/>}
      {model.source.type === "value"
        && showModalStepInputValueEditor
        && <ModalValueAndInstructionEditor
          title={model.description ?? model.name}
          value={safeJsonStringify(model.source.value)}
          instruction={model.source.instruction ?? ""}
          onValueChange={handleValueChange}
          onInstructionChange={handleInstructionChange}
          onCloseClick={() => setShowModalStepInputValueEditor(false)}
        />}
    </div>
  );
};


export default StepInputEditor;
