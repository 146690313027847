import {AppPermissionName, AppPermissionValue} from "../types/AppPermissions";

export const setAppPermission = (
  permissionName: AppPermissionName,
  permissionValue: AppPermissionValue
): void => {
  switch (permissionValue.scope) {
    case "SESSION":
      sessionStorage.setItem(`PERMISSION_${permissionName}`, permissionValue.granted.toString());
      break;
    default:
      throw new Error(`Unsupported permission scope: ${permissionValue.scope}`);
  }
}
