import {useEffect, useRef, useState} from "react";

type DotDotDotSpinnerProps = {
  spinnerChar?: string;
  maxDotNumbers?: number;
  intervalMs?: number;
}
const TextSpinner = (
  {
    spinnerChar = ".",
    maxDotNumbers = 3,
    intervalMs = 500
  }: DotDotDotSpinnerProps) => {
  const [dotNumbers, setDotNumbers] = useState(0);
  const timer = useRef<number | undefined>(undefined);

  useEffect(() => {
    timer.current = window.setInterval(() => {
      setDotNumbers((dotNumbers) => (dotNumbers + 1) % (maxDotNumbers + 1));
    }, intervalMs);
    return () => {
      clearInterval(timer.current);
      timer.current = undefined;
    };
  }, [intervalMs, maxDotNumbers]);

  return (
    <span>{spinnerChar.repeat(dotNumbers)}</span>
  );
}

export default TextSpinner;
