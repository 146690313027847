import TimelineItem from "../timeline-item/TimelineItem";
import {Id} from "../../../utils/types/Id";
import TimelineAddItem from "../timeline-add-item/TimelineAddItem";
import React, {Fragment, useEffect, useState} from "react";
import {StepModel} from "../../types/StepModel";
import Container from "../../../common/components/container/Container";
import {UiColor} from "../../../utils/constants/UiColor";
import TimelineFlowParams from "../timeline-flow-params/TimelineFlowParams";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import {useDialog} from "../../../common/components/dialog/hook/useDialog";
import Dialog from "../../../common/components/dialog/Dialog";
import {StepState} from "../../types/StepState";
import {calculateStepStatus} from "../step/utils/calculateStepStatus";
import TimelineGroup from "../timeline-group/TimelineGroup";
import {GroupModel} from "../../types/GroupModel";
import {unique} from "../../../utils/unique";
import {isStepMarkable} from "./utils/isStepMarkable";
import {getMissingStepIds} from "./utils/getMissingStepIds";
import {GroupedStepsState} from "../../types/GroupedStepsState";
import {createTimelineEntries} from "./utils/createTimelineEntries";
import {GroupState} from "../../types/GroupState";
import {calculateGroupStatus} from "../group/utils/calculateGroupStatus";
import Button, {ButtonType} from "../../../common/components/button/Button";

function AddFlowParamsButton(
  {
    onClick
  }: {
    onClick?: () => void
  }) {
  return (
    <div className={"flex justify-end"}>
      <Button
        title={"+ Add workflow parameters"}
        type={ButtonType.SECONDARY}
        titleTone={UiColor.TextColor.GOLD}
        onClick={onClick}
      />
    </div>
  )
}

export type TimelineProps = {
  title?: string,
  steps: StepState[],
  groups: GroupState[],
  focusOnFlowParams?: true | undefined,
  focusedStepId?: StepModel["id"],
  focusedGroupId?: GroupModel["id"],
  showFlowParams?: boolean,
  isRunAllEnabled?: boolean,
  isStepGenerationInProgress?: boolean,
  isFlowRunning?: boolean,
  allowInsertNewStep?: boolean,
  allowDeleteStep?: boolean,
  allowAddFlowParams?: boolean,
  allowMarkItems?: boolean,
  // events
  onFocusParams?: () => void,
  onFocusItem?: (stepId: StepModel["id"]) => void,
  onFocusGroup?: (groupId: GroupModel["id"]) => void,
  onInsertStep?: (index: number, groupId?: GroupModel["id"]) => void,
  onImportStep?: (index: number, groupId?: GroupModel["id"]) => void,
  onDeleteStep?: (stepId: StepModel["id"]) => void,
  onUngroup?: (groupId: GroupModel["id"][]) => void,
  onGroup?: (stepIds: StepModel["id"][]) => void,
}

const Timeline = (
  {
    title = "Workflow",
    steps,
    groups,
    focusOnFlowParams,
    focusedStepId,
    focusedGroupId,
    showFlowParams = false,
    allowInsertNewStep = true,
    allowDeleteStep = true,
    allowAddFlowParams = true,
    allowMarkItems = true,
    isFlowRunning = false,
    isStepGenerationInProgress = false,
    onFocusParams,
    onFocusItem,
    onFocusGroup,
    onInsertStep,
    onImportStep,
    onDeleteStep,
    onGroup,
    onUngroup
  }: TimelineProps
) => {
  const [lastTimelineItems, setLastTimelineItems] = useState<StepState[]>(steps);
  const [expandedGroupIds, setExpandedGroupIds] = useState<GroupModel["id"][]>([]);
  const [markedStepIds, setMarkedStepIds] = useState<StepModel["id"][]>([]);
  const {openDialog, closeDialog, dialogProps} = useDialog();

  const insertedStepIds = getMissingStepIds(lastTimelineItems, steps);
  const deletedStepIds = getMissingStepIds(steps, lastTimelineItems);

  useEffect(() => {
    // update state upon prop change
    setLastTimelineItems(() => steps);
    // update local states when steps change
    const currentGroupIds = unique(steps.map((step) => step.model.groupId));
    setExpandedGroupIds((prev) => prev.filter((groupId) => currentGroupIds.includes(groupId)))
    const currentStepIds = steps.map((step) => step.model.id);
    setMarkedStepIds((prev) => prev.filter((stepId) => currentStepIds.includes(stepId)));
  }, [steps]);

  useEffect(() => {
    // expand group of focused step
    if (focusedStepId) {
      const focusedStep = steps.find((step) => step.model.id === focusedStepId);
      const group = focusedStep && groups.find((group) => group.model.id === focusedStep.model.groupId);
      if (group) {
        setExpandedGroupIds((prev) => prev.includes(group.model.id) ? prev : [...prev, group.model.id]);
      }
    }
  }, [focusedStepId, groups, steps]);


  const handleInsertStep = (
    index: number,
    groupId?: GroupModel["id"]
  ) => {
    onInsertStep && onInsertStep(index, groupId);
  };
  const handleImportStep = (
    index: number,
    groupId?: GroupModel["id"]
  ) => {
    onImportStep && onImportStep(index, groupId);
  };
  const handleDeleteStep = (
    stepId: Id,
    confirmed = false
  ) => {
    const stepToDelete = steps.find((step) => step.model.id === stepId);
    if (!stepToDelete) {
      return;
    }
    const isStepEmpty = (step: StepModel) =>
      !step.description?.trim()
      && Object.keys(step.inputs).length === 0
      && Object.keys(step.outputs).length === 0
      && step.implementation === undefined

    if (!confirmed && !isStepEmpty(stepToDelete.model)) {
      openDialog({
        title: "Delete step",
        content: "This step is not blank! Are you sure to delete this step?",
        type: MESSAGE_TYPE.WARNING,
        controlButtonList: [
          {
            title: "Cancel",
            onClick: () => closeDialog()
          },
          {
            title: "Delete",
            onClick: () => {
              closeDialog();
              handleDeleteStep(stepId, true);
            }
          }
        ]
      });
      return;
    }
    setLastTimelineItems((prev) => prev.filter((step) => step.model.id !== stepId));
    setTimeout(() => {
      onDeleteStep && onDeleteStep(stepId);
    }, 500);
  };
  const handleParamsClick = () => {
    onFocusParams && onFocusParams();
  }

  const handleTimelineItemClick = (
    stepId: StepModel["id"],
  ) => {
    onFocusItem && onFocusItem(stepId);
  };
  const handleTimelineGroupClick = (
    groupId: GroupModel["id"],
  ) => {
    onFocusGroup && onFocusGroup(groupId);
  };
  const handleGroupExpand = (
    groupId: GroupModel["id"]
  ) => {
    setExpandedGroupIds(prev => prev.includes(groupId) ? prev.filter(id => id !== groupId) : [...prev, groupId]);
  };
  const handleGroupMarkedChange = (
    steps: StepState[],
  ) => (
    isMarked: boolean
  ) => {
    const groupStepIds = steps.map(step => step.model.id);
    setMarkedStepIds(prev => isMarked
      ? [...prev, ...groupStepIds]
      : prev.filter(id => !groupStepIds.includes(id))
    );
  };
  const handleItemMarkedChange = (
    id: StepModel["id"]
  ) => {
    setMarkedStepIds(prev => prev.includes(id) ? prev.filter(stepId => stepId !== id) : [...prev, id]);
  };

  const createTimelineGroup = (
    groupedSteps: GroupedStepsState,
    idx: number,
    isMarkable = false
  ) => {
    const {group, steps} = groupedSteps;
    const firstStep = steps[0];
    const lastStep = steps[steps.length - 1];
    if (!firstStep || !lastStep) {
      return null;
    }
    const itemsInGroup = steps.map((step, stepIndex) => createTimelineItem(
      step,
      stepIndex,
      false,
      idx + 1,
      group.model.id)
    );
    return <div key={group.model.id} className={"contents cranq-component-timeline-entry"}>
      {(idx === 0 && allowInsertNewStep) ? <TimelineAddItem
        enabled={allowInsertNewStep && !isFlowRunning && !isStepGenerationInProgress}
        onInsert={() => handleInsertStep(firstStep.model.index)}
        onImport={() => handleImportStep(firstStep.model.index)}
      /> : null}
      <TimelineGroup
        key={group.model.id}
        id={group.model.id}
        index={idx + 1}
        title={group.model.title}
        stepStatus={calculateGroupStatus(steps)}
        expanded={expandedGroupIds.includes(group.model.id)}
        isFocused={focusedGroupId === group.model.id}
        isMarked={steps.every(step => markedStepIds.includes(step.model.id))}
        isMarkable={isMarkable}
        children={itemsInGroup}
        onClick={() => handleTimelineGroupClick(group.model.id)}
        onExpand={() => handleGroupExpand(group.model.id)}
        onMarkedChange={handleGroupMarkedChange(steps)}
      />
      {allowInsertNewStep ? <TimelineAddItem
        enabled={allowInsertNewStep && !isFlowRunning && !isStepGenerationInProgress}
        onInsert={() => handleInsertStep(lastStep.model.index + 1)}
        onImport={() => handleImportStep(lastStep.model.index + 1)}
      /> : null}
    </div>;
  };

  const createTimelineItem = (
    step: StepState,
    index: number,
    isMarkable = false,
    groupIndex?: number,
    groupId?: GroupModel["id"],
  ) => {
    const {model} = step;
    const isInserted = insertedStepIds.includes(model.id);
    const isDeleted = deletedStepIds.includes(model.id);

    return <div key={model.id}  className={"contents cranq-component-timeline-entry"}>
      {(index === 0 && allowInsertNewStep) ? <TimelineAddItem
        enabled={!isFlowRunning && !isStepGenerationInProgress}
        onInsert={() => handleInsertStep(model.index, groupId)}
        onImport={() => handleImportStep(model.index, groupId)}
      /> : null}
      <TimelineItem
        id={model.id}
        index={`${groupIndex ? `${groupIndex}.` : ""}${index + 1}`}
        title={model.title}
        stepStatus={calculateStepStatus(step)}
        isFocused={focusedStepId === model.id}
        isMarked={markedStepIds.includes(model.id)}
        isMarkable={isMarkable}
        isInserted={isInserted}
        isDeleted={isDeleted}
        allowDelete={allowDeleteStep && !isFlowRunning && !isStepGenerationInProgress}
        onClick={() => handleTimelineItemClick(model.id)}
        onDelete={() => handleDeleteStep(model.id)}
        onMarkedChange={() => handleItemMarkedChange(model.id)}
      />
      {allowInsertNewStep ? <TimelineAddItem
        enabled={!isFlowRunning && !isStepGenerationInProgress}
        isInserted={isInserted}
        isDeleted={isDeleted}
        onInsert={() => handleInsertStep(model.index + 1, groupId)}
        onImport={() => handleImportStep(model.index + 1, groupId)}
      /> : null}
    </div>
  };

  const markedGroupIds = unique(
    steps
      .filter((step) => markedStepIds.includes(step.model.id))
      .map((step) => step.model.groupId)
      .filter((groupId): groupId is GroupModel["id"] => groupId !== undefined)
  )
  const isAnyGroupMarked = markedGroupIds.length > 0;
  const isAnyStepMarked = markedStepIds.length > 0;
  const areMoreStepsMarked = markedStepIds.length > 1;
  const markedStepIndexes = markedStepIds
    .map((stepId) => steps.find((step) => step.model.id === stepId)?.model.index)
    .filter((index): index is number => index !== undefined)
  const areMarkedStepsConsecutive = markedStepIndexes.length === Math.max(...markedStepIndexes) - Math.min(...markedStepIndexes) + 1;

  const handleUngroup = (groupIdsToUngroup: GroupModel["id"][]) => {
    const stepIdsToUnmark = steps
      .filter((step) => step.model.groupId && groupIdsToUngroup.includes(step.model.groupId))
      .map((step) => step.model.id);
    setMarkedStepIds(prev => prev.filter((stepId) => !stepIdsToUnmark.includes(stepId)));
    onUngroup && onUngroup(markedGroupIds);
  };

  const handleGroup = (stepIdsToGroup: StepModel["id"][]) => {
    setMarkedStepIds(prev => prev.filter((stepId) => !stepIdsToGroup.includes(stepId)));
    onGroup && onGroup(markedStepIds);
  }

  return (
    <Container
      headerProps={{
        title,
        level: 4,
        titleTone: UiColor.TextColor.LIGHT_GRAY,
        rightSideButtonListProps: [
          ...(isAnyGroupMarked ? [{
              title: "Ungroup",
              onClick: () => handleUngroup(markedGroupIds)
            }] : (isAnyStepMarked ? [{
              title: "Group",
              disabled: !areMoreStepsMarked || !areMarkedStepsConsecutive,
              onClick: () => handleGroup(markedStepIds)
            }] : [])
          )
        ]
      }}
      indented={false}
      paddingY={false}
    >
      <div id={"timeline"} className={`flex flex-col gap-0 pb-24`}>
        {/* bottom padding must be large enough to avoid cutting the drop shadow if last step is selected */}
        {showFlowParams
          ? (<>
            <TimelineFlowParams
              isFocused={focusOnFlowParams}
              onClick={() => handleParamsClick()}/>
            {(steps.length === 0 && allowInsertNewStep) ? <TimelineAddItem
              enabled={allowInsertNewStep && !isFlowRunning && !isStepGenerationInProgress}
              showOnHoverOnly={false}
              onInsert={() => handleInsertStep(1)}
              onImport={() => handleImportStep(1)}
            /> : null}
          </>)
          : (allowAddFlowParams ? <AddFlowParamsButton onClick={handleParamsClick}/> : null)
        }
        {createTimelineEntries(steps, groups).map((
          entry,
          entryIndex
        ) =>
          ("group" in entry)
            ? createTimelineGroup(
              entry,
              entryIndex,
              allowMarkItems && entry.steps.every(step => isStepMarkable(steps, markedStepIds, step.model.id))
            )
            : createTimelineItem(
              entry,
              entryIndex,
              allowMarkItems && isStepMarkable(steps, markedStepIds, entry.model.id)
            ))}
      </div>
      {dialogProps && <Dialog {...dialogProps}/>}
    </Container>
  )
}

export default Timeline;
