"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateStep = void 0;
const validateInterface_1 = require("./validateInterface");
const AiModel_1 = require("../domain/AiModel");
function validateStep(step, stepsToExecute, flow, env) {
    var _a;
    const output = [];
    if (step.aiModelType) {
        if (!step.aiModelId) {
            output.push({
                property: "aiModelId",
                constraints: {
                    "shouldHaveAiModelId": `No AI model id provided for step #${step.id}`
                }
            });
        }
    }
    if (((_a = step.aiModelType) !== null && _a !== void 0 ? _a : AiModel_1.DEFAULT_AI_MODEL_TYPE) === AiModel_1.AiModelType.CODE_WRITER) {
        if (!step.implementation) {
            output.push({
                property: "implementation",
                constraints: {
                    "shouldHaveImplementation": `No implementation provided for step #${step.id}`
                }
            });
        }
    }
    if (!step.interface) {
        output.push({
            property: "interface",
            constraints: {
                "shouldHaveInterface": `No interface provided for step #${step.id}`
            }
        });
    }
    else {
        const interfaceErrors = (0, validateInterface_1.validateInterface)(step.interface, stepsToExecute, flow, env);
        if (interfaceErrors.length > 0) {
            output.push({
                property: "interface",
                children: interfaceErrors
            });
        }
    }
    return output;
}
exports.validateStep = validateStep;
