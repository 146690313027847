import {LoggedInUserState} from "../../types/UserState";
import React, {Fragment} from "react";
import {SubscriptionWithTimestamps} from "../../types/SubscriptionWithTimestamps";
import {formatSubscriptionPackageName} from "../../utils/formatSubscriptionPackageName";
import {SubscriptionPackageId} from "@cranq-gpt-lowcode/contracts";

const formatUserSubscriptions = (subscriptions: SubscriptionWithTimestamps[] | undefined): string[] => {
  const nonFreeSubscriptions = subscriptions?.filter((s) => s.packageId !== SubscriptionPackageId.NONE);
  return (nonFreeSubscriptions && nonFreeSubscriptions.length > 0)
    ? nonFreeSubscriptions
      .map((s) =>
        `${formatSubscriptionPackageName(s.packageId)} (exp: ${(new Date(s.validUntil)).toLocaleString()})`)
    : [formatSubscriptionPackageName(SubscriptionPackageId.NONE)];
}
type UserAccountProps = {
  subscriptions: SubscriptionWithTimestamps[] | undefined;
  user: LoggedInUserState;
}
export const UserAccount = (
  {
    subscriptions,
    user
  }: UserAccountProps
) => (
  <div className={"grid grid-cols-2"}>
    <span>Last name</span><span>{user.model.lastname}</span>
    <span>First name</span><span>{user.model.firstname}</span>
    <span>Email </span><span>{user.model.email}</span>
    <span>Subscriptions:</span><span>
    {formatUserSubscriptions(subscriptions)
      .map((s, index) => (<Fragment key={index}>{s}<br/></Fragment>))
    }
  </span>
  </div>
);
