import {UiColor} from "../../../utils/constants/UiColor";
import InlineIcon from "../../../common/components/inline-icon/InlineIcon";
import {InlineIconName} from "../../../common/components/inline-icon/InlineIconName";
import {FlowState} from "../../types/FlowState";
import {calculateWebhookStatus} from "./utils/calculateWebhookStatus";
import {WebhookStatus} from "@cranq-gpt-lowcode/contracts/dist/webhook/domain/WebhookStatus";

type WebhookStatusTagProps = {
  webhookId: FlowState["webhookId"]
  webhookExpiryDate: FlowState["webhookExpiryDate"]
  webhookHash: FlowState["webhookHash"]
  flowHash: FlowState["model"]["hash"]
} | {
  webhookStatus: WebhookStatus
}

const WebhookStatusTag = (
  props: WebhookStatusTagProps
) => {
  const webhookStatus = "webhookStatus" in props
    ? props.webhookStatus
    : calculateWebhookStatus(
      props.webhookId,
      props.webhookExpiryDate,
      props.webhookHash,
      props.flowHash
    );
  if (webhookStatus) {
    const iconTone = webhookStatus === WebhookStatus.ACTIVE ? UiColor.TextColor.GOLD : UiColor.TextColor.RED;
    return (
      <div className={"w-4 h-4"}><InlineIcon name={InlineIconName.WEBHOOK} primaryTone={iconTone}/></div>
    )
  }
  return null;
}

export default WebhookStatusTag;
