import {useEffect, useState} from "react";

type useMinimalScreenSizeProps = {
  width: number;
  height: number;
}
export const useMinimalScreenSize = (
  {
    width,
    height
  }: useMinimalScreenSizeProps
): boolean => {
  const [isScreenSizeSufficient, setIsScreenSizeSufficient] = useState<boolean>(true);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < width || window.innerHeight < height) {
        setIsScreenSizeSufficient(false);
      } else {
        setIsScreenSizeSufficient(true);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [width, height]);
  return isScreenSizeSufficient;
};
