import {Id, isId} from "../../utils/types/Id";
import {isStepInputModelSource, StepInputModelSource} from "./StepInputModelSource";

export type StepInputModel = {
  id: Id,
  name: string,
  description?: string,
  type?: string,
  source: StepInputModelSource
  isBoundToCode?: boolean,
  validationError?: string //FIXME: this is not part of the model, but is used in the UI -> should go into some State
}

export const isStepInputModel = (maybeStepInputModel: unknown): maybeStepInputModel is StepInputModel => {
  return typeof maybeStepInputModel === "object" && maybeStepInputModel !== null
    && "id" in maybeStepInputModel && isId(maybeStepInputModel.id)
    && "name" in maybeStepInputModel && typeof maybeStepInputModel.name === "string"
    && ("description" in maybeStepInputModel ? typeof maybeStepInputModel.description === "string" : true)
    && ("type" in maybeStepInputModel ? typeof maybeStepInputModel.type === "string" : true)
    && "source" in maybeStepInputModel && isStepInputModelSource(maybeStepInputModel.source)
    && ("isBoundToCode" in maybeStepInputModel ? typeof maybeStepInputModel.isBoundToCode === "boolean" : true)
    && ("validationError" in maybeStepInputModel ? typeof maybeStepInputModel.validationError === "string" : true)
}
