import Layout from "../../../common/components/layout/Layout";
import FlowEditor from "../../components/flow-editor/FlowEditor";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {isId} from "../../../utils/types/Id";
import Container from "../../../common/components/container/Container";
import Step from "../../components/step/Step";
import {batch, useSelector} from "react-redux";
import {
  makeSelectFlowsOrdered,
  selectFlow,
  selectFlowByIdV0,
  updateFlowAccessedAt,
  updateFlowById
} from "../../flowsSlice";
import {StepModel} from "../../types/StepModel";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import {executeAction, ExecuteActionErrorCode} from "./actions/executeAction";
import {pollingErrorCallback, pollingSuccessCallback as pollingSuccessCallbackOriginal} from "./utils/pollingCallbacks";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {insertStep, makeSelectStepsByFlowId, updateStepById} from "../../stepsSlice";
import {AuthContext} from "../../components/auth-context-provider/AuthContextProvider";
import {useDialog} from "../../../common/components/dialog/hook/useDialog";
import {useExecuteMutation} from "../../runnerApi";
import {useToast} from "../../../common/components/toast/hook/useToast";
import {selectEnvironmentByFlowId} from "../../environmentsSlice";
import {selectUser} from "../../userSlice";
import {useExecutionStatusPolling} from "./hooks/useExecutionStatusPolling";
import FlowParams from "../../components/flow-params/FlowParams";
import {
  useGenerateCodeAsyncMutation,
  useGenerateHintsForStepsAsyncMutation,
  useGenerateStepsAsyncMutation,
  useGetGenerateCodeAsyncStatusQuery,
  useGetGenerateHintsForStepAsyncStatusQuery,
  useGetGenerateStepsAsyncStatusQuery
} from "../../editorApi";
import {RequestStatusPollingInterval, useRequestStatusPolling} from "../../hooks/useRequestStatusPolling";
import {Flow, GenerateCodeForStepResponse, GenerateFlowByInstructionHintsResponse} from "@cranq-gpt-lowcode/contracts";
import {processGenerateStepsResult} from "./actions/processGenerateStepsResult";
import {processGenerateHintsForStepsResult} from "./actions/processGenerateHintsForStepsResult";
import ReactGA from "react-ga4";
import {generateStepsAsyncAction} from "./actions/generateStepsAsyncAction";
import {generateHintsForStepsAsyncAction} from "./actions/generateHintsForStepsAsyncAction";
import Dialog from "../../../common/components/dialog/Dialog";
import {generateStepImplementationAsyncAction} from "./actions/generateStepImplementationAsyncAction";
import {processGenerateCodeForStepResponse} from "./actions/processGenerateCodeForStepResponse";
import {createNewEnvironment} from "../../utils/createNewEnvironment";
import {deleteFlowAction} from "./actions/deleteFlowAction";
import {Timeouts} from "../../../utils/constants/Timeouts";
import createNewId from "../../../utils/createNewId";
import RequestPermission from "../../components/request-permission/RequestPermission";
import Sidebar from "../../components/sidebar/Sidebar";
import Group from "../../components/group/Group";
import {useFocusedEntry} from "./hooks/useFocusedEntry";
import {ThirdPartyServicesWarning} from "./ThirdPartyServicesWarning";
import BreakdownChat from "../../components/mentor-chat/breakdown-chat/BreakdownChat";
import {isUuid} from "../../../utils/types/Uuid";
import LessonContextProvider from "../../components/lesson-context-provider/LessonContextProvider";
import "shepherd.js/dist/css/shepherd.css";
import {openLoginDialog} from "../../components/login/utils/openLoginDialog";
import StepChat from "../../components/mentor-chat/step-chat/StepChat";
import {useGetUserSubscriptionsQuery} from "../../subscriptionsApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {isApiConnected} from "../../utils/isApiConnected";
import {AiModelsContext} from "../../components/ai-models-context-provider/AiModelsContextProvider";

function NoSelectedStep() {
  return (
    <Container height={"full"}>
      <div className={"grid h-screen"}>
        <p className={"self-center text-center text-sm"}>
          👈🏿 Add some steps, then edit the individual steps here
        </p>
      </div>
    </Container>
  );
}

const FlowEditorPage = () => {
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();

  const params = useParams();
  if (!isUuid(params.flowId) && !isId(params.flowId)) {
    throw new SyntaxError(`Flow id is not valid: ${params.flowId}`)
  }
  const flow = useSelector(isUuid(params.flowId) ? selectFlow(params.flowId) : selectFlowByIdV0(params.flowId));
  if (!flow) {
    throw new ReferenceError(`Flow with id ${params.flowId} not found`);
  }
  const flowId = flow.model.id;

  useEffect(() => {
    // redirect to the new flow id
    if (flowId !== params.flowId) {
      navigateTo(`/flow/${flowId}`);
    }
  }, [flowId, navigateTo, params.flowId]);

  useEffect(() => {
    if (isUuid(flowId)) {
      dispatch(updateFlowAccessedAt({flowId}));
    }
  }, [dispatch, flowId]);

  const {
    model: {
      description,
      lessonDescriptor
    },
    generateStepsRequestId,
    generateHintsForStepsRequestId,
    executionId = undefined,
    executedStepIds
  } = flow;
  const selectStepsByFlowId = useMemo(makeSelectStepsByFlowId, []);
  // TODO: can't memoize result of useAppSelector, because it's a hook -> should we really?
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const steps = useAppSelector((state) => selectStepsByFlowId(state, flowId)) ?? [];
  const environment = useAppSelector(selectEnvironmentByFlowId(flowId)) ?? createNewEnvironment(flowId);
  const user = useAppSelector(selectUser);
  const {
    focusOnFlowParams,
    focusedStepId,
    focusedGroupId,
  } = useFocusedEntry(steps);
  const currentStep = focusedStepId ? steps.find(step => step.model.id === focusedStepId) : undefined;
  const selectFlowsOrdered = useMemo(makeSelectFlowsOrdered, []);
  const latestFlows = useAppSelector((state) => selectFlowsOrdered(state, "updatedAt", "desc", 5));
  const [searchParams] = useSearchParams();
  const startLesson = searchParams.has("startLesson");

  const {apiConnections, anonymousUserId} = useContext(AuthContext);
  const {data: subscriptions} = useGetUserSubscriptionsQuery(user.model?.id ?? skipToken);
  const {aiModels} = useContext(AiModelsContext);

  const {
    openDialog,
    closeDialog,
    dialogProps
  } = useDialog();
  const {
    openDialog: openBreakdownChatDialog,
    closeDialog: closeBreakdownChatDialog,
    dialogProps: breakdownChatDialogProps
  } = useDialog();
  const {
    openDialog: openStepChatDialog,
    closeDialog: closeStepChatDialog,
    dialogProps: stepChatDialogProps
  } = useDialog();
  const {showToast} = useToast();
  const [
    nextOperations,
    setNextOperations
  ] = useState<(() => void)[]>([]);


  const [generateStepsAsync] = useGenerateStepsAsyncMutation();
  const [generateHintsForStepsAsync] = useGenerateHintsForStepsAsyncMutation();
  const [execute] = useExecuteMutation();
  const [generateCodeAsync] = useGenerateCodeAsyncMutation();

  const openBreakdownChat = useCallback(() => {
    ReactGA.event({
      category: "chat",
      action: "OPEN_BREAKDOWN_CHAT",
      label: `Flow: ${flowId}`,
    });
    openBreakdownChatDialog({
      title: "AI Wizard",
      type: MESSAGE_TYPE.CUSTOM,
      content: <BreakdownChat flowId={flowId} onChatCompleted={() => {
        closeBreakdownChatDialog();
      }}/>,
      height: "full",
      onClose: () => {
        ReactGA.event({
          category: "chat",
          action: "CLOSE_BREAKDOWN_CHAT",
          label: `Flow: ${flowId}`,
        });
        dispatch(updateFlowById({flowId, update: {lastChatUpdate: undefined}}));
      }
    });
  }, [flowId, openBreakdownChatDialog, closeBreakdownChatDialog, dispatch]);

  const openStepChat = useCallback(() => {
    if (!focusedStepId) {
      return;
    }
    ReactGA.event({
      category: "chat",
      action: "OPEN_STEP_CHAT",
      label: `Flow: ${flowId}, Step: ${focusedStepId}`,
    });
    openStepChatDialog({
      title: "AI Wizard",
      type: MESSAGE_TYPE.CUSTOM,
      content: <StepChat flowId={flowId} stepId={focusedStepId} onChatCompleted={() => {
        closeStepChatDialog();
      }}/>,
      height: "full",
      onClose: () => {
        ReactGA.event({
          category: "chat",
          action: "CLOSE_STEP_CHAT",
          label: `Flow: ${flowId}, Step: ${focusedStepId}`,
        });
        dispatch(updateStepById({stepId: focusedStepId, update: {lastChatUpdate: undefined}}));
      }
    });
  }, [focusedStepId, flowId, openStepChatDialog, closeStepChatDialog, dispatch]);

  useEffect(() => {
    if (flow.lastChatUpdate) {
      openBreakdownChat();
    } else {
      closeBreakdownChatDialog();
    }
  }, [closeBreakdownChatDialog, flow.lastChatUpdate, flow.model.description, openBreakdownChat, steps]);

  useEffect(() => {
    if (currentStep?.lastChatUpdate) {
      openStepChat();
    } else {
      closeStepChatDialog();
    }
  }, [closeStepChatDialog, currentStep?.lastChatUpdate, openStepChat]);

  const pollingSuccessCallback = useCallback(
    (showToast: ReturnType<typeof useToast>["showToast"]) => (title: string) => {
      const nextOperation = nextOperations[0];
      if (nextOperation) {
        setNextOperations((operations) => operations.slice(1));
        setTimeout(() => nextOperation(), 0);
      } else {
        pollingSuccessCallbackOriginal(showToast)(title);
      }
    }, [nextOperations]);

  useExecutionStatusPolling(
    executionId,
    flowId,
    executedStepIds?.[0], //FIXME: handle multiple executed steps
    pollingErrorCallback(showToast, openDialog),
    pollingSuccessCallback(showToast),
    Timeouts.EXECUTION
  );

  // Deprecated!
  useRequestStatusPolling<Flow>(
    generateStepsRequestId,
    "GENERATE_STEPS",
    useGetGenerateStepsAsyncStatusQuery,
    undefined,
    (dispatch) => dispatch(updateFlowById({flowId, update: {generateStepsRequestId: undefined}})),
    processGenerateStepsResult.bind(null, flow, environment),
    pollingErrorCallback(showToast, openDialog),
    pollingSuccessCallback(showToast),
    Timeouts.STEPS_GENERATION
  )

  useRequestStatusPolling<GenerateFlowByInstructionHintsResponse>(
    generateHintsForStepsRequestId,
    "GENERATE_STEP_HINTS",
    useGetGenerateHintsForStepAsyncStatusQuery,
    undefined,
    (dispatch) => dispatch(updateFlowById({flowId, update: {generateHintsForStepsRequestId: undefined}})),
    processGenerateHintsForStepsResult.bind(null, flow),
    () => void 0,
    () => void 0,
    Timeouts.HINTS_GENERATION
  )

  const stepWithCodeGenerationRequestId = currentStep?.codeGenerationRequestId
    ? currentStep
    : (
      focusedGroupId
        ? steps.filter(
          (step) => step.model.groupId === focusedGroupId && step.codeGenerationRequestId
        )[0]
        : undefined
    )
  useRequestStatusPolling<GenerateCodeForStepResponse>(
    stepWithCodeGenerationRequestId?.codeGenerationRequestId,
    "GENERATE_CODE",
    useGetGenerateCodeAsyncStatusQuery,
    RequestStatusPollingInterval.DEFAULT,
    stepWithCodeGenerationRequestId
      ? (dispatch) => dispatch(
        updateStepById({
          stepId: stepWithCodeGenerationRequestId.model.id,
          update: {codeGenerationRequestId: undefined}
        }))
      : () => void 0,
    stepWithCodeGenerationRequestId
      ? processGenerateCodeForStepResponse.bind(null, stepWithCodeGenerationRequestId.model.id)
      : () => () => void 0,
    pollingErrorCallback(showToast, openDialog),
    pollingSuccessCallback(showToast),
    Timeouts.STEP_IMPLEMENTATION_GENERATION
  )
  const handleGenerateStepsRequest = useCallback((forceRequest = false) => {
    if (!flow) {
      return;
    }
    if (forceRequest || steps.length === 0) {
      ReactGA.event({
        category: "request",
        action: "GENERATE_STEPS",
        label: description, // optional
        nonInteraction: true, // optional, true/false
        transport: "xhr" // optional, beacon/xhr/image
      });
      batch(() => {
        dispatch(generateStepsAsyncAction(flow, generateStepsAsync, pollingErrorCallback(showToast, openDialog)));
        dispatch(generateHintsForStepsAsyncAction(flow, generateHintsForStepsAsync));
      });
    } else {
      openDialog({
        content: "By regenerating steps all currents steps (with all your eventual changes) will be deleted. Are you sure to proceed?",
        type: MESSAGE_TYPE.WARNING,
        title: "Warning",
        controlButtonList: [
          {
            title: "Cancel",
            onClick: closeDialog
          },
          {
            title: "Proceed",
            onClick: () => {
              handleGenerateStepsRequest(true);
              closeDialog();
            }
          }
        ]
      });
    }
  }, [closeDialog, description, dispatch, flow, generateHintsForStepsAsync, generateStepsAsync, openDialog, showToast, steps.length]);

  const shouldStartStepGeneration = useRef(!!flow?.generateStepsAutoStart);
  useEffect(() => {
    if (shouldStartStepGeneration.current) {
      shouldStartStepGeneration.current = false;
      dispatch(updateFlowById({
        flowId,
        update: {generateStepsAutoStart: false}
      }));
      const newStepId = createNewId();
      dispatch(insertStep({flowId, index: 1, newStepId}));
    }
  }, [dispatch, flowId, handleGenerateStepsRequest, showToast]);

  const handleGenerateCodeRequest = (stepId: StepModel["id"]) => {
    ReactGA.event({
      category: "request",
      action: "GENERATE_CODE",
      label: description, // optional
      nonInteraction: true, // optional, true/false
      transport: "xhr" // optional, beacon/xhr/image
    });
    return dispatch(generateStepImplementationAsyncAction(
      flowId,
      stepId,
      generateCodeAsync,
      pollingErrorCallback(showToast, openDialog)
    ));
  };

  const handleExecuteRequest = (stepIds?: StepModel["id"][]) => {
    dispatch(
      executeAction(
        flowId,
        stepIds,
        user.model?.id,
        apiConnections,
        aiModels,
        subscriptions,
        execute,
        pollingErrorCallback(showToast, openDialog)
      ))
      .catch((error) => {
        if (error instanceof Error) {
          switch (error.message) {
            case ExecuteActionErrorCode.NOT_LOGGED_IN: {
              openLoginDialog(anonymousUserId, openDialog, closeDialog, showToast, `Please, log in before executing the ${stepIds ? "step" : "flow"}.`);
              return;
            }
            case ExecuteActionErrorCode.NO_STEP_IMPLEMENTATION: {
              const stepIdsWithInvalidImplementation = Array.isArray(error.cause) && error.cause.every(isId) ? error.cause : [];
              if (stepIdsWithInvalidImplementation[0]) {
                setNextOperations([
                  handleExecuteRequest.bind(null, stepIds)
                ]);
                return handleGenerateCodeRequest(stepIdsWithInvalidImplementation[0]); //FIXME: handle properly multiple steps case
              }
              break;
            }
            case ExecuteActionErrorCode.UNCONNECTED_THIRD_PARTY_API: {
              const usedServices = Array.from(
                new Set(steps
                  .filter((step) => !stepIds || stepIds.includes(step.model.id))
                  .flatMap((step) => step.model.implementation?.apis ?? [])
                ));
              const unconnectedServices = usedServices
                .filter((api) => !isApiConnected(apiConnections, subscriptions, api));
              const handelAllConnected = () => {
                //FIXME: we should
                // - close the dialog
                // - retry the operation automatically
              }
              openDialog({
                title: "Unconnected 3rd party services",
                type: MESSAGE_TYPE.WARNING,
                content: <ThirdPartyServicesWarning
                  unconnectedServices={unconnectedServices}
                  onAllConnected={handelAllConnected}/>
              });
              break;
            }
            case ExecuteActionErrorCode.EXECUTION_REQUEST_INVALID: {
              if (error.cause) {
                openDialog({
                  title: "Error",
                  type: MESSAGE_TYPE.ERROR,
                  content: Array.isArray(error.cause) ? (
                    <dl>
                      <dt>The following validation errors were found:</dt>
                      {error.cause.map((cause, index) => (
                        <dd key={index}>{cause}</dd>
                      ))}
                    </dl>
                  ) : (
                    <pre>{JSON.stringify(error.cause, null, 2)}</pre>
                  )
                });
              }
              break;
            }
            default: {
              openDialog({
                title: "Error",
                type: MESSAGE_TYPE.ERROR,
                content: error.message,
              });
              break;
            }
          }
        }
      });
    return;
  }
  const handleProceedStepRequest = (stepIds: StepModel["id"][]) => {
    handleExecuteRequest(stepIds);
  }
  const handleFlowDeletionRequest = (confirmed = false) => {
    if (!confirmed) {
      openDialog({
        title: "Warning",
        type: MESSAGE_TYPE.WARNING,
        content: "Are you sure to delete this flow?",
        controlButtonList: [
          {
            title: "Cancel",
            onClick: closeDialog
          },
          {
            title: "Delete",
            onClick: () => {
              handleFlowDeletionRequest(true);
              closeDialog();
            }
          }
        ]
      });
      return;
    }
    const error = dispatch(deleteFlowAction(flowId));
    if (error) {
      showToast({
        type: MESSAGE_TYPE.ERROR,
        message: `Failed to delete flow: ${error.message}`
      });
    } else {
      const deletedFlowId = flowId;
      const nextLatestFlowId = Object.keys(latestFlows)
        .filter((flowId) => flowId !== deletedFlowId)[0];

      if (nextLatestFlowId) {
        navigateTo(`/flow/${nextLatestFlowId}`);
      } else {
        navigateTo("/");
      }
    }
  };

  const handleBreakdownChatStart = () => {
    dispatch(updateFlowById({flowId, update: {lastChatUpdate: Date.now()}}))
    // chat will be opened as a reaction to the state update by the useEffect
  }

  const isFlowRunning = executionId !== undefined;
  const isFocusedStepRunning = !!focusedStepId
    && isFlowRunning
    && !!executedStepIds
    && executedStepIds.includes(focusedStepId);
  const isFocusedGroupRunning = !!focusedGroupId
    && isFlowRunning
    && !!executedStepIds
    && executedStepIds.some(
      (stepId) => steps.find(
        (step) => step.model.id === stepId
      )?.model.groupId === focusedGroupId);

  return (
    <>
      <RequestPermission permissionName={"SHARE_DATA_WITH_LLMS"}/>
      <LessonContextProvider flowId={flowId} lessonDescriptor={lessonDescriptor}>
        <Layout
          sideBarContent={<Sidebar currentFlowId={flowId}/>}
          leftPanelContent={
            <FlowEditor
              flowId={flowId}
              focusOnFlowParams={focusOnFlowParams}
              focusedStepId={focusedStepId}
              focusedGroupId={focusedGroupId}
              autoStartLesson={startLesson}
              onFlowExecutionRequested={handleExecuteRequest}
              onFlowDeletionRequested={handleFlowDeletionRequest}
              onBreakdownChatStart={handleBreakdownChatStart}
            />
          }
          rightPanelContent={
            focusOnFlowParams
              ? <FlowParams flowId={flowId}/>
              : focusedStepId
                ? <Step stepId={focusedStepId}
                        isFlowRunning={isFlowRunning}
                        isStepRunning={isFocusedStepRunning}
                        onStepExecutionRequested={(stepId) => handleProceedStepRequest([stepId])}
                        onCodeGenerationRequested={handleGenerateCodeRequest}
                />
                : focusedGroupId
                  ? <Group groupId={focusedGroupId}
                           flowId={flowId}
                           isFlowRunning={isFlowRunning}
                           isGroupRunning={isFocusedGroupRunning}
                           onStepExecutionRequested={handleProceedStepRequest}
                  />
                  : <NoSelectedStep/>
          }
        />
        {dialogProps && <Dialog {...dialogProps}/>}
        {breakdownChatDialogProps && <Dialog {...breakdownChatDialogProps}/>}
        {stepChatDialogProps && <Dialog {...stepChatDialogProps}/>}
      </LessonContextProvider>
    </>
  );
}

export default FlowEditorPage;
