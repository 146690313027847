import {useCallback, useEffect, useRef, useState} from "react";
import useInterval from "./useInterval";

type usePopupWindowParameters = {
  windowOptions?: string,
  // events
  onPopupWindowClose?: () => void;
  onPopupWindowOpen?: () => void;
  onPopupWindowOpenFailed?: (error?: Error) => void;
  onPopupWindowMessage?: (event: MessageEvent) => void;
}
type usePopupWindowResult = {
  openWindow: (url: URL) => () => void; // returns closeWindow
}
export const usePopupWindow = (
  {
    windowOptions = "",
    onPopupWindowClose,
    onPopupWindowOpen,
    onPopupWindowOpenFailed,
    onPopupWindowMessage
  }: usePopupWindowParameters
): usePopupWindowResult => {
  const windowRef = useRef<Window | null>(null);
  const urlRef = useRef<URL | null>(null);
  const isWindowOpen = useCallback(() => windowRef.current !== null && !windowRef.current?.closed, []);
  const [wasWindowOpen, setWasWindowOpen] = useState(false);

  const openWindow = useCallback((url: URL) => {
    if (url) {
      if (url.href !== urlRef.current?.href) {
        if (windowRef.current) {
          windowRef.current?.close();
        }
        windowRef.current = window.open(url.toString(), '_blank', windowOptions);
        if (!windowRef.current) {
          onPopupWindowOpenFailed && onPopupWindowOpenFailed(new Error("Failed to open popup window"));
        } else {
          urlRef.current = url; // only store if window was opened
          setWasWindowOpen(true);
          onPopupWindowOpen && onPopupWindowOpen();
        }
      }
    }

    return () => {
      try {
        windowRef.current?.close();
      } finally {
        windowRef.current = null;
        urlRef.current = null;
      }
    }
  }, [onPopupWindowOpen, onPopupWindowOpenFailed, windowOptions]);

  // check if window is closed
  useInterval(() => {
    if (windowRef.current && wasWindowOpen && !isWindowOpen()) {
      windowRef.current = null;
      onPopupWindowClose && onPopupWindowClose();
    }
  }, windowRef.current ? 1000 : null);

  // handle post message
  const handlePostMessage = useCallback((event: MessageEvent) => {
    if (event.origin !== urlRef.current?.origin) return;
    onPopupWindowMessage && onPopupWindowMessage(event);
  }, [onPopupWindowMessage]);
  useEffect(() => {
    window.addEventListener("message", handlePostMessage);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, [handlePostMessage, isWindowOpen]);

  return {
    openWindow
  }
}
