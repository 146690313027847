import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react";
import {CreateScheduledJobRequest, CreateScheduledJobResponse, ScheduledJob} from "@cranq-gpt-lowcode/contracts";
import {EndpointBuilder} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {ApiContext, getApiBaseUrl} from "./utils/getApiBaseUrl";
import {CreateScheduledJobsResponseWithTimestamps} from "./types/messages/CreateScheduledJobsResponseWithTimestamps";
import {MapApiResponse} from "./types/messages/utils/MapApiResponse";

export const schedulerApiRawEndpointBuilder = (
  builder: EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, "ScheduledJob", "schedulerApi">
) => ({
  listJobs: builder.query<MapApiResponse<ScheduledJob>[], Partial<ScheduledJob> | void>({
    query: (filter = undefined) => ({
      url: `jobs`,
      method: "GET",
      ...(filter ? {params: filter} : {})
    }),
    providesTags: [
      {type: "ScheduledJob", id: "jobs"}
    ],
    transformResponse(baseQueryReturnValue: ScheduledJob[]): MapApiResponse<ScheduledJob>[] {
      return baseQueryReturnValue.map((job) => ({
        ...job,
        created: new Date(job.created).getTime(),
        expiry: new Date(job.expiry).getTime(),
        deleted: job.deleted ? new Date(job.deleted).getTime() : undefined,
        lastInvocation: job.lastInvocation ? new Date(job.lastInvocation).getTime() : undefined
      }));
    }
  }),
  getJob: builder.query<MapApiResponse<ScheduledJob>, ScheduledJob["jobId"]>({
    query: (jobId) => ({
      url: `jobs/${jobId}`,
      method: "GET"
    }),
    providesTags: (_result, _error, jobId) => [
      {"type": "ScheduledJob", "id": `jobs/${jobId}`}
    ],
    transformResponse(baseQueryReturnValue: ScheduledJob): MapApiResponse<ScheduledJob> {
      return {
        ...baseQueryReturnValue,
        created: new Date(baseQueryReturnValue.created).getTime(),
        expiry: new Date(baseQueryReturnValue.expiry).getTime(),
        deleted: baseQueryReturnValue.deleted ? new Date(baseQueryReturnValue.deleted).getTime() : undefined,
        lastInvocation: baseQueryReturnValue.lastInvocation ? new Date(baseQueryReturnValue.lastInvocation).getTime() : undefined
      }
    }
  }),
  createJob: builder.mutation<CreateScheduledJobsResponseWithTimestamps, CreateScheduledJobRequest>({
    query: (request) => ({
      url: `jobs`,
      method: "POST",
      body: request
    }),
    invalidatesTags: [
      {type: "ScheduledJob", id: "jobs"},
    ],
    transformResponse(baseQueryReturnValue: CreateScheduledJobResponse): CreateScheduledJobsResponseWithTimestamps {
      return {
        ...baseQueryReturnValue,
        expiry: new Date(baseQueryReturnValue.expiry).getTime(),
      }
    }
  }),
  updateJob: builder.mutation<CreateScheduledJobsResponseWithTimestamps, CreateScheduledJobRequest & {
    jobId: ScheduledJob["jobId"]
  }>({
    query: ({jobId, ...request}) => ({
      url: `jobs/${jobId}`,
      method: "PUT",
      body: request
    }),
    invalidatesTags: (_result, _error, {jobId}) => [
      {type: "ScheduledJob", id: "jobs"},
      {type: "ScheduledJob", id: `jobs/${jobId}`},
    ],
    transformResponse(baseQueryReturnValue: CreateScheduledJobResponse): CreateScheduledJobsResponseWithTimestamps {
      return {
        ...baseQueryReturnValue,
        expiry: new Date(baseQueryReturnValue.expiry).getTime(),
      }
    }
  }),
  deleteJob: builder.mutation<void, ScheduledJob["jobId"]>({
    query: (jobId) => ({
      url: `jobs/${jobId}`,
      method: "DELETE"
    }),
    invalidatesTags: (_result, _error, jobId) => [
      {type: "ScheduledJob", id: "jobs"},
      {type: "ScheduledJob", id: `jobs/${jobId}`},
    ],
  }),
});
const schedulerApi = createApi({
  reducerPath: "schedulerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getApiBaseUrl(ApiContext.SCHEDULER),
    credentials: "include",
    prepareHeaders: (headers) => {
      headers.append("Ga-Client-Id", window.__GLOBAL_VAR__.GA_ClIENT_ID || "unset");
    }
  }),
  endpoints: schedulerApiRawEndpointBuilder
});

export const {
  useListJobsQuery,
  useLazyListJobsQuery,
  useCreateJobMutation,
  useUpdateJobMutation,
  useDeleteJobMutation,
} = schedulerApi;
export default schedulerApi;
