import {utfSafeBase64Decode} from "./utfSafeBase64Decode";
import {unWrapText} from "./unWrapText";
import {
  GroupExportModel,
  GroupExportModelV0,
  GroupExportModelV1,
  GroupExportModelV2,
  isGroupExportModel,
  isGroupExportModelV0,
  isGroupExportModelV1
} from "../../types/GroupExportModel";
import {migrateGroupModelV0ToV1, migrateStepModelV0ToV1} from "../../store-migrations/migrateToV1";
import {FlowModelV1} from "../../types/FlowModel";
import {NilUuid} from "../../../utils/createNewUuid";
import {migrateStepModelV1ToV2} from "../../store-migrations/migrateToV2";

const migrateGroupedStepsModelV1ToV2 = (
  groupedStepsModelV1: GroupExportModelV1,
): GroupExportModelV2 => {
  const {group, steps} = groupedStepsModelV1;
  return {
    group,
    steps: steps.map(step => migrateStepModelV1ToV2(step))
  }
}
const migrateGroupedStepsModelV0ToV1 = (
  groupedStepsModelV0: GroupExportModelV0,
  flowId: FlowModelV1["id"]
): GroupExportModelV1 => {
  const {group, steps} = groupedStepsModelV0;
  return {
    group: migrateGroupModelV0ToV1(group, flowId),
    steps: steps.map(step => migrateStepModelV0ToV1(step, flowId))
  }
}

// !!! This function must support all previous versions of the grouped steps model !!!
export const decodeGroup = (groupExport: string): GroupExportModel | undefined => {
  const data = groupExport.split(/^ *-+ *BEGIN *-+ *$/m)[1]?.split(/^ *-+ *END *-+ *$/m)[0]?.trim();
  const maybeJson = utfSafeBase64Decode(unWrapText(data ?? ""))
  try {
    const maybeGroupedSteps = JSON.parse(maybeJson);
    if (isGroupExportModel(maybeGroupedSteps)) {
      return maybeGroupedSteps;
    }
    if (isGroupExportModelV1(maybeGroupedSteps)) {
      return migrateGroupedStepsModelV1ToV2(maybeGroupedSteps);
    }
    if (isGroupExportModelV0(maybeGroupedSteps)) {
      // flowId is just a dummy flow Id, the real flowId is not exported nor imported
      return migrateGroupedStepsModelV1ToV2(
        migrateGroupedStepsModelV0ToV1(maybeGroupedSteps, NilUuid)
      );
    }
    // there were no groups prior to V0
    return undefined;
  } catch (e) {
    return undefined;
  }
}
