"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateGenerateExecutionErrorHintsRequest = void 0;
function validateGenerateExecutionErrorHintsRequest(request) {
    const output = [];
    if (request.flow === undefined) {
        output.push({
            property: "flow",
            value: request.flow,
            constraints: { "flowShouldBeDefined": `Flow is not specified in the request` }
        });
    }
    if (request.stepId === undefined) {
        output.push({
            property: "stepId",
            value: request.flow,
            constraints: { "stepIdShouldBeDefined": `StepId is not specified in the request` }
        });
    }
    const step = request.flow.find(a => a.id === request.stepId);
    if (step === undefined) {
        output.push({
            property: "step",
            value: step,
            constraints: { "stepShouldBeValid": `StepId should reference a valid step in the specified Flow` }
        });
    }
    if (request.executionError === undefined) {
        output.push({
            property: "executionError",
            value: request.executionError,
            constraints: { "executionErrorShouldBeDefined": `ExecutionError is not specified in the request` }
        });
    }
    return output;
}
exports.validateGenerateExecutionErrorHintsRequest = validateGenerateExecutionErrorHintsRequest;
