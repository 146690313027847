import React, {useEffect} from "react";
import {UiColor} from "../../../utils/constants/UiColor";
import {classNames} from "../../../utils/classNames";

export type InputProps = {
  name: string,
  id?: string,
  value?: string,
  title?: string,
  placeholder?: string,
  backgroundTone?: UiColor.BackgroundColor,
  textTone?: UiColor.TextColor,
  textSize?: string,
  borderTone?: string,
  height?: number,
  size?: "small" | "medium" | "large" | "flexible" | "full",
  padding?: boolean,
  paddingRight?: number,
  readonly?: boolean,
  disabled?: boolean,
  invalid?: boolean,
  autoTrim?: boolean,
  onValueChange?: (newValue: string) => void
  onBlur?: (lastValue: string) => void
}

const Input = (
  {
    name,
    id,
    value = "",
    title,
    placeholder = "",
    backgroundTone = UiColor.BackgroundColor.INPUT,
    textTone = UiColor.TextColor.BLACK,
    textSize = "text-sm",
    borderTone = UiColor.BorderColor.INPUT_GRAY,
    size = "flexible",
    height = 8,
    padding = true,
    paddingRight,
    readonly = false,
    disabled = false,
    invalid = false,
    autoTrim = true,
    onValueChange,
    onBlur
  }: InputProps) => {
  const [lastValue, setLastValue] = React.useState<string>(value);
  useEffect(() => {
    // update state upon prop change
    setLastValue(value);
  }, [value]);

  const sizeClassMapping: Record<Required<InputProps>["size"], string> = {
    "small": "w-16",
    "medium": "w-32",
    "large": "w-48",
    "flexible": "flex-grow flex-shrink",
    "full": "w-full"
  };

  return <input
    name={name}
    id={id}
    size={1 /* reset HTML size to be able to set size from css without a minimal limit */}
    className={classNames(`
      peer ${"" /* to be able to style siblings according to input's state */}
      ${(disabled) ? UiColor.BackgroundColor.DISABLED : backgroundTone}
      ${(disabled) ? UiColor.TextColor.DISABLED : textTone}
      ${invalid ? `${UiColor.BorderColor.RED} ${UiColor.TextColor.RED}` : ""}
      ${borderTone ? `border ${borderTone}` : ""}
      border-solid
      rounded
      ${textSize} 
      ${padding ? "p-1" : ""}
      h-${height}
      ${sizeClassMapping[size]}
      focus:outline-none
      ${!(disabled || readonly) ? `hover:${UiColor.BackgroundColor.INPUT}` : ""}
      ${!(disabled || readonly) ? `hover:${UiColor.BorderColor.INPUT_GRAY}` : ""}
      ${!(disabled || readonly) ? `focus:${UiColor.BackgroundColor.INPUT}` : ""}
      ${!(disabled || readonly) ? `focus:${UiColor.BorderColor.INPUT_GRAY}` : ""}
      ${!(disabled || readonly) ? `hover:${UiColor.TextColor.BLACK}` : ""}
      ${!(disabled || readonly) ? `focus:${UiColor.TextColor.BLACK}` : ""}
    `)}
    style={{
      ...(paddingRight ? {paddingRight: `${paddingRight}px`} : {})
    }}
    value={lastValue}
    title={title ?? value}
    placeholder={placeholder}
    disabled={disabled}
    readOnly={readonly}
    onChange={(event) => {
      setLastValue(event.target.value);
      onValueChange && onValueChange(event.target.value);
    }}
    onBlur={(event) => {
      if (autoTrim) {
        onValueChange && onValueChange(event.target.value.trim());
      }
      onBlur && onBlur(autoTrim ? event.target.value.trim() : event.target.value);
      // reset local state to prop value upon editing finished
      // otherwise an eventual rejected value change would not be reflected in the input
      setLastValue(value);
    }}
  />;

};

export default Input;
