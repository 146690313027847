import {updateFlowById} from "../../../flowsSlice";
import {RootState} from "../../../store";
import {ThunkAction} from "@reduxjs/toolkit";
import {ScheduledJob} from "@cranq-gpt-lowcode/contracts";
import {useDeleteJobMutation} from "../../../schedulerApi";
import {FlowModel} from "../../../types/FlowModel";
import {UserModel} from "../../../types/UserModel";

export const deleteScheduledJobAction = (
  flowId: FlowModel["id"],
  userId: UserModel["id"],
  scheduledJobId: ScheduledJob["jobId"],
  deleteScheduledJobTrigger: ReturnType<typeof useDeleteJobMutation>[0]
): ThunkAction<Promise<void>, RootState, any, any> => async (
  dispatch
) => {
  const result = await deleteScheduledJobTrigger(scheduledJobId);
  if ("error" in result) {
    throw result.error;
  } else {
    dispatch(updateFlowById({
      flowId,
      update: {
        scheduledJobId: undefined,
        scheduledJobExpiryDate: undefined,
        scheduledJobHash: undefined
      }
    }));
  }
}
