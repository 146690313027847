import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {Variable} from "./types/Variable";
import {GlobalParametersModel} from "./types/GlobalParametersModel";

export type GlobalParametersState = {
  model: GlobalParametersModel
}
const initialState: GlobalParametersState = {
  model: []
};

export const globalParametersSlice = createSlice({
  name: "globalParameters",
  initialState,
  reducers: {
    createGlobalParameter: (state, action: PayloadAction<Variable | undefined>) => {
      return {
        ...state,
        model:
          [
            ...state.model,
            action.payload
              ? action.payload
              : {
                key: "",
                value: undefined,
              }
          ]
      }
    },
    updateGlobalParameter: (state, action: PayloadAction<{
      variableIndex: number,
      key: Variable["key"],
      value: Variable["value"],
      instruction: Variable["instruction"]
    }>) => {
      const variableToUpdate = state.model[action.payload.variableIndex];
      if (variableToUpdate) {
        variableToUpdate.key = action.payload.key;
        variableToUpdate.value = action.payload.value;
        variableToUpdate.instruction = action.payload.instruction
      }
    },
    deleteGlobalParameter: (state, action: PayloadAction<{
      variableIndex: number,
    }>) => {
      const variableToDelete = state.model[action.payload.variableIndex];
      if (variableToDelete) {
        return {
          ...state,
          model: [
            ...state.model.slice(0, action.payload.variableIndex),
            ...state.model.slice(action.payload.variableIndex + 1)
          ]
        }
      }
    }
  }
});

export const globalParametersSliceRawReducers = globalParametersSlice.caseReducers;

export const {
  createGlobalParameter,
  updateGlobalParameter,
  deleteGlobalParameter
} = globalParametersSlice.actions;

export const selectGlobalParameters = (state: RootState) => state.globalParameters;
export const globalParametersReducer = globalParametersSlice.reducer;
