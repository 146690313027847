import {MapApiResponse} from "../../../types/messages/utils/MapApiResponse";
import {SubscriptionPackage, SubscriptionWithPackage} from "@cranq-gpt-lowcode/contracts";

export type SchedulerQuotas = Pick<SubscriptionPackage, "maxSchedulerEntries" | "maxSchedulerEntriesPerWorkflow">;
export const calculateSchedulerQuotas = (subscriptions: MapApiResponse<SubscriptionWithPackage>[]): SchedulerQuotas => {
  return subscriptions.reduce((acc, cur) => ({
    maxSchedulerEntries: Math.max(acc.maxSchedulerEntries, cur.package.maxSchedulerEntries),
    maxSchedulerEntriesPerWorkflow: Math.max(acc.maxSchedulerEntriesPerWorkflow, cur.package.maxSchedulerEntriesPerWorkflow)
  }), {maxSchedulerEntries: 10, maxSchedulerEntriesPerWorkflow: 3}) //FIXME: should come from free package!
}
