import {FlowModel} from "../types/FlowModel";
import {ThunkAction} from "@reduxjs/toolkit";
import {selectFlow, updateFlowById} from "../flowsSlice";
import {makeSelectStepsByFlowId} from "../stepsSlice";
import {calculateFlowHash} from "../utils/calculateFlowHash";
import {RootState} from "../store";
import {selectEnvironmentByFlowId} from "../environmentsSlice";
import {createNewEnvironment} from "../utils/createNewEnvironment";
import {selectGlobalParameters} from "../globalParametersSlice";
import {
  createGlobalParameterReferenceResolver,
  GlobalParameterReferencePrefix
} from "../components/environment-editor/utils/createGlobalParameterReferenceResolver";

export const updateFlowHashAction = (
  flowId: FlowModel["id"]
): ThunkAction<Promise<void>, RootState, any, any> => async (
  dispatch,
  getState
) => {
  const state = getState();
  const flow = selectFlow(flowId)(state)?.model;
  const environment = selectEnvironmentByFlowId(flowId)(state)?.model ?? createNewEnvironment(flowId).model;
  const globalParameters = selectGlobalParameters(state);
  const resolveGlobalParameterReference = createGlobalParameterReferenceResolver(globalParameters, GlobalParameterReferencePrefix);
  const resolvedEnvironmentVariables = environment.variables.map(variable => ({
    ...variable,
    value: resolveGlobalParameterReference(variable.value)
  }));
  const selectStepsByFlowId = makeSelectStepsByFlowId();
  const steps = selectStepsByFlowId(state, flowId)?.map((step) => step.model);
  if (flow && steps) {
    const newFlowHash = calculateFlowHash(flow, steps, resolvedEnvironmentVariables);
    if (newFlowHash !== flow.hash) { // prevent infinite loop
      dispatch(updateFlowById({
          flowId,
          update: {
            model: {
              hash: newFlowHash
            }
          }
        }
      ));
    }
  }
}
