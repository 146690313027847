import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {Id, isId} from "../utils/types/Id";
import {EnvironmentState, EnvironmentStateV0, EnvironmentStateV1} from "./types/EnvironmentState";
import {Variable} from "./types/Variable";
import {FlowModel} from "./types/FlowModel";
import {EnvironmentModel} from "./types/EnvironmentModel";
import createNewId from "../utils/createNewId";

type CreateNewEnvironmentPayload = Partial<EnvironmentModel> & Pick<EnvironmentModel, "flowId">;

export type EnvironmentsStateV1 = Record<Id, EnvironmentStateV1>;
export type EnvironmentsStateV0 = Record<Id, EnvironmentStateV0>;
export type EnvironmentsState = EnvironmentsStateV1
const initialState: EnvironmentsState = {
  'id-635dbf': {
    model: {
      id: 'id-635dbf',
      variables: [],
      flowId: '2ec187a7-ecce-495f-bad3-42a06dd95ec1',
      name: 'Default Environment'
    }
  },
  'id-d52b14': {
    model: {
      id: 'id-d52b14',
      variables: [],
      flowId: '087c86d5-43bd-409d-90ca-4224b15ef608',
      name: 'Default Environment'
    }
  },
  'id-34bc15': {
    model: {
      id: 'id-34bc15',
      variables: [],
      flowId: '4f6d9b5d-1236-4985-b4c6-47e3442de55a',
      name: 'Default Environment'
    }
  },
  'id-0300d3': {
    model: {
      id: 'id-0300d3',
      variables: [],
      flowId: '734bd745-b320-4145-b125-230e2925a817',
      name: 'Default Environment'
    }
  }
};

export const environmentsSlice = createSlice({
  name: "environments",
  initialState,
  reducers: {
    createEnvironment: (state, action: PayloadAction<CreateNewEnvironmentPayload>) => {
      const {
        payload: {
          flowId,
          name = "Default Environment",
          variables = []
        }
      } = action;
      const id = createNewId();
      state[id] = {
        model: {
          id,
          variables: variables ?? [],
          flowId: flowId,
          name: name || "Default Environment"
        }
      };
    },
    deleteEnvironmentsByFlowId: (state, action: PayloadAction<FlowModel["id"]>) => {
      Object.entries(state)
        .filter(([, environment]) => environment.model.flowId === action.payload)
        .forEach(([environmentId]) => {
          if (isId(environmentId) && state[environmentId]) {
            delete state[environmentId];
          }
        });
    },
    createEnvironmentVariable: (state, action: PayloadAction<{
      environmentId: EnvironmentModel["id"]
    }>) => {
      const environment = state[action.payload.environmentId];
      if (environment) {
        environment.model.variables.push({
          key: "",
          value: ""
        });
      }
    },
    updateEnvironmentVariable: (state, action: PayloadAction<{
      environmentId: EnvironmentModel["id"],
      variableIndex: number,
      key: Variable["key"],
      value: Variable["value"],
      instruction: Variable["instruction"]
    }>) => {
      const environment = state[action.payload.environmentId];
      const variable = environment?.model.variables[action.payload.variableIndex];
      if (environment && variable) {
        environment.model.variables[action.payload.variableIndex] = {
          key: action.payload.key,
          value: action.payload.value,
          instruction: action.payload.instruction
        };
      }
    },
    deleteEnvironmentVariable: (state, action: PayloadAction<{
      environmentId: EnvironmentModel["id"],
      variableIndex: number,
    }>) => {
      const environment = state[action.payload.environmentId];
      if (environment) {
        environment.model.variables.splice(action.payload.variableIndex, 1);
      }
    }
  }
});

export const environmentsSliceRawReducers = environmentsSlice.caseReducers;

export const {
  createEnvironment,
  deleteEnvironmentsByFlowId,
  createEnvironmentVariable,
  updateEnvironmentVariable,
  deleteEnvironmentVariable
} = environmentsSlice.actions;

export const selectEnvironments = (state: RootState) => state.environments;
export const selectEnvironment = (id?: Id) => (state: RootState): EnvironmentState | undefined => id && state.environments[id];
export const selectEnvironmentByFlowId = (flowId?: EnvironmentModel["flowId"]) => (state: RootState): EnvironmentState | undefined =>
  flowId && Object.values(state.environments).find((environment) => environment.model.flowId === flowId);
export const environmentsReducer = environmentsSlice.reducer;
