import {InlineIconName} from "../inline-icon/InlineIconName";
import {classNames} from "../../../utils/classNames";
import InlineIcon from "../inline-icon/InlineIcon";
import {UiColor} from "../../../utils/constants/UiColor";

export enum StatusLineType {
  HINT = "HINT",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WARNING = "WARNING",
  CUSTOM = "CUSTOM",
}


const statusLineTypeToIconNameMapper = (
  type: StatusLineType
): InlineIconName | undefined => {
  switch (type) {
    case StatusLineType.HINT:
      return InlineIconName.HINT;
    case StatusLineType.SUCCESS:
      return InlineIconName.CHECK;
    case StatusLineType.ERROR:
      return InlineIconName.WARNING_BIG;
    case StatusLineType.WARNING:
      return InlineIconName.WARNING_BIG;
  }
}

const statusLineTypeToPrimaryToneMapper = (
  type: StatusLineType
): UiColor.TextColor | undefined => {
  switch (type) {
    case StatusLineType.HINT:
      return UiColor.TextColor.GOLD;
    case StatusLineType.SUCCESS:
      return UiColor.TextColor.GOLD;
    case StatusLineType.ERROR:
      return UiColor.TextColor.RED;
    case StatusLineType.WARNING:
      return UiColor.TextColor.GOLD;
  }
}

type StatusLinePropsBase = {
  message: string,
  actionText?: string,
  type: StatusLineType
  // events
  onActionClick?: () => void
}
export type StatusLineProps =
  (StatusLinePropsBase & {
    type: Omit<StatusLineType, StatusLineType.CUSTOM>
    iconName?: never,
    primaryTone?: never,
  })
  |
  (StatusLinePropsBase & {
    type: StatusLineType.CUSTOM
    iconName: InlineIconName,
    primaryTone: UiColor.TextColor,
  })

const StatusLine = (
  {
    message,
    actionText,
    type,
    iconName,
    primaryTone,
    //
    onActionClick
  }: StatusLineProps) => {

  const iconNameToUse = iconName
    || (type && statusLineTypeToIconNameMapper(type));
  const primaryToneToUse = primaryTone
    || (type && statusLineTypeToPrimaryToneMapper(type));

  return (
    <div id="statusLine" className={classNames(`w-fit min-w-0 h-8 p-2 flex flex-row items-center gap-2`)}>
      {iconNameToUse && (
        <div className={"flex-grow-0 flex-shrink-0 h-full"}>
          <InlineIcon name={iconNameToUse} primaryTone={primaryToneToUse}/>
        </div>
      )}
      <div className={classNames(`flex-grow truncate text-sm`)}>
        <span className={"overflow-ellipsis"}>{message}</span>
      </div>
      {actionText && <button
        className={`flex-grow-0 flex-shrink-0 text-sm ${primaryToneToUse}`}
        onClick={onActionClick}>{actionText} &gt;</button>}
    </div>
  )
}

export default StatusLine;
