import {GlobalParametersState} from "../../../globalParametersSlice";
import {Variable} from "../../../types/Variable";
import {GlobalParametersModel} from "../../../types/GlobalParametersModel";
import {isGlobalParameterReference} from "./isGlobalParameterReference";

export const GlobalParameterReferencePrefix = "globalParameter:";
export type GlobalParameterReferenceResolver = (
  value: Variable["value"],
) => Variable["value"];

export const createGlobalParameterReferenceResolver = (
  globalParameters: GlobalParametersState | GlobalParametersModel,
  globalParameterReferencePrefix: string
): GlobalParameterReferenceResolver => (
  value,
) => {
  const parameters = "model" in globalParameters ? globalParameters.model : globalParameters;
  if (!isGlobalParameterReference(value)) {
    return value;
  }
  const key = value.replace(globalParameterReferencePrefix, "");
  return parameters.find(param => param.key === key)?.value
}
