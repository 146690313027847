import {MapApiResponse} from "../../types/messages/utils/MapApiResponse";
import {CreateWebhookResponse, WEBHOOK_INVOKE_URL_PATH} from "@cranq-gpt-lowcode/contracts";
import {ApiContext, getApiBaseUrl} from "../../utils/getApiBaseUrl";
import Notification from "../../../common/components/notification/Notification";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import React from "react";
import {UiColor} from "../../../utils/constants/UiColor";

export type WebhookInfoPanelProps =
  Pick<MapApiResponse<CreateWebhookResponse>, "webhookId" | "expiry">
  & Partial<Pick<CreateWebhookResponse, "token">>;
export const WebhookInfoPanel = (
  {
    webhookId,
    expiry,
    token
  }: WebhookInfoPanelProps
) => {
  const webhookUrl = `${getApiBaseUrl(ApiContext.WEBHOOK)}/${WEBHOOK_INVOKE_URL_PATH.replace(":webhookId", webhookId)}`;
  return (
    <div className={"flex flex-col gap-2 flex-grow min-w-0"}>
      <dl className={"list-disc list-inside"}>
        <dt>URL:</dt>
        <dd>{webhookUrl}</dd>
        <dt>Security (bearer) token:</dt>
        <dd>
          <div className={"w-full overflow-x-auto text-sm"}>
            {token}
          </div>
          {token
            ? <Notification
              text={"Please save the security token for yourself as it won't be displayed anymore!"}
              type={MESSAGE_TYPE.WARNING}/>
            : <Notification
              text={"Security token is not available. If you forgot to save it, delete the webhook and create it again!"}
              type={MESSAGE_TYPE.WARNING}/>}
        </dd>
        <dt>Expires:</dt>
        <dd> {new Date(expiry).toLocaleString()}</dd>
      </dl>
      <div>
        <h3>How to call the webhook?</h3>
        <p>
          Call the webhook with a <em>POST</em> request passing the security token as <em>Bearer</em> token in
          the <em>Authorization</em> header.<br/>
        </p>
      </div>
      <div>
        <h3>Example</h3>
        <pre
          className={`text-xs ${UiColor.BackgroundColor.GRAY} ${UiColor.BorderColor.INPUT_GRAY} border rounded p-2 overflow-x-auto`}>
          {`
curl \\
--request POST \\
--url "${webhookUrl}" \\
--header "Authorization: Bearer ${token ?? "***"}"
     `.trim()}
        </pre>
      </div>
    </div>
  );
};
