"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateCodeForStepRequestToInternalMapper = void 0;
function GenerateCodeForStepRequestToInternalMapper(request, userId) {
    return {
        stepId: request.stepId,
        env: request.env,
        flow: request.flow,
        flowDescription: request.flowDescription,
        userId: userId
    };
}
exports.GenerateCodeForStepRequestToInternalMapper = GenerateCodeForStepRequestToInternalMapper;
