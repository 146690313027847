import {useDialog} from "../../../common/components/dialog/hook/useDialog";
import {useAppDispatch} from "../../hooks";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import {ImportFlowEditor} from "../import-flow-editor/ImportFlowEditor";
import {importFlowAction} from "../import-flow-editor/actions/importFlowAction";
import Button, {ButtonType} from "../../../common/components/button/Button";
import {UiColor} from "../../../utils/constants/UiColor";
import Dialog from "../../../common/components/dialog/Dialog";
import React, {useCallback, useContext, useEffect, useRef} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useToast} from "../../../common/components/toast/hook/useToast";
import {ApiContext, getApiBaseUrl} from "../../utils/getApiBaseUrl";
import {AuthContext} from "../auth-context-provider/AuthContextProvider";

export const ImportNewFlow = () => {
  const {openDialog, closeDialog, dialogProps} = useDialog();
  const {showToast} = useToast()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const exportedFlowAsString = searchParams.get("importFlow");
  const {user, anonymousUserId} = useContext(AuthContext);

  const handleCancel = useCallback(() => {
    closeDialog();
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.delete("importFlow");
      return newParams;
    });
  }, [closeDialog, setSearchParams]);

  const importFlow = useCallback((exportedFlowAsString: string = "") => {
    return new Promise<string>((resolve, reject) => {
      if (/^url:/.test(exportedFlowAsString)) {
        const corsProxyUrl = `${getApiBaseUrl(ApiContext.EDITOR)}/flow/importFrom`;
        const urlParam = exportedFlowAsString.replace(/^url:/, "");
        const url = `${corsProxyUrl}?url=${encodeURIComponent(urlParam)}`
        fetch(url)
          .then((response) => {
            if (response.ok) {
              return response.text();
            }
            throw new Error(`Failed to import workflow (${response.statusText})`);
          })
          .then((exportedSlowAsString) => {
            resolve(exportedSlowAsString);
          })
          .catch((e) => {
            reject(e);
          });
      } else {
        resolve(exportedFlowAsString);
      }
    }).then((exportedSlowAsString) => {
      openDialog({
        title: "Import workflow",
        type: MESSAGE_TYPE.CUSTOM,
        content: <ImportFlowEditor
          content={exportedSlowAsString}
          onCancel={handleCancel}
          onImport={async (importedFlow) => {
            const importedFlowId = await dispatch(
              importFlowAction(importedFlow, user.model?.id ?? anonymousUserId)
            );
            closeDialog();
            navigate(`/flow/${importedFlowId}`);
          }}/>,
        onClose: handleCancel
      });
    })
      .catch((error) => showToast({message: error.message, type: MESSAGE_TYPE.ERROR}));
  }, [anonymousUserId, closeDialog, dispatch, handleCancel, navigate, openDialog, showToast, user.model?.id]);

  const isImporting = useRef(false);
  useEffect(() => {
    if (exportedFlowAsString && !isImporting.current) {
      isImporting.current = true;
      importFlow(exportedFlowAsString).then(() => {
        isImporting.current = false;
      });
    }
  }, [exportedFlowAsString, importFlow]);

  return (
    <>
      <Button
        title={"Import workflow"}
        type={ButtonType.SECONDARY}
        shadowTone={UiColor.ShadowColor.BLACK_MEDIUM}
        onClick={() => importFlow()}/>
      {dialogProps && <Dialog {...dialogProps}/>}
    </>
  );
};
