import parseJson from "core-js-pure/full/json/parse.js";


type ParseJsonReviver = Parameters<typeof parseJson>[1];
const parseBigIntegersAsString: ParseJsonReviver = (
  _key,
  parsedValue,
  context
) => {
  const source = context.source;
  const maybeNumber = source !== undefined ? parseFloat(source) : NaN;
  if (!isNaN(maybeNumber) && Number.isInteger(maybeNumber) && !Number.isSafeInteger(parsedValue)) {
    return source; // return too big integer as string
  }
  return parsedValue;
}

export const safeJsonParse = (maybeJsonString: string) => {
  try {
    return parseJson(maybeJsonString, parseBigIntegersAsString) as unknown;
  } catch (e) {
    return maybeJsonString === "" ? undefined : maybeJsonString;
  }
}
