import {RootState} from "../store";
import {isDefinedPersistedState, isObject} from "../store-migrations/utils";
import {isFlowStateV0, isFlowStateV1, isFlowStateV2} from "./FlowState";
import {
  isStepStatePriorV0,
  isStepStateV0,
  isStepStateV1,
  isStepStateV2,
  StepStatePriorV0,
  StepStateV1
} from "./StepState";
import {isGroupStateV0, isGroupStateV1, isGroupStateV2} from "./GroupState";
import {isEnvironmentStateV0, isEnvironmentStateV1, isEnvironmentStateV2} from "./EnvironmentState";
import {isUserStateV0, isUserStateV1, isUserStateV2, UserStateV0} from "./UserState";
import {Id} from "../../utils/types/Id";
import {FlowsStateV0} from "../flowsSlice";
import {GroupsStateV0} from "../groupsSlice";
import {StepsStateV0} from "../stepsSlice";
import {EnvironmentsStateV0} from "../environmentsSlice";

export type PersistedRootStateV2 = RootState;
export const isPersistedRootStateV2 = (state: unknown): state is PersistedRootStateV2 =>
  isDefinedPersistedState(state)
  && state._persist.version === 2
  && "flows" in state && isObject(state.flows) && Object.values(state.flows).every(isFlowStateV2)
  && "groups" in state && isObject(state.groups) && Object.values(state.groups).every(isGroupStateV2)
  && "steps" in state && isObject(state.steps) && Object.values(state.steps).every(isStepStateV2)
  && "environments" in state && isObject(state.environments) && Object.values(state.environments).every(isEnvironmentStateV2)
  && "user" in state && isUserStateV2(state.user)

// versioning
export type PersistedRootStateV1 = Omit<PersistedRootStateV2, "steps"> & {
  steps: Record<Id, StepStateV1>
};
export const isPersistedRootStateV1 = (state: unknown): state is PersistedRootStateV1 =>
  isDefinedPersistedState(state)
  && state._persist.version === 1
  && "flows" in state && isObject(state.flows) && Object.values(state.flows).every(isFlowStateV1)
  && "groups" in state && isObject(state.groups) && Object.values(state.groups).every(isGroupStateV1)
  && "steps" in state && isObject(state.steps) && Object.values(state.steps).every(isStepStateV1)
  && "environments" in state && isObject(state.environments) && Object.values(state.environments).every(isEnvironmentStateV1)
  && "user" in state && isUserStateV1(state.user)


export type PersistedRootStateV0 =
  Omit<PersistedRootStateV1, "flows" | "groups" | "steps" | "environments" | "user">
  & {
  flows: FlowsStateV0,
  groups: GroupsStateV0,
  steps: StepsStateV0,
  environments: EnvironmentsStateV0,
  user: UserStateV0
};
export const isPersistedRootStateV0 = (state: unknown): state is PersistedRootStateV0 =>
  isDefinedPersistedState(state)
  && state._persist.version === 0
  && "flows" in state && isObject(state.flows) && Object.values(state.flows).every(isFlowStateV0)
  && "groups" in state && isObject(state.groups) && Object.values(state.groups).every(isGroupStateV0)
  && "steps" in state && isObject(state.steps) && Object.values(state.steps).every(isStepStateV0)
  && "environments" in state && isObject(state.environments) && Object.values(state.environments).every(isEnvironmentStateV0)
  && "user" in state && isUserStateV0(state.user)


export type PersistedRootStatePriorV0 = Omit<PersistedRootStateV0, "steps"> & {
  steps: Record<Id, StepStatePriorV0>;
};
export const isPersistedRootStatePriorV0 = (state: unknown): state is PersistedRootStatePriorV0 =>
  isDefinedPersistedState(state)
  && state._persist.version < 0
  && "steps" in state && isObject(state.steps) && Object.values(state.steps).every(isStepStatePriorV0)
