"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StagedLogEntryBuilder = void 0;
class StagedLogEntryBuilder {
    constructor(parameters) {
        this._logEntry = {
            parameters,
            stages: {},
            performance: {
                durationMs: 0
            }
        };
        this._t0 = process.hrtime.bigint();
        this._tLast = this._t0;
    }
    recordStage(name, output) {
        const tCurr = process.hrtime.bigint();
        this._logEntry.stages[name] = {
            output: output,
            performance: {
                durationMs: this._substractElapsedMs(tCurr, this._tLast),
                startMs: this._substractElapsedMs(this._tLast, this._t0)
            }
        };
        this._tLast = tCurr;
    }
    recordError(error) {
        this._logEntry.error = error;
    }
    getLogEntry() {
        this._logEntry.performance.durationMs = this._substractElapsedMs(process.hrtime.bigint(), this._t0);
        return this._logEntry;
    }
    _substractElapsedMs(start, end) {
        return Number((start - end) / BigInt(1000000));
    }
}
exports.StagedLogEntryBuilder = StagedLogEntryBuilder;
