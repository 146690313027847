import React, {useEffect, useState} from "react";
import Editor, {EditorMode} from "../../../common/components/editor/Editor";
import Button, {ButtonType} from "../../../common/components/button/Button";
import Notification from "../../../common/components/notification/Notification";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import {decodeFlow} from "../../utils/sharing/decodeFlow";
import {FlowExportModel} from "../../types/FlowExportModel";

export type ImportFlowEditorProps = {
  content?: string,
  onCancel?: () => void,
  onImport?: (flowToImport: FlowExportModel) => void,
}
export const ImportFlowEditor = (
  {
    content = "",
    onCancel,
    onImport,
  }: ImportFlowEditorProps) => {
  const [validDecodedEntity, setValidDecodedEntity] = useState<FlowExportModel | undefined>();
  const [editorContent, setEditorContent] = useState<string>(content);
  const validateImport = (value: string) => {
    const decodedFlow = decodeFlow(value);
    if (decodedFlow) {
      setValidDecodedEntity(decodedFlow);
      return;
    }
    setValidDecodedEntity(undefined);
  }

  const handleOnChange = (value: string) => {
    setEditorContent(value);
    validateImport(value);
  }

  useEffect(() => {
    validateImport(content);
  }, [content]);

  return (
    <section className="container min-w-[80ch] h-full flex flex-col justify-between text-sm">
      <Editor
        mode={EditorMode.TEXT}
        readOnly={false}
        onChange={handleOnChange}
        content={editorContent}
        placeholder={"Paste exported flow here"}
        lines={20}
      />
      <div className={"py-4"}>
        {editorContent.trim() && !validDecodedEntity && <Notification
          text={"This is not a valid flow export format!"}
          secondaryText={"Use the Share button on the Flow page to set the correct export format to the clipboard."}
          type={MESSAGE_TYPE.ERROR}/>}
      </div>
      <div className={"flex justify-between py-4"}>
        <Button title={"Cancel"} type={ButtonType.SECONDARY} onClick={onCancel}/>
        <Button title={"Import"}
                type={ButtonType.PRIMARY}
                disabled={!validDecodedEntity}
                onClick={() => validDecodedEntity && onImport && onImport(validDecodedEntity)}/>
      </div>
    </section>
  )
}
