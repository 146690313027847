import {LoggedInUserState} from "../../types/UserState";
import {isProUser} from "./utils/isProUser";
import {Sticker} from "../../../common/components/sticker/Sticker";
import {classNames} from "../../../utils/classNames";
import {UiColor} from "../../../utils/constants/UiColor";
import fallbackUserAvatarUrl from "../../../common/assets/userAvatar.svg";
import React from "react";
import {SubscriptionWithTimestamps} from "../../types/SubscriptionWithTimestamps";

type UserAvatarProps = {
  user: LoggedInUserState;
  subscriptions?: SubscriptionWithTimestamps[];
}
export const UserAvatar = (
  {
    user,
    subscriptions
  }: UserAvatarProps
) => {
  return (
    <div className={"relative"}>
      {isProUser(subscriptions)
        ? <Sticker text={"pro"}/>
        : null
      }
      <img
        className={classNames(`
          w-8 h-auto 
          align-middle 
          shadow rounded-full cursor-pointer
          ${isProUser(subscriptions) ? `${UiColor.BorderColor.GOLD} border-solid border-2` : "border-none"}
        `)}
        src={user.model.avatarUrl ?? fallbackUserAvatarUrl}
        alt={user.model.displayName}
        referrerPolicy={"no-referrer"}
        title={`Logged in as ${user.model.displayName}`}
      />
    </div>
  );
};
