import {GetOAuthConnectionsResponse} from "@cranq-gpt-lowcode/contracts";
import {SubscriptionWithTimestamps} from "../types/SubscriptionWithTimestamps";
import {hasRequiredSubscription} from "./hasRequiredSubscription";

export const isApiConnected = (
  apiConnections: GetOAuthConnectionsResponse | undefined,
  subscriptions: SubscriptionWithTimestamps[] | undefined,
  api: string): boolean => {
  return !!apiConnections?.[api]?.connected
    && hasRequiredSubscription(subscriptions, apiConnections?.[api]?.requiredSubscriptions);
}
