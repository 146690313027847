import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react";
import {CreateWebhookRequest, CreateWebhookResponse, Webhook, WebhookResponse} from "@cranq-gpt-lowcode/contracts";
import {EndpointBuilder} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {ApiContext, getApiBaseUrl} from "./utils/getApiBaseUrl";
import {MapApiResponse} from "./types/messages/utils/MapApiResponse";

export const webhookApiRawEndpointBuilder = (
  builder: EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, "Webhook", "webhookApi">
) => ({
  listWebhooks: builder.query<MapApiResponse<WebhookResponse>[], void>({
    query: ()=> ({
      url: `/`,
      method: "GET",
    }),
    providesTags: [{type: "Webhook", id: "webhooks"}],
    //FIXME: can we extract transformResponse to a generic function?
    transformResponse(baseQueryReturnValue: WebhookResponse[]): MapApiResponse<WebhookResponse>[] {
      return baseQueryReturnValue.map((webhook) => ({
        ...webhook,
        created: new Date(webhook.created).getTime(),
        expiry: new Date(webhook.expiry).getTime(),
      }));
    }
  }),
  getWebhook: builder.query<MapApiResponse<Webhook>, Webhook["webhookId"]>({
    query: (webhookId) => ({
      url: `/${webhookId}`,
      method: "GET"
    }),
    providesTags: (_result, _error, webhookId) => [
      {"type": "Webhook", "id": `webhooks/${webhookId}`}
    ],
    transformResponse(baseQueryReturnValue: Webhook): MapApiResponse<Webhook> {
      return {
        ...baseQueryReturnValue,
        created: new Date(baseQueryReturnValue.created).getTime(),
        expiry: new Date(baseQueryReturnValue.expiry).getTime(),
        deleted: baseQueryReturnValue.deleted ? new Date(baseQueryReturnValue.deleted).getTime() : undefined,
        lastInvocation: baseQueryReturnValue.lastInvocation ? new Date(baseQueryReturnValue.lastInvocation).getTime() : undefined
      }
    }
  }),
  createWebhook: builder.mutation<MapApiResponse<CreateWebhookResponse>, CreateWebhookRequest>({
    query: (request) => ({
      url: ``,
      method: "POST",
      body: request
    }),
    invalidatesTags: [{type: "Webhook", id: "webhooks"}],
    transformResponse(baseQueryReturnValue: CreateWebhookResponse): MapApiResponse<CreateWebhookResponse> {
      return {
        ...baseQueryReturnValue,
        expiry: new Date(baseQueryReturnValue.expiry).getTime(),
      }
    }
  }),
  deleteWebhook: builder.mutation<void, Webhook["webhookId"]>({
    query: (webhook) => ({
      url: `${webhook}`,
      method: "DELETE"
    }),
    invalidatesTags: (_result, _error, webhookId) => [
      {type: "Webhook", id: `webhooks/${webhookId}`},
      {type: "Webhook", id: "webhooks"}
    ],
  })
});
const webhookApi = createApi({
  reducerPath: "webhookApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getApiBaseUrl(ApiContext.WEBHOOK),
    credentials: "include",
    prepareHeaders: (headers) => {
      headers.append("Ga-Client-Id", window.__GLOBAL_VAR__.GA_ClIENT_ID || "unset");
    }
  }),
  endpoints: webhookApiRawEndpointBuilder
});

export const {
  useListWebhooksQuery,
  useLazyListWebhooksQuery,
  useGetWebhookQuery,
  useCreateWebhookMutation,
  useDeleteWebhookMutation
} = webhookApi;
export default webhookApi;
