import Notification from "../../../common/components/notification/Notification";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import Button, {ButtonType} from "../../../common/components/button/Button";
import {UiColor} from "../../../utils/constants/UiColor";
import React from "react";

type LaunchStepChatProps = {
  permissionGranted: boolean,
  disabled: boolean,
  onStepChatStart?: () => void
}
export const LaunchStepChat = (
  {
    permissionGranted,
    disabled,
    onStepChatStart
  }: LaunchStepChatProps) => (
  <div className={"py-8 flex flex-row justify-between"}>
    <Notification
      text={"Have any problem with the step? 🤔"}
      secondaryText={"Discuss it with our AI Wizard!"}
      type={MESSAGE_TYPE.QUESTION}
    />
    <Button
      title={`Launch AI Wizard${permissionGranted ? "" : " (*)"}`}
      type={ButtonType.SECONDARY}
      backgroundTone={UiColor.BackgroundColor.GOLD}
      shadowTone={UiColor.ShadowColor.BLACK_LIGHT}
      disabled={disabled}
      onClick={() => onStepChatStart && onStepChatStart()}
    />
  </div>
);
