import {StepModel} from "../types/StepModel";
import {unique} from "../../utils/unique";

export const calculateStepGroupedIndex = (
  stepId: StepModel["id"],
  steps: Pick<StepModel, "id" | "index" | "groupId">[]
): string | undefined => {

  const step = steps.find((step) => step.id === stepId);
  if (!step) {
    return
  }
  const precedingSteps = steps.filter((s) => s.index < step.index).sort((a, b) => a.index - b.index);
  const precedingStep = precedingSteps[precedingSteps.length - 1];
  if (precedingStep === undefined) {
    return step.groupId ? "1.1" : "1";
  }
  const precedingGroupedSteps = precedingSteps.filter((s) => s.groupId !== undefined);
  const groupsOfPrecedingSteps = unique(precedingGroupedSteps.map((s) => s.groupId));
  const numberOfUngroupedSteps = precedingSteps.length - precedingGroupedSteps.length;
  const numberOfGroups = groupsOfPrecedingSteps.length;
  const lastIndex = numberOfUngroupedSteps + numberOfGroups;
  if (!step.groupId) {
    return `${lastIndex + 1}`;
  } else {
    if (step.groupId === precedingStep.groupId) {
      const numberOfPrecedingStepsInTheGroup = precedingSteps.filter((s) => s.groupId === step.groupId).length;
      return `${lastIndex}.${numberOfPrecedingStepsInTheGroup + 1}`;
    } else {
      return `${lastIndex + 1}.1`;
    }
  }
}
