import {StepModel} from "./StepModel";
import {StepOutputModel} from "./StepOutputModel";
import {isId} from "../../utils/types/Id";

export type StepInputModelValueSource = {
  type: "value",
  value: unknown,
  instruction?: string
}
export type StepInputModelOutputSource = {
  type: "output",
  stepId: StepModel["id"],
  outputId: StepOutputModel["id"]
}
export type StepInputModelEnvSource = {
  type: "env",
  name: string
}
export type StepInputModelSource = StepInputModelValueSource | StepInputModelOutputSource | StepInputModelEnvSource;


const isStepInputModelValueSource = (maybeStepInputModelValueSource: unknown): maybeStepInputModelValueSource is StepInputModelValueSource => {
  return typeof maybeStepInputModelValueSource === "object" && maybeStepInputModelValueSource !== null
    && "type" in maybeStepInputModelValueSource && maybeStepInputModelValueSource.type === "value"
    && ("instruction" in maybeStepInputModelValueSource ? typeof maybeStepInputModelValueSource.instruction === "string" : true)
}

const isStepInputModelOutputSource = (maybeStepInputModelOutputSource: unknown): maybeStepInputModelOutputSource is StepInputModelOutputSource => {
  return typeof maybeStepInputModelOutputSource === "object" && maybeStepInputModelOutputSource !== null
    && "type" in maybeStepInputModelOutputSource && maybeStepInputModelOutputSource.type === "output"
    && "stepId" in maybeStepInputModelOutputSource && isId(maybeStepInputModelOutputSource.stepId)
    && "outputId" in maybeStepInputModelOutputSource && isId(maybeStepInputModelOutputSource.outputId)
}

const isStepInputModelEnvSource = (maybeStepInputModelEnvSource: unknown): maybeStepInputModelEnvSource is StepInputModelEnvSource => {
  return typeof maybeStepInputModelEnvSource === "object" && maybeStepInputModelEnvSource !== null
    && "type" in maybeStepInputModelEnvSource && maybeStepInputModelEnvSource.type === "env"
    && "name" in maybeStepInputModelEnvSource && typeof maybeStepInputModelEnvSource.name === "string"
}

export const isStepInputModelSource = (maybeStepInputModelSource: unknown): maybeStepInputModelSource is StepInputModelSource => {
  return isStepInputModelValueSource(maybeStepInputModelSource)
    || isStepInputModelOutputSource(maybeStepInputModelSource)
    || isStepInputModelEnvSource(maybeStepInputModelSource)
}
