import {safeJsonParse} from "../../../utils/safeJsonParse";
import {safeJsonStringify} from "../../../utils/safeJsonStringify";
import {StepDrawerProps} from "../StepDrawer";

export const formatExecutionError = (
  executionError: StepDrawerProps["executionError"]
): string => {
  const lastExecutionErrorParsed = safeJsonParse(executionError ?? "");
  const {
    message,
    ...details
  } = typeof lastExecutionErrorParsed === "object" && lastExecutionErrorParsed !== null && "message" in lastExecutionErrorParsed
    ? lastExecutionErrorParsed
    : {
      message: executionError,
    };
  return `${message}${Object.keys(details).length > 0 ? `\n\n--------------- Details-------------\n\n${safeJsonStringify(details)}` : ""}`;
}
