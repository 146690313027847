"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LambdaInvocationError = void 0;
class LambdaInvocationError extends Error {
    constructor(reason, details) {
        super(reason);
        this.reason = reason;
        this.details = details;
        Object.setPrototypeOf(this, LambdaInvocationError.prototype);
    }
}
exports.LambdaInvocationError = LambdaInvocationError;
