import MultilineInput from "../../../common/components/multiline-input/MultilineInput";
import React, {ReactNode, useEffect, useState} from "react";
import {StepModel} from "../../types/StepModel";
import {AiModel, AiModelType} from "@cranq-gpt-lowcode/contracts";
import Editor, {EditorMode} from "../../../common/components/editor/Editor";
import {UiColor} from "../../../utils/constants/UiColor";

const Hint = (props: { children: ReactNode }) => (
  <p className={`text-sm italic ${UiColor.TextColor.GRAY}`}>{props.children}</p>
);
const Warning = (props: { children: ReactNode }) => (
  <p className={`text-sm italic ${UiColor.TextColor.GOLD}`}>{props.children}</p>
);

const validatePlaceholdersInPrompt = (prompt: string, validPlaceholders: string[]) => {
  const placeholders = prompt.match(/{{([^\n]+?)}}/g)?.map((placeholder) => placeholder.replace(/{{|}}/g, "")) ?? [];
  return placeholders.filter((placeholder) => !validPlaceholders.includes(placeholder));
}

type StepDescriptionEditorProps = {
  description: StepModel["description"];
  disabled: boolean;
  targetAiModelType: AiModel["type"];
  inputPlaceholders?: string[];
  onValueChange?: (newDescription: StepDescriptionEditorProps["description"]) => void
  onBlur?: (newDescription: StepDescriptionEditorProps["description"]) => void
}

const StepDescriptionEditor = (
  {
    description,
    disabled,
    targetAiModelType,
    inputPlaceholders = [],
    onValueChange,
    onBlur
  }: StepDescriptionEditorProps
) => {
  const [invalidPlaceholders, setInvalidPlaceholders] = useState<string[]>([]);
  useEffect(() => {
    const invalidPlaceholders = validatePlaceholdersInPrompt(description, inputPlaceholders);
    setInvalidPlaceholders(invalidPlaceholders);
  }, [description, inputPlaceholders]);

  const handlePromptChange = (newPrompt: string) => {
    const invalidPlaceholders = validatePlaceholdersInPrompt(newPrompt, inputPlaceholders);
    setInvalidPlaceholders(invalidPlaceholders);
  };

  const promptContainsPlaceholders = description.match(/{{([^\n]+?)}}/g);

  switch (targetAiModelType) {
    case AiModelType.CODE_WRITER:
      return (
        <MultilineInput
          id={`step-description`}
          name={"description"}
          disabled={disabled}
          placeholder={"Type in the step's description here... (be as specific as possible)"}
          rows={3}
          autoGrowUntil={10}
          value={description}
          onValueChange={onValueChange}
          onBlur={onBlur}/>
      )
    case AiModelType.TEXT_WRITER:
      return (
        <>
          <Editor
            content={description}
            readOnly={disabled}
            placeholder={"Type the prompt here..."}
            lines={5}
            onChange={handlePromptChange}
            onBlur={onBlur}
            wrapEnabled={true}
            showGutter={false}
            mode={EditorMode.HANDLEBARS}
            autoCompletionStrings={inputPlaceholders.map((placeholder) => `{{${placeholder}}}`)}
            autoCompletionMeta={"inputs"}
            autoCompletionTriggerCharacters={["{"]}
          />
          {invalidPlaceholders.length > 0 ? (
            <Warning>
              Warning! Invalid placeholders found in prompt:
              <ul className={"list-disc list-inside"}>{invalidPlaceholders.map((p) => <li>{`{{${p}}}`}</li>)}</ul>
            </Warning>
          ) : (
            !promptContainsPlaceholders &&
            <Hint>Hint: You can refer to input values within the prompt by inserting placeholders like
              this: {"{{input name}}"}.</Hint>
          )}
        </>
      )

  }
}

export default StepDescriptionEditor;
