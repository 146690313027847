import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../../../store";
import {isUuid} from "../../../../utils/types/Uuid";
import {StepModel} from "../../../types/StepModel";
import {makeSelectStepsByFlowId, updateStepById} from "../../../stepsSlice";
import {FlowModel} from "../../../types/FlowModel";
import {selectEnvironmentByFlowId} from "../../../environmentsSlice";
import {StepState} from "../../../types/StepState";
import {EnvironmentModel} from "../../../types/EnvironmentModel";
import {processValidationErrors} from "./processValidationErrors";
import {PendingRequestId} from "../../../../utils/types/RequestId";
import {MESSAGE_TYPE} from "../../../../common/helper/messageTypeColorMapper";
import editorApi from "../../../editorApi";
import {mapStepModelToApiStepModel} from "../../../utils/mapStepModelToApiStepModel";
import {
  GenerateExecutionErrorHintsRequest,
  Step,
  validateGenerateExecutionErrorHintsRequest
} from "@cranq-gpt-lowcode/contracts";
import {createResolvedEnvironment} from "../../../utils/createResolvedEnvironment";
import {GlobalParametersModel} from "../../../types/GlobalParametersModel";
import {selectGlobalParameters} from "../../../globalParametersSlice";


const createRequest = (
  step: StepModel,
  steps: StepModel[],
  environmentVariables: EnvironmentModel["variables"],
  globalParameters: GlobalParametersModel,
  executionError: StepState["lastExecutionError"]
): GenerateExecutionErrorHintsRequest => ({
  flow: steps
    .sort((a, b) => Math.sign(a.index - b.index))
    .map<Step>(
      (step) => mapStepModelToApiStepModel(
        step,
        steps,
        environmentVariables)
    ),
  stepId: step.id,
  env: createResolvedEnvironment(environmentVariables, globalParameters),
  executionError: executionError ?? ""
});

export const generateExecutionErrorHintsAction = (
  flowId: FlowModel["id"],
  stepId: StepModel["id"],
  generateExecutionErrorHintsTrigger: ReturnType<typeof editorApi["useCreateGenerateExecutionErrorHintsTaskMutation"]>[0],
  errorCallback: (title: string, errorMessage?: string, callToAction?: string, messageType?: MESSAGE_TYPE) => void = () => {
  }
): ThunkAction<Promise<void>, RootState, any, any> => async (
  dispatch,
  getState
) => {
  const selectStepsByFlowId = makeSelectStepsByFlowId();
  const steps = selectStepsByFlowId(getState(), flowId);
  if (!steps || steps.length === 0) {
    throw new ReferenceError(`No steps found for flow ${flowId}`)
  }

  const step = steps.find((step) => step.model.id === stepId)
  if (!step) {
    throw new ReferenceError(`Step ${stepId} not found`);
  }

  const stepModels = steps.map((step) => step.model);
  const environment = selectEnvironmentByFlowId(flowId)(getState());
  const environmentVariables = environment?.model.variables ?? [];
  const globalParameters = selectGlobalParameters(getState())?.model;

  const request: GenerateExecutionErrorHintsRequest = createRequest(
    step.model, stepModels, environmentVariables, globalParameters, step.lastExecutionError);
  const validationErrors = validateGenerateExecutionErrorHintsRequest(request);
  if (validationErrors.length > 0) {
    const validationError = new Error("Invalid request");
    validationError.cause = processValidationErrors(dispatch, request.flow, stepModels, validationErrors);
    throw validationError;
  }

  // reset steps' state and last error and input validation errors
  dispatch(updateStepById({
    stepId: step.model.id,
    update: {
      executionErrorHintsGenerationRequestId: PendingRequestId,
    }
  }));


  return generateExecutionErrorHintsTrigger(request)
    .then((result) => {
        dispatch(updateStepById({
          stepId,
          update: {
            executionErrorHintsGenerationRequestId: undefined,
          }
        }));
        if ("data" in result) {
          const {data: {queryId}} = result;
          if (isUuid(queryId)) {
            dispatch(updateStepById({
              stepId,
              update: {
                executionErrorHintsGenerationRequestId: queryId,
              }
            }));
          }
        } else {
          errorCallback(
            "Execution error hints generation failed unexpectedly!",
            "Raw message:\n" + JSON.stringify(result.error, null, 2)
          );
          dispatch(updateStepById({
            stepId,
            update: {
              executionErrorHintsGenerationRequestId: undefined,
            }
          }));
        }
      }
    )
    .catch(() => {
      dispatch(updateStepById({
        stepId,
        update: {
          executionErrorHintsGenerationRequestId: undefined,
        }
      }));
    });
};
