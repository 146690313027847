import {StepState} from "../../../types/StepState";
import {StepStatus} from "../../../types/StepStatus";
import {calculateStepStatus} from "../../step/utils/calculateStepStatus";

export const calculateGroupStatus = (steps: StepState[]): keyof typeof StepStatus => {
  const statusValue = steps.reduce((statusValue, step) => {
    const stepStatus = calculateStepStatus(step);
    const stepStatusValue = Object.entries(StepStatus).find(([stepStatusLabel, stepStatusValue]) => stepStatusLabel === stepStatus)?.[1];
    return Math.min(statusValue, stepStatusValue ?? 0)
  }, Math.max(...Object.values(StepStatus)));
  //FIXME: transform StepStatus to enum?
  return (Object.entries(StepStatus).find(([, stepStatusValue]) => stepStatusValue === statusValue)?.[0] ?? "blank") as keyof typeof StepStatus;
}
