import React, {ReactElement} from "react";
import InlineIcon from "../inline-icon/InlineIcon";
import {InlineIconName} from "../inline-icon/InlineIconName";
import {UiColor} from "../../../utils/constants/UiColor";

export type IconButtonProps = {
  iconName: InlineIconName,
  tooltip?: string,
  iconTone?: UiColor.TextColor,
  backgroundTone?: UiColor.BackgroundColor,
  size?: "normal" | "small",
  disabled?: boolean,
  disableTabindex?: boolean,
  onClick?: () => void
}

const IconButton = (
  {
    iconName,
    tooltip = "",
    iconTone = UiColor.TextColor.BLACK,
    backgroundTone = UiColor.BackgroundColor.TRANSPARENT,
    size = "normal",
    disabled = false,
    disableTabindex = false,
    onClick
  }: IconButtonProps
): ReactElement<IconButtonProps> => {

  const {bgColor, primaryTone} = disabled
    ? {
      bgColor: UiColor.BackgroundColor.DISABLED,
      primaryTone: UiColor.TextColor.DISABLED
    }
    : {
      bgColor: backgroundTone,
      primaryTone: iconTone
    }

  const sizeMapping: Record<Required<IconButtonProps>["size"], string> = {
    "normal": "w-6 h-6",
    "small": "w-4 h-4",
  }
  return (
    <button
      tabIndex={disableTabindex ? -1 : 0}
      title={tooltip}
      className={`flex-shrink-0 ${sizeMapping[size]} flex justify-center items-center ${bgColor} rounded-none`}
      onClick={() => !disabled && onClick && onClick()}>
      {iconName &&
        <InlineIcon name={iconName} primaryTone={primaryTone}/>
      }
    </button>
  );

};

export default IconButton;
