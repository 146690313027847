import {AuthContext} from "../auth-context-provider/AuthContextProvider";
import {useContext, useState} from "react";
import {PathfixHelperContext} from "../patfix-provider/PathfixProvider";
import InlineIcon from "../../../common/components/inline-icon/InlineIcon";
import {InlineIconName} from "../../../common/components/inline-icon/InlineIconName";
import {classNames} from "../../../utils/classNames";
import {UiColor} from "../../../utils/constants/UiColor";
import {useGetUserSubscriptionsQuery} from "../../subscriptionsApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {Sticker} from "../../../common/components/sticker/Sticker";
import {Api} from "@cranq-gpt-lowcode/contracts";
import {hasRequiredSubscription} from "../../utils/hasRequiredSubscription";
import {isApiConnected} from "../../utils/isApiConnected";

export type OAuthConnectButtonProps = {
  api: string;
  onSubscriptionRequired?: (requiredSubscriptions: Api["requiredSubscriptions"]) => void;
}
const OAuthConnectButton = (
  {
    api,
    onSubscriptionRequired
  }: OAuthConnectButtonProps) => {

  const {user, apiConnections} = useContext(AuthContext);
  const {loaded, $pinc} = useContext(PathfixHelperContext);
  const [hover, setHover] = useState(false);
  const {data: subscriptions} = useGetUserSubscriptionsQuery(user.model?.id ?? skipToken);

  const requiredSubscriptions = apiConnections?.[api]?.requiredSubscriptions;
  const userHasRequiredSubscription = hasRequiredSubscription(subscriptions, requiredSubscriptions);
  const connected: boolean = isApiConnected(apiConnections, subscriptions, api);
  const userCanDisconnect: boolean = apiConnections?.[api]?.userShouldConnect ?? true;
  const apiName = apiConnections?.[api]?.name ?? api;
  const oAuthProvider = apiConnections?.[api]?.oAuthProvider;

  return (loaded && user.loggedInAt !== undefined)
    ? <button
      title={connected ? "Click to disconnect" : "Click to connect"}
      className={classNames(`
      flex items-center gap-4 shrink-0 relative
      px-3 py-2 h-8
      text-sm
      border-0 ${UiColor.BorderColor.TRANSPARENT} rounded
      ${UiColor.ShadowColor.BLACK_LIGHT}
      hover:border-gray-400 focus:border-gray-400 focus:outline-none
    `)}
      onClick={() => {
        //FIXME: show message if user hasn't the required subscription
        if (!userHasRequiredSubscription) {
          onSubscriptionRequired && onSubscriptionRequired(requiredSubscriptions);
          return;
        }
        const userId = user.model.id;
        if ($pinc?.current && oAuthProvider === "pathfix" && userId) {
          if (connected) {
            $pinc.current.oauth.revoke(api, userId);
          } else {
            $pinc.current.oauth.processCommand({
              provider: api,
              userId: userId
            })
          }
        }
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
    <span className={"w-4 h-4 bg-no-repeat bg-center bg-contain"}
          style={{backgroundImage: `url('https://storage.googleapis.com/pathfix-test/i/${api}-icon.png')`}}
    />
      {requiredSubscriptions ? requiredSubscriptions.map((sub) => <Sticker text={sub} key={sub}/>) : null}
      <span>{connected ? ((hover && userCanDisconnect) ? "Disconnect" : "Connected") : "Connect"} with {apiName}</span>
      <span className={classNames(`
      h-3 w-3 leading-3 
      ${connected ? UiColor.TextColor.GREEN : UiColor.TextColor.GRAY} 
      ${connected ? UiColor.BackgroundColor.GREEN : UiColor.BackgroundColor.GRAY}/50 
      rounded
    `)}>{connected ?
        <InlineIcon
          name={InlineIconName.CHECK}
          primaryTone={(hover && userCanDisconnect) ? UiColor.TextColor.GRAY : UiColor.TextColor.GREEN}/> : ">"}</span>
    </button>
    : null;
}

export default OAuthConnectButton;
