"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapGenerateCodeForStepRequestToParams = void 0;
function formatArguments(args) {
    return args.map((arg) => {
        const { name, type, description } = arg;
        return {
            name, type, description
        };
    });
}
function mapGenerateCodeForStepRequestToParams(request) {
    var _a, _b, _c, _d;
    const step = request.flow.find((s) => s.id === request.stepId);
    if (!step) {
        throw new Error(`Unable to create GenerateCodeForStepGeneratorParams - invalid step #${request.stepId}`);
    }
    return {
        function_name: (_b = (_a = step.implementation) === null || _a === void 0 ? void 0 : _a.entryFuncName) !== null && _b !== void 0 ? _b : "",
        task_description: step.description,
        flow_description: request.flowDescription,
        arguments: ((_c = step.interface) === null || _c === void 0 ? void 0 : _c.inputs) ? formatArguments(step.interface.inputs) : [],
        outputs: ((_d = step.interface) === null || _d === void 0 ? void 0 : _d.outputs) ? formatArguments(step.interface.outputs) : []
    };
}
exports.mapGenerateCodeForStepRequestToParams = mapGenerateCodeForStepRequestToParams;
