import Container from "../../../../common/components/container/Container";
import Input from "../../../../common/components/input/Input";
import {BreakdownConversationSummary} from "../types/BreakdownConversationSummary";
import MultilineInput from "../../../../common/components/multiline-input/MultilineInput";
import Timeline from "../../timeline/Timeline";
import Header from "../../../../common/components/header/Header";
import {UiColor} from "../../../../utils/constants/UiColor";
import TextSpinner from "../../../../common/components/text-spinner/TextSpinner";
import {StepState} from "../../../types/StepState";

const emptySummary: BreakdownConversationSummary = {
  title: "",
  description: "",
  constraints: "",
  breakdown: []
};


type BreakdownChatSummaryProps = {
  summary: BreakdownConversationSummary | undefined;
  parsedSteps: StepState[] | undefined;
}
const BreakdownChatSummary = (
  {
    summary = emptySummary,
    parsedSteps,
  }: BreakdownChatSummaryProps
) => {
  return (
    <Container height={"full"} overflowY={"auto"}>
      <div className={"flex flex-col"}>
        <label>Workflow title</label>
        <Input name={"title"}
               value={summary.title}
               readonly={true}
               placeholder={"Generating title..."}
        />
      </div>
      <div className={"flex flex-col"}>
        <label>Workflow description</label>
        <MultilineInput name={"summary"}
                        value={summary.description.trim()}
                        readonly={true}
                        placeholder={"Generating description..."}
                        rows={3}
                        autoGrowUntil={10}
        />
      </div>
      <div className={"flex flex-col"}>
        <label>Constraints</label>
        <MultilineInput name={"constraints"}
                        value={summary.constraints.trim()}
                        readonly={true}
                        placeholder={"Generating constraints..."}
                        rows={3}
                        autoGrowUntil={10}
        />
      </div>
      <div className={"flex flex-col"}>
        {parsedSteps?.length
          ? <Timeline title="Workflow preview"
                      steps={parsedSteps ?? []}
                      groups={[]}
                      allowDeleteStep={false}
                      allowAddFlowParams={false}
                      allowInsertNewStep={false}
                      allowMarkItems={false}
          />
          : (
            <section>
              <Header title={"Workflow preview"} titleTone={UiColor.TextColor.GRAY} level={4}/>
              <p className={`my-4 p-2 text-sm ${UiColor.TextColor.LIGHT_GRAY}`}>
                Generating workflow, please wait<TextSpinner/>
              </p>
            </section>
          )}
      </div>
    </Container>
  );
};

export default BreakdownChatSummary;
