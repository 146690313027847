import {StepStateV0} from "../types/StepState";
import {PersistedRootStatePriorV0, PersistedRootStateV0} from "../types/PersistedRootState";
import {StepModelPriorV0, StepModelV0} from "../types/StepModel";

export const migrateStepModelPriorV0ToV0 = (stepModel: StepModelPriorV0): StepModelV0 => {
  const {status, ...stepModelV0} = stepModel;
  return stepModelV0;
}
// migration functions
// version 0:
// - step.model.status is removed -> will be calculated from other fields
// - step.lastExecutionError is set to null if step.model.status === "run"
export const migrateToV0 = (state: PersistedRootStatePriorV0): PersistedRootStateV0 => ({
  ...state,
  steps: {
    ...Object.values(state.steps)
      .reduce((steps, step) => {
        const stepId = step.model.id;
        const stepModelV0 = migrateStepModelPriorV0ToV0(step.model);
        const migratedStep: StepStateV0 = {
          ...step,
          model: stepModelV0,
          lastExecutionError: step.model.status === "run" ? null : step.lastExecutionError
        };
        return ({
          ...steps,
          [stepId]: migratedStep
        });
      }, {})
  }
})
