import Header from "../../../common/components/header/Header";
import React, {Fragment} from "react";
import {UiColor} from "../../../utils/constants/UiColor";
import {classNames} from "../../../utils/classNames";
import {LayoutValues} from "../../../utils/constants/LayoutValues";
import {FlowModel} from "../../types/FlowModel";
import EnvironmentEditor from "../environment-editor/EnvironmentEditor";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {createEnvironment, selectEnvironmentByFlowId} from "../../environmentsSlice";

export type FlowParamsProps = {
  flowId: FlowModel["id"],
}
const FlowParams = (
  {
    flowId,
  }: FlowParamsProps
) => {
  const environment = useAppSelector(selectEnvironmentByFlowId(flowId));
  // create environment if non exists for the flow
  const dispatch = useAppDispatch();
  if (!environment) {
    dispatch(createEnvironment({flowId: flowId}));
    return null
  }
  return (
    <Fragment>
      <section className="container w-full h-full flex flex-col justify-between">
        <div className={"w-full flex-shrink-0 pb-6"}>
          <Header
            title={"Workflow Parameters"}
            titleTone={UiColor.TextColor.WHITE}
          />
        </div>
        <div className={classNames(`
          w-full ${LayoutValues.COLUMN_PADDING_X}
          flex-grow flex-shrink
          flex flex-col
          overflow-y-hidden
          rounded-2xl
          ${UiColor.BackgroundColor.WHITE}
        `)}>
          <div className={classNames(`
            flex-grow flex-shrink 
            ${LayoutValues.COLUMN_PADDING_X_INVERSE} ${LayoutValues.COLUMN_PADDING_X} pt-6
            flex flex-col gap-4
            border-b-[1px] ${UiColor.BorderColor.GRAY}
            overflow-y-auto
          `)}>
            <p>Parameters are values you can use in any step as input. Useful to set values required by multiple
              steps.</p>
            <EnvironmentEditor environmentId={environment.model.id}/>
          </div>
          <div className={"w-full flex-shrink-0 flex justify-end py-6 gap-2"}></div>
        </div>
      </section>
    </Fragment>
  );
};

export default FlowParams;
