import {classNames} from "../../../utils/classNames";
import {UiColor} from "../../../utils/constants/UiColor";

export type TimelineFlowParamsProps = {
  isFocused?: boolean,
  // events
  onClick?: () => void,
}

const TimelineFlowParams = (
  {
    isFocused = false,
    // events
    onClick,
  }: TimelineFlowParamsProps
) => {
  return (
    <div className={classNames(`
        h-16 w-full
        flex items-center
        relative
        cursor-pointer
        group
    `)}>
      <div
        className={classNames(`
          flex-grow
          h-full
          flex items-center gap-6
          rounded-sm
          text-sm
          overflow-hidden
          ${UiColor.BackgroundColor.WHITE}
          ${isFocused ? UiColor.ShadowColor.GOLD_MEDIUM : `${UiColor.ShadowColor.BLACK_LIGHT} group-hover:${UiColor.ShadowColor.GOLD_MEDIUM}`}
        `)}
        onClick={() => onClick && onClick()}>
        <div className={classNames(`
          flex-grow-0 
          flex flex-row items-center
          w-10 h-full
          ${isFocused
          ? `${UiColor.BackgroundColor.GOLD}`
          : `${UiColor.BackgroundColor.BLACK} group-hover:${UiColor.BackgroundColor.GRAY}`
        }
          ${UiColor.TextColor.WHITE} 
        `)}>
          <span className={"w-full text-center"}>P</span>
        </div>
        <div className={classNames(`
          flex-grow
          overflow-hidden
          flex flex-row items-center
          ${isFocused ? UiColor.TextColor.GOLD : UiColor.TextColor.BLACK}
          group-hover:${UiColor.TextColor.GOLD}
        `)}>
          <h3 className={"truncate"}>Workflow parameters</h3>
        </div>
        <div className={"flex-grow-0"}/>
      </div>
    </div>
  );
}

export default TimelineFlowParams;
