import Container from "../../../common/components/container/Container";
import React from "react";
import Button, {ButtonType} from "../../../common/components/button/Button";
import {InlineIconName} from "../../../common/components/inline-icon/InlineIconName";
import SeedGoogleTaskList from "./assets/seed-google-task-list.webp";
import SeedRetrieveEmail from "./assets/seed-retrieve-mail.webp";
import SeedWriteSong from "./assets/seed-write-song.webp";
import SeedGrabHtml from "./assets/seed-grab-html.webp";
import SeedInsertToGoogleSheet from "./assets/seed-insert-to-google-sheet.webp";
import SeedSendEmail from "./assets/seed-send-email.webp";
import {UiColor} from "../../../utils/constants/UiColor";
import {classNames} from "../../../utils/classNames";

type GettingStartedTileProps = {
  title: string,
  description: string,
  imageUrl: string,
  permittedToClickTheButton?: boolean,
  onClick?: (title: string, description: string) => void
}
const GettingStartedTile = (
  {
    title,
    description,
    imageUrl,
    permittedToClickTheButton = false,
    onClick
  }: GettingStartedTileProps
) =>
  (
    <div
      className={classNames(`
        w-full h-full flex flex-col
        ${UiColor.BackgroundColor.WHITE} 
        ${UiColor.BorderColor.TRANSPARENT} border-0 rounded-md 
        overflow-hidden`
      )}>
      <div style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundOrigin: "content-box"
      }}
           className={"w-full h-32 flex-shrink-0 flex-grow-0"}>
      </div>
      <div className={"p-4 flex flex-col flex-grow"}>
        <p className={"text-center"}>&ldquo;{description}&rdquo;</p>
        <div className={"flex-grow py-4 flex flex-col justify-end"}>
          <Button
            type={ButtonType.PRIMARY}
            disabled={!permittedToClickTheButton}
            title={`Try it${!permittedToClickTheButton ? " (*)" : ""}`}
            iconName={InlineIconName.MAGICK}
            backgroundTone={UiColor.BackgroundColor.GOLD}
            titleTone={UiColor.TextColor.BLACK}
            shadowTone={UiColor.ShadowColor.BLACK_MEDIUM}
            padding={true}
            onClick={() => onClick && onClick(title, description)}
          />
        </div>
      </div>
    </div>
  );
type GettingStartedProps = {
  permittedToStartMentor?: boolean;
  onTitleClick?: (title: string, description: string) => void;
}
export const GettingStarted = (
  {
    permittedToStartMentor = false,
    onTitleClick
  }: GettingStartedProps
) => (
  <Container overflowY={"hidden"} height={"full"}>
    <div className={"flex flex-col gap-4 min-h-0"}>
      <div className={"shrink"}>
        <h2>Need an idea?</h2>
        <p>Try one of these example automation seeds</p>
      </div>
      <div className={"grow overflow-y-auto"}>
        <div className={"grid md:grid-cols-2 xl:grid-cols-3 gap-4"}>
          <GettingStartedTile
            title={"Create a Google Tasks"}
            description={"Fetch the list ID for a specified Google Tasks list and then create a task in that list with just a title and no due date."}
            permittedToClickTheButton={permittedToStartMentor}
            onClick={onTitleClick}
            imageUrl={SeedGoogleTaskList}/>
          <GettingStartedTile
            title={"Retrieve Gmails"}
            description={"Retrieve all yesterday’s emails from GMail that have a specific Label applied to them."}
            permittedToClickTheButton={permittedToStartMentor}
            onClick={onTitleClick}
            imageUrl={SeedRetrieveEmail}/>
          <GettingStartedTile
            title={"Write 3 songs with AI"}
            description={"Use OpenAI’s API to write 3 songs in different styles, about the provided subject. Return the songs as a list."}
            permittedToClickTheButton={permittedToStartMentor}
            onClick={() => onTitleClick && onTitleClick(
              "Write 3 songs with AI",
              "Use OpenAI’s API to write 3 songs in different styles, about the provided subject. Return the songs as a list.")}
            imageUrl={SeedWriteSong}/>
          <GettingStartedTile
            title={"Get content from a HTML page"}
            description={"Get the HTML content from a page, parse it, and return the paragraph content"}
            permittedToClickTheButton={permittedToStartMentor}
            onClick={onTitleClick}
            imageUrl={SeedGrabHtml}/>
          <GettingStartedTile
            title={"Insert into a Google Sheet"}
            description={"Insert into a Google Sheet a list of data (array format)"}
            permittedToClickTheButton={permittedToStartMentor}
            onClick={onTitleClick}
            imageUrl={SeedInsertToGoogleSheet}/>
          <GettingStartedTile
            title={"Send an email"}
            description={"Send an email using Gmail"}
            permittedToClickTheButton={permittedToStartMentor}
            onClick={onTitleClick}
            imageUrl={SeedSendEmail}/>
        </div>
      </div>
    </div>
  </Container>
);
