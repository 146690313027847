import BranchContainer, {BranchOutputs} from "../branch-container/BranchContainer";
import {UiColor} from "../../../utils/constants/UiColor";

export type TerminalBranch = {
  branchName: string,
  leaves: BranchOutputs
}

export type IntermediateBranch = {
  branchName: string,
  branches: Branches
}
export const isTerminalBranch = (branch: Branch): branch is TerminalBranch => {
  return "leaves" in branch && branch.leaves !== undefined;
}
export const isIntermediateBranch = (branch: Branch): branch is IntermediateBranch => {
  return "branches" in branch && branch.branches !== undefined;
}

export type Branch = TerminalBranch | IntermediateBranch;
export type Branches = Record<string, Branch>
export type Tree = Branches;

export type TreeSelectorProps = {
  tree: Tree,
  backgroundTone?: string,
  onBranchClick?: (event: {
    branchKeys: string[],
    leafKey: string,
    value: string,
  }) => void
};

const TreeSelector = (
  {
    backgroundTone = UiColor.BackgroundColor.DARK_GRAY,
    tree,
    onBranchClick
  }: TreeSelectorProps
) => {

  return <section className={`w-max ${backgroundTone} py-4 rounded-lg opacity-90`}> {
    Object.entries(tree).map(([branchKey, branch], index) => {
      return <BranchContainer
        key={`branch-container-${index}`}
        containerTitle={branch.branchName}
        branch={branch}
        backgroundTone={backgroundTone}
        titleTone={UiColor.TextColor.WHITE}
        onItemClick={({key: leafKey, value}) => {
          onBranchClick && onBranchClick({
            branchKeys: [branchKey],
            leafKey,
            value
          });
        }}
        onBranchClick={({branchKeys, key, value}) => {
          onBranchClick && onBranchClick({
            branchKeys: [branchKey, ...branchKeys],
            leafKey: key,
            value
          });
        }}
      />;
    })
  } </section>;
};

export default TreeSelector;
