import {useCallback, useContext, useEffect, useState} from "react";
import {FlowModel} from "../types/FlowModel";
import {useNavigate} from "react-router-dom";
import {batch} from "react-redux";
import {createFlow, updateFlowById} from "../flowsSlice";
import {createEnvironment} from "../environmentsSlice";
import {useAppDispatch} from "../hooks";
import createNewUuid from "../../utils/createNewUuid";
import {AuthContext} from "../components/auth-context-provider/AuthContextProvider";

export const useCreateNewFlow = () => {
  const [createdFlowId, setCreatedFlowId] = useState<FlowModel["id"]>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {user, anonymousUserId} = useContext(AuthContext);

  const createNewFlow = useCallback((
    title?: FlowModel["title"],
    description?: FlowModel["description"],
    launchMentor?: boolean
  ) => {
    const flowId = createNewUuid();
    batch(() => {
      dispatch(createFlow({flowId, userId: user.model?.id ?? anonymousUserId}));
      if (title || description || launchMentor) {
        dispatch(updateFlowById({
          flowId,
          update: {
            model: {
              ...(title ? {title} : {}),
              ...(description ? {description} : {})
            },
            ...(launchMentor ? {lastChatUpdate: Date.now()} : {})
          },
        }));
      }
      dispatch(createEnvironment({flowId}));
    });
    setCreatedFlowId(flowId);
  }, [anonymousUserId, dispatch, user.model?.id]);

  useEffect(() => {
    if (createdFlowId) {
      setCreatedFlowId(undefined); // reset to prevent unwanted navigation
      navigate(`/flow/${createdFlowId}`);
    }
  }, [createdFlowId, navigate]);

  return createNewFlow;
}
