import {mapStepModelToApiStepModel} from "../../../utils/mapStepModelToApiStepModel";
import {calculateFlowHash} from "../../../utils/calculateFlowHash";
import {isUuid} from "../../../../utils/types/Uuid";
import {updateFlowById} from "../../../flowsSlice";
import {RootState} from "../../../store";
import {ThunkAction} from "@reduxjs/toolkit";
import {FlowModel} from "../../../types/FlowModel";
import {EnvironmentModel} from "../../../types/EnvironmentModel";
import {StepModel} from "../../../types/StepModel";
import {useCreateJobMutation} from "../../../schedulerApi";
import {ScheduledJob, WeeklySchedulerEntry} from "@cranq-gpt-lowcode/contracts";
import {UserModel} from "../../../types/UserModel";
import {createResolvedEnvironment} from "../../../utils/createResolvedEnvironment";
import {selectGlobalParameters} from "../../../globalParametersSlice";

export const createScheduledJobAction = (
  flowId: FlowModel["id"],
  userId: UserModel["id"],
  flow: FlowModel,
  steps: StepModel[],
  environment: EnvironmentModel,
  schedulerEntries: WeeklySchedulerEntry[],
  createScheduledJobTrigger: ReturnType<typeof useCreateJobMutation>[0]
): ThunkAction<Promise<ScheduledJob["jobId"]>, RootState, any, any> => async (
  dispatch,
  getState
) => {
  const globalParameters = selectGlobalParameters(getState())?.model;
  const result = await createScheduledJobTrigger({
    flowId: flow.id,
    flowName: flow.title,
    flowDescription: flow.description,
    flow: steps.map((step) => mapStepModelToApiStepModel(step, steps, environment.variables)),
    environment: createResolvedEnvironment(environment.variables, globalParameters),
    hash: calculateFlowHash(flow, steps, environment.variables),
    weeklySchedule: schedulerEntries
  });
  if ("data" in result) {
    const jobId = result.data.jobId;
    if (isUuid(jobId)) {
      dispatch(updateFlowById({
        flowId,
        update: {
          scheduledJobId: jobId,
          scheduledJobExpiryDate: result.data.expiry,
          scheduledJobHash: result.data.hash
        }
      }))
    }
    return jobId;
  } else {
    throw result.error; // FIXME: show toast
  }
}
