"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Generators = void 0;
var Generators;
(function (Generators) {
    Generators["generateCodeForStep"] = "generateCodeForStep";
    Generators["generateTasksByInstruction"] = "generateTasksByInstruction";
    Generators["generateTasksByInstructionHints"] = "generateTasksByInstructionHints";
    Generators["generateExecutionErrorHints"] = "generateExecutionErrorHints";
})(Generators = exports.Generators || (exports.Generators = {}));
