import {FlowModel} from "../../../types/FlowModel";
import {useAppSelector} from "../../../hooks";
import {selectFlow} from "../../../flowsSlice";
import {useCallback, useContext, useMemo} from "react";
import {makeSelectStepsByFlowId} from "../../../stepsSlice";
import {useUpdateFlowSnapshotMutation} from "../../../mentorApi";
import {selectEnvironmentByFlowId} from "../../../environmentsSlice";
import {createNewEnvironment} from "../../../utils/createNewEnvironment";
import {selectUser} from "../../../userSlice";
import {FlowSnapshot, UpdateFlowSnapshotRequest} from "@cranq-gpt-lowcode/contracts";
import {StepModel} from "../../../types/StepModel";
import {mapStepModelToApiStepModel} from "../../../utils/mapStepModelToApiStepModel";
import {AuthContext} from "../../auth-context-provider/AuthContextProvider";

export const useUpdateFlowSnapshot = (
  flowId: FlowModel["id"],
  stepId?: StepModel["id"]
) => {
  const flow = useAppSelector(selectFlow(flowId));
  if (!flow) {
    throw new Error(`Flow with id ${flowId} not found`);
  }
  const selectStepsByFlowId = useMemo(makeSelectStepsByFlowId, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const steps = useAppSelector((state) => selectStepsByFlowId(state, flowId)) ?? [];
  // environment must be referentially stable since used as dependency for useCallback of updateFlowSnapshot!
  const newEnvironment = useMemo(() => createNewEnvironment(flowId), [flowId]);
  const environment = useAppSelector(selectEnvironmentByFlowId(flowId)) ?? newEnvironment;
  const user = useAppSelector(selectUser);
  const {anonymousUserId} = useContext(AuthContext);

  const [sendUpdateFlowSnapshotRequest] = useUpdateFlowSnapshotMutation();

  const updateFlowSnapshot = useCallback(async (
    summary: FlowModel["description"],
    steps: StepModel[],
    eventType: UpdateFlowSnapshotRequest["eventType"],
    stepId?: StepModel["id"]
  ) => {
    const snapshot: FlowSnapshot = {
      flowId,
      userId: user.model?.id ?? anonymousUserId,
      steps: steps
        .map((step, _, steps) =>
          mapStepModelToApiStepModel(step, steps, environment.model.variables, false)),
      summary
    };

    const request: UpdateFlowSnapshotRequest = {
      snapshot,
      eventType,
      ...(stepId ? {stepId, scope: "step"} : {scope: "flow"})
    };
    return sendUpdateFlowSnapshotRequest(request);

  }, [anonymousUserId, environment.model.variables, flowId, sendUpdateFlowSnapshotRequest, user.model?.id]);

  return useCallback(async (reason: UpdateFlowSnapshotRequest["eventType"]) => {
    const summary = flow.model.description;
    const stepModels = steps.map((step) => step.model);
    return updateFlowSnapshot(summary, stepModels, reason, stepId);
  }, [flow.model.description, stepId, steps, updateFlowSnapshot]);
};
