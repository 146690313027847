import {Action, combineReducers, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {environmentsReducer, selectEnvironments} from "./environmentsSlice";
import {flowsReducer, selectFlows} from "./flowsSlice";
import {createMigrate, persistReducer, persistStore} from 'redux-persist'
import localforage from 'localforage';
import editorApi from "./editorApi";
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";
import {selectSteps, stepsReducer} from "./stepsSlice";
import {userReducer} from "./userSlice";
import runnerApi from "./runnerApi";
import oAuthApi from "./oAuthApi";
import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";
import {MigrationManifest} from "redux-persist/es/types";
import {migrateToV0} from "./store-migrations/migrateToV0";
import schedulerApi from "./schedulerApi";
import watch from "redux-watch";
import {updateFlowHashUponStepsChanges} from "./utils/updateFlowHashUponStepsChanges";
import {updateFlowHashUponFlowsChanges} from "./utils/updateFlowHashUponFlowsChanges";
import {groupsReducer} from "./groupsSlice";
import {updateFlowHashUponEnvironmentsChanges} from "./utils/updateFlowHashUponEnvironmentsChanges";
import mentorApi from "./mentorApi";
import {migrateToV1} from "./store-migrations/migrateToV1";
import {isPersistedRootStatePriorV0, isPersistedRootStateV0, isPersistedRootStateV1} from "./types/PersistedRootState";
import {migrateToV2} from "./store-migrations/migrateToV2";
import subscriptionsApi from "./subscriptionsApi";
import webhookApi from "./webhookApi";
import {getStateFromLocalStorageThenRemove} from "./utils/getStateFromLocalStorageThenRemove";
import {globalParametersReducer, selectGlobalParameters} from "./globalParametersSlice";
import {updateFlowHashUponGlobalParametersChanges} from "./utils/updateFlowHashUponGlobalParametersChanges";

// Ideally it would be used to generate rtkQueryMiddlewares and rtkQueryApis, but has some TS issues
export const rtkQueryApis = [
  editorApi,
  runnerApi,
  oAuthApi,
  schedulerApi,
  webhookApi,
  subscriptionsApi,
  mentorApi
];
const rtkQueryReducers = {
  [editorApi.reducerPath]: editorApi.reducer,
  [runnerApi.reducerPath]: runnerApi.reducer,
  [oAuthApi.reducerPath]: oAuthApi.reducer,
  [schedulerApi.reducerPath]: schedulerApi.reducer,
  [webhookApi.reducerPath]: webhookApi.reducer,
  [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
  [mentorApi.reducerPath]: mentorApi.reducer
}
const rtkQueryMiddlewares = [
  editorApi.middleware,
  runnerApi.middleware,
  oAuthApi.middleware,
  schedulerApi.middleware,
  webhookApi.middleware,
  subscriptionsApi.middleware,
  mentorApi.middleware
];

const reducers = combineReducers({
  flows: flowsReducer,
  steps: stepsReducer,
  groups: groupsReducer,
  environments: environmentsReducer,
  user: userReducer,
  globalParameters: globalParametersReducer,
  ...rtkQueryReducers
});

const backupStore = (key: string, suffix: string, storage: Storage = window.localStorage) => {
  const storageKeySource = `persist:${key}`;
  const storeSource = storage.getItem(storageKeySource);
  if (!storeSource) {
    console.debug(`source store (${storageKeySource}) is empty, nothing to backup`)
    return;
  }
  const storageKeyTargetBase = `${storageKeySource}_${suffix}`;
  const storageKeyTarget = storage.getItem(storageKeyTargetBase) === null
    ? storageKeyTargetBase
    : `${storageKeyTargetBase}_${Date.now()}`;
  try {
    storage.setItem(storageKeyTarget, storeSource)
    console.debug(`Backed up store as ${storageKeyTarget}`);
  } catch (e) {
    console.debug(`Failed to back up store as ${storageKeyTarget}`);
  }
};

// persist
export const storeMigrations: MigrationManifest = {
  0: (state) => {
    if (isPersistedRootStatePriorV0(state)) {
      console.debug("migrating to v0");
      backupStore(persistConfig.key, `prior_v0_backup`);
      return migrateToV0(state)
    } else {
      console.error("should migrate to v0 but received state is not a valid PersistedRootStatePriorV0");
      return state;
    }
  },
  1: (state) => {
    if (isPersistedRootStateV0(state)) {
      console.debug("migrating to v1");
      backupStore(persistConfig.key, `v0_backup`);
      return migrateToV1(state);
    } else {
      console.error("should migrate to v1 but received state is not a valid PersistedRootStateV0");
      return state;
    }
  },
  2: (state) => {
    if (isPersistedRootStateV1(state)) {
      console.debug("migrating to v2");
      backupStore(persistConfig.key, `v1_backup`);
      return migrateToV2(state);
    } else {
      console.error("should migrate to v2 but received state is not a valid PersistedRootStateV1");
      return state;
    }
  }
}
localforage.config({name: "cranq", driver: localforage.INDEXEDDB});
const persistConfig = {
  key: "root",
  version: 2,
  storage: localforage,
  migrate: createMigrate(storeMigrations, {debug: true})
}
const persistedReducer = persistReducer(persistConfig, reducers);

// redux state sync
const rtkQueryReducerPaths = Object.keys(rtkQueryReducers);
const reducerPathsToExcludeFromSync = [...rtkQueryReducerPaths, "persist"];
const reduxStateSyncPredicate = (action: Action) => {
  return typeof action.type === "string" && !reducerPathsToExcludeFromSync.includes(action.type.split("/")[0] ?? "");
}
const reduxStateSyncConfig = {
  predicate: reduxStateSyncPredicate,
};
const reduxStateSyncMiddleware = createStateSyncMiddleware(reduxStateSyncConfig);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
    .concat(rtkQueryMiddlewares)
    .concat(reduxStateSyncMiddleware),
  // migrate old state - stored in localStorage - into redux-persist as initialState
  // to be than stored in the new localforage based storage (indexDB if possible)
  preloadedState: getStateFromLocalStorageThenRemove(persistConfig.key)
});

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

initMessageListener(store);
const watchStepsChanges = watch(() => selectSteps(store.getState()))
store.subscribe(watchStepsChanges(updateFlowHashUponStepsChanges))

const watchFlowsChanges = watch(() => selectFlows(store.getState()))
store.subscribe(watchFlowsChanges(updateFlowHashUponFlowsChanges))

const watchEnvironmentsChanges = watch(() => selectEnvironments(store.getState()))
store.subscribe(watchEnvironmentsChanges(updateFlowHashUponEnvironmentsChanges));

const watchGlobalParametersChanges = watch(() => selectGlobalParameters(store.getState()))
store.subscribe(watchGlobalParametersChanges(updateFlowHashUponGlobalParametersChanges));
