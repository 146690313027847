import Container from "../../../common/components/container/Container";
import {UiColor} from "../../../utils/constants/UiColor";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {initialLessons, selectFlows, updateFlow} from "../../flowsSlice";
import {unique} from "../../../utils/unique";
import {FlowModel} from "../../types/FlowModel";
import {isUuid} from "../../../utils/types/Uuid";
import {classNames} from "../../../utils/classNames";
import {NavLink, useNavigate} from "react-router-dom";
import Button, {ButtonType} from "../../../common/components/button/Button";
import {InlineIconName} from "../../../common/components/inline-icon/InlineIconName";

const Cranq101 = () => {
  const flows = useAppSelector(selectFlows);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const uniqueLessonIds: FlowModel["id"][] = unique([
      ...Object.values(flows)
        .filter((flow) => flow.model.lessonDescriptor !== undefined),
      ...Object.values(initialLessons)
    ]
      .sort((a, b) => a.model.title.localeCompare(b.model.title))
      .map((flow) => flow.model.id)
      .filter(isUuid)
  );
  const installLesson = (lessonId: FlowModel["id"]) => {
    const lessonToInstall = initialLessons[lessonId];
    if (lessonToInstall) {
      dispatch(updateFlow(lessonToInstall));
    }
  }

  const startLesson = (lessonId: FlowModel["id"]) => {
    return navigate(`/flow/${lessonId}?startLesson`);
  }

  return (
    <Container
      headerProps={{
        title: "CRANQ 101: Ready to learn? Become an AI automation expert",
        level: 3
      }}
      borderTone={UiColor.BorderColor.BLACK}
      indented={true}>
      <div>
        <div className={`flex flex-col gap-3`}>
          {uniqueLessonIds.map((lessonId) => {
            const flow = flows[lessonId] ?? initialLessons[lessonId];
            return flow
              ? (
                <div
                  key={lessonId}
                  className={classNames(`
                    p-2 
                    border-l-2 rounded-r-sm 
                    flex gap-1 items-center 
                    ${UiColor.BorderColor.TRANSPARENT}
                    ${UiColor.TextColor.GRAY}
                    hover:${UiColor.TextColor.GOLD}
                  `)}>
                  <div className={"min-w-0 flex-grow"}>
                    {flows[lessonId]
                      ? <NavLink to={`/flow/${lessonId}`}>
                        <h2 className={"truncate text-sm"}>{flow.model.title}</h2>
                      </NavLink>
                      : <h2 className={"truncate text-sm"}>{flow.model.title}</h2>
                    }
                  </div>
                  <div className={"min-w-0 justify-self-end self-center flex-shrink-0"}>
                    {flows[lessonId]
                      ? <Button type={ButtonType.PRIMARY}
                                title={"Start"}
                                iconName={InlineIconName.PLAY}
                                shadowTone={UiColor.ShadowColor.BLACK_MEDIUM}
                                onClick={() => startLesson(lessonId)}/>
                      : <Button type={ButtonType.PRIMARY}
                                title={"Load"}
                                iconName={InlineIconName.DOWNLOAD}
                                onClick={() => installLesson(lessonId)}/>
                    }
                  </div>
                </div>
              )
              : null;
          })}
        </div>
      </div>
    </Container>
  )
}

export default Cranq101;
