import {useAppDispatch} from "../../../hooks";
import {FlowModel} from "../../../types/FlowModel";
import {useListWebhooksQuery} from "../../../webhookApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {updateFlowById} from "../../../flowsSlice";
import {isUuid} from "../../../../utils/types/Uuid";
import {FlowState} from "../../../types/FlowState";

export const useUpdateWebhookId = (
  flowId: FlowModel["id"] | undefined,
  currentWebhookId: FlowState["webhookId"]
) => {
  const dispatch = useAppDispatch();
  const {
    data: webhooks,
    isLoading: isWebhooksLoading,
    error: webhooksError
  } = useListWebhooksQuery(flowId ? undefined : skipToken, {pollingInterval: 10 * 60 * 60 * 1000});

  if (!flowId) {
    return;
  }
  if (isWebhooksLoading) {
    return;
  }
  if (webhooksError) {
    console.error(webhooksError);
    return;
  }

  if (webhooks) {
    const webhooksForFlow = [...webhooks.filter((webhook) => webhook.flowId === flowId)]
      .sort((a, b) => Math.sign(b.created - a.created));
    if (webhooksForFlow.length > 1) {
      console.error("More than one webhook for flow", flowId);
    }
    const webhook = webhooksForFlow[0];
    if (webhook) {
      if (isUuid(webhook.webhookId)) {
        if (currentWebhookId !== webhook.webhookId) {
          dispatch(updateFlowById({
            flowId,
            update: {
              webhookId: webhook.webhookId,
              webhookExpiryDate: webhook.expiry,
              webhookHash: webhook.hash
            }
          }));
        } else {
          // no change
        }
      } else {
        console.error("WebhookId is not a valid Uuid!", webhook.webhookId)
      }
    } else {
      if (currentWebhookId) {
        dispatch(updateFlowById({
          flowId,
          update: {
            webhookId: undefined,
            webhookExpiryDate: undefined,
            webhookHash: undefined
          }
        }));
      } else {
        // no change
      }
    }
  }
}
