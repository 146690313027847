import {batch} from "react-redux";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {safeJsonStringify} from "../../utils/safeJsonStringify";
import {safeJsonParse} from "../../utils/safeJsonParse";
import React, {useState} from "react";
import {useModalEditor} from "../../../common/components/modal-editor/hook/useModalEditor";
import ModalEditor from "../../../common/components/modal-editor/ModalEditor";
import {Variable} from "../../types/Variable";
import {UiColor} from "../../../utils/constants/UiColor";
import {InputWithActionButtons} from "../../../common/components/input-with-action-buttons/InputWithActionButtons";
import Container from "../../../common/components/container/Container";
import {copyToClipboard} from "../../utils/copyToClipboard";
import {useToast} from "../../../common/components/toast/hook/useToast";
import DeleteItemActionButton from "../../../common/components/delete-item-action-button/DeleteItemActionButton";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import {useDialog} from "../../../common/components/dialog/hook/useDialog";
import Dialog from "../../../common/components/dialog/Dialog";
import {ModalValueAndInstructionEditor} from "../modal-value-and-instruction-editor/ModalValueAndInstructionEditor";
import {
  createGlobalParameter,
  deleteGlobalParameter,
  selectGlobalParameters,
  updateGlobalParameter
} from "../../globalParametersSlice";
import {EnvironmentModel} from "../../types/EnvironmentModel";
import {GlobalParameterReferencePrefix} from "../environment-editor/utils/createGlobalParameterReferenceResolver";
import {selectEnvironments, updateEnvironmentVariable} from "../../environmentsSlice";
import {isId} from "../../../utils/types/Id";
import {resetInputValidationErrorUponEnvironmentVariableValueChange} from "../../stepsSlice";

const isLinked = (variable: Variable, environments: EnvironmentModel[]) => environments.some(
  (env) => env.variables.some(
    (envVar) => typeof envVar.value === "string" && envVar.value === `${GlobalParameterReferencePrefix}${variable.key}`
  )
);

const GlobalParametersEditor = () => {
    const dispatch = useAppDispatch();
    const {openModalEditor, modalEditorProps} = useModalEditor();
    const {openDialog, closeDialog, dialogProps} = useDialog();
    const {showToast} = useToast();
    const [indexOfVariableToShowInModalValueEditor, setIndexOfVariableToShowInModalValueEditor] = useState<number | undefined>(undefined);

  const environments = useAppSelector(selectEnvironments);
  const environmentModels = Object.values(environments).map((env) => env.model);
    const globalParametersState = useAppSelector(selectGlobalParameters);
    const {
      model: globalParameters
    } = globalParametersState;

    const handleAddClick = () => {
      dispatch(createGlobalParameter());
    };

    const handleDeleteClick = (index: number, deleteConfirmed = false) => {
      const parameter = globalParameters[index];
      if (parameter) {
        if (isLinked(parameter, environmentModels) && !deleteConfirmed) {
          openDialog({
            title: "Warning",
            type: MESSAGE_TYPE.WARNING,
            content: "This parameter is linked to one or more workflows' parameter(s). Deleting it will unlink these workflow parameters. Are you sure you want to proceed?",
            controlButtonList: [
              {
                title: "Cancel",
                onClick: closeDialog
              },
              {
                title: "Proceed",
                onClick: () => {
                  closeDialog();
                  handleDeleteClick(index, true)
                }
              }
            ]
          });
          return;
        }
        batch(() => {
          dispatch(deleteGlobalParameter({
            variableIndex: index
          }));
          Object.entries(environments)
            .forEach(([environmentId, environment]) => {
              if (!isId(environmentId)) return;
              environment.model.variables
                .forEach((variable, index) => {
                  if (variable.value !== `${GlobalParameterReferencePrefix}${parameter.key}`) return;
                  dispatch(updateEnvironmentVariable({
                    environmentId: environmentId,
                    variableIndex: index,
                    key: variable.key,
                    value: undefined,
                    instruction: variable.instruction
                  }));
                });
            });
        });
      }
    };

    const handleKeyChange = (
      event: {
        index: number,
        key: Variable["key"]
      }) => {
      const {index, key} = event;
      const parameter = globalParameters[index]
      if (parameter) {
        const oldKey = parameter.key;
        batch(() => {
          dispatch(updateGlobalParameter({
            variableIndex: index,
            key,
            value: parameter.value,
            instruction: parameter.instruction
          }));
          Object.entries(environments)
            .forEach(([environmentId, environment]) => {
              if (!isId(environmentId)) return;
              environment.model.variables
                .forEach((variable, index) => {
                  if (variable.value !== `${GlobalParameterReferencePrefix}${oldKey}`) return;
                  dispatch(updateEnvironmentVariable({
                    environmentId: environmentId,
                    variableIndex: index,
                    key: variable.key,
                    value: `${GlobalParameterReferencePrefix}${key}`,
                    instruction: variable.instruction
                  }));
                });
            });
        });
      }
    };

    const handleValueChange = (
      event: {
        index: number,
        changedValueAsString: string
      }) => {
      const {index, changedValueAsString} = event;
      const newValue = safeJsonParse(changedValueAsString);
      const parameter = globalParameters[index]
      if (parameter) {
        dispatch(updateGlobalParameter({
          variableIndex: index,
          key: parameter.key,
          value: newValue,
          instruction: parameter.instruction
        }));
        Object.entries(environments)
          .forEach(([environmentId, environment]) => {
            if (!isId(environmentId)) return;
            environment.model.variables
              .forEach((variable) => {
                if (variable.value !== `${GlobalParameterReferencePrefix}${parameter.key}`) return;
                dispatch(resetInputValidationErrorUponEnvironmentVariableValueChange({
                  flowId: environment.model.flowId,
                  variableName: variable.key
                }));
              });
          });
      }
    };

    const handleInstructionChange = (
      event: {
        index: number,
        instruction: string
      }) => {
      const {index, instruction} = event;
      const variable = globalParameters[index]
      if (variable) {
        dispatch(updateGlobalParameter({
          variableIndex: index,
          key: variable.key,
          value: variable.value,
          instruction: instruction.trim() !== "" ? instruction.trim() : undefined
        }));
      }
    };

    const handleEditParameterKey = (event: {
      index: number,
      key: Variable["key"]
    }) => {
      const {key, index} = event;
      openModalEditor({
        title: "Edit parameter description",
        content: key,
        handleOnClose: (newKey) => {
          handleKeyChange({
            index,
            key: newKey
          });
        }
      });
    };

    const handleEditParameterValue = (event: {
      index: number
    }) => {
      const {index} = event;
      setIndexOfVariableToShowInModalValueEditor(index);
    };

    const variableToShowInModalValueEditor = indexOfVariableToShowInModalValueEditor !== undefined
      ? globalParameters[indexOfVariableToShowInModalValueEditor]
      : undefined;

    return (
      <Container
        id="globalParameters"
        headerProps={{
          title: "",
          level: 3,
          rightSideButtonListProps: [
            {
              title: "+ Add new parameter",
              backgroundTone: UiColor.BackgroundColor.TRANSPARENT,
              titleTone: UiColor.TextColor.GOLD,
              onClick: handleAddClick,
            }
          ]
        }}
        indented={false}>
        <div className={"grid gap-1 py-1"}
             style={{gridTemplateColumns: "33% 1fr"}}>
          {globalParameters
            && globalParameters.map(({key, value, instruction}, index) => (
              <div className={"contents group relative"} key={index}>
                <InputWithActionButtons
                  name={"description"}
                  value={key}
                  placeholder="Describe the parameter"
                  onEditAction={() => handleEditParameterKey({index, key})}
                  onBlur={(newKey) => handleKeyChange({index, key: newKey})}
                />
                <div className={"relative"}>
                  <InputWithActionButtons
                    name={"value"}
                    size={"full"}
                    value={safeJsonStringify(value)}
                    placeholder={instruction ?? "Type parameter value"}
                    title={instruction ?? ""}
                    onBlur={(changedValueAsString) => handleValueChange({
                      index,
                      changedValueAsString
                    })
                    }
                    editActionTooltip={"Open value & instruction editor"}
                    onEditAction={() => handleEditParameterValue({index})}
                    onCopyAction={() => copyToClipboard(safeJsonStringify(value), showToast)}
                  />
                  <DeleteItemActionButton onClick={() => handleDeleteClick(index)}/>
                </div>
              </div>
            ))}
        </div>
        {modalEditorProps && <ModalEditor {...modalEditorProps}/>}
        {dialogProps && <Dialog {...dialogProps}/>}
        {indexOfVariableToShowInModalValueEditor !== undefined
          && variableToShowInModalValueEditor
          && <ModalValueAndInstructionEditor
            title={variableToShowInModalValueEditor.key}
            value={safeJsonStringify(variableToShowInModalValueEditor.value)}
            instruction={variableToShowInModalValueEditor.instruction ?? ""}
            onValueChange={(changedValueAsString) => handleValueChange({
              index: indexOfVariableToShowInModalValueEditor,
              changedValueAsString
            })}
            onInstructionChange={(newInstruction) => handleInstructionChange({
              index: indexOfVariableToShowInModalValueEditor,
              instruction: newInstruction
            })}
            onCloseClick={() => setIndexOfVariableToShowInModalValueEditor(undefined)}
          />}
      </Container>
    );
  }
;

export default GlobalParametersEditor;
