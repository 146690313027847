import React from "react";
import InlineIcon from "../inline-icon/InlineIcon";
import {classNames} from "../../../utils/classNames";
import {InlineIconName} from "../inline-icon/InlineIconName";
import {UiColor} from "../../../utils/constants/UiColor";
import ButtonSpinner from "../button-spinner/ButtonSpinner";

export enum ButtonType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  HIGHLIGHTED = "HIGHLIGHTED"
}

export type ButtonProps = {
  id?: string,
  title: string,
  tooltip?: string,
  type?: ButtonType,
  iconName?: InlineIconName,
  titleTone?: UiColor.TextColor,
  backgroundTone?: UiColor.BackgroundColor,
  borderTone?: UiColor.BorderColor,
  shadowTone?: UiColor.ShadowColor,
  padding?: boolean,
  disabled?: boolean,
  isInProgress?: boolean,
  progressTimeout?: number,
  expanding?: boolean,
  ping?: boolean,
  // events
  onClick?: () => void,
}

type ButtonColorScheme = {
  bgColor: UiColor.BackgroundColor,
  textColor: UiColor.TextColor,
  borderColor: UiColor.BorderColor,
  shadowColor: UiColor.ShadowColor | undefined
}

const buttonTypeColorSchemeMap: Record<ButtonType, ButtonColorScheme> = {
  [ButtonType.PRIMARY]: {
    bgColor: UiColor.BackgroundColor.BLACK,
    textColor: UiColor.TextColor.WHITE,
    borderColor: UiColor.BorderColor.TRANSPARENT,
    shadowColor: undefined
  },
  [ButtonType.SECONDARY]: {
    bgColor: UiColor.BackgroundColor.WHITE,
    textColor: UiColor.TextColor.BLACK,
    borderColor: UiColor.BorderColor.TRANSPARENT,
    shadowColor: undefined
  },
  [ButtonType.HIGHLIGHTED]: {
    bgColor: UiColor.BackgroundColor.GOLD,
    textColor: UiColor.TextColor.BLACK,
    borderColor: UiColor.BorderColor.TRANSPARENT,
    shadowColor: undefined
  }
}

const ButtonPinger = () => {
  return (
    <span className={"absolute -top-1 -right-1 w-3 h-3"}>
      <span
        className={`absolute top-0 right-0 inline-flex h-full w-full rounded-full ${UiColor.BackgroundColor.GOLD}`}/>
      <span
        className={`animate-ping absolute top-0 right-0 inline-flex h-full w-full rounded-full ${UiColor.BackgroundColor.GOLD} opacity-75`}/>
    </span>
  )
}

const Button = (props: ButtonProps) => {
  const {
    id,
    title,
    tooltip,
    type = ButtonType.PRIMARY,
    titleTone,
    backgroundTone,
    borderTone = UiColor.BorderColor.TRANSPARENT,
    shadowTone,
    padding = true,
    iconName,
    disabled = false,
    isInProgress = false,
    progressTimeout,
    expanding = false,
    ping = false,
    onClick
  } = props;

  const colorScheme: ButtonColorScheme = {
    ...buttonTypeColorSchemeMap[type],
    ...(backgroundTone ? {bgColor: backgroundTone} : {}),
    ...(titleTone ? {textColor: titleTone} : {}),
    ...(borderTone ? {borderColor: borderTone} : {}),
    ...(shadowTone ? {shadowColor: shadowTone} : {})
  };

  const {
    bgColor,
    textColor,
    borderColor,
    shadowColor,
  } = (disabled && !isInProgress) ? { // isInProgress takes precedence over disabled
    bgColor: colorScheme.bgColor === UiColor.BackgroundColor.TRANSPARENT ? colorScheme.bgColor : UiColor.BackgroundColor.GRAY,
    textColor: UiColor.TextColor.DISABLED,
    borderColor: colorScheme.borderColor === UiColor.BorderColor.TRANSPARENT ? colorScheme.borderColor : UiColor.BorderColor.GRAY,
    shadowColor: colorScheme.shadowColor ? UiColor.ShadowColor.BLACK_LIGHT : undefined
  } : colorScheme;

  return (
    <button
      id={id}
      title={tooltip ?? title}
      className={classNames(`
      ${padding ? "px-4 py-1" : ""}
      relative
      flex flex-shrink
      justify-center items-center 
      leading-tight 
      overflow-ellipsis 
      ${bgColor}  
      ${textColor} 
      border ${borderColor} rounded
      focus:outline-none
      ${shadowColor ? shadowColor : ""}
      group
      `)}
      onClick={(e) => {
        !disabled && onClick && onClick();
        e.stopPropagation();
      }}>
      {iconName &&
        <span
          className={`flex-shrink h-[20px] w-[20px] ${disabled ? "grayscale" : ""}`}><InlineIcon
          name={iconName} primaryTone={textColor}/></span>
      }
      <span className={classNames(`
        flex-grow 
        overflow-hidden overflow-ellipsis whitespace-nowrap 
        transition-[max-width] duration-500 ease-in-out
        ${expanding ? "max-w-[0px] group-hover:max-w-[1000px]" : ""}
      `)}><span className={"px-2"}>{title}</span></span>
      {ping && <ButtonPinger/>}
      {isInProgress && <ButtonSpinner timeout={progressTimeout} backgroundTone={bgColor}/>}
    </button>
  );

};

export default Button;
