import React from "react";
import Login from "../Login";
import {UserModel} from "../../../types/UserModel";
import {useDialog} from "../../../../common/components/dialog/hook/useDialog";
import {IdentityProvider} from "@cranq-gpt-lowcode/contracts";
import {MESSAGE_TYPE} from "../../../../common/helper/messageTypeColorMapper";
import {useToast} from "../../../../common/components/toast/hook/useToast";

export const openLoginDialog = (
  anonymousUserId: UserModel["id"],
  openDialog: ReturnType<typeof useDialog>["openDialog"],
  closeDialog: ReturnType<typeof useDialog>["closeDialog"],
  showToast: ReturnType<typeof useToast>["showToast"],
  title = "Login or Register"
) => {
  openDialog({
    title,
    content: <Login
      anonymousUserId={anonymousUserId}
      identityProviders={[
        IdentityProvider.GOOGLE,
        IdentityProvider.GITHUB
        // IdentityProvider.FACEBOOK,
        // IdentityProvider.TWITTER,
        // IdentityProvider.MICROSOFT
      ]}
      onLoginSuccess={closeDialog}
      onLoginFailure={(message) => {
        showToast({message: "Login failed", type: MESSAGE_TYPE.ERROR});
        console.error(message);
        closeDialog();
      }}
    />,
    type: MESSAGE_TYPE.CUSTOM
  })
};
