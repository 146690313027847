import {ChatMessage as ChatMessageProps} from "./types/ChatMessage";
import {ChatMessageActor} from "./types/ChatMessageActor";
import {UiColor} from "../../../../utils/constants/UiColor";

type messageDisplayStyle = {
  markerColor: UiColor.BackgroundColor;
  margin: string;
  timestampMargin: string;
  timestampJustify: string;
}
const styleMap: Record<ChatMessageActor, messageDisplayStyle> = {
  [ChatMessageActor.Assitant]: {
    markerColor: UiColor.BackgroundColor.GOLD,
    margin: "mr-4",
    timestampMargin: "ml-2",
    timestampJustify: "justify-start"
  },
  [ChatMessageActor.User]: {
    markerColor: UiColor.BackgroundColor.DARK_GRAY,
    margin: "ml-4",
    timestampMargin: "ml-2",
    timestampJustify: "justify-start"
  }
}

export const ChatMessage = (
  {
    message,
    role,
    timestamp
  }: ChatMessageProps
) => {
  const style = styleMap[role];
  return (
    <div className={`${style.margin}`}>
      <div className={`pl-4 ${style.markerColor} rounded`}>
        <div className={`p-4 border ${UiColor.BorderColor.GRAY} ${UiColor.BackgroundColor.GRAY} rounded-r-sm`}>
          <div className={"whitespace-pre-line"}>
            {message}
          </div>
        </div>
      </div>
      {timestamp !== undefined ? (
        <div className={`flex ${style.timestampJustify} ${style.timestampMargin} text-xs italic`}>
          <div>{new Date(timestamp).toLocaleString()}</div>
        </div>
      ) : null}
    </div>
  )
}
