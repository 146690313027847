"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapGenerateCodeForStepOutputToResponse = void 0;
function mapGenerateCodeForStepOutputToResponse(request, output) {
    var _a, _b, _c;
    return {
        implementation: {
            code: output.code,
            entryFuncName: output.entry_function,
            dependencies: (_a = output.packages) !== null && _a !== void 0 ? _a : [],
            summary: output.summary,
            apis: output.apis,
            signature: output.signature
        },
        interface: {
            inputs: (_b = output.inputs) !== null && _b !== void 0 ? _b : [],
            outputs: (_c = output.outputs) !== null && _c !== void 0 ? _c : []
        },
    };
}
exports.mapGenerateCodeForStepOutputToResponse = mapGenerateCodeForStepOutputToResponse;
