"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateGenerateCodeForStepRequestInternal = void 0;
function validateGenerateCodeForStepRequestInternal(request) {
    const output = [];
    if (request.flow.find((s) => s.id === request.stepId) === undefined) {
        output.push({
            property: "stepId",
            value: request.stepId,
            constraints: { "stepIdShouldExist": `Requested step #${request.stepId} is not specified in the flow` }
        });
    }
    return output;
}
exports.validateGenerateCodeForStepRequestInternal = validateGenerateCodeForStepRequestInternal;
