import {createContext, ReactNode, useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../auth-context-provider/AuthContextProvider";


const PATHFIX_PUBLIC_KEY = process.env.REACT_APP_PATHFIX_PUBLIC_KEY ?? "";
const PATHFIX_BASE_URL = process.env.REACT_APP_PATHFIX_BASE_URL;

type $PincCommand = {
  provider: string;
  userId: string;
}
type $Pinc = {
  oauth: {
    revoke: (api: string, userId: string) => void;
    processCommand: (command: $PincCommand) => void;
  }
}
type PathfixHelperState = {
  loaded: boolean;
  clientId: string;
  $pinc?: ReturnType<typeof useRef<$Pinc>>
}
export const PathfixHelperContext = createContext<PathfixHelperState>({
  loaded: false,
  clientId: PATHFIX_PUBLIC_KEY,
});

export type PathfixProviderProps = {
  children: ReactNode;
};
const PathfixProvider = (
  {
    children
  }: PathfixProviderProps
) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const $pinc = useRef<any>(null);
  const {refetchApiConnections} = useContext(AuthContext);

  useEffect(() => {
    const body = document.querySelector("body");
    if (!body || !PATHFIX_PUBLIC_KEY) {
      return;
    }
    const pathfixHelperId = "pinc.helper";
    if (body.querySelector(`[id='${pathfixHelperId}']`)) {
      setLoaded(true); // already loaded
      $pinc.current = (window as any).$pinc;
      return;
    }
    const pathfixHelperScript = document.createElement("script");
    pathfixHelperScript.id = pathfixHelperId;
    pathfixHelperScript.src = `https://${PATHFIX_BASE_URL}/helper.js`
    pathfixHelperScript.setAttribute("modules", "pinc.oauth.min");
    pathfixHelperScript.setAttribute("data-public-key", PATHFIX_PUBLIC_KEY);
    pathfixHelperScript.addEventListener("load", () => {
      setLoaded(true);
      $pinc.current = (window as any).$pinc;
      if ($pinc.current) {
        $pinc.current.events.on("$pinc.oauth", "consented", (data: unknown) => {
          console.log("consented", data);
          refetchApiConnections && refetchApiConnections();
        });
        $pinc.current.events.on("$pinc.oauth", "revoked", (data: unknown) => {
          console.log("revoked", data);
          refetchApiConnections && refetchApiConnections();
        });
      }
    });
    pathfixHelperScript.addEventListener("error", () => {
      setLoaded(false);
    });
    body.appendChild(pathfixHelperScript);
  }, [refetchApiConnections]);

  return (
    <PathfixHelperContext.Provider value={{loaded, clientId: PATHFIX_PUBLIC_KEY, $pinc}}>
      {children}
    </PathfixHelperContext.Provider>
  );
}

export default PathfixProvider;
