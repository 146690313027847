import {MESSAGE_TYPE, messageTypeColorMapper} from "../../helper/messageTypeColorMapper";
import InlineIcon from "../inline-icon/InlineIcon";
import {messageTypeIconMapper} from "../../helper/messageTypeIconMapper";
import {InlineIconName} from "../inline-icon/InlineIconName";
import {UiColor} from "../../../utils/constants/UiColor";
import {classNames} from "../../../utils/classNames";

export type NotificationProps = {
  text: string;
  secondaryText?: string;
  backgroundTone?: UiColor.BackgroundColor;
  textTone?: UiColor.TextColor;
  type?: MESSAGE_TYPE;
  iconName?: InlineIconName;
}

const Notification = (
  {
    text,
    secondaryText,
    backgroundTone,
    textTone,
    type,
    iconName
  }: NotificationProps
) => {

  const textToneToUse = textTone || UiColor.TextColor.BLACK;
  const iconNameToUse = iconName || (type && messageTypeIconMapper(type));
  const backgroundToneToUse = backgroundTone || UiColor.BackgroundColor.TRANSPARENT;
  const strokeToneToUse = (type && messageTypeColorMapper(type).textTone) || UiColor.TextColor.BLACK;
  const borderToneToUse = (type && messageTypeColorMapper(type).borderTone);

  return (
    <div className={classNames(`
      flex flex-row gap-2 items-center 
      p-2 
      ${backgroundToneToUse} 
      ${borderToneToUse ? `${borderToneToUse} border-l-4` : ""}
    `)}>
      {iconNameToUse &&
        <div className={`flex-shrink-0 h-6`}><InlineIcon name={iconNameToUse} primaryTone={strokeToneToUse}/></div>}
      <div className={`flex-grow`}>
        <p className={`${textToneToUse}`}>{text}</p>
        {secondaryText &&
          <p className={`${textToneToUse} text-sm opacity-80`}>{secondaryText}</p>}
      </div>
    </div>
  )
    ;
}

export default Notification;
