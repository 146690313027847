/**
 * Tailwind custom colors
 * Used by tailwind.config.js and TailwindColors.ts
 */
export const tailwindColors = {
  "cr-blue": "#58C9FA",
  "cr-pink": "#FF729F",
  "cr-purple": "#5751F6",
  "cr-gold": "#FFCC81",
  "cr-dark": "#202020",
  "cr-dark-gray": "#707070",
  "cr-gray": "#626262",
  "cr-light": "#EEF1FB",

  "cr-black1": "#333333",
  "cr-black2": "#202020",
  "cr-blue1": "#6490FF",
  "cr-blue2": "#EFF3FF",
  "cr-blue3": "#B8CCFF",
  "cr-gold1": "#FFCC81",
  "cr-gold2": "#FFB548",
  "cr-gray1": "#EEF1FB",
  "cr-gray2": "#F2F2F2",
  "cr-gray3": "#F2F3F5",
  "cr-gray4": "#F5F7FA",
  "cr-gray5": "#F8F9FB",
  "cr-gray6": "#C6CBD9",
  "cr-gray7": "#626262",
  "cr-gray8": "#B3B3B3",
  "cr-green1": "#49CB1B",
  "cr-green2": "#EAFAE4",
  "cr-red": "#F56666",
  "cr-white1": "#FFFFFF",
  "cr-white2": "#FCFCFC",
}
