import {EnvironmentState} from "../types/EnvironmentState";
import {FlowModel} from "../types/FlowModel";
import createNewId from "../../utils/createNewId";

export const createNewEnvironment = (
  flowId: FlowModel["id"]
): EnvironmentState => {
  const id = createNewId();
  return {
    model: {
      id,
      flowId,
      name: "Default Environment",
      variables: []
    }
  };
}
