import {WeeklySchedule} from "@cranq-gpt-lowcode/contracts";
import {SchedulerQuotas} from "../../flow-editor/utils/calculateSchedulerQuotas";

export const calculateMaxEntriesLimit = (
  currentJobSchedule: WeeklySchedule,
  allJobSchedules: WeeklySchedule[],
  schedulerQuotas: SchedulerQuotas
): number => {
  const allEntriesCount = allJobSchedules.reduce(
    (count, schedule) => count + schedule.length, 0);
  const currentEntriesCount = currentJobSchedule.length
  const limit = Math.min(
    schedulerQuotas.maxSchedulerEntries - (allEntriesCount - currentEntriesCount),
    schedulerQuotas.maxSchedulerEntriesPerWorkflow
  );
  return Math.max(0, limit);
}
