import React, {useEffect} from "react";
import "./App.css";
import {Route, Routes} from "react-router-dom";
import HomePage from "./app/pages/home-page/HomePage";
import FlowEditorPage from "./app/pages/flow-editor-page/FlowEditorPage";
import NotFoundPage from "./app/pages/not-found-page/NotFoundPage";
import {getGaClientId} from "./app/utils/getGaClientId";
import AuthContextProvider from "./app/components/auth-context-provider/AuthContextProvider";
import PathfixProvider from "./app/components/patfix-provider/PathfixProvider";
import {ErrorBoundary} from "react-error-boundary";
import LessonEditorPage from "./app/pages/lesson-editor-page/LessonEditorPage";
import MyFlowsPage from "./app/pages/my-flows-page/MyFlowsPage";
import ToastContextProvider from "./app/components/toast-context-provider/ToastContextProvider";
import ScreenSizeGuard from "./app/components/screen-size-guard/ScreenSizeGuard";
import AiModelsContextProvider from "./app/components/ai-models-context-provider/AiModelsContextProvider";
import {ErrorFallback} from "./app/components/error-fallback/ErrorFallback";
import ServicePage from "./app/pages/service-page/ServicePage";

declare global {
  interface Window {
    __GLOBAL_VAR__: any;
  }
}
window.__GLOBAL_VAR__ = window.__GLOBAL_VAR__ || {};

function App() {
  useEffect(() => {
    getGaClientId().then((gaClientId) => {
      window.__GLOBAL_VAR__.GA_ClIENT_ID = gaClientId;
    }).catch(
      (error) => {
        console.error(`Failed to get clientId: ${JSON.stringify(error)}`);
      }
    );
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ToastContextProvider>
        <ScreenSizeGuard size={{width: 1024, height: 768}}>
          <AuthContextProvider>
            <PathfixProvider>
              <AiModelsContextProvider>
                <Routes>
                  <Route path="/" element={<HomePage/>}/>
                  <Route path="/service" element={<ServicePage/>}/>
                  <Route path="/flow/?" element={<MyFlowsPage/>}/>
                  <Route path="/flow/:flowId" element={<FlowEditorPage/>}/>
                  <Route path="/lesson/:flowId" element={<LessonEditorPage/>}/>
                  <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
              </AiModelsContextProvider>
            </PathfixProvider>
          </AuthContextProvider>
        </ScreenSizeGuard>
      </ToastContextProvider>
    </ErrorBoundary>
  );
}

export default App;
