import {LoggedInUserState} from "../../types/UserState";
import {UserModel} from "../../types/UserModel";
import {UiColor} from "../../../utils/constants/UiColor";
import Dialog from "../../../common/components/dialog/Dialog";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import {IdentityProvider} from "@cranq-gpt-lowcode/contracts";

type RefreshLoginDialogProps = {
  loggedInUser: LoggedInUserState,
  anonymousUserId: UserModel["id"];
  isAuthWindowBlocked: boolean;
  openAuthWindow: (identityProvider: IdentityProvider, anonymousUserId: UserModel["id"]) => void;
  onLoginRefreshFailure: (error: any) => void;
}
const RefreshLoginDialog = (
  {
    loggedInUser,
    anonymousUserId,
    isAuthWindowBlocked,
    openAuthWindow,
    onLoginRefreshFailure,
  }: RefreshLoginDialogProps
) => {
  const refreshDialogContent = (
    <div
      className={`rounded ${UiColor.BorderColor.BLACK} ${UiColor.BackgroundColor.OFF_WHITE}`}>
      <p>Refreshing login via {loggedInUser.model.identityProvider}</p>
      {isAuthWindowBlocked
        ? (
          <div>
            Auth window blocked, please
            <span
              className={"cursor-pointer font-bold hover:underline"}
              onClick={() => openAuthWindow(loggedInUser.model.identityProvider, anonymousUserId)}>click here to start process</span>.
          </div>
        )
        : (
          <div>
            <p>Waiting for authentication, please do not close...</p>
          </div>
        )
      }
    </div>
  );

  return (
    <section className={"refreshLogin"}>
      <Dialog
        type={MESSAGE_TYPE.CUSTOM}
        title={"Refresh login"}
        content={refreshDialogContent}
        onCloseClick={() => onLoginRefreshFailure && onLoginRefreshFailure("Refresh login dialog closed")}/>
    </section>
  );
}

export default RefreshLoginDialog;
