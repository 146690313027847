import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {StepModel} from "../../../types/StepModel";
import {RootState} from "../../../store";
import {insertStep, selectStep, updateStep} from "../../../stepsSlice";
import {createUniqueName} from "../../../utils/createUniqueName";
import {StepState} from "../../../types/StepState";
import createNewId from "../../../../utils/createNewId";

const stepModelPropertiesToKeep = ["id", "flowId", "groupId", "index"] as const;
type StepModelPartToKeep = Pick<StepModel, typeof stepModelPropertiesToKeep[number]>;
const getStepModelPartToKeep = (
  {id, flowId, groupId, index}: StepModel
): StepModelPartToKeep => (
  {id, flowId, groupId, index}
)

export const importStepAction = (
  index: StepModel["index"],
  groupId: StepModel["groupId"],
  flowId: StepModel["flowId"],
  stepModelToImport: StepModel,
  stepId?: StepModel["id"]
): ThunkAction<Promise<StepModel["id"]>, RootState, any, AnyAction> => async (
  dispatch,
  getState
) => {
  const newStepId = stepId ?? createNewId();
  dispatch(insertStep({flowId, index, newStepId, groupId}));
  const originalStepState = selectStep(newStepId)(getState());
  if (!originalStepState) {
    throw new Error(`Unknown stepId: ${newStepId}`);
  }

  const {title} = stepModelToImport;
  const otherStepsInFlow = Object.values(getState().steps)
    .filter((step) => step.model.flowId === originalStepState.model.flowId)
    .filter((step) => step.model.id !== originalStepState.model.id);
  const otherStepTitlesInFlow = otherStepsInFlow.map((step) => step.model.title);
  const uniqueTitle = createUniqueName(title, otherStepTitlesInFlow);

  const updatedStepState: StepState = {
    model: {
      ...stepModelToImport,
      ...getStepModelPartToKeep(originalStepState.model),
      title: uniqueTitle,
    },
  }
  dispatch(updateStep(updatedStepState));
  return newStepId;
}
