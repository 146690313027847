import React, {useContext, useEffect} from "react";
import {AuthContext} from "../../components/auth-context-provider/AuthContextProvider";
import Container from "../../../common/components/container/Container";
import OAuthConnectButtonList from "../../components/oauth-connect-button-list/OAuthConnectButtonList";
import {useGetUserSubscriptionsQuery} from "../../subscriptionsApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {isApiConnected} from "../../utils/isApiConnected";

type ThirdPartyServicesWarningProps = {
  unconnectedServices: string[];
  onAllConnected?: () => void;
}
export const ThirdPartyServicesWarning = (
  {
    unconnectedServices,
    onAllConnected
  }: ThirdPartyServicesWarningProps
) => {
  const {apiConnections, user} = useContext(AuthContext);
  const {data: subscriptions} = useGetUserSubscriptionsQuery(user?.model?.id ?? skipToken);
  const stillUnconnectedServices = unconnectedServices
    .filter((api) => !isApiConnected(apiConnections, subscriptions, api));

  useEffect(() => {
    if (stillUnconnectedServices.length === 0 && onAllConnected) {
      onAllConnected();
    }
  }, [stillUnconnectedServices, onAllConnected])

  return <Container>
    {stillUnconnectedServices.length > 0
      ? (
        <>
          <p>The following 3rd party services are used in the steps you're trying to execute but are in non connected
            state:</p>
          <OAuthConnectButtonList apis={stillUnconnectedServices}/>
          <p>Please connect to each of them and, when all are in "Connected" state, retry execution.</p>
        </>
      )
      : (
        <p>All 3rd party services are now connected. You can close the window and retry execution!</p>
      )}
  </Container>
};
