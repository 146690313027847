import {FlowModel, FlowModelV0, FlowModelV1, isFlowModel, isFlowModelV0, isFlowModelV1} from "./FlowModel";
import {GroupModel, GroupModelV0, GroupModelV1, isGroupModel, isGroupModelV0, isGroupModelV1} from "./GroupModel";
import {isStepModel, isStepModelV0, isStepModelV1, StepModel, StepModelV0, StepModelV1} from "./StepModel";
import {EnvironmentModel, EnvironmentModelV0, EnvironmentModelV1} from "./EnvironmentModel";
import {isVariable} from "./Variable";

// This must be versioned to support backward compatibility!
export type FlowExportModel = {
  flow: Omit<FlowModel, "idV0">; // idV0 is only for backward compatibility, should not be exported
  groups: GroupModel[];
  steps: StepModel[];
  parameters?: EnvironmentModel["variables"]; // fow backward compatibility parameters are optional
}
export const isFlowExportModel = (maybeFlowExport: unknown): maybeFlowExport is FlowExportModel =>
  typeof maybeFlowExport === "object" && maybeFlowExport !== null
  && "flow" in maybeFlowExport && isFlowModel(maybeFlowExport.flow)
  && !("idV0" in maybeFlowExport.flow)
  && "groups" in maybeFlowExport && Array.isArray(maybeFlowExport.groups) && maybeFlowExport.groups.every((group) => isGroupModel(group))
  && "steps" in maybeFlowExport && Array.isArray(maybeFlowExport.steps) && maybeFlowExport.steps.every((step) => isStepModel(step))
  && ("parameters" in maybeFlowExport ? Array.isArray(maybeFlowExport.parameters) && maybeFlowExport.parameters.every(isVariable) : true)

// versioning
export type FlowExportModelV2 = FlowExportModel;
export const isFlowExportModelV2 = isFlowExportModel;

export type FlowExportModelV1 = {
  flow: FlowModelV1;
  groups: GroupModelV1[];
  steps: StepModelV1[];
  parameters?: EnvironmentModelV1["variables"]; // fow backward compatibility parameters are optional
}
export const isFlowExportModelV1 = (maybeFlowExport: unknown): maybeFlowExport is FlowExportModelV1 =>
  typeof maybeFlowExport === "object" && maybeFlowExport !== null
  && "flow" in maybeFlowExport && isFlowModelV1(maybeFlowExport.flow)
  && "groups" in maybeFlowExport && Array.isArray(maybeFlowExport.groups) && maybeFlowExport.groups.every((group) => isGroupModelV1(group))
  && "steps" in maybeFlowExport && Array.isArray(maybeFlowExport.steps) && maybeFlowExport.steps.every((step) => isStepModelV1(step))
  && ("parameters" in maybeFlowExport ? Array.isArray(maybeFlowExport.parameters) && maybeFlowExport.parameters.every(isVariable) : true)

export type FlowExportModelV0 = {
  flow: FlowModelV0;
  groups: GroupModelV0[];
  steps: StepModelV0[];
  parameters?: EnvironmentModelV0["variables"]; // fow backward compatibility parameters are optional
}

export const isFlowExportModelV0 = (maybeFlowExport: unknown): maybeFlowExport is FlowExportModelV0 =>
  typeof maybeFlowExport === "object" && maybeFlowExport !== null
  && "flow" in maybeFlowExport && isFlowModelV0(maybeFlowExport.flow)
  && "groups" in maybeFlowExport && Array.isArray(maybeFlowExport.groups) && maybeFlowExport.groups.every((group) => isGroupModelV0(group))
  && "steps" in maybeFlowExport && Array.isArray(maybeFlowExport.steps) && maybeFlowExport.steps.every((step) => isStepModelV0(step))
  && ("parameters" in maybeFlowExport ? Array.isArray(maybeFlowExport.parameters) && maybeFlowExport.parameters.every(isVariable) : true)
