const parseStringifiedState = (state: string) => {
  try {
    return Object.entries<string>(JSON.parse(state))
      .reduce((acc, [key, value]) => ({
        ...acc,
        [key]: JSON.parse(value)
      }), {});
  } catch (e) {
    console.error("Failed to parse stringified state", e);
    return undefined;
  }
}
export const getStateFromLocalStorageThenRemove = (key: string) => {
  const storageKey = `persist:${key}`;
  const state = window.localStorage.getItem(storageKey);
  if (state) {
    const parsedState = parseStringifiedState(state);
    try {
      window.localStorage.removeItem(storageKey);
      window.localStorage.setItem(`${storageKey}_removed_${Date.now()}`, state);
    } catch (e) {
      console.error(`Failed to rename persist:${key} in localStorage.`, e);
    }
    return parsedState;
  }
  return undefined;
};
