import React from "react";
import {inlineIconsMapping} from "./inlineIconsMapping";
import {InlineIconName} from "./InlineIconName";
import {UiColor} from "../../../utils/constants/UiColor";

export type InlineIconProps = {
  name: InlineIconName;
  primaryTone?: UiColor.TextColor;
  hoverTone?: UiColor.TextColor;
}

const InlineIcon = (
  {
    name,
    primaryTone = UiColor.TextColor.BLACK,
    hoverTone
  }: InlineIconProps
) => {
  const SpecifiedIcon = inlineIconsMapping[name] ?? null

  return SpecifiedIcon &&
    <SpecifiedIcon
      className={`${primaryTone} ${hoverTone ? `hover:${hoverTone} group-hover:${hoverTone}` : ""} h-full w-full`}/>;
};

export default InlineIcon;
