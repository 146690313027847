import {StepState} from "../../../types/StepState";
import {StepOutputModel} from "../../../types/StepOutputModel";

export const isOutputLinkedInsideGroup = (
  stepsInGroup: StepState[],
  step: StepState,
  output: StepOutputModel
) => {
  return stepsInGroup.some(
    (s) => Object.values(s.model.inputs)
      .some((input) => input.source.type === "output" && input.source.stepId === step.model.id && input.source.outputId === output.id)
  );
}
