"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generatorRequestToParamsMapperFactory = void 0;
const Generators_1 = require("../domain/Generators");
const mapGenerateCodeForStepRequestToParams_1 = require("./mapGenerateCodeForStepRequestToParams");
const mapGenerateTasksByInstructionRequestToParams_1 = require("./mapGenerateTasksByInstructionRequestToParams");
const mapGenerateTasksByInstructionHintsRequestToParams_1 = require("./mapGenerateTasksByInstructionHintsRequestToParams");
const mapGenerateExecutionErrorHintsRequestToParams_1 = require("./mapGenerateExecutionErrorHintsRequestToParams");
function generatorRequestToParamsMapperFactory(generator) {
    const mappers = {
        [Generators_1.Generators.generateCodeForStep]: mapGenerateCodeForStepRequestToParams_1.mapGenerateCodeForStepRequestToParams,
        [Generators_1.Generators.generateTasksByInstruction]: mapGenerateTasksByInstructionRequestToParams_1.mapGenerateTasksByInstructionRequestToParams,
        [Generators_1.Generators.generateTasksByInstructionHints]: mapGenerateTasksByInstructionHintsRequestToParams_1.mapGenerateTasksByInstructionHintsRequestToParams,
        [Generators_1.Generators.generateExecutionErrorHints]: mapGenerateExecutionErrorHintsRequestToParams_1.mapGenerateExecutionErrorHintsRequestToParams
    };
    return mappers[generator];
}
exports.generatorRequestToParamsMapperFactory = generatorRequestToParamsMapperFactory;
