import React, {Fragment, useContext, useEffect, useMemo} from "react";
import {batch} from "react-redux";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {deleteStep, makeSelectStepsByFlowId, updateStep, updateStepById} from "../../stepsSlice";
import {StepId} from "../../types/FlowState";
import Synopsis from "../synopsis/Synopsis";
import {selectFlow, updateFlow} from "../../flowsSlice";
import Timeline from "../timeline/Timeline";
import Button from "../../../common/components/button/Button";
import TimelineScrollableContainer from "../timeline-scrollable-container/TimelineScrollableContainer";
import {selectEnvironmentByFlowId} from "../../environmentsSlice";
import createNewId from "../../../utils/createNewId";
import {Timeouts} from "../../../utils/constants/Timeouts";
import {CreateFirstStepsPanel} from "./CreateFirstStepsPanel";
import {createUniqueName} from "../../utils/createUniqueName";
import {createDefaultFlowTitle} from "../../utils/createDefaultFlowTitle";
import {calculateStepStatus} from "../step/utils/calculateStepStatus";
import {useCreateJobMutation, useDeleteJobMutation, useListJobsQuery, useUpdateJobMutation,} from "../../schedulerApi";
import {AuthContext} from "../auth-context-provider/AuthContextProvider";
import {useDialog} from "../../../common/components/dialog/hook/useDialog";
import Dialog from "../../../common/components/dialog/Dialog";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import {createScheduledJobAction} from "./actions/createScheduledJobAction";
import {createNewEnvironment} from "../../utils/createNewEnvironment";
import {deleteScheduledJobAction} from "./actions/deleteScheduledJobAction";
import {useToast} from "../../../common/components/toast/hook/useToast";
import {calculateScheduledFlowStatus} from "../scheduled-flow-status-tag/utils/calculateScheduledFlowStatus";
import {calculateImplementationParametersHash} from "../../utils/calculateImplementationParametersHash";
import {GroupModel} from "../../types/GroupModel";
import {createGroup, deleteGroup, makeSelectGroupsByFlowId} from "../../groupsSlice";
import {StepModel} from "../../types/StepModel";
import {useFocusedEntry} from "../../pages/flow-editor-page/hooks/useFocusedEntry";
import {shouldDeleteGroupWithDeletedStep} from "./utils/shouldDeleteGroupWithDeletedStep";
import {ImportEntityEditor} from "../import-entity-editor/ImportEntityEditor";
import {moveFocusToAfterStepDeletion} from "./utils/moveFocusToAfterStepDeletion";
import {insertStepAction} from "../import-entity-editor/actions/insertStepAction";
import {importStepAction} from "../import-entity-editor/actions/importStepAction";
import {importGroupAction} from "../import-entity-editor/actions/importGroupAction";
import {encodeFlow} from "../../utils/sharing/encodeFlow";
import {copyToClipboard} from "../../utils/copyToClipboard";
import {FlowModel} from "../../types/FlowModel";
import {calculateFlowHashV0} from "../../utils/calculateFlowHash";
import {LessonContext} from "../lesson-context-provider/LessonContextProvider";
import {calculateWebhookStatus} from "../webhook-status-tag/utils/calculateWebhookStatus";
import {useCreateWebhookMutation, useDeleteWebhookMutation} from "../../webhookApi";
import {createWebhookAction} from "./actions/createWebhookAction";
import {deleteWebhookAction} from "./actions/deleteWebhookAction";
import {WebhookInfoPanel, WebhookInfoPanelProps} from "../webhook-info-panel/WebhookInfoPanel";
import {AiModelType, WeeklySchedulerEntry} from "@cranq-gpt-lowcode/contracts";
import SchedulerPanel from "../scheduler-panel/SchedulerPanel";
import {updateScheduledJobAction} from "./actions/updateScheduledJobAction";
import {useFixInvalidUserId} from "./hooks/useFixInvalidUserId";
import {useUpdateScheduledJobId} from "./hooks/useUpdateScheduledJobId";
import {useUpdateWebhookId} from "./hooks/useUpdateWebhookId";
import {useGetUserSubscriptionsQuery} from "../../subscriptionsApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {calculateSchedulerQuotas, SchedulerQuotas} from "./utils/calculateSchedulerQuotas";
import {calculateMaxEntriesLimit} from "../scheduler-panel/utils/calculateMaxEntriesLimit";
import {isLoggedInUserState} from "../../types/UserState";
import {openLoginDialog} from "../login/utils/openLoginDialog";

export type FlowEditorProps = {
  flowId: FlowModel["id"],
  focusOnFlowParams?: true | undefined,
  focusedStepId?: StepId,
  focusedGroupId?: GroupModel["id"],
  autoStartLesson?: boolean,
  // events
  onFlowExecutionRequested?: () => void,
  onFlowDeletionRequested?: () => void,
  onBreakdownChatStart?: () => void
}

const FlowEditor = (
    {
      flowId,
      focusOnFlowParams,
      focusedStepId,
      focusedGroupId,
      autoStartLesson,
      onFlowExecutionRequested,
      onFlowDeletionRequested,
      onBreakdownChatStart
    }: FlowEditorProps
  ) => {
    const dispatch = useAppDispatch();

    const flow = useAppSelector(selectFlow(flowId));
    const {
      model: flowModel,
      executionId,
      executedStepIds,
      generateStepsRequestId,
      scheduledJobId,
      scheduledJobExpiryDate,
      scheduledJobHash,
      webhookId,
      webhookExpiryDate,
      webhookHash
    } = flow ?? {};
    const {
      title,
      description,
      hints,
      hash: flowHash,
    } = flowModel ?? {};
  const {permissions, user, anonymousUserId} = useContext(AuthContext);
    const selectStepsByFlowId = useMemo(makeSelectStepsByFlowId, []);
    const steps = useAppSelector((state) => selectStepsByFlowId(state, flowId)) ?? [];
    const selectGroupsByFlowId = useMemo(makeSelectGroupsByFlowId, []);
    const groups = useAppSelector((state) => selectGroupsByFlowId(state, flowId)) ?? [];
    const otherFlowTitles = Object.values(useAppSelector((state) => state.flows))
      .filter((flow) => flow.model.id !== flowId)
      .map((flow) => flow.model.title.trim());
    const environment = useAppSelector(selectEnvironmentByFlowId(flowId))
      ?? createNewEnvironment(flowId);
    const {setFocusOnFlowParams, setFocusedStepId, setFocusedGroupId} = useFocusedEntry(steps);

  const {data: subscriptions} = useGetUserSubscriptionsQuery(user.model?.id ?? skipToken);
  const {data: scheduledJobs, isLoading: areScheduledJobsLoading,} = useListJobsQuery();
  const [createScheduledJobTrigger, createScheduledJobMutationStatus] = useCreateJobMutation();
  const [updateScheduledJobTrigger, updateScheduledJobMutationStatus] = useUpdateJobMutation();
  const [deleteScheduledJobTrigger, deleteScheduledJobMutationStatus] = useDeleteJobMutation();

    const [createWebhookTrigger, createWebhookMutationStatus] = useCreateWebhookMutation();
    const [deleteWebhookTrigger, deleteWebhookMutationStatus] = useDeleteWebhookMutation();

    const {openDialog, closeDialog, dialogProps} = useDialog();
    const {showToast} = useToast();
    const {startLesson} = useContext(LessonContext);

    useEffect(() => {
      if (autoStartLesson && startLesson) {
        startLesson();
      }
    }, [autoStartLesson, startLesson]);

    useFixInvalidUserId(flow?.model, user.model?.id ?? anonymousUserId);
    useUpdateScheduledJobId(flowId, flow?.scheduledJobId);
    useUpdateWebhookId(flowId, flow?.webhookId);


    const handleDescriptionChange = (newDescription: string) => {
      flow && dispatch(updateFlow({
        ...flow,
        model: {...flow.model, description: newDescription}
      }));
    };
    const handleTitleChange = (newTitle: string) => {
      const newValidTitle = newTitle.trim() || createDefaultFlowTitle();
      const newValidUniqueTitle = createUniqueName(newValidTitle, otherFlowTitles);
      flow && dispatch(updateFlow({
        ...flow,
        model: {
          ...flow.model,
          title: newValidUniqueTitle
        }
      }));
    };
    const handleFocusOnFlowParams = () => {
      setFocusOnFlowParams();
    }
    const handleFocusStep = (stepId: StepId) => {
      setFocusedStepId(stepId);
    };
    const handleFocusGroup = (groupId: GroupModel["id"]) => {
      setFocusedGroupId(groupId);
    };
    const handleInsertStep = async (
      index: number,
      groupId?: GroupModel["id"]
    ) => {
      const newStepId = await dispatch(insertStepAction(index, groupId, flowId));
      setFocusedStepId(newStepId);
    };
    const handleDeleteStep = (stepId: StepId) => {
      if (!flow) {
        return;
      }
      const stepToDelete = steps.find((step) => step.model.id === stepId);
      if (!stepToDelete) {
        return;
      }
      batch(() => {
        dispatch(deleteStep({flowId, stepId}));
        if (stepToDelete.model.groupId
          && shouldDeleteGroupWithDeletedStep(steps, stepToDelete.model.groupId, stepToDelete.model.id)
        ) {
          dispatch(deleteGroup(stepToDelete.model.groupId));
        }
        const moveFocus = moveFocusToAfterStepDeletion(
          steps, stepToDelete.model.id, focusedStepId, focusedGroupId
        )
        focusedStepId && setFocusedStepId(moveFocus.stepId);
        focusedGroupId && setFocusedGroupId(moveFocus.groupId);
      });
    };
    const handleImportEntity = (
      index: StepModel["index"],
      groupId?: StepModel["groupId"]
    ) => {
      openDialog({
        title: "Import step or group",
        type: MESSAGE_TYPE.CUSTOM,
        content: <ImportEntityEditor
          allowImportGroup={!groupId}
          onCancel={closeDialog}
          onImport={async (importedEntity) => {
            if ("group" in importedEntity) { // group
              const importedGroupId = await dispatch(importGroupAction(index, flowId, importedEntity))
              setFocusedGroupId(importedGroupId);
            } else { // step
              const importedStepId = await dispatch(importStepAction(index, groupId, flowId, importedEntity));
              setFocusedStepId(importedStepId);
            }
            closeDialog();
          }}/>,
      })
    };

    const openSchedulerPanel = async () => {
      if (!flow) {
        return;
      }
      if (!isLoggedInUserState(user)) {
        openLoginDialog(
          anonymousUserId, openDialog, closeDialog, showToast, "Please, login to use the scheduler feature!")
        return;
      }
      const schedulerQuotas: SchedulerQuotas = calculateSchedulerQuotas(subscriptions ?? []);
      const allJobSchedules = (scheduledJobs ?? []).map((job) => job.weeklySchedule ?? [])
      const currentJobSchedule = (scheduledJobs ?? [])
        .find((job) => job.flowId === flowId)?.weeklySchedule ?? [];
      openDialog({
        title: "Schedule Flow",
        type: MESSAGE_TYPE.CUSTOM,
        content: <SchedulerPanel
          initialEntries={currentJobSchedule}
          maxEntriesLimit={calculateMaxEntriesLimit(
            currentJobSchedule, allJobSchedules, schedulerQuotas)}
          onSetSchedule={(entries) => {
            handleSetSchedule(entries);
            closeDialog();
          }}
        />,
        controlButtonList: []
      })
    }
    const handleSetSchedule = (entries: WeeklySchedulerEntry[]) => {
      if (!flow) return;
      if (!user.model) return;

      if (flow.scheduledJobId) {
        if (entries.length > 0) {
          dispatch(updateScheduledJobAction(
            flow.scheduledJobId,
            user.model.id,
            flow.model,
            steps.map((s) => s.model),
            environment.model,
            entries,
            updateScheduledJobTrigger
          ))
            .catch((e) => {
              showToast({
                message: "Failed to update weekly schedule",
                type: MESSAGE_TYPE.ERROR,
                onToastClick: () => {
                  openDialog({
                    title: "Failed to update weekly schedule",
                    type: MESSAGE_TYPE.ERROR,
                    content: e.message,
                  });
                }
              });
            });
        } else {
          return handleDeleteSchedule();
        }
      } else {
        if (entries.length > 0) {
          dispatch(createScheduledJobAction(
            flowId,
            user.model.id,
            flow.model,
            steps.map((s) => s.model),
            environment.model,
            entries,
            createScheduledJobTrigger
          ))
            .catch((e) => {
              showToast({
                message: "Failed to schedule the flow",
                type: MESSAGE_TYPE.ERROR,
                onToastClick: () => {
                  openDialog({
                    title: "Failed to schedule the flow",
                    type: MESSAGE_TYPE.ERROR,
                    content: e.message,
                  });
                }
              });
            });
        } else {
          // nothing to do
        }
      }
    }
  const handleDeleteSchedule = async () => {
      if (user.model && flow && flow.scheduledJobId) {
        dispatch(
          deleteScheduledJobAction(
            flowId, user.model.id, flow.scheduledJobId, deleteScheduledJobTrigger)
        ).catch((e) => {
          showToast({
            message: "Failed to deschedule the flow",
            type: MESSAGE_TYPE.ERROR,
            onToastClick: () => {
              openDialog({
                title: "Failed to deschedule the flow",
                type: MESSAGE_TYPE.ERROR,
                content: e.message,
              });
            }
          });
        });
      }
    }

    const handleCreateWebhook = async () => {
      if (!flow) {
        return;
      }
      if (!isLoggedInUserState(user)) {
        openLoginDialog(
          anonymousUserId, openDialog, closeDialog, showToast, "Please, login to use 3rd party services")
        return;
      }
      dispatch(
        createWebhookAction(
          flowId,
          flow.model,
          steps.map((s) => s.model),
          environment.model,
          createWebhookTrigger)
      )
        .then((result) => {
          openWebhookInfoPanel("Webhook created successfully", result);
        })
        .catch((e) => {
          showToast({
            message: "Failed to create webhook",
            type: MESSAGE_TYPE.ERROR,
            onToastClick: () => {
              openDialog({
                title: "Failed to create webhook",
                type: MESSAGE_TYPE.ERROR,
                content: e.message,
              });
            }
          });
        });
    }
    const openWebhookInfoPanel = (
      title: string,
      webhookInfo: WebhookInfoPanelProps
    ) => {
      if (!isLoggedInUserState(user)) {
        openLoginDialog(
          anonymousUserId, openDialog, closeDialog, showToast, "Please, login to use the webhook feature!")
        return;

      }
      openDialog(
        {
          title,
          type: MESSAGE_TYPE.SUCCESS,
          content: <WebhookInfoPanel {...webhookInfo}/>,
        }
      );
    }
    const handleDeleteWebhook = async () => {
      if (!flow || !flow.webhookId) {
        return;
      }
      if (!isLoggedInUserState(user)) {
        openLoginDialog(
          anonymousUserId, openDialog, closeDialog, showToast, "Please, login to use the webhook feature!")
        return;
      }
      dispatch(
        deleteWebhookAction(
          flowId, flow.webhookId, deleteWebhookTrigger)
      ).catch((e) => {
        showToast({
          message: "Failed to delete webhook",
          type: MESSAGE_TYPE.ERROR,
          onToastClick: () => {
            openDialog({
              title: "Failed to delete webhook",
              type: MESSAGE_TYPE.ERROR,
              content: e.message,
            });
          }
        });
      });
    }


    const handleUngroup = (groupIds: GroupModel["id"][]) => {
      groupIds.every((groupId) => {
        Object.values(steps)
          .filter((step) => step.model.groupId === groupId)
          .every((step) => {
            const {groupId, ...modelWithoutGroup} = step.model;
            return dispatch(updateStep({
              ...step,
              model: modelWithoutGroup
            }));
          });
        return dispatch(deleteGroup(groupId));
      });
    }
    const handleGroup = (stepIds: StepModel["id"][]) => {
      const newGroupId = createNewId();
      dispatch(createGroup({id: newGroupId, flowId}));
      stepIds.every((stepId) => dispatch(updateStepById({
        stepId,
        update: {
          model: {
            groupId: newGroupId
          }
        }
      })));
      setFocusedGroupId(newGroupId);
    }

  const handleExportFlow = () => {
    if (flow) {
      const exportedFlow = encodeFlow(
        flow?.model,
        groups.map((group) => group.model),
        steps.map((s) => s.model),
        environment.model
      );
      copyToClipboard(exportedFlow, showToast, "Flow exported to clipboard");
    }
  };


    const flowHasParameters = environment !== undefined && environment.model.variables.length !== 0;
    const isFlowRunning = executionId !== undefined;
    const isStepGenerationInProgress = generateStepsRequestId !== undefined;
    const isRunAllInProgress = isFlowRunning && executedStepIds === undefined;
    const allStepsHaveValidImplementation = steps.length > 0
      && steps?.every((step) =>
        step.model.aiModelType === AiModelType.CODE_WRITER
          ? (
            step.model.implementation !== undefined
            && calculateImplementationParametersHash(step.model) === step.model.implementation.parametersHash
          )
          : true // non coding steps has no implementation, thus are always valid
      );
    const haveAllStepsRunSuccessfully = steps.length > 0
      && steps?.every((step) =>
        calculateStepStatus(step) === "run"
      )
    const isRunAllPermitted = permissions["SHARE_DATA_WITH_LLMS"]?.granted;
  const isRunAllEnabled = !isStepGenerationInProgress && !isFlowRunning && allStepsHaveValidImplementation;
  const schedulerDisabledReason = haveAllStepsRunSuccessfully ? undefined : "Workflow should have run successfully first!";
  const webhookDisabledReason = haveAllStepsRunSuccessfully ? undefined : "Workflow should have run successfully first!";
  const isFlowScheduleOperationInProgress = areScheduledJobsLoading || createScheduledJobMutationStatus.isLoading
    || updateScheduledJobMutationStatus.isLoading || deleteScheduledJobMutationStatus.isLoading;
    const isWebhookOperationInProgress = createWebhookMutationStatus.isLoading
      || deleteWebhookMutationStatus.isLoading;
    const scheduledFlowStatus = calculateScheduledFlowStatus(
      scheduledJobId,
      scheduledJobExpiryDate,
      scheduledJobHash,
      flowHash,
      flowModel
        ? calculateFlowHashV0(flowModel, steps.map((s) => s.model), environment.model.variables)
        : flowHash
    );
    const webhookStatus = calculateWebhookStatus(
      webhookId,
      webhookExpiryDate,
      webhookHash,
      flowHash,
      flowModel
        ? calculateFlowHashV0(flowModel, steps.map((s) => s.model), environment.model.variables)
        : flowHash
    );

  return flow
      ? (
        <Fragment>
          <section className="w-full h-full flex flex-col justify-between">
            <div className={"w-full flex-shrink-0 z-10"}>
              <Synopsis
                title={title}
                description={description}
                scheduledFlowStatus={scheduledFlowStatus}
                scheduledJobExpiryDate={scheduledJobExpiryDate}
                webhookStatus={webhookStatus}
                webhookExpiryDate={webhookExpiryDate}
                hints={hints}
                isStepGenerationInProgress={isStepGenerationInProgress}
                isFlowRunning={isFlowRunning}
                schedulerDisabledReason={schedulerDisabledReason}
                isFlowScheduleOperationInProgress={isFlowScheduleOperationInProgress}
                webhookDisabledReason={webhookDisabledReason}
                isWebhookOperationInProgress={isWebhookOperationInProgress}
                onTitleChange={handleTitleChange}
                onDescriptionChange={handleDescriptionChange}
                onDeleteFlowClick={() => onFlowDeletionRequested && onFlowDeletionRequested()}
                onScheduleFlowClick={openSchedulerPanel}
                onDescheduleFlowClick={openSchedulerPanel}
                onCreateWebhookClick={handleCreateWebhook}
                onDeleteWebhookClick={handleDeleteWebhook}
                onWebhookInfoClick={() => (webhookId && webhookExpiryDate)
                  && openWebhookInfoPanel(
                    "Webhook info", {webhookId, expiry: webhookExpiryDate}
                  )}
                onExportFlow={handleExportFlow}
                onStartLesson={startLesson}
              />
            </div>
            <TimelineScrollableContainer>
              {steps.length > 0 || flowHasParameters
                ? <Timeline
                  steps={steps}
                  groups={groups}
                  focusOnFlowParams={focusOnFlowParams}
                  focusedStepId={focusedStepId}
                  focusedGroupId={focusedGroupId}
                  showFlowParams={flowHasParameters || focusOnFlowParams}
                  isRunAllEnabled={isRunAllEnabled}
                  isStepGenerationInProgress={isStepGenerationInProgress}
                  isFlowRunning={isFlowRunning}
                  onFocusParams={handleFocusOnFlowParams}
                  onFocusItem={handleFocusStep}
                  onFocusGroup={handleFocusGroup}
                  onInsertStep={handleInsertStep}
                  onImportStep={handleImportEntity}
                  onDeleteStep={handleDeleteStep}
                  onGroup={handleGroup}
                  onUngroup={handleUngroup}
                />
                : <CreateFirstStepsPanel
                  isStepGenerationInProgress={isStepGenerationInProgress}
                  isFlowRunning={isFlowRunning}
                  onCreateBlankFirstStep={() => handleInsertStep(1)}
                  onImport={() => handleImportEntity(1)}
                  onBreakdownChatStart={onBreakdownChatStart}
                />
              }
            </TimelineScrollableContainer>
            <div className={"w-full flex-shrink-0 flex flex-col"}>
              <Button
                title={`Run Entire Workflow${isRunAllPermitted ? "" : " (*)"}`}
                disabled={(!isRunAllEnabled || !isRunAllPermitted) && !isRunAllInProgress}
                isInProgress={isRunAllInProgress}
                progressTimeout={Timeouts.EXECUTION}
                onClick={() => onFlowExecutionRequested && onFlowExecutionRequested()}/>
            </div>
          </section>
          {dialogProps && <Dialog {...dialogProps}/>}
        </Fragment>
      )
      : (
        <p>Cannot find flow with id {flowId}</p>
      )
  }
;

export default FlowEditor;
