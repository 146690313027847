"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateInterface = void 0;
const validateInputs_1 = require("./validateInputs");
function validateInterface(stepInterface, stepsToExecute, flow, env) {
    const output = [];
    if (stepInterface.inputs.length > 0) {
        const inputErrors = (0, validateInputs_1.validateInputs)(stepInterface.inputs, stepsToExecute, flow, env);
        if (inputErrors.length > 0) {
            output.push({
                property: "inputs",
                children: inputErrors
            });
        }
    }
    return output;
}
exports.validateInterface = validateInterface;
