import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {StepModel} from "../../../types/StepModel";
import {RootState} from "../../../store";
import createNewId from "../../../../utils/createNewId";
import {insertStep} from "../../../stepsSlice";

export const insertStepAction = (
  index: StepModel["index"],
  groupId: StepModel["groupId"],
  flowId: StepModel["flowId"]
): ThunkAction<Promise<StepModel["id"]>, RootState, any, AnyAction> => async (
  dispatch,
) => {
  const newStepId = createNewId();
  dispatch(insertStep({flowId, index, newStepId, groupId}));
  return newStepId;
}
