import {Id, isId} from "../../utils/types/Id";
import {isStepInputModel, StepInputModel} from "./StepInputModel";
import {isStepOutputModel, StepOutputModel} from "./StepOutputModel";
import {StepStatus} from "./StepStatus";
import {FlowModel, FlowModelV0} from "./FlowModel";
import {isUuid} from "../../utils/types/Uuid";
import {AiModel, AiModelType, isAiModelId} from "@cranq-gpt-lowcode/contracts";

export type StepModel = {
  id: Id,
  flowId: FlowModel["id"],
  groupId?: Id,
  index: number,
  title: string,
  aiModelId: AiModel["id"],
  aiModelType: AiModelType,
  description: string,
  inputs: Record<Id, StepInputModel>,
  outputs: Record<Id, StepOutputModel>,
  implementation?: {
    summary?: string,
    code: string,
    entryFuncName: string,
    dependencies: string[],
    apis?: string[],
    parametersHash: string,
    signature?: string
  },
}

const isImplementation = (maybeImplementation: unknown): maybeImplementation is StepModel["implementation"] => {
  return typeof maybeImplementation === "object" && maybeImplementation !== null
    && ("summary" in maybeImplementation ? typeof maybeImplementation.summary === "string" : true)
    && "code" in maybeImplementation && typeof maybeImplementation.code === "string"
    && "entryFuncName" in maybeImplementation && typeof maybeImplementation.entryFuncName === "string"
    && "dependencies" in maybeImplementation && Array.isArray(maybeImplementation.dependencies) && maybeImplementation.dependencies.every((dep) => typeof dep === "string")
    && ("apis" in maybeImplementation ? Array.isArray(maybeImplementation.apis) && maybeImplementation.apis.every((api) => typeof api === "string") : true)
    && "parametersHash" in maybeImplementation && typeof maybeImplementation.parametersHash === "string"
    && ("signature" in maybeImplementation ? typeof maybeImplementation.signature === "string" : true)
}

export const isStepModelBase = (maybeStepModel: unknown): maybeStepModel is StepModelV1 | StepModelV0 => {
  return typeof maybeStepModel === "object" && maybeStepModel !== null
    && "id" in maybeStepModel && isId(maybeStepModel.id)
    && ("groupId" in maybeStepModel ? isId(maybeStepModel.groupId) : true)
    && "index" in maybeStepModel && typeof maybeStepModel.index === "number"
    && ("title" in maybeStepModel ? typeof maybeStepModel.title === "string" : true)
    && ("description" in maybeStepModel ? typeof maybeStepModel.description === "string" : true)
    && "inputs" in maybeStepModel && typeof maybeStepModel.inputs === "object" && maybeStepModel.inputs !== null && Object.values(maybeStepModel.inputs).every(isStepInputModel)
    && "outputs" in maybeStepModel && typeof maybeStepModel.outputs === "object" && maybeStepModel.outputs !== null && Object.values(maybeStepModel.outputs).every(isStepOutputModel)
    && ("implementation" in maybeStepModel ? typeof maybeStepModel.implementation === "object" && maybeStepModel.implementation !== null && isImplementation(maybeStepModel.implementation) : true)
}
export const isStepModel = (maybeStepModel: unknown): maybeStepModel is StepModel => {
  return isStepModelBase(maybeStepModel)
    && "flowId" in maybeStepModel && isUuid(maybeStepModel.flowId)
    && "description" in maybeStepModel && typeof maybeStepModel.description === "string"
    && "aiModelId" in maybeStepModel && isAiModelId(maybeStepModel.aiModelId)
}

// versioning
export type StepModelV2 = StepModel;
export const isStepModelV2 = isStepModel;

// model was introduced on v2
// description became required on v2
export type StepModelV1 = Omit<StepModelV2, "aiModelId" | "aiModelType" | "description"> & {
  description?: StepModelV2["description"]
}
export const isStepModelV1 = (maybeStepModel: unknown): maybeStepModel is StepModelV1 => {
  return isStepModelBase(maybeStepModel)
    && "flowId" in maybeStepModel && isUuid(maybeStepModel.flowId)
}

// flowId became Uuid in v1
export type StepModelV0 = Omit<StepModelV1, "flowId"> & { flowId: FlowModelV0["id"] }
export const isStepModelV0 = (maybeStepModel: unknown): maybeStepModel is StepModelV0 => {
  return isStepModelBase(maybeStepModel)
    && "flowId" in maybeStepModel && isId(maybeStepModel.flowId)
}

// status field was removed in v0
export type StepModelPriorV0 = StepModelV0 & { status: keyof typeof StepStatus }
export const isStepModelPriorV0 = (maybeStepModel: unknown): maybeStepModel is StepModelPriorV0 => {
  return isStepModelV0(maybeStepModel)
    && "status" in maybeStepModel && typeof maybeStepModel.status === "string" && Object.keys(StepStatus).includes(maybeStepModel.status)
}
