import React from "react";
import Dialog from "../../../common/components/dialog/Dialog";
import {useDialog} from "../../../common/components/dialog/hook/useDialog";
import {useModalEditor} from "../../../common/components/modal-editor/hook/useModalEditor";
import ModalEditor from "../../../common/components/modal-editor/ModalEditor";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import {StepOutputModel} from "../../types/StepOutputModel";
import {safeJsonStringify} from "../../utils/safeJsonStringify";
import {InputWithActionButtons} from "../../../common/components/input-with-action-buttons/InputWithActionButtons";
import {useToast} from "../../../common/components/toast/hook/useToast";
import {copyToClipboard} from "../../utils/copyToClipboard";
import DeleteItemActionButton from "../../../common/components/delete-item-action-button/DeleteItemActionButton";

type StepOutputEditorPropsBase = {
  model: StepOutputModel,
  disabled?: boolean,
  isLinked?: boolean,
  // events
  onInspect?: () => void,
  onDelete?: () => void,
  onChange?: (newOutput: StepOutputModel) => void,
}

export type StepOutputEditorProps = StepOutputEditorPropsBase & {
  allowValueModificationOnly?: false,
  outputNameFixer: (name: string) => string
}
  | StepOutputEditorPropsBase & {
  allowValueModificationOnly: true,
  outputNameFixer?: never
}

const StepOutputEditor = (
  {
    model,
    allowValueModificationOnly,
    disabled = false,
    isLinked = false,
    outputNameFixer,
    onDelete,
    onChange
  }: StepOutputEditorProps) => {

  const {openDialog, closeDialog, dialogProps} = useDialog();

  const handleDescriptionChange = (
    newDescription: StepOutputModel["description"],
  ) => {
    if (!allowValueModificationOnly) {
      // do not run onChange if description is not changed to avoid changing the name unintentionally
      return newDescription !== model.description && onChange && onChange({
        ...model,
        name: outputNameFixer(newDescription ?? ""),
        description: newDescription
      });
    }
  };

  const handleEditOutputDescription = () => {
    !allowValueModificationOnly && openModalEditor({
      title: model.name,
      content: model.description ?? "",
      handleOnClose: (newDescription) => handleDescriptionChange(newDescription)
    })
  }

  const handleDelete = () => {
    if (!allowValueModificationOnly) {
      if (isLinked) {
        openDialog({
          title: "Warning",
          type: MESSAGE_TYPE.WARNING,
          content: "This output is linked to one or more later steps' input(s). Deleting this output will unlink these inputs. Are you sure you want to proceed?",
          controlButtonList: [
            {
              title: "Cancel",
              onClick: closeDialog
            },
            {
              title: "Proceed",
              onClick: () => onDelete && onDelete()
            }
          ]
        });
      } else {
        onDelete && onDelete();
      }
    }
  };

  const {openModalEditor, modalEditorProps} = useModalEditor();
  const {showToast} = useToast();

  return (
    <div className="contents group">
      <InputWithActionButtons
        name={"description"}
        disabled={disabled}
        readonly={allowValueModificationOnly}
        value={model.description}
        onBlur={allowValueModificationOnly ? undefined : handleDescriptionChange}
        onEditAction={allowValueModificationOnly ? undefined : handleEditOutputDescription}
        placeholder={"Describe the output"}
      />
      <div className={"relative"}>
        <InputWithActionButtons
          name={"value"}
          size={"full"}
          placeholder={"Empty output value"}
          readonly={true}
          value={safeJsonStringify(model.value)}
          title={""}
          onInspectAction={() => {
            openModalEditor({
              title: model.name,
              content: safeJsonStringify(model.value) || "",
            });
          }}
          onCopyAction={() => copyToClipboard(safeJsonStringify(model.value), showToast)}
        />
        {!allowValueModificationOnly && <DeleteItemActionButton onClick={handleDelete} disabled={disabled}/>}
      </div>
      {modalEditorProps && <ModalEditor {...modalEditorProps}/>}
      {dialogProps && <Dialog {...dialogProps}/>}
    </div>
  )
}

export default StepOutputEditor;
