"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapExecutorTaskToExecutionStatusResponse = void 0;
function mapExecutorTaskToExecutionStatusResponse(task) {
    var _a;
    const { result, status, executionId, invocationError } = task;
    const log = (_a = result === null || result === void 0 ? void 0 : result.log) !== null && _a !== void 0 ? _a : [];
    if (invocationError) {
        log.push("Executor function failed");
    }
    return {
        executionId,
        state: status,
        outcome: result === null || result === void 0 ? void 0 : result.outcome,
        steps: result === null || result === void 0 ? void 0 : result.steps,
        log: log
    };
}
exports.mapExecutorTaskToExecutionStatusResponse = mapExecutorTaskToExecutionStatusResponse;
