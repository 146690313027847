import Header from "../../../common/components/header/Header";
import {Branches} from "../../../common/components/tree-selector/TreeSelector";
import {selectEnvironmentByFlowId} from "../../environmentsSlice";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
  addStepInput,
  addStepOutput,
  deleteStepInput,
  deleteStepOutput,
  makeSelectStepsByFlowId,
  selectStep,
  updateStep,
  updateStepById,
  updateStepInput,
  updateStepOutput
} from "../../stepsSlice";
import {StepInputModel} from "../../types/StepInputModel";
import {StepModel} from "../../types/StepModel";
import {StepOutputModel} from "../../types/StepOutputModel";
import {createTreeSelectorBranchesFromStepsAndEnvs} from "../../utils/createTreeSelectorBranchesFromStepsAndEnvs";
import {calculateImplementationParametersHash} from "../../utils/calculateImplementationParametersHash";
import {createNewEnvironment} from "../../utils/createNewEnvironment";
import React, {Fragment, useContext, useMemo, useState} from "react";
import {UiColor} from "../../../utils/constants/UiColor";
import {classNames} from "../../../utils/classNames";
import {LayoutValues} from "../../../utils/constants/LayoutValues";
import {InlineIconName} from "../../../common/components/inline-icon/InlineIconName";
import Button, {ButtonProps, ButtonType} from "../../../common/components/button/Button";
import {Timeouts} from "../../../utils/constants/Timeouts";
import {copyToClipboard} from "../../utils/copyToClipboard";
import {useToast} from "../../../common/components/toast/hook/useToast";
import {isOutputLinked} from "./utils/isOutputLinked";
import {encodeStep} from "../../utils/sharing/encodeStep";
import {useDialog} from "../../../common/components/dialog/hook/useDialog";
import Dialog from "../../../common/components/dialog/Dialog";
import {getLinkedPropertyDisplay} from "../step-input-editor/utils/getLinkedPropertyDisplay";
import {createUniqueName} from "../../utils/createUniqueName";
import {createDefaultStepTitle} from "../../utils/createDefaultStepTitle";
import {useRequestStatusPolling} from "../../hooks/useRequestStatusPolling";
import {useGetGenerateExecutionErrorHintsTaskStatusQuery} from "../../editorApi";
import {pollingErrorCallback, pollingSuccessCallback} from "../../pages/flow-editor-page/utils/pollingCallbacks";
import {calculateStepStatus} from "./utils/calculateStepStatus";
import {AiModel, AiModelType, GenerateExecutionErrorHintsResponse} from "@cranq-gpt-lowcode/contracts";
import {AuthContext} from "../auth-context-provider/AuthContextProvider";
import {generateStatusLine} from "./utils/generateStatusLine";
import {makeSelectGroupsByFlowId} from "../../groupsSlice";
import TabbedContainer from "../../../common/components/tabbed-container/TabbedContainer";
import ExecutionErrorHints from "../execution-error-hints/ExecutionErrorHints";
import StepCodeAndError from "./StepCodeAndError";
import {LaunchStepChat} from "./LaunchStepChat";
import {AiModelsContext} from "../ai-models-context-provider/AiModelsContextProvider";
import Select from "../../../common/components/select/Select";
import StepDescriptionEditor from "./StepDescriptionEditor";
import createNewId from "../../../utils/createNewId";
import {StepParameters} from "./StepParameters";
import {selectGlobalParameters} from "../../globalParametersSlice";


const AiModelTypeToInstructionPrefixMap = new Map<AiModelType, string>([
  [AiModelType.CODE_WRITER, "I want to generate code based on the following specification..."],
  [AiModelType.TEXT_WRITER, "I want to send the following prompt to the AI model..."]
]);

enum StepTab {
  "parameters",
  "aiHints",
  "code"
}

export type StepProps = {
  stepId: StepModel["id"],
  isFlowRunning: boolean,
  isStepRunning: boolean,
  //events
  onStepExecutionRequested?: (stepId: StepModel["id"]) => void,
  onCodeGenerationRequested?: (stepId: StepModel["id"]) => void,
}
const setLlmStepOutputs = (outputs: StepModel["outputs"]) => {
  const defaultLlmOutput: StepOutputModel = {
    id: createNewId(),
    name: "output",
    description: "output",
    type: "string"
  }
  const llmOutput = Object.values(outputs).find((output) =>
    output.name === defaultLlmOutput.name
    && output.description === defaultLlmOutput.description
    && output.type === defaultLlmOutput.type
  ) ?? defaultLlmOutput;
  return {[llmOutput.id]: llmOutput}
};

const Step = (
  {
    stepId,
    isFlowRunning,
    isStepRunning,
    //
    onStepExecutionRequested,

  }: StepProps
) => {
  const dispatch = useAppDispatch();
  const step = useAppSelector(selectStep(stepId));
  if (!step) {
    throw new ReferenceError("Failed to find step with id: " + stepId);
  }

  const {aiModels} = useContext(AiModelsContext);
  const {openDialog, dialogProps} = useDialog();
  const {permissions} = useContext(AuthContext);
  const {showToast} = useToast();

  const {
    model: {
      flowId,
      index: stepIndex,
      title = "",
      aiModelId,
      aiModelType,
      description = "",
      inputs = {},
      outputs = {},
      implementation: {
        code,
        parametersHash,
        apis = [],
      } = {
        code: "",
        parametersHash: ""
      },
    },
    codeGenerationRequestId,
    executionErrorHintsGenerationRequestId,
    lastExecutionError,
    lastExecutionErrorHints,
    aiHintsUpdatedTimestamp = 0,
    aiHintsViewedTimestamp = 0
  } = step;
  const status = calculateStepStatus(step);
  const selectStepsByFlowId = useMemo(makeSelectStepsByFlowId, []);
  const steps = useAppSelector((state) => selectStepsByFlowId(state, flowId));
  const selectGroupsByFlowId = useMemo(makeSelectGroupsByFlowId, []);
  const groups = useAppSelector((state) => selectGroupsByFlowId(state, flowId));
  if (!steps || !groups) {
    throw new ReferenceError(`Failed to find steps and/or groups for flow with id: ${flowId}`);
  }
  const aiModel = aiModels && aiModels[aiModelId];

  const globalParameters = useAppSelector(selectGlobalParameters);
  const environment = useAppSelector(selectEnvironmentByFlowId(flowId)) ?? createNewEnvironment(flowId);
  const branches: Branches = createTreeSelectorBranchesFromStepsAndEnvs(
    steps,
    groups,
    environment,
    globalParameters,
    stepIndex);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const resolveLinkedInput = useMemo(
    () => getLinkedPropertyDisplay.bind(null, steps, groups, environment, globalParameters),
    [steps, groups, environment, globalParameters]
  );

  useRequestStatusPolling<GenerateExecutionErrorHintsResponse>(
    executionErrorHintsGenerationRequestId,
    "GENERATE_EXECUTION_ERROR_HINTS",
    useGetGenerateExecutionErrorHintsTaskStatusQuery,
    undefined,
    (dispatch) => dispatch(updateStepById({
      stepId,
      update: {
        executionErrorHintsGenerationRequestId: undefined
      }
    })),
    (queryResult) => (dispatch) => {
      console.log(queryResult)
      dispatch(updateStepById({
        stepId,
        update: {
          lastExecutionErrorHints: queryResult,
          aiHintsUpdatedTimestamp: Date.now()
        }
      }));
    },
    pollingErrorCallback(showToast, openDialog),
    pollingSuccessCallback(showToast),
    Timeouts.EXECUTION_ERROR_HINTS_GENERATION
  )


  const handleAddInput = () => {
    dispatch(addStepInput(stepId));
  };
  const handleChangeInput = (changedInput: StepInputModel) => {
    dispatch(updateStepInput({stepId, input: changedInput}));
  };
  const handleDeleteInput = (inputId: StepInputModel["id"]) => {
    dispatch(deleteStepInput({stepId, inputId}));
  };
  const handleDeleteOutput = (outputId: StepOutputModel["id"]) => {
    dispatch(deleteStepOutput({stepId, outputId}));
  };
  const handleTitleChange = (newTitle: string) => {
    const defaultTitle = createDefaultStepTitle(stepIndex);
    const newValidTitle = newTitle.trim() || defaultTitle;
    const otherStepTitles = Object.values(steps).filter(
      (step) => step.model.id !== stepId).map(step => step.model.title
    );
    const newValidUniqueTitle = createUniqueName(newValidTitle, otherStepTitles);
    dispatch(updateStep({
      ...step,
      model: {...step.model, title: newValidUniqueTitle}
    }));
  };
  const handleDescriptionIsEmptyChange = (newDescription: string) => {
    if ((newDescription.trim() === "") !== (description.trim() === "")) {
      dispatch(updateStep({
        ...step,
        model: {...step.model, description: newDescription}
      }));
    }
  };
  const handleDescriptionChange = (newDescription: string) => {
    dispatch(updateStep({
      ...step,
      model: {...step.model, description: newDescription}
    }));
  };
  const handleAiModelChange = (newAiModelId: AiModel["id"]) => {
    const newAiModelType = aiModels?.[newAiModelId]?.type;
    if (!newAiModelType) {
      return;
    }
    dispatch(updateStep({
      ...step,
      model: {
        ...step.model,
        aiModelId: newAiModelId,
        aiModelType: newAiModelType,
        outputs: newAiModelType === AiModelType.TEXT_WRITER
          ? setLlmStepOutputs(step.model.outputs)
          : step.model.outputs,
        implementation: newAiModelType === AiModelType.TEXT_WRITER
          ? undefined
          : step.model.implementation
      }
    }));
  }
  const handleAddOutput = () => {
    dispatch(addStepOutput(stepId));
  };
  const handleChangeOutput = (changedOutput: StepOutputModel) => {
    dispatch(updateStepOutput({stepId, output: changedOutput}));
  };
  const handleRunStepRequest = () => {
    onStepExecutionRequested && onStepExecutionRequested(stepId);
  };
  const handleViewGeneratedCode = () => {
    setActiveTabIndex(StepTab.code);
  }
  const handleViewError = () => {
    setActiveTabIndex(StepTab.code);
  }
  const handleViewAiHints = () => {
    setActiveTabIndex(StepTab.aiHints);
  }
  const handleTabChange = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
    if (tabIndex === StepTab.aiHints) {
      dispatch(updateStepById({
        stepId,
        update: {
          aiHintsViewedTimestamp: Date.now()
        }
      }));
    }
  };

  const handleStepChatStart = () => {
    dispatch(updateStepById({stepId, update: {lastChatUpdate: Date.now()}}))
    // opening chat will be handled by useEffect
  }

  const isCodeStep = aiModelType === AiModelType.CODE_WRITER;
  const currentStepImplementationParametersHash = isCodeStep ? calculateImplementationParametersHash(step.model) : null;
  const isImplementationObsolete = isCodeStep && (!!code && currentStepImplementationParametersHash !== parametersHash);
  const isCodeGenerationPending = isCodeStep && codeGenerationRequestId !== undefined;

  const rightSideButtonListProps: ButtonProps[] = [
    {
      title: "Export step to clipboard",
      type: ButtonType.SECONDARY,
      expanding: true,
      iconName: InlineIconName.COPY,
      onClick: () => copyToClipboard(encodeStep(step.model), showToast, "Step exported to clipboard"),
    }
  ];
  const statusLine = generateStatusLine(
    "step",
    description,
    status,
    code,
    inputs,
    outputs,
    lastExecutionErrorHints,
    isCodeGenerationPending,
    isStepRunning,
    isImplementationObsolete,
    () => lastExecutionErrorHints ? handleViewAiHints() : handleViewError(),
    handleViewGeneratedCode,
  );

  return (
    <Fragment>
      <section id="step" className="container w-full h-full flex flex-col justify-between">
        <div className={"w-full flex-shrink-0 pb-6"}>
          <Header
            title={title}
            onTitleChange={handleTitleChange}
            titleTone={UiColor.TextColor.WHITE}
            rightSideButtonListProps={rightSideButtonListProps}
          />
        </div>
        <div className={classNames(`
          w-full ${LayoutValues.COLUMN_PADDING_X}
          flex-grow flex-shrink
          flex flex-col
          overflow-y-hidden
          rounded-2xl
          ${UiColor.BackgroundColor.WHITE}
        `)}>
          <div className={classNames(`
            flex-grow flex-shrink 
            ${LayoutValues.COLUMN_PADDING_X_INVERSE} ${LayoutValues.COLUMN_PADDING_X} pt-6
            flex flex-col gap-4
            border-b-[1px] ${UiColor.BorderColor.GRAY}
            overflow-y-auto
          `)}>
            {aiModels
              ? (
                <div className={"flex gap-2 items-center"}>
                  <label>Target Ai Model:</label>
                  <Select name={"aiModel"}
                          options={Object.fromEntries(
                            Object.entries(aiModels).map(([id, aiModel]) => ([id, `${aiModel.name}#${aiModel.version}`]))
                          )}
                          value={aiModelId}
                          onValueChange={handleAiModelChange}
                  />
                </div>)
              : null
            }
            <p>{aiModel && AiModelTypeToInstructionPrefixMap.get(aiModel.type)}</p>
            <StepDescriptionEditor
              disabled={isCodeGenerationPending || isStepRunning}
              description={description}
              targetAiModelType={aiModel?.type ?? AiModelType.CODE_WRITER}
              inputPlaceholders={Object.values(step.model.inputs).map((input) => input.description ?? input.name)}
              onValueChange={handleDescriptionIsEmptyChange}
              onBlur={handleDescriptionChange}/>
            {isCodeStep
              ? <LaunchStepChat permissionGranted={permissions.SHARE_DATA_WITH_LLMS.granted}
                                onStepChatStart={handleStepChatStart} disabled={isFlowRunning || isStepRunning}/>
              : null
            }
            <TabbedContainer
              tabs={[
                {
                  title: "Parameters",
                  children: <StepParameters
                    inputs={inputs}
                    outputs={outputs}
                    apis={apis}
                    isCodeStep={isCodeStep}
                    disabled={isStepRunning || isCodeGenerationPending}
                    linkables={branches}
                    linkedInputResolver={resolveLinkedInput}
                    isOutputLinked={isOutputLinked.bind(null, steps)}
                    onAddInput={handleAddInput}
                    onChangeInput={handleChangeInput}
                    onDeleteInput={handleDeleteInput}
                    onAddOutput={handleAddOutput}
                    onChangeOutput={handleChangeOutput}
                    onDeleteOutput={handleDeleteOutput}
                  />
                },
                {
                  title: "AI Hints",
                  children: (
                    <ExecutionErrorHints
                      executionErrorHints={lastExecutionErrorHints}
                      isLoading={executionErrorHintsGenerationRequestId !== undefined}
                      inputs={inputs}
                      isImplementationObsolete={isImplementationObsolete}
                      onModifyDescription={handleDescriptionChange}
                    />
                  ),
                  promoted: aiHintsUpdatedTimestamp > aiHintsViewedTimestamp ? true : undefined,
                  demoted: (lastExecutionErrorHints === undefined && executionErrorHintsGenerationRequestId === undefined) ? true : undefined
                },
                ...(isCodeStep
                  ? [{
                    title: lastExecutionError ? "Code & Error" : "Code",
                    children: (
                      <StepCodeAndError
                        code={code}
                        error={lastExecutionError === null ? undefined : lastExecutionError}
                        isImplementationObsolete={isImplementationObsolete}/>
                    ),
                    demoted: (code === "" && typeof lastExecutionError !== "string") ? true as true : undefined,
                  }] : [])
              ]}
              activeTabIndex={activeTabIndex}
              onTabChange={handleTabChange}
            />
          </div>
          <div className={"w-full flex-shrink-0 flex justify-between py-6 gap-2"}>
            {statusLine}
            <Button
              id={"proceed"}
              title={`Proceed${permissions["SHARE_DATA_WITH_LLMS"]?.granted ? "" : " (*)"}`}
              type={ButtonType.PRIMARY}
              disabled={isFlowRunning || description.trim() === "" || !permissions["SHARE_DATA_WITH_LLMS"]?.granted}
              isInProgress={isStepRunning || isCodeGenerationPending}
              progressTimeout={isStepRunning ? Timeouts.EXECUTION : Timeouts.STEP_IMPLEMENTATION_GENERATION}
              shadowTone={UiColor.ShadowColor.BLACK_LIGHT}
              onClick={handleRunStepRequest}
            />
          </div>
        </div>
      </section>
      {dialogProps && <Dialog {...dialogProps}/>}
    </Fragment>
  );
};

export default Step;
