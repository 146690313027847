import {FlowModel} from "../../types/FlowModel";
import {InlineIconName} from "../../../common/components/inline-icon/InlineIconName";
import {UiColor} from "../../../utils/constants/UiColor";
import FlowList from "../flow-list/FlowList";
import LinkList from "../../../common/components/link-list/LinkList";
import UserStatus from "../user-status/UserStatus";
import React, {useContext} from "react";
import {ExternalLinks} from "../../../utils/constants/ExternalLinks";
import {Link} from "react-router-dom";
import {StartNewFlowButton} from "./StartNewFlowButton";
import {ImportNewFlow} from "./ImportNewFlow";
import {isProUser} from "../user-status/utils/isProUser";
import {useGetUserSubscriptionsQuery} from "../../subscriptionsApi";
import {AuthContext} from "../auth-context-provider/AuthContextProvider";
import {skipToken} from "@reduxjs/toolkit/query";
import {Sticker} from "../../../common/components/sticker/Sticker";

const links = [
  {
    url: ExternalLinks.LATEST_SEO_WORKFLOWS,
    text: "Community workflows",
    icon: InlineIconName.LINK
  },
  {
    url: ExternalLinks.CRANQ_DISCORD_CHANNEL,
    text: "Join us on Discord",
    icon: InlineIconName.LOGO_DISCORD
  },
  {
    url: "/flow",
    text: "Global parameters",
    icon: InlineIconName.SETTINGS,
  }
];

const proLinks = [
  {
    url: ExternalLinks.PRO_WORKFLOWS,
    text: "Pro workflows",
    icon: InlineIconName.LINK
  },
];

type SidebarProps = {
  currentFlowId?: FlowModel["id"],
  showFlowList?: boolean,
  forceLogin?: boolean
}
const Sidebar = (
  {
    currentFlowId,
    showFlowList = !!currentFlowId,
    forceLogin = false
  }: SidebarProps
) => {
  const {user} = useContext(AuthContext);
  const {data: subscriptions} = useGetUserSubscriptionsQuery(user.model?.id ?? skipToken);
  return (
    <div className={`flex flex-col gap-6 h-full justify-between`}>
      {/* Flow-related */}
      <div className={"flex flex-col gap-9"}>
        <div className={"flex flex-col gap-2 text-sm"}>
          <StartNewFlowButton/>
          <ImportNewFlow/>
        </div>
        {showFlowList &&
          <div className={"flex flex-col gap-3 text-sm"}>
            <div className={`text-xs ${UiColor.TextColor.LIGHT_GRAY}`}>My recent workflows</div>
            <FlowList
              currentFlowId={currentFlowId}
              flavour={"navBarPanel"}
              orderBy={"updatedAt"}
              orderDirection={"desc"}
              limit={5}
              includeUserFlowsOnly={true}
            />
            <div className={`p-2 hover:${UiColor.TextColor.GOLD}`}><Link to={"/flow"}>All my workflows &gt;</Link></div>
          </div>
        }
      </div>
      <div className={"flex flex-col gap-8 grow-0 text-sm"}>
        {isProUser(subscriptions)
          ? (
            <div className={"relative flex flex-col gap-4"}>
              <Sticker text={"pro"}/>
              <LinkList links={proLinks}/>
              <LinkList links={links}/>
            </div>
          )
          : <LinkList links={links}/>
        }
        <UserStatus forceLogin={forceLogin}/>
      </div>
    </div>
  );
};

export default Sidebar;
