import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {StepModel} from "./types/StepModel";
import {StepState, StepStateV0, StepStateV1, StepStateV2} from "./types/StepState";
import createNewId from "../utils/createNewId";
import {Id} from "../utils/types/Id";
import {StepInputModel} from "./types/StepInputModel";
import {StepOutputModel} from "./types/StepOutputModel";
import {FlowModel} from "./types/FlowModel";
import {calculateImplementationParametersHash} from "./utils/calculateImplementationParametersHash";
import {createUniqueName} from "./utils/createUniqueName";
import {createDefaultStepTitle} from "./utils/createDefaultStepTitle";
import {unlinkInputsFromDeletedStepOrOutput} from "./utils/unlinkInputsFromDeletedStepOrOutput";
import {
  DEFAULT_AI_MODEL_ID,
  DEFAULT_AI_MODEL_TYPE,
  GenerateCodeForStepResponse,
  PortBase
} from "@cranq-gpt-lowcode/contracts";

export type StepsStateV0 = Record<Id, StepStateV0>;
export type StepsStateV1 = Record<Id, StepStateV1>;
export type StepsStateV2 = Record<Id, StepStateV2>;
export type StepsState = StepsStateV2;
const initialState: StepsState = {};

type GeneratedInputModel = GenerateCodeForStepResponse["interface"]["inputs"][number]
type GeneratedOutputModel = GenerateCodeForStepResponse["interface"]["outputs"][number];

const arePortsTheSame = <T extends StepInputModel | StepOutputModel>(p1: T, p2: PortBase): boolean => {
  return (p1.description ?? "").trim().toLocaleLowerCase() === p2.description.trim().toLocaleLowerCase()
    || p1.name.trim().toLocaleLowerCase() === p2.name.trim().toLocaleLowerCase();
};

export const stepsSlice = createSlice({
  name: "steps",
  initialState,
  reducers: {
    createStep: (state, action: PayloadAction<StepState>) => {
      state[action.payload.model.id] = action.payload;
    },
    updateStep: (state, action: PayloadAction<StepState>) => {
      state[action.payload.model.id] = action.payload;
    },
    updateStepById: (state, action: PayloadAction<{
      stepId: StepModel["id"],
      update: Partial<Omit<StepState, "model">> & {
        model?: Partial<StepModel>
      }
    }>) => {
      const step = state[action.payload.stepId];
      if (step) {
        state[action.payload.stepId] = {
          ...step,
          ...action.payload.update,
          model: {
            ...step.model,
            ...(action.payload.update.model ?? {})
          }
        };
      }
    },
    insertStep: (state, {
      payload: {
        flowId,
        index,
        newStepId,
        groupId
      }
    }: PayloadAction<{
      flowId: FlowModel["id"],
      index: StepModel["index"],
      newStepId: StepModel["id"],
      groupId?: StepModel["groupId"]
    }>) => {
      const flowSteps = Object.values(state)
        .filter(step => step.model.flowId === flowId)
        .sort((a, b) => a.model.index - b.model.index);

      // Fix indexes and default titles of steps after the inserted step
      flowSteps.forEach((step) => {
        if (step.model.index >= index) {
          const currentStepIndex = step.model.index;
          step.model.index += 1;
          step.model.title = step.model.title.trim() === createDefaultStepTitle(currentStepIndex)
            ? createDefaultStepTitle(step.model.index)
            : step.model.title;
        }
      });
      const newStepTitle = createDefaultStepTitle(index);
      const existingStepTitles = flowSteps.map((step) => step.model.title);
      const newUniqueStepTitle = createUniqueName(newStepTitle, existingStepTitles);
      state[newStepId] = {
        model: {
          id: newStepId,
          title: newUniqueStepTitle,
          aiModelId: DEFAULT_AI_MODEL_ID,
          aiModelType: DEFAULT_AI_MODEL_TYPE,
          description: "",
          flowId: flowId,
          ...(groupId ? {groupId: groupId} : {}),
          index: index,
          inputs: {},
          outputs: {}
        }
      };
    },
    deleteStep: (state, action: PayloadAction<{
      flowId: FlowModel["id"],
      stepId: StepModel["id"]
    }>) => {
      const step = state[action.payload.stepId];
      if (step) {
        if (step.model.flowId !== action.payload.flowId) {
          throw new Error(`Step ${action.payload.stepId} does not belong to flow ${action.payload.flowId}`);
        }
        delete state[action.payload.stepId];
        // Make remaining steps' indexes sequential
        Object.values(state)
          .filter(step => step.model.flowId === action.payload.flowId)
          .sort((a, b) => a.model.index - b.model.index)
          .forEach((step, index) => {
            const currentIndex = step.model.index;
            step.model.index = index + 1;
            step.model.title = step.model.title.trim() === createDefaultStepTitle(currentIndex) ? createDefaultStepTitle(step.model.index) : step.model.title;

          });
        unlinkInputsFromDeletedStepOrOutput({
          state,
          flowId: action.payload.flowId,
          stepId: action.payload.stepId
        });
      }
    },
    resetStepExecutionStatus(state, action: PayloadAction<StepModel["id"]>) {
      const step = state[action.payload];
      if (step) {
        delete step.lastExecutionError;
        delete step.executionErrorHintsGenerationRequestId
        delete step.lastExecutionErrorHints;
        delete step.aiHintsUpdatedTimestamp;
        delete step.aiHintsViewedTimestamp;
      }
      stepsSlice.caseReducers.resetStepOutputValues(state, {type: "resetStepOutputValues", payload: action.payload});
    },
    deleteStepsByFlowId: (state, action: PayloadAction<StepModel["flowId"]>) => {
      Object.values(state)
        .filter(step => step.model.flowId === action.payload)
        .forEach(step => delete state[step.model.id]);
    },
    addStepInput: (state, action: PayloadAction<StepModel["id"]>) => {
      const step = state[action.payload];
      if (step) {
        const inputId = createNewId();
        step.model.inputs[inputId] = {
          id: inputId,
          name: `input${Object.keys(step.model.inputs).length + 1}`,
          description: "",
          type: "unknown",
          source: {
            type: "value",
            value: undefined
          }
        };
      }
    },
    updateStepInput: (state, action: PayloadAction<{
      stepId: StepModel["id"],
      input: StepInputModel
    }>) => {
      const step = state[action.payload.stepId];
      if (step) {
        step.model.inputs[action.payload.input.id] = action.payload.input;
      }
    },
    deleteStepInput: (state, action: PayloadAction<{
      stepId: StepModel["id"],
      inputId: StepInputModel["id"]
    }>) => {
      const step = state[action.payload.stepId];
      if (step) {
        const input = step.model.inputs[action.payload.inputId];
        if (input?.isBoundToCode) {
          step.model.implementation = undefined;
        }
        delete step.model.inputs[action.payload.inputId];
      }
    },
    addStepOutput: (state, action: PayloadAction<StepModel["id"]>) => {
      const step = state[action.payload];
      if (step) {
        const outputId = createNewId();
        step.model.outputs[outputId] = {
          id: outputId,
          name: `output${Object.keys(step.model.outputs).length + 1}`,
          description: "",
          type: "unknown",
          value: undefined
        };
      }
    },
    updateStepOutput: (state, action: PayloadAction<{
      stepId: StepModel["id"],
      output: StepOutputModel
    }>) => {
      const step = state[action.payload.stepId];
      if (step) {
        step.model.outputs[action.payload.output.id] = action.payload.output;
      }
    },
    updateStepOutputValues: (state, action: PayloadAction<{
      stepId: StepModel["id"],
      outputs: Pick<StepOutputModel, "name" | "value">[]
    }>) => {
      const step = state[action.payload.stepId];
      if (step) {
        action.payload.outputs.forEach((output) => {
          const outputToSetValueOn = Object.values(step.model.outputs).find((o) => o.name === output.name);
          if (outputToSetValueOn) {
            outputToSetValueOn.value = output.value;
            stepsSlice.caseReducers.deleteLinkedInputsValidationError(
              state,
              {
                type: "updateLinkedStepInputs",
                payload: {
                  stepId: step.model.id,
                  outputId: outputToSetValueOn.id
                }
              });
          }
        });
      }
    },
    deleteLinkedInputsValidationError: (state, action: PayloadAction<{
      stepId: StepModel["id"],
      outputId: StepOutputModel["id"]
    }>) => {
      const {stepId, outputId} = action.payload;
      const step = state[stepId];
      if (step) {
        const flowId = step.model.flowId;
        Object.values(state)
          .filter((step) => step.model.flowId === flowId)
          .forEach((step) => {
            Object.values(step.model.inputs)
              .filter((input) => input.source.type === "output" && input.source.stepId === stepId && input.source.outputId === outputId)
              .forEach((input) => {
                delete input.validationError
              });
          });
      }
    },
    resetStepOutputValues: (state, action: PayloadAction<StepModel["id"]>) => {
      const step = state[action.payload];
      if (step) {
        Object.values(step.model.outputs).forEach((output) => {
          output.value = undefined;
        });
      }
    },
    deleteStepOutput: (state, action: PayloadAction<{
      stepId: StepModel["id"],
      outputId: StepOutputModel["id"]
    }>) => {
      const step = state[action.payload.stepId];
      if (step) {
        const output = step.model.outputs[action.payload.outputId];
        if (output) {
          if (output.isBoundToCode) {
            step.model.implementation = undefined;
          }
          unlinkInputsFromDeletedStepOrOutput({
            state,
            flowId: step.model.flowId,
            stepId: step.model.id,
            outputId: action.payload.outputId
          });
          delete step.model.outputs[action.payload.outputId];
        }
      }
    },
    updateStepImplementation: (state, action: PayloadAction<{
      stepId: StepModel["id"],
      implementation: NonNullable<StepModel["implementation"]>,
      inputs: GeneratedInputModel[],
      outputs: GeneratedOutputModel[]
    }>) => {
      const {stepId, inputs, outputs} = action.payload;
      const step = state[stepId];
      if (step) {
        step.model.implementation = action.payload.implementation;
        // Unbind all inputs and outputs from code
        Object.values(step.model.inputs).forEach((i) => i.isBoundToCode = false);
        Object.values(step.model.outputs).forEach((o) => o.isBoundToCode = false);
        // update ports
        stepsSlice.caseReducers.updateStepPorts(state, {
          type: "updateStepPorts",
          payload: {
            stepId,
            inputs: inputs.map((input) => ({...input, isBoundToCode: true})),
            outputs: outputs.map((output) => ({...output, isBoundToCode: true}))
          }
        });
        // update parametersHash
        step.model.implementation.parametersHash = calculateImplementationParametersHash(step.model)
      }
    },
    updateStepPorts: (state, action: PayloadAction<{
      stepId: StepModel["id"],
      inputs: (GeneratedInputModel & Pick<StepInputModel, "isBoundToCode">)[],
      outputs: (GeneratedOutputModel & Pick<StepOutputModel, "isBoundToCode">)[]
    }>) => {
      const step = state[action.payload.stepId];
      if (step) {
        action.payload.inputs.forEach((input) => {
          const inputId = Object.values(step.model.inputs)
            // we try to match ports by description since
            // description is the identifier the user can see/define on the UI,
            // name is generated by the code generator and only used in the code
            .find((i) => arePortsTheSame(i, input))?.id;
          const inputToUpdate = inputId && step.model.inputs[inputId];
          if (inputToUpdate) {
            step.model.inputs[inputId] = {
              ...inputToUpdate,
              ...input,
            }
          } else {
            const newInputId = createNewId();
            step.model.inputs[newInputId] = {
              ...input,
              id: newInputId,
              source: {
                type: "value",
                value: undefined
              }
            };
          }
        });
        action.payload.outputs.forEach((output) => {
          const outputId = Object.values(step.model.outputs)
            // we try to match ports by description since
            // description is the identifier the user can see/define on the UI,
            // name is generated by the code generator and only used in the code
            .find((o) => arePortsTheSame(o, output))?.id;
          const outputToUpdate = outputId && step.model.outputs[outputId];
          if (outputToUpdate) {
            step.model.outputs[outputId] = {
              ...outputToUpdate,
              ...output
            }
          } else {
            const newOutputId = createNewId();
            step.model.outputs[newOutputId] = {
              ...output,
              id: newOutputId,
              value: undefined
            };
          }
        });
      }
    },
    unlinkStepInputsFromEnvironmentVariable: (state, action: PayloadAction<{
      flowId: FlowModel["id"],
      variableName: string
    }>) => {
      Object.values(state)
        .filter((step) => step.model.flowId === action.payload.flowId)
        .forEach((step) => {
          Object.values(step.model.inputs).forEach((input) => {
            const {source} = input;
            if (source.type === "env" && source.name === action.payload.variableName) {
              input.source = {type: "value", value: undefined};
            }
          });
        });
    },
    updateEnvironmentVariableNameChangeOnStepInputs: (state, action: PayloadAction<{
      flowId: FlowModel["id"],
      oldVariableName: string,
      newVariableName: string
    }>) => {
      Object.values(state).filter((step) =>
        (step.model.flowId === action.payload.flowId)
      ).forEach((step) => {
        Object.values(step.model.inputs).forEach((input) => {
          const {source} = input;
          if (source.type === "env" && source.name === action.payload.oldVariableName) {
            source.name = action.payload.newVariableName;
          }
        });
      });
    },
    resetInputValidationErrorUponEnvironmentVariableValueChange: (state, action: PayloadAction<{
      flowId: FlowModel["id"],
      variableName: string,
    }>) => {
      Object.values(state)
        .filter((step) =>
          (step.model.flowId === action.payload.flowId)
        )
        .forEach((step) => {
          Object.values(step.model.inputs)
            .forEach((input) => {
              const {source} = input;
              if (source.type === "env" && source.name === action.payload.variableName) {
                input.validationError = undefined;
              }
            });
        });
    }

  }
});

export const {
  createStep,
  updateStep,
  updateStepById,
  deleteStepsByFlowId,
  insertStep,
  deleteStep,
  resetStepExecutionStatus,
  addStepInput,
  updateStepInput,
  deleteStepInput,
  addStepOutput,
  updateStepOutput,
  updateStepOutputValues,
  resetStepOutputValues,
  deleteStepOutput,
  updateStepImplementation,
  updateStepPorts,
  unlinkStepInputsFromEnvironmentVariable,
  updateEnvironmentVariableNameChangeOnStepInputs,
  resetInputValidationErrorUponEnvironmentVariableValueChange
} = stepsSlice.actions;

export const stepsSliceRawReducers = stepsSlice.caseReducers;
export const selectSteps = (state: RootState) => state.steps;
export const selectStep = (id?: Id) => (state: RootState) => id && state.steps[id];
export const makeSelectStepsByFlowId = () => createSelector(
  [
    selectSteps,
    (_: any, flowId: FlowModel["id"] | undefined) => flowId
  ],
  (steps, flowId) =>
    flowId
      ? Object.values(steps)
        .filter(step => step.model.flowId === flowId)
        .sort((a, b) => Math.sign(a.model.index - b.model.index))
      : undefined
);
export const makeSelectStepsByGroupId = () => createSelector(
  [
    selectSteps,
    (_: any, groupId: Id | undefined) => groupId
  ],
  (steps, groupId) => groupId
    ? Object.values(steps)
      .filter(step => step.model.groupId === groupId)
      .sort((a, b) => Math.sign(a.model.index - b.model.index))
    : undefined
);
export const stepsReducer = stepsSlice.reducer;
