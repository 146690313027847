import {ThunkAction} from "@reduxjs/toolkit";
import {batch} from "react-redux";
import {deleteStepsByFlowId} from "../../../stepsSlice";
import editorApi from "../../../editorApi";
import {RootState} from "../../../store";
import {FlowState} from "../../../types/FlowState";
import {updateFlow, updateFlowById} from "../../../flowsSlice";
import {isUuid} from "../../../../utils/types/Uuid";
import {PendingRequestId} from "../../../../utils/types/RequestId";


export const generateStepsAsyncAction = (
  flow: FlowState,
  generateStepsAsyncTrigger: ReturnType<typeof editorApi["useGenerateStepsAsyncMutation"]>[0],
  errorCallback: (title: string, errorMessage?: string) => void = () => {
  }
): ThunkAction<void, RootState, any, any> => (dispatch) => {
  const {model: {id: flowId, description}} = flow;

  const {hints, ...flowModelWithoutHints} = flow.model;
  //reset first
  batch(() => {
    dispatch(deleteStepsByFlowId(flowId));
    dispatch(updateFlow({
        ...flow,
        model: flowModelWithoutHints,
        generateStepsRequestId: PendingRequestId
      }
    ));
  });

  generateStepsAsyncTrigger({instruction: description})
    .then((response) => {
      if ("data" in response) {
        const queryId = response.data.queryId;
        if (isUuid(queryId)) {
          dispatch(updateFlowById({
            flowId: flowId,
            update: {
              generateStepsRequestId: queryId
            }
          }));
        } else {
          console.error("Received invalid queryId in response of step generation request");
        }
      } else {
        errorCallback(
          "Step generation failed unexpectedly!",
          "Raw message:\n" + JSON.stringify(response.error, null, 2)
        );
      }
    });
};
