import {FlowModel} from "../../../types/FlowModel";
import Container from "../../../../common/components/container/Container";
import {ChatMessageActor} from "../ai-chat/types/ChatMessageActor";
import AiChat from "../ai-chat/AiChat";
import Button, {ButtonType} from "../../../../common/components/button/Button";
import {UiColor} from "../../../../utils/constants/UiColor";
import {useEffect, useState} from "react";
import {classNames} from "../../../../utils/classNames";
import {
  useGenerateFlowBreakdownMutation,
  useGetFlowConversationQuery,
  usePostFlowMessageMutation
} from "../../../mentorApi";
import {ChatMessage} from "../ai-chat/types/ChatMessage";
import {skipToken} from "@reduxjs/toolkit/query";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {createStep, deleteStepsByFlowId} from "../../../stepsSlice";
import {mapApiStepToStepState} from "../../../pages/flow-editor-page/utils/mapApiStepToStepState";
import {selectFlow, updateFlowById} from "../../../flowsSlice";
import {FlowConversationMessageResponse, FlowConversationSummaryResponse} from "@cranq-gpt-lowcode/contracts";
import {StepState} from "../../../types/StepState";
import ReactGA from "react-ga4";
import {BreakdownConversation} from "../types/BreakdownConversation";
import {BreakdownConversationSummary} from "../types/BreakdownConversationSummary";
import {getFormattedError} from "../utils/getFormattedError";
import BreakdownChatSummary from "./BreakdownChatSummary";
import {useUpdateFlowSnapshot} from "../utils/useUpdateFlowSnapshot";

/**
 * Helper function until the summary messages will be marked with a flag
 */
const isSummaryMessage = (message: string) => {
  const score = (message.includes("[Summary]") ? 1 : 0)
    + (message.includes("[Explanation]") ? 1 : 0)
    + (message.includes("[Constraints]") ? 1 : 0)
    + (message.includes("[Breakdown]") ? 1 : 0);
  return score >= 3;
};

export type BreakdownChatProps = {
  flowId: FlowModel["id"];
  onChatCompleted?: () => void;
};

/**
 * This component is responsible for:
 *  - manage the state of the chat
 *  - provide data to AiChat
 */
const BreakdownChat = (
  {
    flowId,
    onChatCompleted
  }: BreakdownChatProps) => {
  const dispatch = useAppDispatch();
  const flow = useAppSelector(selectFlow(flowId));
  if (!flow) {
    throw new Error(`Flow with id ${flowId} not found`);
  }

  // local state init
  const [showSummary, setShowSummary] = useState(false);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [conversation, setConversation] = useState<BreakdownConversation>({
    statusMessage: "Loading...",
    lastUpdated: -1
  });
  const [initialized, setInitialized] = useState(false);
  const [completed, setCompleted] = useState(false);

  // update flow snapshot upon initialization
  const updateFlowSnapshotWithReason = useUpdateFlowSnapshot(flowId);
  useEffect(() => {
    if (!initialized) {
      setConversation((conversation) => ({
        ...conversation,
        statusMessage: "Updating workflow data...",
        lastUpdated: -1
      }));
      updateFlowSnapshotWithReason("conversation_start")
        .then((result) => {
          if ("error" in result && result.error) {
            console.error(`Failed to update flow snapshot: ${result.error}`);
            setConversation((conversation) => ({
              ...conversation,
              statusMessage: "Failed to update workflow data, chat cannot be initialized. Please reload the page and retry."
            }));
          } else {
            setInitialized(true);
          }
        });
    }
  }, [initialized, updateFlowSnapshotWithReason])

  // update flow snapshot upon completion
  useEffect(() => {
    if (completed) {
      setConversation((conversation) => ({
        ...conversation,
        statusMessage: "Updating workflow data...",
        lastUpdated: -1
      }));
      updateFlowSnapshotWithReason("summary_accepted")
        .then((result) => {
          if ("error" in result && result.error) {
            console.error(`Failed to update flow snapshot: ${result.error}`);
            setConversation((conversation) => ({
              ...conversation,
              statusMessage: "Failed to update workflow data, chat cannot be initialized. Please reload the page and retry."
            }));
          }
          // independently of the eventual error we consider the chat as completed
          onChatCompleted && onChatCompleted()
        });
    }
  }, [completed, onChatCompleted, updateFlowSnapshotWithReason])


  const shouldSyncConversation = initialized && conversation.lastUpdated < (flow.lastChatUpdate ?? 0);
  // api hooks
  const {
    currentData: conversationData,
    isFetching: conversationIsFetching,
    isSuccess: conversationIsSuccess,
    isError: conversationIsError,
    error: conversationError,
  } = useGetFlowConversationQuery(shouldSyncConversation ? {flowId} : skipToken);
  const [generateBreakdown] = useGenerateFlowBreakdownMutation();
  const [postMessage] = usePostFlowMessageMutation();


  // load chat messages from api
  useEffect(() => {
    if (shouldSyncConversation) {
      if (conversationIsSuccess && conversationData) {
        const messages: ChatMessage[] = (conversationData?.messages ?? [])
          .map((message) => ({
            message: message.message,
            role: message.role === "assistant" ? ChatMessageActor.Assitant : ChatMessageActor.User,
            ...(isSummaryMessage(message.message) ? {isSummary: true} : {})
          }));
        setConversation((conversation) => ({
          ...conversation,
          statusMessage: undefined,
          messages,
          ...(conversationData?.summary ? {summary: conversationData.summary} : {}),
          lastUpdated: flow.lastChatUpdate ?? -1
        }));
      }
      if (conversationIsError) {
        console.error("Failed to load conversation", conversationError);
        setConversation((conversation) => ({
          ...conversation,
          statusMessage: "Failed to load conversation"
        }));
      }
    }
  }, [conversationData, conversationIsSuccess, shouldSyncConversation, conversationIsError, conversationError, flow.lastChatUpdate, conversationIsFetching]);

  const handleSendMessage = async (message: string) => {
    setWaitingForResponse(true);
    setConversation((conversation) => ({
      ...conversation,
      messages: [
        ...(conversation.messages ?? []),
        {message, role: ChatMessageActor.User},
        {message: "...", role: ChatMessageActor.Assitant}
      ]
    }));
    const onEvent = (chunk: FlowConversationMessageResponse) => {
      setConversation((conversation) => ({
        ...conversation,
        messages: [
          ...(conversation.messages ?? []).slice(0, -1),
          {message: `${chunk.message}...`, role: ChatMessageActor.Assitant}
        ]
      }));
    }
    return postMessage({flowId, message, onEvent})
      .then((response) => {
        setWaitingForResponse(false);
        if ("error" in response) {
          console.error("Failed to send message", response.error);
          setConversation((conversation) => ({
            ...conversation,
            messages: [
              ...(conversation.messages ?? []).slice(0, -2)
            ]
          }));
          dispatch(updateFlowById({flowId, update: {lastChatUpdate: Date.now()}}));
          return false;
        }
        dispatch(updateFlowById({flowId, update: {lastChatUpdate: Date.now()}}));
        return true;
      });
  }

  const handleSummaryRequest = () => {
    setShowSummary(true);
    if (!isLastMessageSummary) { // don't generate summary if no message was sent since the last generation
      ReactGA.event({
        category: "chat",
        action: "REQUEST_BREAKDOWN_CHAT_SUMMARY",
        label: `Flow: ${flowId}`,
      });
      setWaitingForResponse(true);
      setConversation((conversation) => ({
        ...conversation,
        summary: {
          title: "",
          description: "",
          constraints: "",
          breakdown: []
        },
      }));
      const onEvent = (chunk: FlowConversationSummaryResponse) => {
        setConversation((conversation) => ({
          ...conversation,
          summary: {
            title: chunk.title,
            description: chunk.description,
            constraints: chunk.constraints,
            breakdown: chunk.breakdown
          },
        }));
      }
      generateBreakdown({flowId, onEvent})
        .then((response) => {
          if ("error" in response) {
            console.error("Failed to generate breakdown", response.error);
            const reason = getFormattedError(response.error);
            setConversation((conversation) => ({
              ...conversation,
              statusMessage: "Failed to generate summary",
              error: new Error(reason)
            }));
          }
          dispatch(updateFlowById({flowId, update: {lastChatUpdate: Date.now()}}));
          setWaitingForResponse(false);
          return;
        });
    }
  }

  const convertBreakdownIntoStepStates = (breakdown: BreakdownConversationSummary["breakdown"]): StepState[] | undefined => {
    if (breakdown) {
      return mapApiStepToStepState(breakdown, flow, []);
    }
  }
  const handleCreateWorkflow = () => {
    ReactGA.event({
      category: "chat",
      action: "REQUEST_BREAKDOWN_CHAT_WORKFLOW",
      label: `Flow: ${flowId}`,
    });

    const {summary} = conversation;
    if (summary) {
      const title = summary.title.trim();
      const description = `${summary.description.trim()}\n\nConstraints:\n${summary.constraints.trim()}`;
      const steps = convertBreakdownIntoStepStates(summary.breakdown);
      if (steps) {
        dispatch(updateFlowById({
          flowId,
          update: {
            model: {
              title,
              description
            }
          }
        }));
        dispatch(deleteStepsByFlowId(flowId));
        steps.forEach((step) => {
          dispatch(createStep(step));
        });
        dispatch(updateFlowById({flowId, update: {lastChatUpdate: undefined}}));
        setCompleted(true);
      }
    }
  };

  // derived variables
  const parsedSteps = convertBreakdownIntoStepStates(conversation.summary?.breakdown ?? []);
  const initialChatMessage = conversation.messages && flow.model.description
    ? `Hi,\nI'd like to create a workflow accomplishing the following task:\n${flow.model.description}`
    : "";
  const isLastMessageSummary = (conversation.messages ?? []).slice(-1)[0]?.isSummary;
  const isProceedEnabled = !waitingForResponse
    && !conversationIsFetching
    && (conversation.messages ?? [])
      .filter((message) => message.role === ChatMessageActor.User)
      .length > 0;


  return (
    <div className={"h-full w-screen max-w-screen-md flex flex-row overflow-hidden relative"}>
      <div className={classNames(`
        h-full w-full overflow-hidden 
        flex flex-col gap-4
        absolute top-0 ${showSummary ? "-left-full" : "left-0"}
        transition-all duration-500
      `)}>
        {conversationIsError
          ? <div>Failed to load chat, please try again! <pre>{getFormattedError(conversationError)}</pre></div>
          : (
            <>
              <AiChat statusMessage={conversation.statusMessage}
                      messages={conversation.messages}
                      initialMessage={initialChatMessage}
                      waitingForResponse={waitingForResponse}
                      onMessageSend={handleSendMessage}/>
              <Container shrinkOrGrow={"no-shrink"}>
                <div className={`flex justify-center items-center pt-4 border-t-2 ${UiColor.BorderColor.GOLD}`}>
                  <Button title={`${isLastMessageSummary ? "View Summary >>" : "Proceed >>"}`}
                          disabled={!isProceedEnabled} onClick={handleSummaryRequest}/>
                </div>
              </Container>
            </>
          )}
      </div>
      <div className={classNames(`
        h-full w-full overflow-hidden
        flex flex-col gap-4
        absolute top-0 ${showSummary ? "left-0" : "left-full"}
        transition-all duration-500
      `)}>{conversation.error
        ? <div>Error while trying the generate summary: <pre>{conversation.error.message}</pre></div>
        : (
          <>
            <BreakdownChatSummary
              summary={conversation.summary}
              parsedSteps={parsedSteps}
            />
            <Container shrinkOrGrow={"no-shrink"}>
              <div className={`flex justify-center items-center gap-4 pt-4 border-t-2 ${UiColor.BorderColor.GOLD}`}>
                <Button title={"<< I'd like to refine, let's continue discussion"} disabled={waitingForResponse}
                        type={ButtonType.SECONDARY}
                        shadowTone={UiColor.ShadowColor.BLACK_MEDIUM}
                        onClick={() => setShowSummary(false)}/>
                <Button title={"Looks fine, let's create Workflow!"}
                        type={ButtonType.PRIMARY}
                        shadowTone={UiColor.ShadowColor.BLACK_MEDIUM}
                        disabled={waitingForResponse}
                        onClick={handleCreateWorkflow}/>
              </div>
            </Container>
          </>
        )}
      </div>
    </div>
  );
};

export default BreakdownChat;
