import {ReactNode} from "react";
import Button, {ButtonProps} from "../button/Button";
import Editor, {EditorMode} from "../editor/Editor"
import Container from "../container/Container";
import Modal from "../modal/Modal";
import {InlineIconName} from "../inline-icon/InlineIconName";
import {UiColor} from "../../../utils/constants/UiColor";

export type ModalEditorProps = {
  content: string,
  title: string,
  mode?: EditorMode,
  readOnly?: boolean,
  controlButtonList?: ButtonProps[]
  onCloseClick?: () => void,
  onChange?: (value: string) => void
}
const ModalEditor = (
  {
    content,
    title,
    mode = EditorMode.JSON,
    controlButtonList,
    readOnly,
    onCloseClick,
    onChange
  }: ModalEditorProps) => {

  const headerProps = {
    title,
    rightSideButtonListProps: [
      {
        iconName: InlineIconName.CLOSE,
        onClick: () => onCloseClick && onCloseClick(),
      }
    ]
  };

  const Buttons = controlButtonList
    && controlButtonList.map((buttonProps, index) => {
      return <Button key={index} {...buttonProps} />;
    });

  const editor: ReactNode = (
    <div className="w-5/12 h-fit justify-self-center self-center">
      <Container headerProps={headerProps} backgroundTone={UiColor.BackgroundColor.WHITE}>
        <section className="flex flex-row gap-4">
          <Editor
            content={content}
            readOnly={readOnly}
            mode={mode}
            onChange={onChange}/>
        </section>
        {controlButtonList &&
          <div className="flex flex-row gap-1 justify-end">{Buttons}</div>}
      </Container>
    </div>
  );


  return (
    <Modal onOutsideClick={onCloseClick}>
      {editor}
    </Modal>
  );
};

export default ModalEditor;

