import {InputWithActionButtons} from "../../../common/components/input-with-action-buttons/InputWithActionButtons";
import {safeJsonStringify} from "../../utils/safeJsonStringify";
import {copyToClipboard} from "../../utils/copyToClipboard";
import DeleteItemActionButton from "../../../common/components/delete-item-action-button/DeleteItemActionButton";
import React, {useEffect, useRef, useState} from "react";
import {GlobalParametersBranchKey} from "./EnvironmentEditor";
import {Variable} from "../../types/Variable";
import ModalEditor from "../../../common/components/modal-editor/ModalEditor";
import {ModalValueAndInstructionEditor} from "../modal-value-and-instruction-editor/ModalValueAndInstructionEditor";
import TreeSelector, {Branches, TreeSelectorProps} from "../../../common/components/tree-selector/TreeSelector";
import {useModalEditor} from "../../../common/components/modal-editor/hook/useModalEditor";
import {useToast} from "../../../common/components/toast/hook/useToast";
import {safeJsonParse} from "../../utils/safeJsonParse";
import {
  GlobalParameterReferencePrefix,
  GlobalParameterReferenceResolver
} from "./utils/createGlobalParameterReferenceResolver";
import {isLinkedToGlobalParameter} from "./utils/isLinkedToGlobalParameter";

type EnvironmentVariableEditorProps = {
  variable: Variable,
  linkables: Branches,
  globalParameterReferenceResolver: GlobalParameterReferenceResolver
  //
  onChange: (event: { variable: Variable }) => void,
  onDelete: () => void,
}
export const EnvironmentVariableEditor = (
  {
    variable,
    linkables,
    globalParameterReferenceResolver,
    //
    onChange,
    onDelete,
  }: EnvironmentVariableEditorProps
) => {
  const {key, value, instruction} = variable;

  const {openModalEditor, modalEditorProps} = useModalEditor();
  const {showToast} = useToast();
  const [showModalStepInputValueEditor, setShowModalStepInputValueEditor] = useState(false);
  const [treeSelectorIsOpen, setTreeSelectorIsOpen] = useState(false);
  const treeSelectorRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (treeSelectorRef.current && !treeSelectorRef.current.contains(event.target as Node)) {
        setTreeSelectorIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [setTreeSelectorIsOpen, treeSelectorRef]);


  const handleEditParameterKey = () => {
    openModalEditor({
      title: "Edit parameter description",
      content: variable.key,
      handleOnClose: (newKey) => {
        onChange && onChange({
          variable: {
            ...variable,
            key: newKey
          }
        });
      }
    });
  };

  const handleEditParameterValue = () => {
    setShowModalStepInputValueEditor(true)
  };


  const handleLinkClick = () => {
    setTreeSelectorIsOpen((prev) => !prev);
  };

  const handleUnlinkClick = () => {
    onChange && onChange({
      variable: {
        ...variable,
        value: undefined
      }
    })
  };

  const handleBranchClick: TreeSelectorProps["onBranchClick"] = (
    {
      branchKeys,
      leafKey
    }) => {
    const topBranchKey = branchKeys[0];
    if (topBranchKey === GlobalParametersBranchKey) {
      onChange && onChange({
        variable: {
          ...variable,
          value: `${GlobalParameterReferencePrefix}${leafKey}`
        }
      });
    } else {
      throw new Error(`Unexpected branch key: ${topBranchKey}`);
    }
    setTreeSelectorIsOpen(false);
  };

  const isVariableLinkedToGlobalParameter = isLinkedToGlobalParameter(variable);
  const resolvedValue = globalParameterReferenceResolver(value);
  const resolvedValueAsString = safeJsonStringify(resolvedValue);
  return (
    <div className={"contents group relative"}>
      <InputWithActionButtons
        name={"description"}
        value={key}
        placeholder="Describe the parameter"
        onEditAction={() => handleEditParameterKey()}
        onBlur={(newKey) => onChange({variable: {...variable, key: newKey}})}
      />
      <div className={"relative"}>
        <InputWithActionButtons
          name={"value"}
          size={"full"}
          value={resolvedValueAsString}
          placeholder={isVariableLinkedToGlobalParameter
            ? `Linked to global variable <${variable.value.replace(GlobalParameterReferencePrefix, "")}>`
            : instruction ?? "Type parameter value"
          }
          title={isVariableLinkedToGlobalParameter
            ? `Linked to global variable <${variable.value.replace(GlobalParameterReferencePrefix, "")}>`
            : (instruction ?? "")
          }
          readonly={isVariableLinkedToGlobalParameter}
          onBlur={isVariableLinkedToGlobalParameter ? undefined : (changedValueAsString) => onChange && onChange({
            variable: {
              ...variable,
              value: safeJsonParse(changedValueAsString)
            }
          })}
          editActionTooltip={"Open value & instruction editor"}
          onEditAction={isVariableLinkedToGlobalParameter ? undefined : () => handleEditParameterValue()}
          onLinkAction={isVariableLinkedToGlobalParameter
            ? undefined
            : () => handleLinkClick()}
          onUnlinkAction={isVariableLinkedToGlobalParameter
            ? () => handleUnlinkClick()
            : undefined}
          onCopyAction={() => copyToClipboard(resolvedValueAsString, showToast)}
        />
        <DeleteItemActionButton onClick={onDelete}/>
        {
          treeSelectorIsOpen
            ? <div className="absolute top-2 right-2 z-10"
                   ref={treeSelectorRef}>
              <TreeSelector
                tree={linkables}
                onBranchClick={handleBranchClick}/>
            </div>
            : null
        }
      </div>
      {modalEditorProps && <ModalEditor {...modalEditorProps}/>}
      {showModalStepInputValueEditor
        && <ModalValueAndInstructionEditor
          title={variable.key}
          value={safeJsonStringify(variable.value)}
          instruction={variable.instruction ?? ""}
          onValueChange={(changedValueAsString) => onChange && onChange({
            variable: {
              ...variable,
              value: safeJsonParse(changedValueAsString)
            }
          })}
          onInstructionChange={(newInstruction) => onChange && onChange({
            variable: {
              ...variable,
              instruction: newInstruction.trim() ? newInstruction.trim() : undefined
            }
          })}
          onCloseClick={() => setShowModalStepInputValueEditor(false)}
        />}
    </div>
  );
}
