// see: https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query/react";
import {SerializedError} from "@reduxjs/toolkit";

export const getFormattedError = (error: FetchBaseQueryError | SerializedError) => {
  if ("status" in error) {
    return 'error' in error ? error.error : JSON.stringify(error.data)
  } else {
    return error.message;
  }
};
