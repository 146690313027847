import {StepModel} from "../../types/StepModel";
import React, {useState} from "react";
import {decodeStep} from "../../utils/sharing/decodeStep";
import Editor, {EditorMode} from "../../../common/components/editor/Editor";
import Button, {ButtonType} from "../../../common/components/button/Button";
import Notification from "../../../common/components/notification/Notification";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import {GroupExportModel, isGroupExportModel} from "../../types/GroupExportModel";
import {decodeGroup} from "../../utils/sharing/decodeGroup";

type ImportEntityEditorProps = {
  allowImportGroup?: boolean,
  onCancel?: () => void,
  onImport?: (entityToImport: StepModel | GroupExportModel) => void,

}
export const ImportEntityEditor = (
  {
    allowImportGroup = false,
    onCancel,
    onImport,
  }: ImportEntityEditorProps
) => {
  const [validDecodedEntity, setValidDecodedEntity] = useState<StepModel | GroupExportModel | undefined>();
  const [editorContent, setEditorContent] = useState<string>("");
  const validateImport = (value: string) => {
    const decodedStep = decodeStep(value);
    const decodedGroupedSteps = decodeGroup(value);
    if (decodedStep) {
      setValidDecodedEntity(decodedStep);
      return;
    }
    if (decodedGroupedSteps) {
      setValidDecodedEntity(decodedGroupedSteps);
      return;
    }
    setValidDecodedEntity(undefined);
  }

  const handleOnChange = (value: string) => {
    setEditorContent(value);
    validateImport(value);
  }

  const getValidDecodedEntityType = (validDecodedEntity: StepModel | GroupExportModel): "step" | "group" => {
    if (isGroupExportModel(validDecodedEntity)) {
      return "group";
    }
    return "step";
  }

  const validDecodedEntityType = validDecodedEntity && getValidDecodedEntityType(validDecodedEntity);
  return (
    <section className="container min-w-[80ch] h-full flex flex-col justify-between text-sm">
      <Editor
        mode={EditorMode.TEXT}
        readOnly={false}
        onChange={handleOnChange}
        content={editorContent}
        placeholder={"Paste exported step or group here"}
        lines={20}
      />
      <div className={"py-4"}>
        {editorContent.trim() && !validDecodedEntity && <Notification
          text={"This is not a valid step or group export format!"}
          secondaryText={"Use the Share button on the Step or Group page to set the correct export format to the clipboard."}
          type={MESSAGE_TYPE.ERROR}/>}
        {validDecodedEntity && validDecodedEntityType === "group" && !allowImportGroup && <Notification
          text={"Importing a group to this position is not allowed!"}
          secondaryText={"Groups cannot be nested."}
          type={MESSAGE_TYPE.ERROR}/>}
      </div>
      <div className={"flex justify-between py-4"}>
        <Button title={"Cancel"} type={ButtonType.SECONDARY} onClick={onCancel}/>
        <Button title={`Import${validDecodedEntity ? ` ${validDecodedEntityType}` : ""}`}
                type={ButtonType.PRIMARY}
                disabled={!validDecodedEntity || (validDecodedEntityType === "group" && !allowImportGroup)}
                onClick={() => validDecodedEntity && onImport && onImport(validDecodedEntity)}/>
      </div>
    </section>
  )
}
