import {Draft} from "@reduxjs/toolkit";
import {FlowModel} from "../types/FlowModel";
import {StepModel} from "../types/StepModel";
import {StepOutputModel} from "../types/StepOutputModel";
import {StepsState} from "../stepsSlice";

type UnlinkInputsFromDeletedStepOrOutputParams = {
  state: Draft<StepsState>,
  flowId: FlowModel["id"],
  stepId: StepModel["id"],
  outputId?: StepOutputModel["id"]
}
export const unlinkInputsFromDeletedStepOrOutput = (
  {
    state,
    flowId,
    stepId,
    outputId
  }: UnlinkInputsFromDeletedStepOrOutputParams
): void => {
  Object.values(state)
    .filter(step => step.model.flowId === flowId)
    .forEach(step => {
      step.model.inputs = Object.fromEntries(
        Object.entries(step.model.inputs)
          .map(function ([inputId, input]) {
            return [
              inputId,
              (input.source.type === "output"
                && stepId && input.source.stepId === stepId
                && (outputId ? input.source.outputId === outputId : true))
                ? {
                  ...input,
                  source: {
                    type: "value",
                    value: undefined
                  }
                }
                : input
            ];
          }));
    });
};
