import Layout from "../../../common/components/layout/Layout";
import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import {ResetApiState} from "./ResetApiState";
import {ManageFeatureFlags} from "./ManageFeatureFlags";
import {RecoverFlowFromExecutorTask} from "./RecoverFlowFromExecutorTask";

const ServicePage = () => {
  return (
    <Layout
      sideBarContent={<Sidebar showFlowList={false}/>}
      leftPanelContent={(
        <div className={"w-full flex flex-col gap-2 items-center"}>
          <ManageFeatureFlags/>
          <ResetApiState/>
        </div>
      )}
      rightPanelContent={(
        <div className={"w-full flex flex-col gap-2 items-center"}>
          <RecoverFlowFromExecutorTask/>
        </div>
      )}
    />
  );
};

export default ServicePage;
