"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapGenerateExecutionErrorHintsOutputToResponse = void 0;
const GenerateExecutionErrorHintsGeneratorOutput_1 = require("../outputs/GenerateExecutionErrorHintsGeneratorOutput");
function mapGenerateExecutionErrorHintsOutputToResponse(request, output) {
    switch (output.root_cause) {
        case GenerateExecutionErrorHintsGeneratorOutput_1.ExecutionErrorCause.CODING_ERROR:
            return {
                hints: {
                    category: "BAD_CODE",
                    fixedTaskDescription: output.fixed_task_description,
                    analysis: output.summary
                }
            };
        case GenerateExecutionErrorHintsGeneratorOutput_1.ExecutionErrorCause.INPUT_ERROR:
            return {
                hints: {
                    category: "BAD_INPUT",
                    exampleInputs: output.example_inputs,
                    invalidInputs: output.invalid_inputs,
                    summary: output.summary
                }
            };
    }
}
exports.mapGenerateExecutionErrorHintsOutputToResponse = mapGenerateExecutionErrorHintsOutputToResponse;
