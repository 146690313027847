import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../../../store";
import {StepState} from "../../../types/StepState";
import {mapApiStepToStepState} from "../utils/mapApiStepToStepState";
import {batch} from "react-redux";
import {createStep} from "../../../stepsSlice";
import {Flow} from "@cranq-gpt-lowcode/contracts";
import {FlowState} from "../../../types/FlowState";
import {EnvironmentState} from "../../../types/EnvironmentState";

export const processGenerateStepsResult = (
  flow: FlowState | undefined,
  environment: EnvironmentState | undefined,
  result: Flow
): ThunkAction<string | void, RootState, undefined, AnyAction> => (
  dispatch
): string | void => {
  if (flow) {
    const generatedSteps: StepState[] = mapApiStepToStepState(result, flow, environment?.model.variables);
    batch(() => {
      // create the steps
      generatedSteps
        .forEach((step) => dispatch(createStep(step)));
      // TODO: set the first step as selected
    });
  }
};
