import {StepState} from "../../../types/StepState";
import {StepModel} from "../../../types/StepModel";
import {GroupModel} from "../../../types/GroupModel";

export const moveFocusToAfterStepDeletion = (
  steps: StepState[],
  deletedStepId: StepModel["id"],
  focusedStepId: StepModel["id"] | undefined,
  focusedGroupId: GroupModel["id"] | undefined
): {
  stepId: StepModel["id"] | undefined,
  groupId: GroupModel["id"] | undefined
} => {
  const fallback = {
    stepId: focusedStepId,
    groupId: focusedGroupId
  }
  if (focusedStepId !== deletedStepId) {
    return fallback
  }
  const stepToDelete = steps.find((step) => step.model.id === deletedStepId);
  if (!stepToDelete) {
    return fallback;
  }
  const remainingSteps = steps.filter((step) => step.model.id !== deletedStepId);
  const nextStep = steps.find((step) => step.model.index > stepToDelete.model.index) ?? remainingSteps[remainingSteps.length - 1];
  const previousStep = steps.reverse().find((step) => step.model.index < stepToDelete.model.index) ?? remainingSteps[0];
  if (stepToDelete.model.groupId) {
    const nextStepInGroup = nextStep?.model.groupId === stepToDelete.model.groupId ? nextStep : undefined;
    const previousStepInGroup = previousStep?.model.groupId === stepToDelete.model.groupId ? previousStep : undefined;
    return ({
      stepId: nextStepInGroup ? nextStepInGroup.model.id : (
        previousStepInGroup ? previousStepInGroup.model.id : (
          nextStep ? nextStep.model.id : (
            previousStep ? previousStep?.model.id : undefined
          ))),
      groupId: undefined
    });
  }
  return ({
    stepId: nextStep?.model.id ?? previousStep?.model.id,
    groupId: undefined
  });
}
