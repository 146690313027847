import {AuthContext} from "../auth-context-provider/AuthContextProvider";
import {UiColor} from "../../../utils/constants/UiColor";
import React, {useContext} from "react";
import Button from "../../../common/components/button/Button";

import {AppPermissionName} from "../auth-context-provider/types/AppPermissions";
import {AppPermissionType} from "../auth-context-provider/types/AppPermissionType";

type RequestPermissionProps = {
  permissionName: AppPermissionName,
}
const RequestPermission = (
  {
    permissionName,
  }: RequestPermissionProps) => {
  const {permissions, setPermission} = useContext(AuthContext);
  const permissionHumanReadableName = Object.entries(AppPermissionType)
    .find(([key]) => key === permissionName)?.[1];

  return permissions[permissionName]?.granted
    ? null
    : (
      <section
        className={`fixed z-50 top-0 left-0 w-full p-2 flex gap-2 justify-center items-center ${UiColor.BackgroundColor.DARK_GRAY} shadow ${UiColor.ShadowColor.BLACK_MEDIUM}`}>
        <p className={`${UiColor.TextColor.WHITE} text-sm`}>
          (*) I grant permission to CRANQ to {permissionHumanReadableName} according to CRANQ's <a
          href={"https://cranq.ai/privacy"}>Privacy Policy</a>
        </p>
        <div className={"flex gap-2 flex-grow-0 flex-shrink-0"}>
          <Button
            title={"Grant for this session"}
            onClick={() => setPermission?.(permissionName, {granted: true, scope: "SESSION"})}/>
        </div>
      </section>
    );
}
export default RequestPermission;
