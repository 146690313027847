import {FlowModel, FlowModelV0} from "../types/FlowModel";
import {StepModel, StepModelV0} from "../types/StepModel";
import {compactString} from "./compactString";
import {Id} from "../../utils/types/Id";
import {StepInputModel} from "../types/StepInputModel";
import {StepInputModelEnvSource, StepInputModelSource} from "../types/StepInputModelSource";
import {EnvironmentModel} from "../types/EnvironmentModel";
import {objectHash} from "./objectHash";

type HashFunction = (object: any) => string | undefined;

const makeHashEntity = <T extends Object>(
  ignoredProperties: Array<keyof T> = [],
  specialPropertyHasherMap: Map<keyof T, HashFunction> = new Map()
) => (
  entity: T | undefined,
): string | undefined => {
  if (entity === undefined) {
    return undefined;
  }
  const entityHashBase = Object.entries(entity).reduce(
    (acc, [key, value]) => {
      const propertyHashFunction = specialPropertyHasherMap.get(key as keyof T);
      return value === undefined
        ? acc // ignore undefined values as they are equivalent to missing properties
        : {
          ...acc,
          [key]: propertyHashFunction ? propertyHashFunction(value) : value
        }
    },
    {});
  return objectHash(entityHashBase, {
    excludeKeys: (key: string) => ignoredProperties.includes(key as keyof T),
    replacer: (value: any) => {
      if (typeof value === "string") {
        return compactString(value);
      }
      return value;
    }
  });
}

const makeHashRecord = <U extends string, T extends Record<U, any>>(
  itemHasher: HashFunction
): HashFunction => (
  items: T
): string => objectHash(
  Object.entries(items)
    .reduce((acc, [key, value]) => {
      const itemHash = itemHasher(value);
      return {
        ...acc,
        [key]: itemHash
      }
    }, {})
);

const hashStepImplementation = makeHashEntity<NonNullable<StepModel["implementation"]>>(
  ["parametersHash"]
);
/**
 * From hashing point of view we don't distinguish between a static value set directly as input value
 * or by reference to an environment variable.
 * If the directly set value or the referred environment variable value changes, the hash will change.
 * Thus, we convert environment variable references to direct input value before hashing.
 */
const convertInputModelEnvSourceToValueSource = (
  inputSource: StepInputModelSource,
  environmentVariables: EnvironmentModel["variables"],
): Exclude<StepInputModelSource, StepInputModelEnvSource> =>
  inputSource.type === "env"
    ? {
      type: "value",
      value: environmentVariables.find((variable) => variable.key === inputSource.name)?.value
    }
    : inputSource

const hashInputSource = makeHashEntity<StepInputModelSource>();
const hashStepInput = makeHashEntity<StepInputModel>(
  ["isBoundToCode", "validationError"],
  new Map([
    ["source", hashInputSource]
  ])
);
const hashStepOutput = makeHashEntity<NonNullable<StepModel["outputs"][Id]>>(
  ["isBoundToCode", "value"]
);
const hashStepInputs = makeHashRecord<Id, StepModel["inputs"]>(hashStepInput);
const hashStepOutputs = makeHashRecord<Id, StepModel["outputs"]>(hashStepOutput);

export const flowPropertiesToIgnoreInHash: (keyof FlowModel)[] = ["idV0", "hash", "hints", "createdAt", "accessedAt", "updatedAt"];
const hashFlow = makeHashEntity<FlowModel>(
  flowPropertiesToIgnoreInHash
);
const hashStep = makeHashEntity<StepModel>(
  [],
  new Map([
    ["implementation", hashStepImplementation],
    ["inputs", hashStepInputs],
    ["outputs", hashStepOutputs]
  ])
);

export const calculateFlowHash = (
  flow: FlowModel,
  steps: StepModel[],
  environmentVariables: EnvironmentModel["variables"]
): string => {
  const flowHash = hashFlow(flow);
  const stepsWithEnvInputSourceConverted = steps.map((step) => ({
    ...step,
    inputs: Object.entries(step.inputs).reduce((acc, [id, input]) => ({
      ...acc,
      [id]: {
        ...input,
        source: convertInputModelEnvSourceToValueSource(input.source, environmentVariables)
      }
    }), {})
  }));
  const stepsHash = objectHash(stepsWithEnvInputSourceConverted.map(hashStep));
  return objectHash({
    flowHash,
    stepsHash
  });
};

// Temporary solution due to flow ID format change
// used only for change detection of already scheduled flows
// can be deleted after 28 days (~2024-04-30)
export const flowPropertiesToIgnoreInHashV0: (keyof FlowModelV0)[] = ["hash", "hints", "createdAt", "accessedAt", "updatedAt"];
const hashFlowV0 = makeHashEntity<FlowModelV0>(
  flowPropertiesToIgnoreInHashV0
);
const hashStepV0 = makeHashEntity<StepModelV0>(
  [],
  new Map([
    ["implementation", hashStepImplementation],
    ["inputs", hashStepInputs],
    ["outputs", hashStepOutputs]
  ])
);

export const calculateFlowHashV0 = (
  flow: FlowModel,
  steps: StepModel[],
  environmentVariables: EnvironmentModel["variables"]
): string => {
  const {id: _, idV0, userId: __, ...rest} = flow;
  if (!idV0) {
    return calculateFlowHash(flow, steps, environmentVariables);
  }
  const flowV0: FlowModelV0 = {
    ...rest,
    id: idV0
  }
  const flowHash = hashFlowV0(flowV0);
  const stepsV0: StepModelV0[] = steps.map((step) => ({
    ...step,
    flowId: idV0
  }));
  const stepsWithEnvInputSourceConverted = stepsV0.map((step) => ({
    ...step,
    inputs: Object.entries(step.inputs).reduce((acc, [id, input]) => ({
      ...acc,
      [id]: {
        ...input,
        source: convertInputModelEnvSourceToValueSource(input.source, environmentVariables)
      }
    }), {})
  }));
  const stepsHash = objectHash(stepsWithEnvInputSourceConverted.map(hashStepV0));
  return objectHash({
    flowHash,
    stepsHash
  });
};
