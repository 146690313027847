import {StepStatus} from "../../types/StepStatus";
import {classNames} from "../../../utils/classNames";
import {UiColor} from "../../../utils/constants/UiColor";
import {InlineIconName} from "../../../common/components/inline-icon/InlineIconName";
import IconButton from "../../../common/components/icon-button/IconButton";
import {createStatusTag} from "./utils/createStatusTag";

export type TimelineItemProps = {
  id: string;
  index: number | string;
  title: string;
  stepStatus?: keyof typeof StepStatus;
  isFocused?: boolean,
  isMarked?: boolean,
  isMarkable?: boolean,
  isInserted?: boolean,
  isDeleted?: boolean,
  allowDelete?: boolean,
  // events
  onClick?: () => void,
  onDelete?: () => void,
  onMarkedChange?: (isMarked: boolean) => void,
}

type TimelineItemInnerProps = Omit<TimelineItemProps, "allowDelete" | "onDelete"> & { showShadow?: boolean }
const TimelineItemInner = (
  {
    id,
    index,
    title,
    stepStatus = "blank",
    isFocused = false,
    isMarked = false,
    isMarkable = false,
    isInserted = false,
    isDeleted = false,
    showShadow = true,
    // events
    onClick,
  }: TimelineItemInnerProps
) => (
  <div
    className={classNames(`
        flex-grow
        h-full
        flex items-center gap-6
        rounded-sm
        text-sm
        overflow-hidden
        ${UiColor.BackgroundColor.WHITE}
        ${showShadow && (isFocused ? UiColor.ShadowColor.GOLD_MEDIUM : `${UiColor.ShadowColor.BLACK_LIGHT} group-hover:${UiColor.ShadowColor.GOLD_MEDIUM}`)}
        ${(isInserted || isDeleted) ? "opacity-0" : "opacity-100"}
        transition-opacity duration-500 ease-in-out
        `)}
    onClick={() => onClick && onClick()}>
    <div className={classNames(`
        flex-grow-0 
        flex flex-row items-center
        w-10 h-full
        ${isFocused
      ? `${UiColor.BackgroundColor.GOLD}`
      : `${UiColor.BackgroundColor.BLACK} group-hover:${UiColor.BackgroundColor.GRAY}`
    }
        ${UiColor.TextColor.WHITE} 
        `)}>
      <span className={"w-full text-center"}>{index}</span>
    </div>
    <div className={classNames(`
        flex-grow
        overflow-hidden
        flex flex-row items-center
        ${isFocused ? UiColor.TextColor.GOLD : UiColor.TextColor.BLACK}
        group-hover:${UiColor.TextColor.GOLD}
        `)}>
      <h3 className={"truncate"}>{title}</h3>
    </div>
    <div className={"flex-grow-0"}>
      {createStatusTag(stepStatus)}
    </div>
    <div className={"flex-grow-0"}/>
  </div>

);

const TimelineItem = (props: TimelineItemProps) => {
  const {
    isMarked = false,
    isMarkable = false,
    isInserted = false,
    isDeleted = false,
    allowDelete,
    onDelete,
    onMarkedChange
  } = props;
  return (
    <div className={classNames(`
      ${(isInserted || isDeleted) ? "h-0" : "h-16"} w-full
      flex items-center
      relative
      cursor-pointer
      group
      transition-[height] duration-500 ease-in-out
      cranq-component-timeline-item
    `)}>
      { //TODO: extract into MarkActionItem component?
        isMarkable && <div className={classNames(`
        flex-grow-0 
        absolute -left-6
        flex items-center
        h-full w-6 pr-2 
        ${isMarked ? "" : "opacity-0 group-hover:opacity-100"}
      `)}>
          <input
            id={`mark-${props.id}`}
            type={"checkbox"}
            checked={isMarked}
            className={"w-4 h-4"}
            onChange={(e) => onMarkedChange && onMarkedChange(e.target.checked)}
          />
        </div>}
      <TimelineItemInner {...props}/>
      {allowDelete && <div className={classNames(`
        flex-grow-0 
        absolute -right-6
        flex items-center
        h-full w-6 pl-2 
        opacity-0 group-hover:opacity-100
      `)}>
        <IconButton
          iconName={InlineIconName.DELETE}
          iconTone={UiColor.TextColor.RED}
          size={"small"}
          onClick={() => onDelete && onDelete()}/>
      </div>}
    </div>
  );
}

export default TimelineItem;
export {TimelineItemInner}
