"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAuthMessage = exports.isAuthErrorMessage = exports.isAuthSuccessMessage = exports.isAuthMessageBase = void 0;
const User_1 = require("../domain/User");
const isAuthMessageBase = (message) => {
    return typeof message === "object" && message !== null
        && "type" in message && message.type === "auth"
        && "code" in message && typeof message.code === "string"
        && "details" in message && typeof message.details === "string";
};
exports.isAuthMessageBase = isAuthMessageBase;
const isAuthSuccessMessage = (message) => {
    return (0, exports.isAuthMessageBase)(message)
        && "result" in message && message.result === "success"
        && "user" in message && (0, User_1.isUserProfile)(message.user)
        && ("anonymousUserId" in message ? typeof message.anonymousUserId === "string" : true)
        && "isNewRegistration" in message && typeof message.isNewRegistration === "boolean"
        && "validUntil" in message && typeof message.validUntil === "number";
};
exports.isAuthSuccessMessage = isAuthSuccessMessage;
const isAuthErrorMessage = (message) => {
    return (0, exports.isAuthMessageBase)(message)
        && "result" in message && message.result === "error";
};
exports.isAuthErrorMessage = isAuthErrorMessage;
const isAuthMessage = (message) => (0, exports.isAuthSuccessMessage)(message) || (0, exports.isAuthErrorMessage)(message);
exports.isAuthMessage = isAuthMessage;
