import {StepInputModel} from "../../types/StepInputModel";
import StepInputEditor, {StepInputEditorProps} from "../step-input-editor/StepInputEditor";
import Container from "../../../common/components/container/Container";
import {UiColor} from "../../../utils/constants/UiColor";
import {fixVariableName} from "../../utils/fixVariableName";
import React from "react";
import OAuthConnectButtonList from "../oauth-connect-button-list/OAuthConnectButtonList";
import {StepModel} from "../../types/StepModel";
import StepOutputEditor from "../step-output-editor/StepOutputEditor";
import {StepOutputModel} from "../../types/StepOutputModel";

type StepParametersProps = {
  inputs: StepModel["inputs"],
  outputs: StepModel["outputs"],
  apis: NonNullable<StepModel["implementation"]>["apis"],
  isCodeStep: boolean,
  disabled: boolean,
  linkables: StepInputEditorProps["linkables"],
  linkedInputResolver: StepInputEditorProps["linkedInputResolver"]
  isOutputLinked: (output: StepOutputModel) => boolean,
  //
  onAddInput?: () => void
  onDeleteInput?: (inputId: StepInputModel["id"]) => void,
  onChangeInput?: (changedInput: StepInputModel) => void
  onAddOutput?: () => void,
  onDeleteOutput?: (outputId: StepOutputModel["id"]) => void,
  onChangeOutput?: (changedOutput: StepOutputModel) => void
}
export const StepParameters = (
  {
    inputs,
    outputs,
    apis = [],
    isCodeStep,
    disabled,
    linkables,
    linkedInputResolver,
    isOutputLinked,
    //
    onAddInput,
    onDeleteInput,
    onChangeInput,
    onAddOutput,
    onDeleteOutput,
    onChangeOutput
  }: StepParametersProps
) => {
  const inputNames: Record<StepInputModel["id"], string> = Object.values(inputs)
    .reduce((result, input) => {
      return {
        ...result,
        [input.id]: input.name
      }
    }, {});
  const outputNames: Record<StepOutputModel["id"], string> = Object.values(outputs)
    .reduce((result, output) => {
      return {
        ...result,
        [output.id]: output.name
      }
    }, {});

  return <>
    <OAuthConnectButtonList apis={apis}/>
    <Container
      id={"inputs"}
      headerProps={{
        title: "Inputs",
        level: 3,
        rightSideButtonListProps: [
          {
            title: "+ Add new input",
            backgroundTone: UiColor.BackgroundColor.TRANSPARENT,
            titleTone: UiColor.TextColor.GOLD,
            onClick: onAddInput,
            disabled
          }
        ]
      }}
      indented={false}>
      <div className={"grid gap-1 items-center"}
           style={{gridTemplateColumns: "33% 1fr"}}>
        {Object.entries(inputs).map(([key, input]) => {
          const otherInputNames = Object.entries(inputNames)
            .filter(([id]) => id !== input.id)
            .map(([, name]) => name);
          const fixInputName = fixVariableName.bind(null, otherInputNames, "unnamed_input");
          return (
            <StepInputEditor
              key={key}
              model={input}
              linkables={linkables}
              linkedInputResolver={linkedInputResolver}
              inputNameFixer={fixInputName}
              disabled={disabled}
              onChange={onChangeInput}
              onDelete={() => onDeleteInput && onDeleteInput(input.id)}/>);
        })}
      </div>
    </Container>
    <Container
      id={"outputs"}
      headerProps={{
        title: "Outputs",
        level: 3,
        rightSideButtonListProps: [
          ...(isCodeStep ? [{
            title: "+ Add new output",
            backgroundTone: UiColor.BackgroundColor.TRANSPARENT,
            titleTone: UiColor.TextColor.GOLD,
            onClick: onAddOutput,
            disabled
          }] : [])
        ]
      }}
      indented={false}>
      <div className={"grid gap-1 items-center"}
           style={{gridTemplateColumns: "33% 1fr"}}>
        {Object.entries(outputs).map(([key, output]) => {
          const otherOutputNames = Object.entries(outputNames)
            .filter(([id]) => id !== output.id)
            .map(([, name]) => name);
          const fixOutputName = fixVariableName.bind(null, otherOutputNames, "unnamed_output");
          return (
            <StepOutputEditor
              key={key}
              model={output}
              disabled={disabled || !isCodeStep}
              isLinked={isOutputLinked(output)}
              outputNameFixer={fixOutputName}
              onChange={onChangeOutput}
              onDelete={() => onDeleteOutput && onDeleteOutput(output.id)}/>);
        })}
      </div>
    </Container>
  </>
}
