"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapExecutorTaskToEntities = void 0;
function mapExecutorTaskToEntities(executorTask) {
    const { executionId, result, request, logEntry, invocationError, status } = executorTask;
    const executorTaskEntity = Object.assign(Object.assign(Object.assign({ executionId, request: {
            userId: request.userId,
            flow: request.flow,
            env: request.env,
            action: request.action
        }, created: new Date(Date.now()) }, (result ? {
        result: Object.assign(Object.assign({ log: result.log }, (result.outcome === "failed" ? {
            outcome: result.outcome,
            failureReason: result.failureReason
        } : {})), (result.outcome === "succeeded" ? {
            outcome: result.outcome
        } : {}))
    } : {})), (invocationError ? {
        invocationError
    } : {})), { status: status, schemaVersion: "2" });
    const executorTaskStepResultEntities = result && result.steps ? result.steps.map(a => {
        return Object.assign(Object.assign({ executionId: executionId, stepId: a.id }, (a.error ? {
            error: a.error,
            errorReason: a.errorReason,
        } : {})), { outputs: a.outputs, created: new Date(Date.now()) });
    }, []) : [];
    const executorTaskLogEntryEntity = logEntry ? {
        executionId: executionId,
        created: new Date(Date.now()),
        logEntry: logEntry
    } : undefined;
    return {
        executorTaskEntity,
        executorTaskStepResultEntities,
        executorTaskLogEntryEntity
    };
}
exports.mapExecutorTaskToEntities = mapExecutorTaskToEntities;
