import {StepModel} from "../../../types/StepModel";
import {StepStatus} from "../../../types/StepStatus";
import StatusLine, {StatusLineType} from "../../../../common/components/status-line/StatusLine";
import {InlineIconName} from "../../../../common/components/inline-icon/InlineIconName";
import {UiColor} from "../../../../utils/constants/UiColor";
import React from "react";
import {StepState} from "../../../types/StepState";

export const generateStatusLine = (
  entityType: "step" | "group",
  description: Required<StepModel>["description"],
  status: keyof typeof StepStatus,
  code: Required<StepModel>["implementation"]["code"],
  inputs: StepModel["inputs"],
  outputs: StepModel["outputs"],
  errorHints: StepState["lastExecutionErrorHints"],
  isCodeGenerationPending: boolean,
  isRunning: boolean,
  isImplementationObsolete: boolean,
  handleViewError?: () => void,
  handleViewGeneratedCode?: () => void
): ReturnType<typeof StatusLine> => {
  if (!description.trim()) {
    return <StatusLine
      message={"Fill in the description"}
      type={StatusLineType.HINT}
    />
  }
  if (Object.keys(inputs).length === 0 && Object.keys(outputs).length === 0) {
    return <StatusLine
      message={"Add required inputs and outputs"}
      type={StatusLineType.HINT}
    />
  }
  if (
    Object.keys(inputs).length > 0
    && Object.values(inputs).some(
      (input) => input.source.type === "value" && input.source.value === undefined
    )
  ) {
    return <StatusLine
      message={"Fill in the Input fields and hit Proceed"}
      type={StatusLineType.HINT}
    />
  }
  if (isCodeGenerationPending) {
    //FIXME: in case of group multiples code generation may be required (can be parallelized?)
    return <StatusLine
      message={"Generating code..."}
      type={StatusLineType.CUSTOM}
      iconName={InlineIconName.CODE}
      primaryTone={UiColor.TextColor.GOLD}
    />;
  }
  if (isRunning) {
    return <StatusLine
      message={"Code execution in progress..."}
      type={StatusLineType.CUSTOM}
      iconName={InlineIconName.PLAY}
      primaryTone={UiColor.TextColor.GOLD}
    />;
  }
  if (status === "run") {
    return <StatusLine
      message={"Code generated and run successfully"}
      type={StatusLineType.SUCCESS}
      actionText={handleViewGeneratedCode ? "View code" : ""}
      onActionClick={handleViewGeneratedCode}
    />
  }
  if (status === "failed") {
    return <StatusLine
      message={`${entityType === "group" ? "Some steps" : "Step"} didn't finish successfully`}
      type={StatusLineType.WARNING}
      actionText={handleViewError ? (entityType === "group" ? "View failed step" : `View ${errorHints ? "AI hints" : "errors"}`) : undefined}
      onActionClick={handleViewError}
    />
  }
  if (code && !isImplementationObsolete) {
    return <StatusLine
      message={"Code generated but execution not successful"}
      type={StatusLineType.HINT}
      actionText={handleViewGeneratedCode ? "View code" : undefined}
      onActionClick={handleViewGeneratedCode}
    />
  }
  return <StatusLine
    message={"If you feel ready just hit Proceed"}
    type={StatusLineType.HINT}
  />
};
