"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_AI_MODEL_TYPE = exports.DEFAULT_AI_MODEL_ID = exports.isAiModelId = exports.isAiModel = exports.AiModel = exports.AiModelProvider = exports.AiModelType = void 0;
const swagger_1 = require("@nestjs/swagger");
const class_validator_1 = require("class-validator");
var AiModelType;
(function (AiModelType) {
    AiModelType["CODE_WRITER"] = "CODE_WRITER";
    AiModelType["TEXT_WRITER"] = "TEXT_WRITER";
})(AiModelType = exports.AiModelType || (exports.AiModelType = {}));
var AiModelProvider;
(function (AiModelProvider) {
    AiModelProvider["OPENAI"] = "OpenAI";
})(AiModelProvider = exports.AiModelProvider || (exports.AiModelProvider = {}));
class AiModel {
}
__decorate([
    (0, swagger_1.ApiProperty)({ type: String }),
    __metadata("design:type", String)
], AiModel.prototype, "id", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: String }),
    __metadata("design:type", String)
], AiModel.prototype, "name", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: String }),
    __metadata("design:type", String)
], AiModel.prototype, "version", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ enum: AiModelType }),
    __metadata("design:type", String)
], AiModel.prototype, "type", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: String }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], AiModel.prototype, "description", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ enum: AiModelProvider }),
    __metadata("design:type", String)
], AiModel.prototype, "modelProvider", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: String }),
    __metadata("design:type", String)
], AiModel.prototype, "modelName", void 0);
exports.AiModel = AiModel;
const isAiModel = (maybeAiModel) => {
    return typeof maybeAiModel === "object" && maybeAiModel !== null
        && "id" in maybeAiModel && typeof maybeAiModel.id === "string"
        && "name" in maybeAiModel && typeof maybeAiModel.name === "string"
        && "version" in maybeAiModel && typeof maybeAiModel.version === "string"
        && "type" in maybeAiModel && Object.values(AiModelType).includes(maybeAiModel.type)
        && ("description" in maybeAiModel ? typeof maybeAiModel.description === "string" : true)
        && "modelProvider" in maybeAiModel && Object.values(AiModelProvider).includes(maybeAiModel.modelProvider)
        && "modelName" in maybeAiModel && typeof maybeAiModel.modelName === "string";
};
exports.isAiModel = isAiModel;
const isAiModelId = (maybeAiModelId) => {
    return typeof maybeAiModelId === "string";
};
exports.isAiModelId = isAiModelId;
exports.DEFAULT_AI_MODEL_ID = "code-writer-v1";
exports.DEFAULT_AI_MODEL_TYPE = AiModelType.CODE_WRITER;
