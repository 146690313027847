"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveOutput = void 0;
function resolveOutput(flow, stepId, outputName) {
    var _a, _b;
    const referencedStep = flow.find((s) => s.id === stepId);
    if (referencedStep) {
        const refOutputs = (_b = (_a = referencedStep.interface) === null || _a === void 0 ? void 0 : _a.outputs) !== null && _b !== void 0 ? _b : [];
        if (refOutputs) {
            return refOutputs.find((o) => o.name === outputName);
        }
    }
    return undefined;
}
exports.resolveOutput = resolveOutput;
