import Button, {ButtonProps} from "../button/Button";
import IconButton, {IconButtonProps} from "../icon-button/IconButton";
import {UiColor} from "../../../utils/constants/UiColor";
import {classNames} from "../../../utils/classNames";
import Input from "../input/Input";
import {ReactNode} from "react";

export type HeaderProps = {
  title: string,
  subtitle?: string | ReactNode,
  titleTone?: UiColor.TextColor,
  backgroundTone?: UiColor.BackgroundColor | string,
  level?: 2 | 3 | 4 | 5 | 6,
  centered?: boolean,
  leftSideIconButtonProps?: IconButtonProps,
  rightSideButtonListProps?: (ButtonProps | IconButtonProps)[],

  // events
  onLeftSideButtonClick?: () => void,
  onRightSideButtonClick?: (index: number) => void,
  onTitleChange?: (newTitle: string) => void,
}


const Header = (props: HeaderProps) => {
  const {
    title,
    subtitle,
    level = 2,
    centered = false,
    titleTone = UiColor.TextColor.BLACK,
    backgroundTone = UiColor.BackgroundColor.TRANSPARENT,
    leftSideIconButtonProps,
    rightSideButtonListProps,
    onLeftSideButtonClick,
    onRightSideButtonClick,
    onTitleChange
  } = props;

  const handleTitleChange = (newTitle: string) => {
    if (newTitle !== title) {
      onTitleChange && onTitleChange(newTitle);
    }
  }
  const defaultLeftSideButtonProps: Partial<IconButtonProps> = {
    iconTone: titleTone,
    size: "small",
    onClick: () => onLeftSideButtonClick && onLeftSideButtonClick()
  };
  const leftSideButton = leftSideIconButtonProps &&
    <IconButton {...{...defaultLeftSideButtonProps, ...leftSideIconButtonProps}} />;
  const rightSideButtons = rightSideButtonListProps?.length ? (
    <div className="flex flex-row gap-1 flex-shrink">{
      rightSideButtonListProps
        .map((buttonProps, index) => {
          if ("title" in buttonProps) {
            return <Button
              key={index} {...buttonProps}
              onClick={() => {
                buttonProps.onClick && buttonProps.onClick();
                onRightSideButtonClick && onRightSideButtonClick(index);
              }}/>;
          } else {
            return <IconButton
              key={index} {...buttonProps}
              onClick={() => {
                buttonProps.onClick && buttonProps.onClick();
                onLeftSideButtonClick && onLeftSideButtonClick();
              }}/>;
          }
        })
    }</div>
  ) : null;
  const HeaderTag = `h${level}` as keyof JSX.IntrinsicElements;
  const padding = backgroundTone === UiColor.BackgroundColor.TRANSPARENT ? "" : `p-${(6 - level) / 2 + .5}`;
  const editable = onTitleChange !== undefined;

  return (
    <header className={`bg-${backgroundTone} flex flex-row ${padding} gap-4 items-center justify-between`}>
      {leftSideButton}
      <div className={"flex-grow min-w-0"}>
        <HeaderTag
          title={title}
          className={classNames(`
            ${centered ? "text-center" : ""}
            ${titleTone} 
            whitespace-nowrap overflow-hidden overflow-ellipsis
        `)}>
          {editable
            ? <Input
              name={"title"}
              value={title}
              onBlur={handleTitleChange}
              textTone={titleTone}
              textSize={""}
              backgroundTone={UiColor.BackgroundColor.TRANSPARENT}
              borderTone={UiColor.BorderColor.TRANSPARENT}
              padding={false}
              size="full"
            />
            : title}
        </HeaderTag>
        {typeof subtitle === "string"
          ? <div className={"text-xs"}>{subtitle}</div>
          : subtitle === undefined
            ? null
            : subtitle}
      </div>
      {rightSideButtons}
    </header>
  );
};

export default Header;
