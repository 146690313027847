import {DEFAULT_AI_MODEL_ID, DEFAULT_AI_MODEL_TYPE, Step} from "@cranq-gpt-lowcode/contracts";
import {FlowState} from "../../../types/FlowState";
import {Variable} from "../../../types/Variable";
import {StepModel} from "../../../types/StepModel";
import {isId} from "../../../../utils/types/Id";
import createNewId from "../../../../utils/createNewId";
import {convertInputSourceFromEditorApi} from "../../../utils/convertInputSourceFromEditorApi";
import {calculateImplementationParametersHash} from "../../../utils/calculateImplementationParametersHash";

export const mapApiStepToStepModel = (
  apiSteps: Step[],
  flow: FlowState,
  variables: undefined | Variable[]
): StepModel[] => apiSteps
  .reduce((
    previousSteps,
    {
      id,
      name,
      description,
      interface: {
        inputs = [],
        outputs = []
      } = {},
      implementation
    },
    index,
  ) => {
    const stepModel: StepModel = {
      id: isId(id) ? id : createNewId(),
      flowId: flow.model.id,
      index: index + 1,
      title: name,
      aiModelId: DEFAULT_AI_MODEL_ID, //FIXME: should come from Breakdown!
      aiModelType: DEFAULT_AI_MODEL_TYPE, //FIXME: should come from Breakdown!
      description,
      inputs: Object.fromEntries(
        inputs.map(
          ({name, description, type, source}) => {
            const id = createNewId();
            return [
              id,
              {
                id,
                name,
                description,
                type,
                isBoundToCode: true,
                source: convertInputSourceFromEditorApi(source, previousSteps, variables)
              }];
          })),
      outputs: Object.fromEntries(
        outputs.map(
          ({name, description, type}) => {
            const id = createNewId();
            return [id, {id, name, description, type, isBoundToCode: true}];
          })),
      implementation: implementation ? {
        ...implementation,
        parametersHash: "TO CALCULATE"
      } : undefined
    };
    if (stepModel.implementation) {
      stepModel.implementation.parametersHash = calculateImplementationParametersHash(stepModel);
    }
    return previousSteps.concat(stepModel);
  }, [] as StepModel[]);
