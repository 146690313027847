import {useCallback, useState} from "react";
import {DialogProps} from "../Dialog";

type OpenDialogParams = Omit<DialogProps, "onCloseClick"> & {
  onClose?: () => void;
}

type DialogHookReturn = {
  dialogProps: DialogProps | undefined
  openDialog: (params: OpenDialogParams) => void;
  closeDialog: () => void;
}
export const useDialog = (): DialogHookReturn => {
  const [
    dialogProps,
    setDialogProps
  ] = useState<DialogProps | undefined>(
    undefined
  );

  const openDialog = useCallback((params: OpenDialogParams) => {
    const {onClose, ...dialogProps} = params;
    setDialogProps({
      ...dialogProps,
      onCloseClick: () => {
        onClose && onClose();
        setDialogProps(undefined);
      }
    });
  }, []);

  const closeDialog = useCallback(() => {
    setDialogProps(undefined);
  }, [])

  return {
    dialogProps,
    closeDialog,
    openDialog
  };
};
