import {RootState, store} from "../store";
import {detailedDiff} from "deep-object-diff";
import {isDefined} from "@cranq-gpt-lowcode/contracts";
import {selectEnvironments} from "../environmentsSlice";
import {isLinkedToGlobalParameter} from "../components/environment-editor/utils/isLinkedToGlobalParameter";
import {
  GlobalParameterReferencePrefix
} from "../components/environment-editor/utils/createGlobalParameterReferenceResolver";
import {unique} from "../../utils/unique";
import {updateFlowHashAction} from "../actions/updateFlowHashAction";

export const updateFlowHashUponGlobalParametersChanges = (
  state: RootState["globalParameters"],
  previousState: RootState["globalParameters"]
) => {
  // we only care about model changes
  const diff = detailedDiff(previousState.model, state.model)
  // we don't care about added parameters since they surely are not linked to any environment variable
  // we don't care about deleted ones since upon deletion linked environment variables are unlinked, and we'll capture that change
  const {updated} = diff;

  const updatedParameters = Object.keys(updated)
    .map((key) => parseInt(key, 10))
    .map((index) => state.model[index])
    .filter(isDefined)

  const environments = selectEnvironments(store.getState());
  const flowsToUpdate = unique(updatedParameters
    .map((parameter) => Object.values(environments)
      .filter((environment) => environment.model.variables
        .some((variable) => isLinkedToGlobalParameter(variable) && variable.value === `${GlobalParameterReferencePrefix}${parameter.key}`)
      )
      .map((environment) => environment.model.flowId)
    )
    .flat());

  flowsToUpdate.forEach((flowId) => store.dispatch(updateFlowHashAction(flowId)))
};
