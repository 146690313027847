import {useState} from "react";
import FlowList, {FlowListProps} from "../flow-list/FlowList";
import Input from "../../../common/components/input/Input";
import Select from "../../../common/components/select/Select";
import Container from "../../../common/components/container/Container";
import {UiColor} from "../../../utils/constants/UiColor";

type OrderBy = Exclude<FlowListProps["orderBy"], undefined>
const orderByOptions: Record<OrderBy, string> = {
  "createdAt": "Created",
  "accessedAt": "Opened",
  "updatedAt": "Last updated"
};
const isOrderBy = (value: string): value is OrderBy => Object.keys(orderByOptions).includes(value);

type OrderDirection = Exclude<FlowListProps["orderDirection"], undefined>
const orderDirectionOptions: Record<OrderDirection, string> = {
  "asc": "Ascending",
  "desc": "Descending"
};
const isOrderDirection = (value: string): value is OrderDirection => Object.keys(orderDirectionOptions).includes(value);

type Limit = 10 | 25 | 50 | 100;
const limitOptions: Record<Limit, string> = {
  10: "10",
  25: "25",
  50: "50",
  100: "100"
};
const isLimit = (value: number): value is Limit => Object.keys(limitOptions).includes(value.toString());

const MyFlows = () => {
  const [orderBy, setOrderBy] = useState<OrderBy>("updatedAt");
  const [orderDirection, setOrderDirection] = useState<OrderDirection>("desc");
  const [limit, setLimit] = useState<Limit>(25);
  const [filterText, setFilterText] = useState<string>("");

  return (
    <section className={"h-full flex flex-col gap-4 pb-4"}>
      <Container dropShadow={true} borderTone={UiColor.BorderColor.BLACK} paddingY={true} indented={true}
                 shrinkOrGrow={"no-shrink"}>
        <div className={"flex flex-col gap-2"}>
          <div className={"flex flex-row gap-1 items-center"}>
            <label htmlFor={"my-flows-filter"}>Filter</label>
            <Input
              name={"filter"}
              id={"my-flows-filter"}
              size={"full"}
              value={filterText}
              placeholder={"Type some text to filter flows by title or description"}
              onValueChange={(newValue) => setFilterText(newValue)}
            />
          </div>
          <div className={"flex flex-row gap-1 items-center"}>
            <label htmlFor={"my-flows-order-by"}>Order by</label>
            <Select
              name={"orderBy"}
              id={"my-flows-order-by"}
              value={orderBy}
              options={orderByOptions}
              onValueChange={(newValue) => {
                if (isOrderBy(newValue)) {
                  setOrderBy(newValue);
                }
              }}
            />
            <Select
              name={"orderDirection"}
              value={orderDirection}
              options={orderDirectionOptions}
              onValueChange={(newValue) => {
                if (isOrderDirection(newValue)) {
                  setOrderDirection(newValue);
                }
              }}
            />
            <Select
              name={"limit"}
              value={limit.toString()}
              options={limitOptions}
              onValueChange={(newValue) => {
                const limit = parseInt(newValue);
                if (isLimit(limit)) {
                  setLimit(limit);
                }
              }}
            />
          </div>
        </div>
      </Container>
      <Container indented={true} overflowY={"auto"} height={"full"}>
        {FlowList({
          orderBy: orderBy,
          orderDirection: orderDirection,
          limit: limit,
          filterText: filterText,
          includeUserFlowsOnly: true
        }) ?? <p className={`text-sm italic ${UiColor.TextColor.GRAY}`}>No Workflows to list...</p>}
      </Container>
    </section>
  );
};

export default MyFlows;
