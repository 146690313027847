import {safeJsonStringify} from "../../utils/safeJsonStringify";
import {StepDrawerProps} from "../step-drawer/StepDrawer";
import React, {Fragment} from "react";
import {StepModel} from "../../types/StepModel";
import {UiColor} from "../../../utils/constants/UiColor";
import TextSpinner from "../../../common/components/text-spinner/TextSpinner";
import Notification from "../../../common/components/notification/Notification";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import Button from "../../../common/components/button/Button";

type ExecutionErrorHintsProps = {
  executionErrorHints: StepDrawerProps["executionErrorHints"],
  isLoading: StepDrawerProps["areExecutionErrorHintsLoading"],
  inputs: StepModel["inputs"],
  isImplementationObsolete?: boolean,
  onModifyDescription?: (newDescription: NonNullable<StepModel["description"]>) => void
}
const ExecutionErrorHints = (
  {
    executionErrorHints,
    isLoading,
    inputs,
    isImplementationObsolete = false,
    onModifyDescription
  }: ExecutionErrorHintsProps) => {
  if (isLoading) {
    return <p>Hints are being generated, please wait<TextSpinner/></p>;
  }
  if (!executionErrorHints) {
    return <p>AI Hints will appear here.</p>;
  }
  const inputMap: Record<string, string> = Object.values(inputs)
    .reduce((acc, input) => ({
      ...acc,
      [input.name]: input.description
    }), {});

  const obsoleteMessage = isImplementationObsolete
    ? <Notification
      text={"Hint may be obsolete"}
      secondaryText={"Parameters were changed since last run that the hints refer to. You may need to proceed the step again."}
      type={MESSAGE_TYPE.WARNING}/>
    : null;

  const handleModifyDescription = () => {
    if (onModifyDescription && "fixedTaskDescription" in executionErrorHints.hints) {
      onModifyDescription(executionErrorHints.hints.fixedTaskDescription);
    }
  }

  switch (executionErrorHints.hints.category) {
    case "BAD_INPUT":
      return (
        <>
          {obsoleteMessage}
          <p className={"py-2 text-lg font-bold"}>The error is mostly likely caused by invalid inputs.</p>
          <h3 className={"pt-2"}>Suggestion:</h3>
          <p>Correct the input values according to the below examples:</p>
          <dl>
            {Object.entries(executionErrorHints.hints.exampleInputs).map(([inputName, exampleValue], idx) => (
              <Fragment key={idx}>
                <dt>{inputMap[inputName] ?? inputName}</dt>
                <dd>
                    <pre
                      className={`border ${UiColor.BackgroundColor.DARK_GRAY} ${UiColor.BackgroundColor.GRAY} p-2 text-sm whitespace-pre-wrap`}>
                      {safeJsonStringify(exampleValue)}
                    </pre>
                </dd>
              </Fragment>
            ))}
          </dl>
          <h3 className={"pt-2"}>Summary:</h3>
          <p>{executionErrorHints.hints.summary}</p>
        </>
      );
    case "BAD_CODE":
      return (
        <>
          {obsoleteMessage}
          <p className={"py-2 text-lg font-bold"}>The issue is mostly likely caused by wrong code.</p>
          <h3 className={"pt-2"}>Suggestion:</h3>
          <p>Modify the step description to the following:</p>
          <pre
            className={`border ${UiColor.BackgroundColor.DARK_GRAY} ${UiColor.BackgroundColor.GRAY} p-2 text-sm whitespace-pre-wrap`}>
              {executionErrorHints.hints.fixedTaskDescription}
          </pre>
          <div className={"flex justify-end"}>
            <Button title={"Modify description"} onClick={handleModifyDescription}/>
          </div>
        </>
      );
    default:
      return <p>Unexpected error hints format.</p>
  }
}

export default ExecutionErrorHints;
