import {StepInputModel} from "../types/StepInputModel";
import {Variable} from "../types/Variable";
import {EnvSource, OutputSource, ValueSource} from "@cranq-gpt-lowcode/contracts";
import {StepModel} from "../types/StepModel";

export const convertInputSourceFromEditorApi = (
  source: ValueSource | OutputSource | EnvSource | undefined,
  stepModels: StepModel[],
  environmentVariables?: Variable[],
): StepInputModel["source"] => {
  const fallbackSource: StepInputModel["source"] = {
    type: "value",
    value: undefined
  }
  if (!source) return fallbackSource;

  switch (source.type) {
    case "value":
      return {
        type: "value",
        value: source.value
      }
    case "output":
      const step = stepModels.find((s) => s.id === source.stepId);
      const output = Object.values(step?.outputs ?? {}).find((o) => o.name === source.outputName);
      if (step && output) {
        return {
          type: "output",
          stepId: step.id,
          outputId: output.id
        }
      }
      break;
    case "env":
      if (environmentVariables) {
        const variable = environmentVariables.find((v) => v.key === source.envVariableName);
        if (variable) {
          return {
            type: "env",
            name: variable.key
          }
        }
      }
  }
  return fallbackSource;
};
