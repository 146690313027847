import {UserState} from "../../types/UserState";
import {createContext, ReactNode, useCallback, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {login, loginAnonymous, logout, selectUser} from "../../userSlice";
import RefreshLoginDialog from "../refresh-login-dialog/RefreshLoginDialog";
import {isAnonymousUserModel, UserModel} from "../../types/UserModel";
import oAuthApi, {useGetConnectionsQuery} from "../../oAuthApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {GetOAuthConnectionsResponse} from "@cranq-gpt-lowcode/contracts";
import {ensureAnonymousUserId} from "../../utils/ensureAnonymousUserId";
import {useRefreshLogin} from "../../hooks/useRefreshLogin";
import {readStoredAppPermissions} from "./utils/readStoredAppPermissions";
import {AppPermissionName, AppPermissions, AppPermissionValue} from "./types/AppPermissions";
import {setAppPermission} from "./utils/setAppPermission";
import {useToast} from "../../../common/components/toast/hook/useToast";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";

const anonymousUserId = ensureAnonymousUserId();

type AuthState = {
  user: UserState;
  anonymousUserId: UserModel["id"];
  loading: boolean;
  permissions: AppPermissions,
  setPermission?: (permissionName: AppPermissionName, permissionValue: AppPermissionValue) => void;
  apiConnections?: GetOAuthConnectionsResponse;
  apiConnectionsFetching?: boolean;
  refetchApiConnections?: (forceApiStateReset?: boolean) => void;
}
export const AuthContext = createContext<AuthState>({
  user: {validUntil: Date.now()},
  anonymousUserId,
  loading: true,
  permissions: readStoredAppPermissions(),
});

type AuthContextProviderProps = {
  children: ReactNode;
}
const AuthContextProvider = (
  {
    children
  }: AuthContextProviderProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [loading, setLoading] = useState<boolean>(true);
  const {
    data: apiConnections,
    refetch: refetchApiConnectionsQuery,
    isFetching: apiConnectionsFetching,
  } = useGetConnectionsQuery(user.model?.id ?? skipToken, {
    pollingInterval: 5 * 60 * 1000,
  });
  const {showToast} = useToast();
  const [permissions, setPermissions] = useState<AppPermissions>(readStoredAppPermissions());

  const handleLoginRefreshSuccess = (user: UserModel, validUntil: number) => {
    setLoading(false);
    if (isAnonymousUserModel(user)) {
      dispatch(loginAnonymous({validUntil}))
    } else {
      dispatch(login({user, validUntil}))
    }
  }

  const handleLoginRefreshFailure = () => {
    setLoading(false);
    showToast({
      type: MESSAGE_TYPE.ERROR,
      message: "Login refresh failed"
    })
    dispatch(logout());
  }

  const handleLoginRefreshSkipped = () => {
    setLoading(false);
  }

  const handleAnonymousLoginSuccess = (validUntil: number) => {
    setLoading(false);
    dispatch(loginAnonymous({validUntil}))
  }
  const handleSetPermission = (
    permissionName: AppPermissionName,
    permissionValue: AppPermissionValue
  ) => {
    setAppPermission(permissionName, permissionValue);
    setPermissions(readStoredAppPermissions());
  }

  const refetchApiConnections = useCallback((
    forceApiStateReset: boolean = false
  ) => {
    if (forceApiStateReset) {
      dispatch(oAuthApi.util.resetApiState());
    } else {
      dispatch(refetchApiConnectionsQuery);
    }
  }, [dispatch, refetchApiConnectionsQuery]);

  const {
    openAuthWindow,
    isAuthWindowOpen,
    isAuthWindowBlocked
  } = useRefreshLogin(
    user,
    anonymousUserId,
    loading,
    handleLoginRefreshSuccess,
    handleLoginRefreshFailure,
    handleLoginRefreshSkipped,
    handleAnonymousLoginSuccess,
  )

  const shouldShowRefreshLoginDialog = user.loggedInAt && (isAuthWindowOpen || isAuthWindowBlocked);
  return (
    <AuthContext.Provider
      value={{
        user,
        anonymousUserId,
        loading,
        apiConnections,
        apiConnectionsFetching,
        refetchApiConnections,
        permissions,
        setPermission: handleSetPermission,
      }}>
      {shouldShowRefreshLoginDialog
        ? <RefreshLoginDialog
          loggedInUser={user}
          anonymousUserId={anonymousUserId}
          openAuthWindow={openAuthWindow}
          isAuthWindowBlocked={isAuthWindowBlocked}
          onLoginRefreshFailure={handleLoginRefreshFailure}
        />
        : null}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
