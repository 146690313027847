import {ThunkAction} from "@reduxjs/toolkit";
import editorApi from "../../../editorApi";
import {RootState} from "../../../store";
import {makeSelectStepsByFlowId, resetStepExecutionStatus, updateStepById} from "../../../stepsSlice";
import {createGenerateCodeForStepRequest} from "../utils/createGenerateCodeForStepRequest";
import {isUuid} from "../../../../utils/types/Uuid";
import {batch} from "react-redux";
import {selectEnvironmentByFlowId} from "../../../environmentsSlice";
import {createNewEnvironment} from "../../../utils/createNewEnvironment";
import {StepModel} from "../../../types/StepModel";
import {FlowModel} from "../../../types/FlowModel";
import {selectFlow} from "../../../flowsSlice";
import {PendingRequestId} from "../../../../utils/types/RequestId";
import {MESSAGE_TYPE} from "../../../../common/helper/messageTypeColorMapper";

export const generateStepImplementationAsyncAction = (
  flowId: FlowModel["id"],
  stepId: StepModel["id"],
  generateCodeAsyncTrigger: ReturnType<typeof editorApi["useGenerateCodeAsyncMutation"]>[0],
  errorCallback: (title: string, errorMessage?: string, callToAction?: string, messageType?: MESSAGE_TYPE) => void = () => {
  }
): ThunkAction<Promise<void>, RootState, any, any> => async (
  dispatch,
  getState
) => {
  const flow = selectFlow(flowId)(getState());
  if (!flow) {
    return Promise.reject(`Flow with id ${flowId} not found`);
  }
  const environment = selectEnvironmentByFlowId(flowId)(getState()) ?? createNewEnvironment(flowId);
  const {model: {variables}} = environment;
  const selectStepsByFlowId = makeSelectStepsByFlowId();
  const steps = (selectStepsByFlowId(getState(), flowId) ?? []).map((step) => step.model);
  const step = steps.find((step) => step.id === stepId);
  if (!step) {
    return Promise.reject(`Step with id ${stepId} not found`);
  }
  batch(() => {
    dispatch(resetStepExecutionStatus(stepId));
    dispatch(updateStepById({
      stepId,
      update: {
        model: {
          implementation: undefined,
        },
        codeGenerationRequestId: PendingRequestId
      }
    }));
  });
  const request = createGenerateCodeForStepRequest(
    stepId, step, steps, variables, flow.model.description);
  try {
    const result = await generateCodeAsyncTrigger(request);
    dispatch(updateStepById({stepId, update: {codeGenerationRequestId: undefined}}));
    if ("data" in result) {
      const queryId = result.data.queryId;
      if (isUuid(queryId)) {
        dispatch(updateStepById({
          stepId,
          update: {
            codeGenerationRequestId: queryId
          }
        }));
      } else {
        console.error("Received invalid queryId in response of code generation request");
      }
    } else {
      errorCallback(
        "Code generation failed unexpectedly!",
        "Raw message:\n" + JSON.stringify(result.error, null, 2)
      );
    }
  } catch {
    dispatch(updateStepById({stepId, update: {codeGenerationRequestId: undefined}}));
  }
};
