"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveStepInputValue = void 0;
const resolveOutput_1 = require("./resolveOutput");
function resolveStepInputValue(input, flow, env) {
    if (!input.source) {
        return;
    }
    switch (input.source.type) {
        case "value":
            return input.source.value;
        case "env":
            return env[input.source.envVariableName];
        case "output":
            const { stepId, outputName } = input.source;
            const refOutput = (0, resolveOutput_1.resolveOutput)(flow, stepId, outputName);
            if (refOutput) {
                return refOutput.lastValue;
            }
            return undefined;
    }
}
exports.resolveStepInputValue = resolveStepInputValue;
