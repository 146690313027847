import {mapStepModelToApiStepModel} from "../../../utils/mapStepModelToApiStepModel";
import {calculateFlowHash} from "../../../utils/calculateFlowHash";
import {isUuid} from "../../../../utils/types/Uuid";
import {updateFlowById} from "../../../flowsSlice";
import {RootState} from "../../../store";
import {ThunkAction} from "@reduxjs/toolkit";
import {FlowModel} from "../../../types/FlowModel";
import {EnvironmentModel} from "../../../types/EnvironmentModel";
import {StepModel} from "../../../types/StepModel";
import {CreateWebhookResponse} from "@cranq-gpt-lowcode/contracts";
import {useCreateWebhookMutation} from "../../../webhookApi";
import {MapApiResponse} from "../../../types/messages/utils/MapApiResponse";
import {createResolvedEnvironment} from "../../../utils/createResolvedEnvironment";
import {selectGlobalParameters} from "../../../globalParametersSlice";

export const createWebhookAction = (
  flowId: FlowModel["id"],
  flow: FlowModel,
  steps: StepModel[],
  environment: EnvironmentModel,
  createWebhookTrigger: ReturnType<typeof useCreateWebhookMutation>[0]
): ThunkAction<Promise<MapApiResponse<CreateWebhookResponse>>, RootState, any, any> => async (
  dispatch,
  getState
) => {
  const globalParameters = selectGlobalParameters(getState())?.model;
  const result = await createWebhookTrigger({
    flowId: flow.id,
    flow: steps.map((step) => mapStepModelToApiStepModel(step, steps, environment.variables)),
    environment: createResolvedEnvironment(environment.variables, globalParameters),
    hash: calculateFlowHash(flow, steps, environment.variables)
  });
  if ("data" in result) {
    const webhookId = result.data.webhookId;
    if (isUuid(webhookId)) {
      dispatch(updateFlowById({
        flowId,
        update: {
          webhookId: webhookId,
          webhookExpiryDate: result.data.expiry,
          webhookHash: result.data.hash
        }
      }))
    }
    return result.data;
  } else {
    throw result.error; // FIXME: show toast
  }
}
