import {useToast} from "../../common/components/toast/hook/useToast";
import {MESSAGE_TYPE} from "../../common/helper/messageTypeColorMapper";

export const copyToClipboard = (
  value: string,
  showToast?: ReturnType<typeof useToast>["showToast"],
  message: string = "Value copied to clipboard"
) => {
  if (value === "") {
    return;
  }
  return navigator.clipboard.writeText(value)
    .then(() => {
      showToast && showToast({
        message,
        type: MESSAGE_TYPE.SUCCESS
      });
    });
};
