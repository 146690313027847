import {StepModel} from "../types/StepModel";
import {StepInputModel} from "../types/StepInputModel";
import {StepOutputModel} from "../types/StepOutputModel";
import {compactString} from "./compactString";
import {objectHash} from "./objectHash";

type ImplementationParameters = {
  description: StepModel["description"],
  inputs: Pick<StepInputModel, "name" | "description" | "type">[],
  outputs: Pick<StepOutputModel, "name" | "description" | "type">[],
};

export function calculateImplementationParametersHash(step: StepModel) {
  const {inputs, outputs, description} = step;
  const implementationParameters: ImplementationParameters = {
    inputs: Object.values(inputs).map(i => ({
      name: i.name.trim(),
      description: compactString(i.description ?? ""),
      type: i.type
    })),
    outputs: Object.values(outputs).map(o => ({
      name: o.name.trim(),
      description: compactString(o.description ?? ""),
      type: o.type?.trim()
    })),
    description: compactString(description ?? "")
  };
  return objectHash(implementationParameters);
}
