import {StepOutputModel} from "../../../types/StepOutputModel";
import {StepState} from "../../../types/StepState";

/**
 * Checks if the given output is linked to any input of any step.
 * @param output
 * @param steps
 */
export const isOutputLinked = (
  steps: StepState[],
  output: StepOutputModel
) => steps
  .some(step => {
    const {inputs} = step.model;
    return Object.values(inputs).some(input => input.source.type === "output" && input.source.outputId === output.id);
  });
