"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryType = void 0;
var QueryType;
(function (QueryType) {
    QueryType["GENERATE_STEPS"] = "Step generation";
    QueryType["GENERATE_STEP_HINTS"] = "Step hints generation";
    QueryType["GENERATE_CODE"] = "Code generation";
    QueryType["GENERATE_EXECUTION_ERROR_HINTS"] = "Execution error hints generation";
})(QueryType = exports.QueryType || (exports.QueryType = {}));
