import {Id} from "../../utils/types/Id";

export type StepOutputModel = {
  id: Id,
  name: string,
  type?: string,
  value?: unknown,
  description?: string,
  isBoundToCode?: boolean
}

export const isStepOutputModel = (maybeStepOutputModel: unknown): maybeStepOutputModel is StepOutputModel => {
  return typeof maybeStepOutputModel === "object" && maybeStepOutputModel !== null
    && "id" in maybeStepOutputModel && typeof maybeStepOutputModel.id === "string"
    && "name" in maybeStepOutputModel && typeof maybeStepOutputModel.name === "string"
    && ("type" in maybeStepOutputModel ? typeof maybeStepOutputModel.type === "string" : true)
    && ("description" in maybeStepOutputModel ? typeof maybeStepOutputModel.description === "string" : true)
    && ("isBoundToCode" in maybeStepOutputModel ? typeof maybeStepOutputModel.isBoundToCode === "boolean" : true)
}
