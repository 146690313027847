import ReactGA from "react-ga4";
import {useGetExecutionStatusQuery} from "../../../runnerApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {useEffect, useRef} from "react";
import {ExecuteResponse, ExecutionStatusResponse, StepResult} from "@cranq-gpt-lowcode/contracts";
import {batch} from "react-redux";
import {updateFlowExecution} from "../../../flowsSlice";
import {isId} from "../../../../utils/types/Id";
import {resetStepOutputValues, updateStepById, updateStepOutputValues} from "../../../stepsSlice";
import {FlowModel} from "../../../types/FlowModel";
import {useAppDispatch} from "../../../hooks";
import {safeJsonStringify} from "../../../utils/safeJsonStringify";
import {safeJsonParse} from "../../../utils/safeJsonParse";
import {isUuid} from "../../../../utils/types/Uuid";
import {usePollingTimeout} from "../../../hooks/usePollingTimeout";
import {MESSAGE_TYPE} from "../../../../common/helper/messageTypeColorMapper";
import {StepModel} from "../../../types/StepModel";
import {generateExecutionErrorHintsAction} from "../actions/generateExecutionErrorHintsAction";
import {useCreateGenerateExecutionErrorHintsTaskMutation} from "../../../editorApi";


const EXECUTION_ERROR_MESSAGE_MAX_LENGTH = 1024;

type ExecutionError = {
  type: "Error",
  message: string
}
const isExecutionError = (maybeError: unknown): maybeError is ExecutionError => (
  typeof maybeError === "object"
  && maybeError !== null
  && "type" in maybeError
  && maybeError["type"] === "Error"
  && "message" in maybeError
);

const formatExecutionStatusLog = (log: ExecutionStatusResponse["log"]) =>
  log
    .map((line) => {
      const maybeError = safeJsonParse(line);
      return safeJsonStringify(isExecutionError(maybeError) ? maybeError.message : maybeError);
    })
    ?.join("\n");

//FIXME: This should be merged into useRequestStatusPolling to remove duplications!
export const useExecutionStatusPolling = (
  executionId: ExecuteResponse["executionId"] | undefined,
  flowId: FlowModel["id"] | undefined,
  stepId: StepModel["id"] | undefined,
  errorCallback: (title: string, message?: string, callToAction?: string, messageType?: MESSAGE_TYPE) => void = () => {
  },
  successCallback: (title: string, message?: string) => void = () => {
  },
  maxPollingTime: number = 120000
) => {
  const dispatch = useAppDispatch();
  const [generateExecutionErrorHints] = useCreateGenerateExecutionErrorHintsTaskMutation();

  const initialPollingInterval = 1000;
  const pollingInterval = useRef(initialPollingInterval);
  useEffect(() => {
    isUuid(executionId) && console.debug(`Resetting polling interval to default for executionId ${executionId}.`);
    pollingInterval.current = initialPollingInterval;
  }, [executionId]);

  const pollingTimeoutUtils = usePollingTimeout(maxPollingTime);
  const currentPollingStartTime = isUuid(executionId) ? pollingTimeoutUtils.register(executionId) : 0;

  const lastRequestId = useRef<string | undefined>(undefined);

  // get query data
  // params: queryId (executionId), pollingInterval, queryHook
  const queryInfo = useGetExecutionStatusQuery(
    isUuid(executionId) ? executionId : skipToken,
    {pollingInterval: pollingInterval.current}
  );
  isUuid(executionId) && console.debug(`Polling for queryId ${executionId} started at ${currentPollingStartTime} with interval ${pollingInterval.current} in request ${queryInfo.requestId}.`);

  //update data from execution status poll
  // params:
  // - queryId reset function
  // - action name for User ("Execution")
  // - action name for GA ("EXECUTION")
  // - query result processing function (inline!)
  useEffect(() => {
    if (!isUuid(flowId) || !isUuid(executionId)) {
      return;
    }
    const {
      currentData: currentQueryData,
      error: queryError,
      isSuccess: isQuerySuccess,
      isError: isQueryError,
      isFetching: isQueryFetching,
      requestId
    } = queryInfo;

    if (isQueryFetching) {
      return;
    }
    if (lastRequestId.current === requestId) { // prevent multiple executions of this effect for the same request
      return;
    }
    lastRequestId.current = requestId;

    if (isQueryError) {
      if ("status" in queryError && queryError.status === 429) { // request rejected due to throttling
        pollingInterval.current *= 2;
        console.debug(`Polling for queryId ${executionId} (${requestId}) throttled. Increasing polling interval to ${pollingInterval.current}ms.`);
      } else {
        dispatch(updateFlowExecution({flowId, executionId: undefined}));
        errorCallback(
          "Execution failed!",
          JSON.stringify(queryError, null, 2)
        );
        ReactGA.event({
          category: "request",
          action: "EXECUTION_STATUS_POLLING_FAILED",
          label: `FlowId: ${flowId} | ExecutionId: ${currentQueryData?.executionId}`,
          nonInteraction: true,
          transport: "xhr"
        });
      }
    }

    if (isQuerySuccess) {
      const {
        state,
        outcome,
        log,
        steps: stepResults = [] as StepResult[]
      } = currentQueryData ?? {};

      stepResults.forEach(({id: stepId, outputs, error}) => {
        if (isId(stepId)) {
          const stepResult = stepResults.find(
            (sr) => sr.id === stepId);
          if (stepResult) {
            if (error) {
              dispatch(updateStepById({
                stepId,
                update: {
                  lastExecutionError: error.slice(0, EXECUTION_ERROR_MESSAGE_MAX_LENGTH)
                }
              }));
              dispatch(generateExecutionErrorHintsAction(
                flowId,
                stepId,
                generateExecutionErrorHints,
                errorCallback)
              );
              dispatch(resetStepOutputValues(stepId));
            } else {
              dispatch(updateStepById({
                stepId,
                update: {
                  lastExecutionError: null // last run successful
                }
              }));
              dispatch(updateStepOutputValues({
                stepId,
                outputs: outputs.map(
                  ({name, value}) => ({name, value})
                )
              }));
            }
          }
        }
      });

      if (state === "finished") {
        batch(() => {
          dispatch(updateFlowExecution({flowId, executionId: undefined}));
        });
        if (outcome === "failed") {
          ReactGA.event({
            category: "request",
            action: "EXECUTION_FAILED",
            label: `FlowId: ${flowId} | ExecutionId: ${currentQueryData?.executionId} | Steps count: ${stepResults.length}`,
            nonInteraction: true,
            transport: "xhr"
          });
          const logMessage = formatExecutionStatusLog(log ?? []);
          errorCallback(
            `Execution finished with error!`,
            logMessage ? `Log:\n${logMessage}` : undefined,
            "Click to see the logs",
            MESSAGE_TYPE.WARNING
          );
        } else {
          ReactGA.event({
            category: "request",
            action: "EXECUTION_SUCCESS",
            label: `FlowId: ${flowId} | ExecutionId: ${currentQueryData?.executionId} | Steps count: ${stepResults.length}`,
            nonInteraction: true,
            transport: "xhr"
          });
          successCallback(
            `${stepId ? "Step" : "Flow"} finished successfully.`
          );
        }
        pollingTimeoutUtils.unregister(executionId);
        return;

      } else {
        console.debug(`Polling for executionId ${executionId} still in progress. (for ${Date.now() - currentPollingStartTime}ms)}`)
        if (pollingTimeoutUtils.hasTimedOut(executionId)) {
          console.debug(`Polling for executionId ${executionId} timed out after ${Date.now() - currentPollingStartTime}ms.`);
          dispatch(updateFlowExecution({flowId, executionId: undefined}));
          errorCallback(
            `Execution failed!`,
            `Operation timed out. Id: ${executionId}`
          );
          pollingTimeoutUtils.unregister(executionId);
          return;
        }
      }
    }
  }, [executionId, maxPollingTime, dispatch, errorCallback, successCallback, flowId, pollingTimeoutUtils, stepId, pollingInterval, queryInfo, currentPollingStartTime, generateExecutionErrorHints]);
};
