"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSubscription = exports.Subscription = void 0;
const swagger_1 = require("@nestjs/swagger");
const class_validator_1 = require("class-validator");
const SubscriptionPackageId_1 = require("./SubscriptionPackageId");
class Subscription {
}
__decorate([
    (0, swagger_1.ApiProperty)({ type: String }),
    (0, class_validator_1.IsDefined)(),
    __metadata("design:type", String)
], Subscription.prototype, "id", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: String }),
    (0, class_validator_1.IsDefined)(),
    __metadata("design:type", String)
], Subscription.prototype, "userId", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: SubscriptionPackageId_1.SubscriptionPackageId }),
    (0, class_validator_1.IsDefined)(),
    __metadata("design:type", String)
], Subscription.prototype, "packageId", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: Date }),
    (0, class_validator_1.IsDefined)(),
    __metadata("design:type", Date)
], Subscription.prototype, "validFrom", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: Date }),
    (0, class_validator_1.IsDefined)(),
    __metadata("design:type", Date)
], Subscription.prototype, "validUntil", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: Date }),
    (0, class_validator_1.IsDefined)(),
    __metadata("design:type", Date)
], Subscription.prototype, "created", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: Date }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Date)
], Subscription.prototype, "deleted", void 0);
exports.Subscription = Subscription;
const isSubscription = (maybeSubscription) => {
    return typeof maybeSubscription === "object"
        && maybeSubscription !== null
        && "id" in maybeSubscription && typeof maybeSubscription.id === "string"
        && "packageId" in maybeSubscription && Object.values(SubscriptionPackageId_1.SubscriptionPackageId).includes(maybeSubscription.packageId)
        && "validFrom" in maybeSubscription && maybeSubscription.validFrom instanceof Date
        && "validUntil" in maybeSubscription && maybeSubscription.validUntil instanceof Date
        && "created" in maybeSubscription && maybeSubscription.created instanceof Date
        && ("deleted" in maybeSubscription ? maybeSubscription.deleted instanceof Date : true);
};
exports.isSubscription = isSubscription;
