"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = void 0;
const PortBase_1 = require("./PortBase");
const swagger_1 = require("@nestjs/swagger");
const class_validator_1 = require("class-validator");
const Source_1 = require("./Source");
const class_transformer_1 = require("class-transformer");
let Input = class Input extends PortBase_1.PortBase {
};
__decorate([
    (0, swagger_1.ApiProperty)({
        oneOf: [
            { $ref: (0, swagger_1.getSchemaPath)(Source_1.EnvSource) },
            { $ref: (0, swagger_1.getSchemaPath)(Source_1.OutputSource) },
            { $ref: (0, swagger_1.getSchemaPath)(Source_1.ValueSource) }
        ],
        discriminator: {
            propertyName: "type",
            mapping: {
                "env": (0, swagger_1.getSchemaPath)(Source_1.EnvSource),
                "output": (0, swagger_1.getSchemaPath)(Source_1.OutputSource),
                "value": (0, swagger_1.getSchemaPath)(Source_1.ValueSource)
            }
        }
    }),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => Object, {
        keepDiscriminatorProperty: true,
        discriminator: {
            property: "type",
            subTypes: [
                { value: Source_1.EnvSource, name: "env" },
                { value: Source_1.OutputSource, name: "output" },
                { value: Source_1.ValueSource, name: "value" }
            ]
        }
    }),
    __metadata("design:type", Object)
], Input.prototype, "source", void 0);
Input = __decorate([
    (0, swagger_1.ApiExtraModels)(Source_1.EnvSource),
    (0, swagger_1.ApiExtraModels)(Source_1.OutputSource),
    (0, swagger_1.ApiExtraModels)(Source_1.ValueSource)
], Input);
exports.Input = Input;
