import WeeklyCalendar from "../../../common/components/weekly-calendar/WeeklyCalendar";
import Container from "../../../common/components/container/Container";
import {useEffect, useState} from "react";
import Button, {ButtonType} from "../../../common/components/button/Button";
import {UiColor} from "../../../utils/constants/UiColor";
import {
  getOffsetOfTimezones,
  isDefined,
  mapWeeklySchedulerEntryToTimezone,
  Timezone,
  WeeklySchedulerEntry
} from "@cranq-gpt-lowcode/contracts";

const mapInitialEntriesToTimezone = (
  entries: SchedulerPanelProps["initialEntries"] = [],
  timezoneName: SchedulerPanelProps["timezoneName"] = Timezone.UTC
) => entries
  .map((entry) => mapWeeklySchedulerEntryToTimezone(entry, timezoneName))
  .filter(isDefined);

type SchedulerPanelProps = {
  initialEntries?: WeeklySchedulerEntry[];
  maxEntriesLimit?: number;
  timezoneName?: string;
  onSetSchedule?: (entries: WeeklySchedulerEntry[]) => void;
}
const SchedulerPanel = (
  {
    initialEntries = [],
    maxEntriesLimit,
    timezoneName = (new Intl.DateTimeFormat()).resolvedOptions().timeZone,
    onSetSchedule
  }: SchedulerPanelProps
) => {
  const timeZoneOffsetToUtc = getOffsetOfTimezones(new Date(), timezoneName)
  const validTimezoneName = isNaN(timeZoneOffsetToUtc) ? "UTC" : timezoneName;
  const validTimeZoneOffsetToUtc = timeZoneOffsetToUtc || 0;
  const timeZoneDisplayed = `${validTimezoneName} (UTC${timeZoneOffsetToUtc >= 0 ? `+${validTimeZoneOffsetToUtc}` : `${validTimeZoneOffsetToUtc}`})`;

  const [schedulerEntries, setSchedulerEntries] = useState<WeeklySchedulerEntry[]>(mapInitialEntriesToTimezone(initialEntries, timezoneName));
  const [entriesChanged, setEntriesChanged] = useState(false);
  const entriesLimitReached = maxEntriesLimit !== undefined && schedulerEntries.length >= maxEntriesLimit;

  useEffect(() => {
    if (!entriesChanged) {
      setSchedulerEntries(mapInitialEntriesToTimezone(initialEntries, timezoneName));
    } else {
      // potential conflict between server side data and manual changes... how to handle?
    }
  }, [entriesChanged, initialEntries, timezoneName]);

  const handleHourSlotClick = (weekday: number, hour: number) => {
    setEntriesChanged(true);
    setSchedulerEntries((currentEntries) => {
      const foundEntryIndex = currentEntries.findIndex((entry) => entry.weekday === weekday && entry.hour === hour);
      if (entriesLimitReached && foundEntryIndex < 0) { // cannot add more schedulerEntries
        return currentEntries;
      }
      return foundEntryIndex >= 0
        ? currentEntries.slice(0, foundEntryIndex).concat(currentEntries.slice(foundEntryIndex + 1))
        : currentEntries.concat({weekday, hour, timezone: timezoneName})
    });
  }

  const handleSaveChanges = () => {
    setEntriesChanged(false)
    return onSetSchedule && onSetSchedule(schedulerEntries);
  };

  const handleResetChanges = () => {
    setEntriesChanged(false)
    setSchedulerEntries(mapInitialEntriesToTimezone(initialEntries, timezoneName));
  }

  const handleClearAll = () => {
    setEntriesChanged(true)
    setSchedulerEntries([])
  }

  return (
    <Container height={"full"} overflowY={"auto"}>
      <p className={"flex flex-row justify-between text-sm"}>
        <span>Current timezone: {timeZoneDisplayed}</span>
        {maxEntriesLimit !== undefined ?
          <span className={`text-sm ${entriesLimitReached ? UiColor.TextColor.RED : ""}`}>Available
          entries: {schedulerEntries.length}/{maxEntriesLimit}</span> : null}
      </p>
      <WeeklyCalendar
        startDay={"Monday"}
        reservations={schedulerEntries}
        allowNewReservation={!entriesLimitReached}
        onHourSlotClick={handleHourSlotClick}
      />
      <div className={"flex flex-row gap-4 text-sm justify-end"}>
        <Button title={"Clear all"} type={ButtonType.SECONDARY}
                disabled={schedulerEntries.length === 0}
                onClick={handleClearAll}/>
        <Button title={"Reset changes"} type={ButtonType.SECONDARY}
                disabled={!entriesChanged}
                onClick={handleResetChanges}/>
        <Button title={"Save changes"}
                disabled={!entriesChanged}
                onClick={handleSaveChanges}/>
      </div>
    </Container>
  )
}

export default SchedulerPanel;
