import {GroupModel, GroupModelV0, GroupModelV1, isGroupModel, isGroupModelV0, isGroupModelV1} from "./GroupModel";
import {isStepModel, isStepModelV0, isStepModelV1, StepModel, StepModelV0, StepModelV1} from "./StepModel";

// This must be versioned to support backward compatibility!
export type GroupExportModel = {
  group: GroupModel,
  steps: StepModel[]
}

export const isGroupExportModel = (maybeGroupExportModel: unknown): maybeGroupExportModel is GroupExportModel => {
  if (typeof maybeGroupExportModel !== "object" || maybeGroupExportModel === null) {
    return false;
  }
  const {group, steps} = maybeGroupExportModel as GroupExportModel;
  return isGroupModel(group) && Array.isArray(steps) && steps.every(isStepModel);

}

// versioning
export type GroupExportModelV2 = GroupExportModel;
export const isGroupExportModelV2 = isGroupExportModel;

export type GroupExportModelV1 = {
  group: GroupModelV1,
  steps: StepModelV1[]
}
export const isGroupExportModelV1 = (maybeGroupExportModel: unknown): maybeGroupExportModel is GroupExportModelV1 => {
  if (typeof maybeGroupExportModel !== "object" || maybeGroupExportModel === null) {
    return false;
  }
  const {group, steps} = maybeGroupExportModel as GroupExportModelV1;
  return isGroupModelV1(group) && Array.isArray(steps) && steps.every(isStepModelV1);
}
export type GroupExportModelV0 = {
  group: GroupModelV0,
  steps: StepModelV0[]
}
export const isGroupExportModelV0 = (maybeGroupExportModel: unknown): maybeGroupExportModel is GroupExportModelV0 => {
  if (typeof maybeGroupExportModel !== "object" || maybeGroupExportModel === null) {
    return false;
  }
  const {group, steps} = maybeGroupExportModel as GroupExportModelV0;
  return isGroupModelV0(group) && Array.isArray(steps) && steps.every(isStepModelV0);
}
