export enum InlineIconName {
  ADD = "add",
  PLAY = "play",
  DELETE = "delete",
  DOWNLOAD = "download",
  EDIT = "edit",
  INSPECT = "inspect",
  COPY = "copy",
  PASTE = "paste",
  REFRESH = "refresh",
  SETTINGS = "settings",
  LAB = "lab",
  LINK = "link",
  UNLINK = "unlink",
  SHARE = "share",
  SCHEDULE = "schedule",
  DESCHEDULE = "deschedule",
  WEBHOOK = "webhook",
  WROKFLOW = "workflow",
  DELETE_WEBHOOK = "deleteWebhook",
  UP = "up",
  RIGHT = "right",
  DOWN = "down",
  BACK = "back",
  VERTICAL_LIST = "verticalList",
  HORIZONTAL_LIST = "horizontalList",
  CLOSE = "close",
  INFO_BIG = "infoBig",
  CODE = "code",
  HINT = "hint",
  MAGICK = "magick",
  CHECK = "check",
  CHECK_BIG = "checkBig",
  WARNING_BIG = "warningBig",
  ERROR_BIG = "errorBig",
  QUESTION_BIG = "questionBig",
  LOGO_GOOGLE = "logoGoogle",
  LOGO_APPLE = "logoApple",
  LOGO_FACEBOOK = "logoFacebook",
  LOGO_GITHUB = "logoGithub",
  LOGO_MICROSOFT = "logoMicrosoft",
  LOGO_TWITTER = "logoTwitter",
  LOGO_DISCORD = "logoDiscord",
}
