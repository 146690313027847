"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateExecutionErrorHintsResponse = void 0;
const swagger_1 = require("@nestjs/swagger");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
class InputErrorHints {
}
__decorate([
    (0, swagger_1.ApiProperty)({ type: String }),
    (0, class_validator_1.Equals)("BAD_INPUT"),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], InputErrorHints.prototype, "category", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: [String] }),
    __metadata("design:type", Array)
], InputErrorHints.prototype, "invalidInputs", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: Object }),
    (0, class_validator_1.IsNotEmptyObject)(),
    __metadata("design:type", Object)
], InputErrorHints.prototype, "exampleInputs", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: String }),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], InputErrorHints.prototype, "summary", void 0);
class CodeErrorHints {
}
__decorate([
    (0, swagger_1.ApiProperty)({ type: String }),
    (0, class_validator_1.Equals)("BAD_CODE"),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], CodeErrorHints.prototype, "category", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: String }),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], CodeErrorHints.prototype, "fixedTaskDescription", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: String }),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], CodeErrorHints.prototype, "analysis", void 0);
let GenerateExecutionErrorHintsResponse = class GenerateExecutionErrorHintsResponse {
};
__decorate([
    (0, swagger_1.ApiProperty)({
        oneOf: [
            { $ref: (0, swagger_1.getSchemaPath)(InputErrorHints) },
            { $ref: (0, swagger_1.getSchemaPath)(CodeErrorHints) },
        ],
        discriminator: {
            propertyName: "category",
            mapping: {
                "BAD_INPUT": (0, swagger_1.getSchemaPath)(InputErrorHints),
                "BAD_CODE": (0, swagger_1.getSchemaPath)(CodeErrorHints),
            }
        }
    }),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => Object, {
        discriminator: {
            property: "category",
            subTypes: [
                { value: InputErrorHints, name: "BAD_INPUT" },
                { value: CodeErrorHints, name: "BAD_CODE" },
            ]
        }
    }),
    __metadata("design:type", Object)
], GenerateExecutionErrorHintsResponse.prototype, "hints", void 0);
GenerateExecutionErrorHintsResponse = __decorate([
    (0, swagger_1.ApiExtraModels)(InputErrorHints),
    (0, swagger_1.ApiExtraModels)(CodeErrorHints)
], GenerateExecutionErrorHintsResponse);
exports.GenerateExecutionErrorHintsResponse = GenerateExecutionErrorHintsResponse;
