export enum UiElementSelectors {
  AddBlankFirstStep = "button[title='Add a blank first step']",
  StepDescription = "#step-description",
  StepTitle = "#step header input[name = \"title\"]",
  AddNewInput = "button[title = \"+ Add new input\"]",
  AddNewOutput = "button[title = \"+ Add new output\"]",
  LastInputName = "#inputs .grid > div:last-child input[name='description']",
  LastInputValue = "#inputs .grid > div:last-child input[name='value']",
  LastOutputName = "#outputs .grid > div:last-child input[name='description']",
  LastOutputValue = "#outputs .grid > div:last-child input[name='value']",
  ProceedButton = "button#proceed",
  StatusLine = "#statusLine",
  InsertFirstStep = "#timeline .cranq-component-timeline-item .cranq-component-timeline-add-item",
  AddNewStep = "#timeline .cranq-component-timeline-entry:last-of-type .cranq-component-timeline-add-item:last-of-type",
  AddWorkflowParameters = "button[title='+ Add workflow parameters']",
  AddNewWorkflowParameter = "button[title='+ Add new parameter']",
}

export enum UiElementIndexedSelectors {
  "InputName#N" = "#inputs .grid > div:nth-child({INDEX}) input[name='description']",
  "InputValue#N" = "#inputs .grid > div:nth-child({INDEX}) input[name='value']",
  "LinkInputValue#N" = "#inputs .grid > div:nth-child({INDEX}) input[name='value'] ~ * > button[title~='Link']",
  "OutputName#N" = "#outputs .grid > div:nth-child({INDEX}) input[name='description']",
  "OutputValue#N" = "#outputs .grid > div:nth-child({INDEX}) input[name='value']",
  "WorkflowParameterName#N" = "#workflowParameters .grid > div:nth-child({INDEX}) input[name='description']",
  "WorkflowParameterValue#N" = "#workflowParameters .grid > div:nth-child({INDEX}) input[name='value']",
  //first step has two add step buttons, the second one is the one we want to target with ":not(:first-child)"
  "AddStepAfter#N" = "#timeline *:nth-child({INDEX} of .cranq-component-timeline-entry) .cranq-component-timeline-item ~.cranq-component-timeline-add-item",
  "TimelineStep#N" = "#timeline *:nth-child({INDEX} of .cranq-component-timeline-entry) .cranq-component-timeline-item",
}

export type UiElementSelectorKey = keyof typeof UiElementSelectors;
export const isUiElementSelectorKey = (maybeKey: unknown): maybeKey is UiElementSelectorKey => {
  return typeof maybeKey === "string" && Object.keys(UiElementSelectors).includes(maybeKey);
}

export type UiElementIndexedSelectorKey = keyof typeof UiElementIndexedSelectors;
export const isUiElementIndexedSelectorKey = (maybeKey: unknown): maybeKey is UiElementIndexedSelectorKey => {
  return typeof maybeKey === "string" && Object.keys(UiElementIndexedSelectors).includes(maybeKey);
}
export const normalizeUiElementIndexedSelectorKey = (selectorExpression: string): string => {
  return selectorExpression.replace(/#[0-9]+$/, "#N");
}

export type UiElementSelector =
  typeof UiElementSelectors[UiElementSelectorKey]
  | typeof UiElementIndexedSelectors[UiElementIndexedSelectorKey];

export type UiElementSelectorExpression = UiElementSelectorKey | UiElementIndexedSelectorKey
export const isUiElementSelectorExpression = (maybeExpression: unknown): maybeExpression is UiElementSelectorExpression => {
  return typeof maybeExpression === "string" && (
    isUiElementSelectorKey(maybeExpression) ||
    isUiElementIndexedSelectorKey(normalizeUiElementIndexedSelectorKey(maybeExpression))
  );
}
