export namespace UiColor {
  export enum TextColor {
    BLACK = "text-cr-black2",
    BLUE = "text-cr-blue3",
    DISABLED = "text-cr-gray6",
    GOLD = "text-cr-gold2",
    GRAY = "text-cr-gray7",
    GREEN = "text-cr-green1",
    LIGHT_GRAY = "text-cr-gray8",
    RED = "text-cr-red",
    WHITE = "text-cr-white1",
    TRANSPARENT = "text-transparent",
  }

  export enum BorderColor {
    BLACK = "border-cr-black1",
    BLUE = "border-cr-blue1",
    GOLD = "border-cr-gold1",
    GRAY = "border-cr-gray1",
    GREEN = "border-cr-green2",
    RED = "border-cr-red",
    INPUT_GRAY = "border-cr-gray2",
    TRANSPARENT = "border-transparent",
  }

  export enum BackgroundColor {
    BLACK = "bg-cr-black1",
    BLUE = "bg-cr-blue2",
    DISABLED = "bg-cr-gray4",
    GOLD = "bg-cr-gold1",
    GRAY = "bg-cr-gray3",
    DARK_GRAY = "bg-cr-dark-gray",
    GREEN = "bg-cr-green2",
    INPUT = "bg-cr-gray5",
    OFF_WHITE = "bg-cr-white2",
    RED = "bg-cr-red",
    WHITE = "bg-cr-white1",
    TRANSPARENT = "bg-transparent",
  }

  export enum ShadowColor {
    BLACK_MEDIUM = "shadow-cr-black-medium",
    BLACK_LIGHT = "shadow-cr-black-light",
    GOLD_MEDIUM = "shadow-cr-gold-medium"
  }

}
