import {UiColor} from "../../../utils/constants/UiColor";
import {classNames} from "../../../utils/classNames";
import Button, {ButtonType} from "../../../common/components/button/Button";

type TimelineAddItemProps = {
  enabled?: boolean,
  showOnHoverOnly?: boolean,
  isInserted?: boolean,
  isDeleted?: boolean,
  // events
  onInsert?: () => void
  onImport?: () => void
}
const TimelineAddItem = (
  {
    enabled = true,
    showOnHoverOnly = true,
    isInserted,
    isDeleted,
    onInsert,
    onImport
  }: TimelineAddItemProps
) => {

  const handleImport = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.stopPropagation();
    onImport && onImport();
  }

  return (
    <div className={classNames(`
    cranq-component-timeline-add-item
    ${enabled ? "" : "invisible"} 
    relative 
    flex items-center 
    ${(isInserted || isDeleted) ? "h-0" : "h-4"} w-full px-1
    transition-[height] duration-500 ease-in-out
    group 
    cursor-pointer
    `)} onClick={() => enabled && onInsert && onInsert()}>
      <div className={classNames(`
      flex-grow 
      border-cr-gold border-t-2 border-dashed 
      transition duration-500
      ${showOnHoverOnly ? "opacity-0" : ""} group-hover:opacity-100
      `)}/>
      <div className={classNames(`
      absolute z-10 right-[-1rem] 
      h-6
      flex items-center 
      transition duration-500 ease-bounce-in
      ${showOnHoverOnly ? " opacity-0 scale-125 translate-x-10" : ""} 
      group-hover:opacity-100
      group-hover:scale-100
      group-hover:translate-x-0
      `)}>
        <div className={`${UiColor.BorderColor.BLACK} border-[0.75rem] border-y-transparent border-l-transparent`}/>
        <div
          className={`${UiColor.BackgroundColor.BLACK} ${UiColor.TextColor.WHITE} text-xs h-full px-2 flex items-center gap-2`}>
          <span>
          + Add new step or
          </span>
          <Button title={"Import"} type={ButtonType.SECONDARY} padding={false} onClick={handleImport}/>
        </div>
      </div>
    </div>
  );
}

export default TimelineAddItem;
