import {useState} from "react";
import {ModalEditorProps} from "../ModalEditor";

type OpenModalEditorParams = {
  title: string;
  content: string;
  handleOnClose?: (value: string) => void;
}

type ModalEditorHookReturn = {
  modalEditorProps?: ModalEditorProps;
  openModalEditor: (params: OpenModalEditorParams) => void;
}
export const useModalEditor = (): ModalEditorHookReturn => {
  const [
    modalEditorProps,
    setModalEditorProps
  ] = useState<ModalEditorProps | undefined>(
    undefined
  );

  const handleContentChange = (value: string) => {
    setModalEditorProps((props) => {
      if (props) {
        return {...props, content: value};
      }
    });
  };

  const openModalEditor = (params: OpenModalEditorParams) => {
    const {title, content, handleOnClose} = params;
    if (!handleOnClose) {
      setModalEditorProps({
        content,
        title,
        readOnly: true,
        onCloseClick: () => {
          setModalEditorProps(undefined);
        }
      });
    } else {
      setModalEditorProps({
        content,
        title,
        readOnly: false,
        onCloseClick: () => {
          setModalEditorProps((modalEditorProps) => {
            if (modalEditorProps) {
              handleOnClose(modalEditorProps.content);
            }
            return undefined;
          });
        },
        onChange: handleContentChange
      });
    }
  };

  return {
    modalEditorProps,
    openModalEditor
  };
};
