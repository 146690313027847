import {MESSAGE_TYPE} from "./messageTypeColorMapper";
import {InlineIconName} from "../components/inline-icon/InlineIconName";

export const messageTypeIconMapper = (type: MESSAGE_TYPE): InlineIconName | undefined => {
  switch (type) {
    case MESSAGE_TYPE.INFO:
      return InlineIconName.INFO_BIG;
    case MESSAGE_TYPE.QUESTION:
      return InlineIconName.QUESTION_BIG;
    case MESSAGE_TYPE.WARNING:
      return InlineIconName.WARNING_BIG;
    case MESSAGE_TYPE.ERROR:
      return InlineIconName.ERROR_BIG;
    case MESSAGE_TYPE.SUCCESS:
      return InlineIconName.CHECK_BIG;
  }
};
