import {UiElementIndexedSelectors, UiElementSelector, UiElementSelectors} from "./UiElementSelectors";
import {LessonFloatingUiPlacement, LessonFloatingUiPlacements} from "./LessonFloatingUiPlacements";

export const LessonFloatingUiPlacementMap: Record<UiElementSelector, LessonFloatingUiPlacement> = {
  [UiElementSelectors.AddBlankFirstStep]: LessonFloatingUiPlacements.Top,
  [UiElementSelectors.StepDescription]: LessonFloatingUiPlacements.Bottom,
  [UiElementSelectors.StepTitle]: LessonFloatingUiPlacements.Bottom,
  [UiElementSelectors.AddNewInput]: LessonFloatingUiPlacements.Top,
  [UiElementSelectors.AddNewOutput]: LessonFloatingUiPlacements.Top,
  [UiElementSelectors.LastInputName]: LessonFloatingUiPlacements.Top,
  [UiElementSelectors.LastInputValue]: LessonFloatingUiPlacements.Top,
  [UiElementSelectors.LastOutputValue]: LessonFloatingUiPlacements.Top,
  [UiElementSelectors.LastOutputName]: LessonFloatingUiPlacements.Top,
  [UiElementIndexedSelectors["InputName#N"]]: LessonFloatingUiPlacements.Top,
  [UiElementIndexedSelectors["InputValue#N"]]: LessonFloatingUiPlacements.Top,
  [UiElementIndexedSelectors["OutputName#N"]]: LessonFloatingUiPlacements.Top,
  [UiElementIndexedSelectors["OutputValue#N"]]: LessonFloatingUiPlacements.Top,
  [UiElementSelectors.ProceedButton]: LessonFloatingUiPlacements.Top,
  [UiElementSelectors.StatusLine]: LessonFloatingUiPlacements.Top,
  [UiElementSelectors.InsertFirstStep]: LessonFloatingUiPlacements.Top,
  [UiElementSelectors.AddNewStep]: LessonFloatingUiPlacements.Top,
  [UiElementIndexedSelectors["AddStepAfter#N"]]: LessonFloatingUiPlacements.Top,
  [UiElementIndexedSelectors["LinkInputValue#N"]]: LessonFloatingUiPlacements.Top,
  [UiElementSelectors.AddWorkflowParameters]: LessonFloatingUiPlacements.Top,
  [UiElementIndexedSelectors["WorkflowParameterName#N"]]: LessonFloatingUiPlacements.Top,
  [UiElementIndexedSelectors["WorkflowParameterValue#N"]]: LessonFloatingUiPlacements.Top,
  [UiElementSelectors.AddNewWorkflowParameter]: LessonFloatingUiPlacements.Top,
  [UiElementIndexedSelectors["TimelineStep#N"]]: LessonFloatingUiPlacements.Top,
}
