import {RootState} from "../../../store";
import {ThunkAction} from "@reduxjs/toolkit";
import {useUpdateJobMutation} from "../../../schedulerApi";
import {ScheduledJob, WeeklySchedulerEntry} from "@cranq-gpt-lowcode/contracts";
import {UserModel} from "../../../types/UserModel";
import {FlowModel} from "../../../types/FlowModel";
import {StepModel} from "../../../types/StepModel";
import {EnvironmentModel} from "../../../types/EnvironmentModel";
import {mapStepModelToApiStepModel} from "../../../utils/mapStepModelToApiStepModel";
import {calculateFlowHash} from "../../../utils/calculateFlowHash";
import {createResolvedEnvironment} from "../../../utils/createResolvedEnvironment";
import {selectGlobalParameters} from "../../../globalParametersSlice";

export const updateScheduledJobAction = (
  jobId: ScheduledJob["jobId"],
  userId: UserModel["id"],
  flow: FlowModel,
  steps: StepModel[],
  environment: EnvironmentModel,
  schedulerEntries: WeeklySchedulerEntry[],
  updateScheduledJobTrigger: ReturnType<typeof useUpdateJobMutation>[0]
): ThunkAction<Promise<void>, RootState, any, any> => async (
  dispatch,
  getState
) => {
  const globalParameters = selectGlobalParameters(getState())?.model;
  const result = await updateScheduledJobTrigger({
    jobId,
    flowId: flow.id,
    flowName: flow.title,
    flowDescription: flow.description,
    flow: steps.map((step) => mapStepModelToApiStepModel(step, steps, environment.variables)),
    environment: createResolvedEnvironment(environment.variables, globalParameters),
    hash: calculateFlowHash(flow, steps, environment.variables),
    weeklySchedule: schedulerEntries
  });
  if ("data" in result) {
    return;
  } else {
    throw result.error; // FIXME: show toast
  }
}
