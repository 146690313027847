import {createContext, ReactNode, useEffect, useMemo, useRef} from "react";
import {FlowModel} from "../../types/FlowModel";
import {ShepherdOptionsWithType, ShepherdTour, Tour, useShepherdTour} from "react-shepherd";
import {safeJsonParse} from "../../utils/safeJsonParse";
import {isLesson} from "./types/Lesson";
import {toShepherdStep} from "./utils/toShepherdStep";
import {offset} from '@floating-ui/dom';
import ReactGA from "react-ga4";

type LessonState = {
  startLesson?: () => void;
}
export const LessonContext = createContext<LessonState>({
  startLesson: undefined
});

type LessonProviderProps = {
  flowId: FlowModel["id"];
  lessonDescriptor: FlowModel["lessonDescriptor"];
  children: ReactNode;
}

const LessonContextProvider = (
  {
    flowId,
    lessonDescriptor,
    children
  }: LessonProviderProps) => {
  const lastTour = useRef<{ tour: Tour, flowId: FlowModel["id"] } | null>(null);
  const lessonSteps = useMemo(() => {
      const maybeLesson = safeJsonParse(lessonDescriptor ?? "");
      const lesson = isLesson(maybeLesson) ? maybeLesson : undefined;
      return (lesson?.steps ?? [])
        .map(toShepherdStep)
        .filter((step): step is ShepherdOptionsWithType => step !== undefined);
    }
    , [lessonDescriptor]);
  const tourOptions = useMemo(() => ({
    defaultStepOptions: {
      classes: 'cranq-lesson',
      cancelIcon: {
        enabled: true
      },
      floatingUIOptions: {
        middleware: [offset({mainAxis: 12, crossAxis: 0})]
      }
    }
  }), []);
  const tour = useShepherdTour({
    tourOptions,
    steps: lessonSteps
  });
  useEffect(() => {
    lastTour.current = {tour, flowId};
    const handleLessonComplete = ((flowId: FlowModel["id"]) => {
      ReactGA.event({
        category: "lesson",
        action: "LESSON_COMPLETED",
        label: `Lesson: ${flowId}`,
        nonInteraction: true,
        transport: "xhr"
      });
    }).bind(this, flowId);
    lastTour.current.tour.on("complete", handleLessonComplete);

    const handleLessonCancel = ((flowId: FlowModel["id"]) => {
      ReactGA.event({
        category: "lesson",
        action: "LESSON_CANCELED",
        label: `Lesson: ${flowId}`,
        nonInteraction: true,
        transport: "xhr"
      });
    }).bind(this, flowId);
    lastTour.current.tour.on("cancel", handleLessonCancel);

    return () => {
      if (lastTour.current) {
        if (lastTour.current.tour.isActive()) {
          lastTour.current.tour.cancel();
        }
        lastTour.current.tour.off("complete", handleLessonComplete);
        lastTour.current.tour.off("cancel", handleLessonCancel);
        lastTour.current = null;
      }
    }
  }, [flowId, tour]);

  const handleLessonStart = useMemo(() => ((flowId: FlowModel["id"]) => {
    if (!tour.isActive()) {
      tour.start();
      ReactGA.event({
        category: "lesson",
        action: "LESSON_STARTED",
        label: `Lesson: ${flowId}`,
        nonInteraction: true,
        transport: "xhr"
      });
    }
  }).bind(this, flowId), [flowId, tour]);

  return lessonSteps.length > 0 ? (
    <LessonContext.Provider value={{startLesson: handleLessonStart}}>
      <ShepherdTour steps={lessonSteps} tourOptions={tourOptions}>
        {children}
      </ShepherdTour>
    </LessonContext.Provider>
  ) : (
    <>
      {children}
    </>
  );
};

export default LessonContextProvider;
