import Home from "../../components/home/Home";
import Layout from "../../../common/components/layout/Layout";
import {useSearchParams} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import {GettingStarted} from "../../components/getting-started/GettingStarted";
import Sidebar from "../../components/sidebar/Sidebar";
import RequestPermission from "../../components/request-permission/RequestPermission";
import {AuthContext} from "../../components/auth-context-provider/AuthContextProvider";
import {useCreateNewFlow} from "../../hooks/useCreateNewFlow";

export enum HomePageAction {
  CREATE_NEW_FLOW = "createNewFlow",
  REGISTER = "register"
}

const HomePage = () => {
  const [searchParams] = useSearchParams();
  const action = searchParams.get("action");
  const newFlowTitle = searchParams.get("newFlowTitle") ?? "";
  const newFlowDescription = searchParams.get("newFlowDescription") ?? "";
  const validAction = Object.values(HomePageAction).find((a) => a === action);

  const {permissions} = useContext(AuthContext);

  const createNewFlow = useCreateNewFlow();
  useEffect(() => {
    if (validAction === HomePageAction.CREATE_NEW_FLOW) {
      createNewFlow(newFlowTitle, newFlowDescription);
    }
  });
  const forceLogin = validAction === HomePageAction.REGISTER;

  return (
    <>
      <RequestPermission permissionName={"SHARE_DATA_WITH_LLMS"}/>
      <Layout
        sideBarContent={<Sidebar showFlowList={true} forceLogin={forceLogin}/>}
        leftPanelContent={(
          <Home onStartNewWorkflow={(withMentor) => createNewFlow("", "", withMentor)}/>
        )}
        rightPanelContent={(
          <GettingStarted
            permittedToStartMentor={permissions.SHARE_DATA_WITH_LLMS.granted}
            onTitleClick={(title, description) => createNewFlow(title, description, true)}
          />
        )}
      />
    </>
  );
};

export default HomePage;
