import {StepModel} from "../../types/StepModel";
import {GroupModel} from "../../types/GroupModel";
import {sanitizePortsBeforeSharing} from "./sanitizePortsBeforeSharing";
import {wrapText} from "./wrapText";
import {utfSafeBase64Encode} from "./utfSafeBase64Encode";
import {FlowModel} from "../../types/FlowModel";
import {EnvironmentModel} from "../../types/EnvironmentModel";

import {FlowExportModel} from "../../types/FlowExportModel";
import {NilUuid} from "../../../utils/createNewUuid";
import {isLinkedToGlobalParameter} from "../../components/environment-editor/utils/isLinkedToGlobalParameter";

export const encodeFlow = (
  flow: FlowModel,
  groups: GroupModel[],
  steps: StepModel[],
  environment: EnvironmentModel
): string => {

  const sharedFlowId: FlowModel["id"] = NilUuid; // required by data model but irrelevant for step sharing
  const sharedUserId: FlowModel["userId"] = NilUuid; // required by data model but irrelevant for step sharing
  const environmentVariableNames = environment.variables.map((variable) => variable.key) ?? [];
  const stepIds = steps.map((step) => step.id);

  const sanitizedFlow: FlowModel = (({idV0: _, ...flowToExport}) => ({
    ...flowToExport,
    id: sharedFlowId,
    userId: sharedUserId,
    hash: undefined
  }))(flow);
  const sanitizedGroups: GroupModel[] = groups.map((group) => ({
    ...group,
    flowId: sharedFlowId
  }));
  const sanitizedSteps: StepModel[] = steps.map(
    (step, idx) => ({
      ...sanitizePortsBeforeSharing(step, stepIds, environmentVariableNames),
      index: idx + 1, // reset index to start from 1
      flowId: sharedFlowId,
    }));
  const sanitizedParameters: FlowExportModel["parameters"] = environment.variables.map(
    (variable) => ({
      key: variable.key,
      // preserve references to global parameters, wipe any other value
      value: isLinkedToGlobalParameter(variable) ? variable.value : undefined
    })
  ) ?? []

  const exportedFlow: FlowExportModel = {
    flow: sanitizedFlow,
    groups: sanitizedGroups,
    steps: sanitizedSteps,
    parameters: sanitizedParameters
  }
  const exportedFlowAsString = utfSafeBase64Encode(JSON.stringify(exportedFlow));

  return `
--------------------------------------------------------------------------------
 subject: cranq.ai flow export
 version: 1.0
   title: ${flow.title}
------------------------------------ BEGIN -------------------------------------
${wrapText(exportedFlowAsString, 80)}
------------------------------------- END --------------------------------------
`;
}
