import Container, {ContainerProps} from "../../../common/components/container/Container";
import OAuthConnectButton from "../oauth-connect-button/OAuthConnectButton";
import React, {useContext} from "react";
import {AuthContext} from "../auth-context-provider/AuthContextProvider";
import {InlineIconName} from "../../../common/components/inline-icon/InlineIconName";
import {UiColor} from "../../../utils/constants/UiColor";
import {IconButtonProps} from "../../../common/components/icon-button/IconButton";
import Clickable from "../../../common/components/clickable/Clickable";
import {openLoginDialog} from "../login/utils/openLoginDialog";
import {useDialog} from "../../../common/components/dialog/hook/useDialog";
import Dialog from "../../../common/components/dialog/Dialog";
import {useToast} from "../../../common/components/toast/hook/useToast";
import {Api, SubscriptionPackageId} from "@cranq-gpt-lowcode/contracts";
import {MESSAGE_TYPE} from "../../../common/helper/messageTypeColorMapper";
import {formatSubscriptionPackageName} from "../../utils/formatSubscriptionPackageName";
import {Link} from "react-router-dom";

export type OAuthConnectButtonListProps = {
  apis: string[];
}

const SubscriptionRequiredMessage = (
  {
    requiredSubscriptions
  }: { requiredSubscriptions: SubscriptionPackageId[] }
) => requiredSubscriptions[0] ? ( // there is at least one element
  <div>
    <p>
      {requiredSubscriptions.length > 1
        ? <>One of the following subscriptions is
          required: {requiredSubscriptions.map(formatSubscriptionPackageName).join(", ")}</>
        : <>The following subscription is required: {formatSubscriptionPackageName(requiredSubscriptions[0])}</>
      }
    </p>
    <p>
      Please get in touch with our sales team to get access to this feature.<br/>
      You can reach us at <Link className={"underline"} to={"https://cranq.ai/xcom"}
                                target={"_blank"}>https://cranq.ai/xcom</Link>. {/* TODO: link should belong to the subscription package and should be configurable in the DB */}
    </p>
  </div>
) : null;

const OAuthConnectButtonList = (
  {
    apis,
  }: OAuthConnectButtonListProps
) => {
  const {
    user,
    anonymousUserId,
    refetchApiConnections,
    apiConnectionsFetching,
  } = useContext(AuthContext);
  const {openDialog, closeDialog, dialogProps} = useDialog();
  const {showToast} = useToast();


  const handleSubscriptionRequired = (requiredSubscriptions: Api["requiredSubscriptions"]) => {
    if (!requiredSubscriptions || requiredSubscriptions[0] === undefined) {
      return;
    }
    openDialog({
      type: MESSAGE_TYPE.INFO,
      title: "Subscription required",
      content: <SubscriptionRequiredMessage requiredSubscriptions={requiredSubscriptions}/>,
    });
  }

  const handleLogin = () => {
    openLoginDialog(
      anonymousUserId, openDialog, closeDialog, showToast, "Please, login to use 3rd party services")
  }
  const refreshButtonProps: IconButtonProps = {
    iconName: InlineIconName.REFRESH,
    size: "small",
    onClick: () => {
      refetchApiConnections && refetchApiConnections(true);
    }
  };
  const containerHeaderProps: ContainerProps["headerProps"] = {
    title: "Third party services*",
    subtitle: <div className={"text-xs"}>
      *Your data will be processed by partners including <a className={"hover:text-cr-gold"} target={"_blank"}
                                                            rel={"noreferrer"}
                                                            href={"https://openai.com"}>OpenAI</a> and <a
      className={"hover:text-cr-gold"} target={"_blank"} rel={"noreferrer"}
      href={"https://pathfix.com"}>Pathfix</a> according to the terms
      of our <a
      className={"hover:text-cr-gold"} target={"_blank"} rel={"noreferrer"} href={"https://cranq.ai/privacy"}>privacy
      policy</a>.
    </div>,
    level: 3,
    rightSideButtonListProps: [
      refreshButtonProps
    ]
  }
  return apis.length > 0
    ? (
      <Container headerProps={containerHeaderProps} indented={false} paddingY={false}>
        {user.loggedInAt !== undefined
          ? (
            <div className={"flex flex-wrap justify-start items-center gap-4"}>
              {apiConnectionsFetching
                ? <span>Loading...</span>
                : apis.map((api) =>
                  <OAuthConnectButton key={api} api={api} onSubscriptionRequired={handleSubscriptionRequired}/>
                )}
            </div>
          )
          : (
            <span className={`${UiColor.TextColor.RED}`}>Please <Clickable onClick={handleLogin}>login</Clickable> to use 3rd party services!</span>
          )}
        {dialogProps && <Dialog {...dialogProps}/>}
      </Container>
    )
    : null;
}

export default OAuthConnectButtonList;
