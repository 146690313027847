import {FlowModel} from "../../../types/FlowModel";
import {useListJobsQuery} from "../../../schedulerApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {updateFlowById} from "../../../flowsSlice";
import {isUuid} from "../../../../utils/types/Uuid";
import {useAppDispatch} from "../../../hooks";
import {FlowState} from "../../../types/FlowState";

export const useUpdateScheduledJobId = (
  flowId: FlowModel["id"] | undefined,
  currentScheduledJobId: FlowState["scheduledJobId"]
) => {
  const dispatch = useAppDispatch();
  const {
    data: jobs,
    isLoading: isJobsLoading,
    error: jobsError
  } = useListJobsQuery(flowId ? {flowId} : skipToken, {pollingInterval: 10 * 60 * 60 * 1000});
  if (!flowId) {
    return;
  }
  if (isJobsLoading) {
    return;
  }
  if (jobsError) {
    console.error(jobsError);
    return;
  }

  if (jobs) {
    const jobsByCreatedDesc = [...jobs]
      .sort((a, b) => b.created - a.created);
    if (jobsByCreatedDesc.length > 1) {
      console.error("More than one scheduled job for flow", flowId);
    }
    const job = jobsByCreatedDesc[0];
    if (job) {
      if (isUuid(job.jobId)) {
        if (currentScheduledJobId !== job.jobId) {
          dispatch(updateFlowById({
            flowId,
            update: {
              scheduledJobId: job.jobId,
              scheduledJobExpiryDate: job.expiry,
              scheduledJobHash: job.hash
            }
          }));
        } else {
          // no change
        }
      } else {
        console.error("JobId is not a valid Uuid!", job.jobId);
      }
    } else {
      if (currentScheduledJobId) {
        dispatch(updateFlowById({
          flowId,
          update: {
            scheduledJobId: undefined,
            scheduledJobExpiryDate: undefined,
            scheduledJobHash: undefined
          }
        }));
      } else {
        // no change
      }
    }
  }
}
