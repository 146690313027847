"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStepsToRun = void 0;
function getStepsToRun(executeRequest) {
    if (executeRequest.action.type === "runAll") {
        return executeRequest.flow;
    }
    else {
        return executeRequest.flow.filter(a => executeRequest.action.stepIds.includes(a.id));
    }
}
exports.getStepsToRun = getStepsToRun;
