import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../../../store";
import {updateStepImplementation} from "../../../stepsSlice";
import {GenerateCodeForStepResponse} from "@cranq-gpt-lowcode/contracts";
import {StepModel} from "../../../types/StepModel";

export const processGenerateCodeForStepResponse = (
  stepId: StepModel["id"],
  response: GenerateCodeForStepResponse
): ThunkAction<void, RootState, any, any> =>
  (
    dispatch,
  ) => {
    dispatch(updateStepImplementation({
      stepId,
      implementation: {
        ...response.implementation,
        parametersHash: "" // just reset here, it will be calculated later
      },
      inputs: response.interface.inputs.map((input) => ({
        name: input.name,
        description: input.description,
        type: input.type
      })),
      outputs: response.interface.outputs
    }));
  };
