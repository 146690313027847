import {StepModel} from "../../types/StepModel";
import {EnvironmentModel} from "../../types/EnvironmentModel";

/**
 * Removes all values from inputs and outputs before sharing the step.
 * This is required to avoid sharing sensitive data.
 * For linked inputs values are replaced with simple empty values, unless
 *  - the linked step is also shared
 *  - linked environment variable is also shared
 */
export const sanitizePortsBeforeSharing = (
  stepModel: StepModel,
  stepIdsToKeepLinksTo: string[] = [],
  environmentVariableNamesToKeepLinksTo: EnvironmentModel["variables"][number]["key"][] = []
): StepModel => ({
  ...stepModel,
  inputs: Object.values(stepModel.inputs)
    .reduce((inputs, input) => {
      const {validationError, ...inputWithoutValidationError} = input;
      return ({
        ...inputs,
        [input.id]: {
          ...inputWithoutValidationError,
          source: (
            (input.source.type === "output" && stepIdsToKeepLinksTo.includes(input.source.stepId))
            || (input.source.type === "env" && environmentVariableNamesToKeepLinksTo.includes(input.source.name))
          )
            ? input.source
            : { // replace linked values with empty values
              type: "value",
              value: undefined
            }
        }
      });
    }, {}),
  outputs: Object.values(stepModel.outputs)
    .reduce((outputs, output) => ({
      ...outputs,
      [output.id]: {
        ...output,
        value: undefined
      }
    }), {}),
});
