"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExecutorInvocationError = void 0;
class ExecutorInvocationError extends Error {
    constructor(reason, details) {
        super(reason);
        this.reason = reason;
        this.details = details;
        Object.setPrototypeOf(this, ExecutorInvocationError.prototype);
    }
}
exports.ExecutorInvocationError = ExecutorInvocationError;
