export type Variable = {
  key: string,
  value: unknown,
  instruction?: string
}

export const isVariable = (maybeVariable: any): maybeVariable is Variable =>
  typeof maybeVariable === "object" && maybeVariable !== null
  && "key" in maybeVariable && typeof maybeVariable.key === "string"
  && ("instruction" in maybeVariable ? typeof maybeVariable.instruction === "string" : true);
// should not typecheck on `value` because it can be anything including undefined
// that results in missing property if going through JSON serialization/deserialization
