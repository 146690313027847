"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isGeneratorError = exports.GeneratorErrorReason = void 0;
var GeneratorErrorReason;
(function (GeneratorErrorReason) {
    GeneratorErrorReason["UNAVAILABLE"] = "UNAVAILABLE";
    GeneratorErrorReason["INSTRUCTION_LENGTH"] = "INSTRUCTION_LENGTH";
    GeneratorErrorReason["COMPLETION_LENGTH"] = "COMPLETION_LENGTH";
    GeneratorErrorReason["UNSUPPORTED_INSTRUCTION"] = "UNSUPPORTED_INSTRUCTION";
    GeneratorErrorReason["MODERATION"] = "MODERATION";
    GeneratorErrorReason["OTHER"] = "OTHER";
})(GeneratorErrorReason = exports.GeneratorErrorReason || (exports.GeneratorErrorReason = {}));
const isGeneratorError = (error) => {
    return typeof error === "object" && error !== null
        && "reason" in error && Object.values(GeneratorErrorReason).includes(error.reason)
        && ("message" in error ? typeof error.message === "string" : true)
        && ("can_retry" in error ? typeof error.can_retry === "boolean" : true);
};
exports.isGeneratorError = isGeneratorError;
