import {StepState} from "../../../types/StepState";
import {StepStatus} from "../../../types/StepStatus";
import {calculateImplementationParametersHash} from "../../../utils/calculateImplementationParametersHash";
import {AiModelType} from "@cranq-gpt-lowcode/contracts";

export const calculateStepStatus = (step: StepState): keyof typeof StepStatus => {
  if (step.model.aiModelType === AiModelType.CODE_WRITER) {
    if (step.model.implementation) {
      if (step.model.implementation.parametersHash === calculateImplementationParametersHash(step.model)) {
        if (step.lastExecutionError === null) {
          return "run";
        } else if (step.lastExecutionError !== undefined) {
          return "failed";
        }
      }
      return "generated";
    }
  } else {
    if (step.lastExecutionError === null) {
      return "run";
    } else if (step.lastExecutionError !== undefined) {
      return "failed";
    }
  }
  if (step.model.description?.trim() || Object.values(step.model.inputs).length > 0 || Object.values(step.model.outputs).length > 0) {
    return "in-progress";
  }
  return "blank";
}
