import {useEffect, useLayoutEffect, useRef} from 'react'

/**
 * Taken from https://usehooks-ts.com/react-hook/use-interval
 * @param callback A callback function to be executed every delay milliseconds.
 * @param delay The time, in milliseconds (thousandths of a second), the timer should delay in between
 *              executions of the specified function or code.
 *              Null if interval should be cleared.
 */
function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return
    }

    const id = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(id)
  }, [delay])
}

export default useInterval
