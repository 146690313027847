export enum ApiContext {
  EDITOR = "EDITOR",
  RUNNER = "RUNNER",
  AUTH = "AUTH",
  OAUTH = "OAUTH",
  SCHEDULER = "SCHEDULER",
  WEBHOOK = "WEBHHOK",
  MENTOR = "MENTOR",
  SUBSCRIPTIONS = "SUBSCRIPTIONS"
}

export const getApiBaseUrl = (context: ApiContext) => {
  const base = (process.env.REACT_APP_EDITOR_API_BASE_URL ?? "http://localhost:3000/").replace(/\/$/, "");
  const mentorBase = (process.env.REACT_APP_MENTOR_API_BASE_URL ?? "http://localhost:8000/ai").replace(/\/$/, "");
  switch (context) {
    case ApiContext.EDITOR:
      return `${base}/editor/v1`
    case ApiContext.RUNNER:
      return `${base}/runner/v1`
    case ApiContext.AUTH:
      return `${base}/auth/v1`
    case ApiContext.OAUTH:
      return `${base}/oauth/v1`
    case ApiContext.SCHEDULER:
      return `${base}/scheduler/v1`
    case ApiContext.WEBHOOK:
      return `${base}/webhook/v1`
    case ApiContext.SUBSCRIPTIONS:
      return `${base}/subscriptions/v1`
    case ApiContext.MENTOR:
      return `${mentorBase}/v1`
    default:
      throw new Error(`Unknown context: ${context}`)
  }
}
