import ReactGA from "react-ga4";
import {QueryDefinition, skipToken} from "@reduxjs/toolkit/query";
import {useEffect, useRef} from "react";
import {useAppDispatch} from "../hooks";
import {GeneratorErrorReason, QueryResponse, QueryResult, QueryType} from "@cranq-gpt-lowcode/contracts";
import {UseQuery} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta} from "@reduxjs/toolkit/query/react";
import {isUuid} from "../../utils/types/Uuid";
import {usePollingTimeout} from "./usePollingTimeout";
import {MESSAGE_TYPE} from "../../common/helper/messageTypeColorMapper";

const reportGaEvent = (
  actionNameForGA: string,
  queryId: QueryResponse["queryId"] | undefined,
  success: boolean
) => {
  ReactGA.event({
    category: "request",
    action: `${actionNameForGA}_${success ? "SUCCESS" : "FAILURE"}`,
    label: `QueryId: ${queryId}`,
    nonInteraction: true,
    transport: "xhr"
  });
};

type EditorApiQueryHook<T> = UseQuery<QueryDefinition<
  string,
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
  never,
  QueryResult<T>,
  "editorApi"
>>;

export enum RequestStatusPollingInterval {
  MINIMAL = 1000,
  DEFAULT = 5000,
  SHORT = 3000,
  LONG = 10000
}

const mapGeneratorErrorToMessage = (
  error: QueryResult<unknown>["error"],
  actionName: keyof typeof QueryType,
  queryId: QueryResponse["queryId"]
): string | undefined => {
  const contactSupportMessage = `Please send the following requestId to our support for further investigation: ${actionName}/${queryId}`;
  if (!error) {
    return undefined;
  }

  switch (error.reason) {
    case GeneratorErrorReason.INSTRUCTION_LENGTH:
      switch (actionName) {
        case "GENERATE_STEPS":
        case "GENERATE_STEP_HINTS":
          return "Flow description is too long.\nPlease try to rephrase it to a shorter form.";
        case "GENERATE_CODE":
          return "Step description is too long.\nPlease try to rephrase it to a shorter form or split it into multiple steps.";
        default: //should never happen
          return "Input is too long.\nPlease try to rephrase it to a shorter form.";
      }
    case GeneratorErrorReason.COMPLETION_LENGTH:
      return "The answer generated by the AI was too long.\nPlease try to rephrase your request and retry.";
    case GeneratorErrorReason.MODERATION:
      return `Your request cannot be fulfilled due to moderation rules.\n${contactSupportMessage}`;
    case GeneratorErrorReason.UNAVAILABLE:
      return `Your request cannot be fulfilled due to technical issues.\n${error.canRetry ? "Please try again later" : contactSupportMessage}`;
    case GeneratorErrorReason.UNSUPPORTED_INSTRUCTION:
      switch (actionName) {
        case "GENERATE_STEPS":
        case "GENERATE_STEP_HINTS":
          return "Flow description could not be understood.\nPlease try to rephrase it.";
        case "GENERATE_CODE":
          return "Step description could not be understood.\nPlease try to rephrase it.";
        default: //should never happen
          return "Instruction could not understood.\nPlease try to rephrase it.";
      }
    case GeneratorErrorReason.OTHER:
      return `Your request cannot be fulfilled due to technical issues.\n${error.canRetry ? "Please try again later." : contactSupportMessage}`;
    default:
      return `Your request cannot be fulfilled for unknown reason.\n${error.canRetry ? "Please try again later." : contactSupportMessage}`;
  }
};

export const useRequestStatusPolling = <T>(
  queryId: QueryResponse["queryId"] | undefined,
  queryTypeName: keyof typeof QueryType,
  queryHook: EditorApiQueryHook<T>,
  initialPollingInterval: number = RequestStatusPollingInterval.DEFAULT,
  resetQueryIdFunction: ThunkAction<void, RootState, any, AnyAction>,
  processQueryResultFunction: (queryResult: T) => ThunkAction<string | void, RootState, any, AnyAction>,
  errorCallback: (title: string, message?: string, callToAction?: string, messageType?: MESSAGE_TYPE) => void = () => {
  },
  successCallback: (title: string, message?: string) => void = () => {
  },
  maxPollingTime: number = 120000
) => {
  const dispatch = useAppDispatch();

  const safeInitialPollingInterval = Math.max(initialPollingInterval, RequestStatusPollingInterval.MINIMAL);
  const pollingInterval = useRef(safeInitialPollingInterval);

  const actionName = queryTypeName;
  const actionNameForHuman = QueryType[actionName];

  useEffect(() => {
    isUuid(queryId) && console.debug(`Resetting polling interval to default for queryId ${queryId}.`);
    pollingInterval.current = safeInitialPollingInterval;
  }, [queryId, safeInitialPollingInterval]);

  const pollingTimeoutUtils = usePollingTimeout(maxPollingTime);
  const currentPollingStartTime = isUuid(queryId) ? pollingTimeoutUtils.register(queryId) : 0;

  const lastRequestId = useRef<string | undefined>(undefined);

  const queryInfo = queryHook(
    isUuid(queryId) ? queryId : skipToken,
    {pollingInterval: pollingInterval.current}
  );
  isUuid(queryId) && console.debug(`Polling for queryId ${queryId} started at ${currentPollingStartTime} with interval ${pollingInterval.current} in request ${queryInfo.requestId}.`);

  //update data from execution status poll
  useEffect(() => {
      if (!isUuid(queryId)) {
        return;
      }

      const {
        currentData: currentQueryData,
        error: queryError,
        isSuccess: isQuerySuccess,
        isError: isQueryError,
        isFetching: isQueryFetching,
        requestId
      } = queryInfo;

      if (isQueryFetching) {
        return;
      }
      if (lastRequestId.current === requestId) { // prevent multiple executions of this effect for the same request
        return;
      }
      lastRequestId.current = requestId;

      if (isQueryError) {
        dispatch(resetQueryIdFunction);
        errorCallback(
          `${actionNameForHuman} failed!`,
          JSON.stringify(queryError, null, 2)
        );
        reportGaEvent(`${actionName}_STATUS_POLLING`, queryId, false);
        pollingTimeoutUtils.unregister(queryId);
        return;
      }

      if (isQuerySuccess) {
        const {
          state,
          result,
          error
        } = currentQueryData ?? {};

        if (state === "finished") {
          dispatch(resetQueryIdFunction);
          const errorMessage = result
            ? dispatch(processQueryResultFunction(result))
            : mapGeneratorErrorToMessage(error, actionName, queryId);

          if (!errorMessage) {
            reportGaEvent(actionName, queryId, true);
            successCallback(
              `${actionNameForHuman} finished successfully.`
            );
          } else {
            reportGaEvent(actionName, queryId, false);
            errorCallback(
              `${actionNameForHuman} didn't finish successfully!`,
              errorMessage,
              "Click for details",
              MESSAGE_TYPE.WARNING
            );
          }
          pollingTimeoutUtils.unregister(queryId);
          return;

        } else {
          console.debug(`Polling for queryId ${queryId} still in progress. (for ${Date.now() - currentPollingStartTime}ms)}`)
          if (pollingTimeoutUtils.hasTimedOut(queryId)) {
            console.debug(`Polling for queryId ${queryId} timed out after ${Date.now() - currentPollingStartTime}ms.`);
            dispatch(resetQueryIdFunction);
            errorCallback(
              `${actionNameForHuman} failed!`,
              "Operation timed out."
            );
            pollingTimeoutUtils.unregister(queryId);
            return;
          }
        }
      }
    },
    [dispatch, errorCallback, successCallback, queryId, resetQueryIdFunction, actionNameForHuman, actionName, processQueryResultFunction, maxPollingTime, pollingTimeoutUtils, queryInfo, currentPollingStartTime]);
};
