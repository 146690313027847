import {NavLink} from "react-router-dom";
import {StepStatus} from "../../types/StepStatus";
import StatusTag from "../../../common/components/status-tag/StatusTag";
import {classNames} from "../../../utils/classNames";
import {UiColor} from "../../../utils/constants/UiColor";
import {ReactNode} from "react";
import {ScheduledJobStatus} from "@cranq-gpt-lowcode/contracts";
import ScheduledFlowStatusTag from "../scheduled-flow-status-tag/ScheduledFlowStatusTag";

/**
 * Status tag is either "ran" which means success, or in DRAFT for any other state.
 * @param flowState
 */
const statusTag = (flowState?: keyof typeof StepStatus): ReactNode => {
  switch (flowState) {
    case "run":
      return <div className={classNames(`
        w-2 h-2 m-1
        rounded-full 
        ${UiColor.BackgroundColor.GOLD} 
        ${UiColor.TextColor.LIGHT_GRAY}`)}/>
    default:
      return <StatusTag title={"Draft"} color={UiColor.TextColor.GRAY}/>
  }
}

export type FlowListItemProps = {
  title: string;
  flowId: string;
  flowState?: keyof typeof StepStatus;
  scheduledFlowStatus?: ScheduledJobStatus
  isCurrent?: boolean,
}

const FlowListItem = (
  {
    title,
    flowId,
    flowState,
    scheduledFlowStatus,
    isCurrent = false
  }: FlowListItemProps
) => {
  return (
    <div
      className={classNames(`
        p-2 
        border-l-2 rounded-r-sm 
        flex gap-1 items-center 
        ${isCurrent ? `${UiColor.BorderColor.GOLD} ${UiColor.BackgroundColor.WHITE} ${UiColor.ShadowColor.GOLD_MEDIUM}` : `${UiColor.BorderColor.TRANSPARENT}`}
        ${UiColor.TextColor.GRAY}
        hover:${UiColor.TextColor.GOLD}
        `)}>
      <div className={"min-w-0 flex-grow"}>
        <NavLink to={`/flow/${flowId}`}>
          <h2 className={"truncate text-sm"}>{title}</h2>
        </NavLink>
      </div>
      <div className={"min-w-0 justify-self-end self-center flex-shrink-0"}>
        <NavLink to={`/flow/${flowId}`}>
          {scheduledFlowStatus
            ? <ScheduledFlowStatusTag scheduledFlowStatus={scheduledFlowStatus}/>
            : statusTag(flowState)}
        </NavLink>
      </div>
    </div>
  );
}

export default FlowListItem;
