"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapGenerateExecutionErrorHintsRequestToParams = void 0;
const resolveStepInputValue_1 = require("../../common/utils/resolveStepInputValue");
function resolveInputs(inputs, flow, env) {
    return inputs.reduce((reduced, input) => {
        const value = (0, resolveStepInputValue_1.resolveStepInputValue)(input, flow, env);
        return Object.assign(Object.assign({}, reduced), { [input.name]: value });
    }, {});
}
function mapGenerateExecutionErrorHintsRequestToParams(request) {
    var _a;
    const { flow, stepId, env, executionError } = request;
    const step = flow.find(a => a.id === stepId);
    if (!step) {
        throw ReferenceError(`Invalid step #${stepId}`);
    }
    if (!step.implementation) {
        throw Error(`Step #${stepId} has no implementation`);
    }
    return {
        task_description: step.description,
        inputs: ((_a = step.interface) === null || _a === void 0 ? void 0 : _a.inputs) ? resolveInputs(step.interface.inputs, flow, env) : {},
        error: executionError,
        code: step.implementation.code
    };
}
exports.mapGenerateExecutionErrorHintsRequestToParams = mapGenerateExecutionErrorHintsRequestToParams;
