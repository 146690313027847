"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generatorOutputToResponseMapperFactory = void 0;
const Generators_1 = require("../domain/Generators");
const mapGenerateCodeForStepOutputToResponse_1 = require("./mapGenerateCodeForStepOutputToResponse");
const mapGenerateTasksByInstructionOutputToResponse_1 = require("./mapGenerateTasksByInstructionOutputToResponse");
const mapGenerateTasksByInstructionHintsOutputToResponse_1 = require("./mapGenerateTasksByInstructionHintsOutputToResponse");
const mapGenerateExecutionErrorHintsOutputToResponse_1 = require("./mapGenerateExecutionErrorHintsOutputToResponse");
function generatorOutputToResponseMapperFactory(generator) {
    const mappers = {
        [Generators_1.Generators.generateCodeForStep]: mapGenerateCodeForStepOutputToResponse_1.mapGenerateCodeForStepOutputToResponse,
        [Generators_1.Generators.generateTasksByInstruction]: mapGenerateTasksByInstructionOutputToResponse_1.mapGenerateTasksByInstructionOutputToResponse,
        [Generators_1.Generators.generateTasksByInstructionHints]: mapGenerateTasksByInstructionHintsOutputToResponse_1.mapGenerateTasksByInstructionHintsOutputToResponse,
        [Generators_1.Generators.generateExecutionErrorHints]: mapGenerateExecutionErrorHintsOutputToResponse_1.mapGenerateExecutionErrorHintsOutputToResponse
    };
    return mappers[generator];
}
exports.generatorOutputToResponseMapperFactory = generatorOutputToResponseMapperFactory;
