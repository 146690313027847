import {ScheduledJobStatus} from "@cranq-gpt-lowcode/contracts";
import {UiColor} from "../../../utils/constants/UiColor";
import InlineIcon from "../../../common/components/inline-icon/InlineIcon";
import {InlineIconName} from "../../../common/components/inline-icon/InlineIconName";
import {FlowState} from "../../types/FlowState";
import {calculateScheduledFlowStatus} from "./utils/calculateScheduledFlowStatus";

type ScheduledFlowStatusTagProps = {
  scheduledJobId: FlowState["scheduledJobId"]
  scheduledJobExpiryDate: FlowState["scheduledJobExpiryDate"]
  scheduledJobHash: FlowState["scheduledJobHash"]
  flowHash: FlowState["model"]["hash"]
} | {
  scheduledFlowStatus: ScheduledJobStatus
}

const ScheduledFlowStatusTag = (
  props: ScheduledFlowStatusTagProps
) => {
  const scheduledFlowStatus = "scheduledFlowStatus" in props
    ? props.scheduledFlowStatus
    : calculateScheduledFlowStatus(
      props.scheduledJobId,
      props.scheduledJobExpiryDate,
      props.scheduledJobHash,
      props.flowHash
    );
  if (scheduledFlowStatus) {
    const iconTone = scheduledFlowStatus === ScheduledJobStatus.SCHEDULED ? UiColor.TextColor.GOLD : UiColor.TextColor.RED;
    return (
      <div className={"w-4 h-4"}><InlineIcon name={InlineIconName.SCHEDULE} primaryTone={iconTone}/></div>
    )
  }
  return null;
}

export default ScheduledFlowStatusTag;
