import {detailedDiff} from "deep-object-diff";
import {unique} from "../../utils/unique";
import {updateFlowHashAction} from "../actions/updateFlowHashAction";
import {RootState, store} from "../store";
import {FlowModel} from "../types/FlowModel";
import {flowPropertiesToIgnoreInHash} from "./calculateFlowHash";
import {isUuid} from "../../utils/types/Uuid";

const isAnyChangedPropertyRelevantForHash = (partialFlowModel: Partial<FlowModel>): boolean =>
  Object.keys(partialFlowModel).some((key) => !flowPropertiesToIgnoreInHash.includes(key as keyof FlowModel))

export const updateFlowHashUponFlowsChanges = (
  state: RootState["flows"],
  previousState: RootState["flows"]
) => {
  const diff = detailedDiff(previousState, state)
  const {added, updated, deleted} = diff;

  const flowsToUpdate = unique(
      [added, updated]
        .flatMap((i) => Object.entries(i))
        .filter(
          ([, flow]) => "model" in flow && isAnyChangedPropertyRelevantForHash(flow.model))
        .map(([flowId]) => flowId)
        .filter(isUuid)
        .concat(
          Object.entries(deleted)
            // if flow is undefined, the entire flow was deleted, so we don't need to update it
            // otherwise only some of its properties were deleted
            .filter(([, flow]) => flow !== undefined)
            .filter(([, flow]) => "model" in flow && isAnyChangedPropertyRelevantForHash(flow.model))
            .map(([flowId]) => flowId)
            .filter(isUuid)
        )
    )
  ;
  flowsToUpdate.forEach((flowId) => store.dispatch(updateFlowHashAction(flowId)))
}
