import {TimelineItemInner, TimelineItemProps} from "../timeline-item/TimelineItem";
import {classNames} from "../../../utils/classNames";
import {UiColor} from "../../../utils/constants/UiColor";
import IconButton from "../../../common/components/icon-button/IconButton";
import {InlineIconName} from "../../../common/components/inline-icon/InlineIconName";
import {ReactNode} from "react";

export type TimelineGroupProps = TimelineItemProps & {
  expanded?: boolean,
  onExpand?: () => void,
  children?: ReactNode,
}

const layers = [
  "top-0 left-0",
  "top-1 left-1",
  "top-2 left-2"
]

const TimelineGroup = (props: TimelineGroupProps) => {
  const {
    expanded = false,
    isInserted = false,
    isMarked = false,
    isMarkable = false,
    isDeleted = false,
    allowDelete = false,
    onDelete,
    onExpand,
    onMarkedChange
  } = props;
  return (
    <div className={"flex flex-col w-full gap-0"}>
      <div className={classNames(`
        ${(isInserted || isDeleted) ? "h-0" : "h-16"} w-full
        mb-2
        flex items-center
        relative
        cursor-pointer
        group
        transition-[height] duration-500 ease-in-out
    `)}
           title={"Double click to expand/collapse"}
           onDoubleClick={() => onExpand && onExpand()}>
        { //TODO: extract into MarkActionItem component?
          isMarkable && <div className={classNames(`
          flex-grow-0 
          absolute -left-6
          flex items-center
          h-full w-6 pr-2 
          ${isMarked ? "" : "opacity-0 group-hover:opacity-100"}
        `)}>
            <input
              id={`mark-${props.id}`}
              type={"checkbox"}
              checked={isMarked}
              className={"w-4 h-4"}
              onChange={(e) => onMarkedChange && onMarkedChange(e.target.checked)}
            />
          </div>}
        {expanded
          ? <TimelineItemInner {...props} />
          : Object.entries(layers).reverse()
            .map<[number, string]>(([n, s]) => [parseInt(n), s])
            .map(([n, classes]) => {
              const isLastLayer = (n: number) => n === layers.length - 1;
              return (
                <div key={n}
                     className={classNames(`
                       absolute ${classes}
                       w-full h-full
                       `)}
                     style={{width: `calc(100% - 0.5rem) /* minus value should match the largest indentation from left in "layers" */`}}>
                  <TimelineItemInner {...{...props, ...(isLastLayer(n) ? {} : {showShadow: false})}} />
                </div>
              );
            })}
        <div className={classNames(`
            flex-grow-0 
            absolute -right-6
            flex items-center
            h-full w-6 pl-2 
            opacity-0 group-hover:opacity-100
            `)}>
          {allowDelete && <IconButton
            iconName={InlineIconName.DELETE}
            iconTone={UiColor.TextColor.RED}
            size={"small"}
            onClick={() => onDelete && onDelete()}/>}
        </div>
      </div>
      {
        expanded && (
          <div className={"pl-10 flex flex-col gap-0"}>
            {props.children}
          </div>
        )
      }
    </div>
  )
    ;

}

export default TimelineGroup;
