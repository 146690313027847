import {createContext, ReactNode, useCallback} from "react";
import {AiModels} from "@cranq-gpt-lowcode/contracts";
import editorApi, {useGetAiModelsQuery} from "../../editorApi";
import {useAppDispatch} from "../../hooks";


type AiModelsState = {
  aiModels?: AiModels;
  aiModelsFetching?: boolean;
  refetchAiModels?: (forceApiStateReset?: boolean) => void;
}
export const AiModelsContext = createContext<AiModelsState>({});

type AiModelsContextProviderProps = {
  children: ReactNode;
}
const AiModelsContextProvider = (
  {
    children
  }: AiModelsContextProviderProps) => {
  const dispatch = useAppDispatch();
  const {
    data: aiModels,
    refetch: refetchAiModelsQuery,
    isFetching: aiModelsFetching,
  } = useGetAiModelsQuery(undefined, {pollingInterval: 10 * 60 * 1000 /* in ms */});

  const refetchAiModels = useCallback((
    forceApiStateReset: boolean = false
  ) => {
    if (forceApiStateReset) {
      dispatch(editorApi.util.resetApiState());
    } else {
      dispatch(refetchAiModelsQuery);
    }
  }, [dispatch, refetchAiModelsQuery]);

  return (
    <AiModelsContext.Provider
      value={{
        aiModels,
        aiModelsFetching,
        refetchAiModels,
      }}>
      {children}
    </AiModelsContext.Provider>
  );
};

export default AiModelsContextProvider;
