import React, {useRef} from "react";
import {UiColor} from "../../../utils/constants/UiColor";
import {classNames} from "../../../utils/classNames";

type TransitionSpeed = "none" | "fast" | "normal" | "slow";

const transitionDurationClassMap: Record<TransitionSpeed, string> = {
  "none": "duration-0",
  "fast": "duration-200",
  "normal": "duration-500",
  "slow": "duration-1000"
};

export type ModalProps = {
  children: React.ReactNode,
  open?: boolean,
  transitionSpeed?: TransitionSpeed,
  backdropTone?: UiColor.BackgroundColor,
  backdropOpacity?: number,
  //events
  onOutsideClick?: () => void,
}

const Modal = (
  {
    children,
    open = true,
    transitionSpeed = "none",
    backdropTone = UiColor.BackgroundColor.DARK_GRAY,
    backdropOpacity = 80,

    onOutsideClick
  }: ModalProps) => {

  const backdropDiv = useRef<HTMLDivElement>(null);
  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === backdropDiv.current) {
      onOutsideClick && onOutsideClick();
    }
  };

  return <div
    ref={backdropDiv}
    className={classNames(`
      ${open ? "w-screen h-screen" : "w-0 h-0" /* do not use display: none, because it will not trigger transition */}
      fixed top-0 left-0 z-40 
      overflow-hidden
      grid
      ${(open ? `${backdropTone}/${backdropOpacity}` : `bg-none`)}
      transition-colors ${transitionDurationClassMap[transitionSpeed]} ease-in-out
    `)}
    onClick={handleOutsideClick}>
    {children}
  </div>;

};

export default Modal;
