"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapEntitiesToExecutorTask = void 0;
function mapEntitiesToExecutorTask(executorTaskEntity, executorTaskStepResultEntities, executorTaskLogEntryEntity) {
    const { executionId, request, status, invocationError } = executorTaskEntity;
    const logEntry = executorTaskLogEntryEntity ? executorTaskLogEntryEntity.logEntry : undefined;
    const taskBase = {
        executionId,
        request,
        status
    };
    if (executorTaskEntity.status === "pending") {
        return Object.assign(Object.assign({}, taskBase), { status: "pending" });
    }
    if (executorTaskEntity.status === "running") {
        const result = {
            steps: mapEntitiesToStepResults(executorTaskStepResultEntities),
            log: executorTaskEntity.result ? executorTaskEntity.result.log : []
        };
        return Object.assign(Object.assign({}, taskBase), { result, status: "running" });
    }
    if (executorTaskEntity.status === "finished" && executorTaskEntity.result) {
        if (executorTaskEntity.result.outcome === "succeeded") {
            const result = {
                steps: mapEntitiesToStepResults(executorTaskStepResultEntities),
                log: executorTaskEntity.result.log,
                outcome: "succeeded"
            };
            return Object.assign(Object.assign({}, taskBase), { result,
                logEntry, status: "finished" });
        }
        if (executorTaskEntity.result.outcome === "failed" && executorTaskEntity.result.failureReason) {
            const result = {
                steps: mapEntitiesToStepResults(executorTaskStepResultEntities),
                log: executorTaskEntity.result.log,
                outcome: "failed",
                failureReason: executorTaskEntity.result.failureReason
            };
            return Object.assign(Object.assign({}, taskBase), { result,
                logEntry,
                invocationError, status: "finished" });
        }
        else {
            throw new Error(`Error while reading malformed executor task #${executionId} - error not present on failed task`);
        }
    }
    else {
        throw new Error(`Error while reading malformed executor task #${executionId} - result not present on finished task`);
    }
}
exports.mapEntitiesToExecutorTask = mapEntitiesToExecutorTask;
function mapEntitiesToStepResults(stepResultEntities) {
    return stepResultEntities.map(a => {
        return {
            id: a.stepId,
            error: a.error,
            errorReason: a.errorReason,
            outputs: a.outputs
        };
    });
}
