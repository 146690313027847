export const createUniqueName = (
  requestedName: string,
  existingNames: string[],
  suffixFormatter: (i: number) => string = (i) => ` (${i})`
) => {
  let uniqueName = requestedName;
  for (let i = 0; i < existingNames.length; i++) {
    if (!existingNames.includes(uniqueName)) {
      break;
    }
    uniqueName = `${requestedName}${suffixFormatter(i + 1)}`;
  }
  return uniqueName;
}
