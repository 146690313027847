import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../../../store";
import {
  AiModels,
  AiModelType,
  ExecuteRequest,
  GetOAuthConnectionsResponse,
  Step,
  validateExecuteRequest
} from "@cranq-gpt-lowcode/contracts";
import {isUuid} from "../../../../utils/types/Uuid";
import {updateFlowExecution} from "../../../flowsSlice";
import {StepModel} from "../../../types/StepModel";
import {makeSelectStepsByFlowId, resetStepExecutionStatus, updateStepById, updateStepInput} from "../../../stepsSlice";
import {batch} from "react-redux";
import {UserModel} from "../../../types/UserModel";
import runnerApi from "../../../runnerApi";
import {FlowModel} from "../../../types/FlowModel";
import {selectEnvironmentByFlowId} from "../../../environmentsSlice";
import {createNewEnvironment} from "../../../utils/createNewEnvironment";
import {calculateImplementationParametersHash} from "../../../utils/calculateImplementationParametersHash";
import {EnvironmentModel} from "../../../types/EnvironmentModel";
import {isId} from "../../../../utils/types/Id";
import {processValidationErrors} from "./processValidationErrors";
import {PendingRequestId} from "../../../../utils/types/RequestId";
import {MESSAGE_TYPE} from "../../../../common/helper/messageTypeColorMapper";
import {mapStepModelToApiStepModel} from "../../../utils/mapStepModelToApiStepModel";
import {SubscriptionWithTimestamps} from "../../../types/SubscriptionWithTimestamps";
import {isApiConnected} from "../../../utils/isApiConnected";
import {GlobalParametersModel} from "../../../types/GlobalParametersModel";
import {selectGlobalParameters} from "../../../globalParametersSlice";
import {createResolvedEnvironment} from "../../../utils/createResolvedEnvironment";

//FIXME: can this be imported from contracts?
type ExecuteActionErrorResult = {
  error: string,
  message: string[],
  statusCode: number
}
const isExecuteActionErrorResult = (result: unknown): result is ExecuteActionErrorResult => {
  return typeof result === "object"
    && result !== null
    && "error" in result && typeof result.error === "string"
    && "message" in result && Array.isArray(result.message) && result.message.every((m) => typeof m === "string")
    && "statusCode" in result && typeof result.statusCode === "number";
};

//FIXME can this be imported from api
const apiErrorMessageRegex = /flow\.([0-9]+)\.(.*)/;

export enum ExecuteActionErrorCode {
  NO_STEPS = "Flow has no steps",
  STEP_NOT_FOUND = "Specified step cannot be found",
  NO_STEP_IMPLEMENTATION = "Step has no code implementation",
  UNCONNECTED_THIRD_PARTY_API = "Some 3rd party API is not connected",
  EXECUTION_REQUEST_INVALID = "Execution request is invalid",
  NOT_LOGGED_IN = "Login is required for execution"
}

const createExecuteRequest = (
  stepIds: StepModel["id"][] | undefined,
  steps: StepModel[],
  userId: string,
  environmentVariables: EnvironmentModel["variables"],
  globalParameters: GlobalParametersModel
): ExecuteRequest => ({
  userId: userId,
  flow: steps
    .sort((a, b) => Math.sign(a.index - b.index))
    .map<Step>(
      (step) => mapStepModelToApiStepModel(
        step,
        steps,
        environmentVariables)
    ),
  action: stepIds
    ? {
      type: "runSteps",
      stepIds: stepIds
    }
    : {
      type: "runAll"
    },
  env: createResolvedEnvironment(environmentVariables, globalParameters),
});

const extractErrorsFromExecuteActionErrorResult = (
  errorData: ExecuteActionErrorResult,
  stepIds: StepModel["id"][] | undefined,
  steps: StepModel[],
) => {
  const errorsWithoutStepId: string[] = [];
  const errorsByStep = errorData.message
    .reduce<Record<StepModel["id"], string[]>>((
      messagesByStep,
      message: string
    ) => {
      const [, stepIndex, error] = message.match(apiErrorMessageRegex) ?? [];
      if (error) {
        const executedSteps = stepIds
          ? stepIds.map((stepId) => steps.find((step) => step.id === stepId))
          : steps;
        const step = executedSteps[Number(stepIndex)];
        if (step) {
          const stepErrors = messagesByStep[step.id] ?? [];
          messagesByStep[step.id] = [...stepErrors, error];
        } else {
          errorsWithoutStepId.push(message);
        }
      }
      return messagesByStep
    }, {});
  return {errorsByStep, errorsWithoutStepId: errorsWithoutStepId};
};

export const executeAction = (
  flowId: FlowModel["id"],
  stepIds: StepModel["id"][] | undefined,
  userId: UserModel["id"] | undefined,
  apiConnections: GetOAuthConnectionsResponse | undefined,
  aiModels: AiModels | undefined,
  subscriptions: SubscriptionWithTimestamps[] | undefined,
  executeStepTrigger: ReturnType<typeof runnerApi["useExecuteMutation"]>[0],
  errorCallback: (title: string, errorMessage?: string, callToAction?: string, messageType?: MESSAGE_TYPE) => void = () => {
  }
): ThunkAction<Promise<void>, RootState, any, any> => async (
  dispatch,
  getState
) => {
  const environment = selectEnvironmentByFlowId(flowId)(getState()) ?? createNewEnvironment(flowId);
  const environmentVariables = environment?.model.variables ?? [];
  const selectStepsByFlowId = makeSelectStepsByFlowId();
  const steps = selectStepsByFlowId(getState(), flowId)?.map((step) => step.model) ?? [];
  if (!steps) {
    throw new Error(ExecuteActionErrorCode.NO_STEPS)
  }

  const stepsToExecute = (stepIds ?? [])
    .map((stepId) => steps.find((step) => step.id === stepId))
    .filter((step): step is StepModel => !!step)
  if (stepIds && stepsToExecute.length !== stepIds.length) {
    throw new ReferenceError(ExecuteActionErrorCode.STEP_NOT_FOUND)
  }

  const stepsWithoutValidImplementation = stepsToExecute.filter((step) =>
    step.aiModelType === AiModelType.CODE_WRITER
    && (!step.implementation || calculateImplementationParametersHash(step) !== step.implementation.parametersHash));
  if (stepsWithoutValidImplementation.length > 0) {
    throw new Error(ExecuteActionErrorCode.NO_STEP_IMPLEMENTATION, {cause: stepsWithoutValidImplementation.map((step) => step.id)});
  }

  if (userId === undefined) {
    throw new Error(ExecuteActionErrorCode.NOT_LOGGED_IN)
  }

  const usedApis = Array.from(new Set<string>(
    steps
      .filter((step) => !stepIds || stepIds.includes(step.id))
      .flatMap((step) => step.implementation?.apis ?? [])
  ));
  if (usedApis.some((api) => !isApiConnected(apiConnections, subscriptions, api))) {
    throw new Error(ExecuteActionErrorCode.UNCONNECTED_THIRD_PARTY_API);
  }

  const globalParameters = selectGlobalParameters(getState())?.model;

  const request: ExecuteRequest = createExecuteRequest(
    stepIds, steps, userId, environmentVariables, globalParameters);
  const validationErrors = validateExecuteRequest(request);
  if (validationErrors.length > 0) {
    const validationError = new Error(ExecuteActionErrorCode.EXECUTION_REQUEST_INVALID);
    validationError.cause = processValidationErrors(dispatch, request.flow, steps, validationErrors);
    throw validationError;
  }

  // reset steps' state and last error and input validation errors
  batch(() => {
    dispatch(updateFlowExecution({flowId, executionId: PendingRequestId, executedStepIds: stepIds}));
    steps
      .filter((step) => !stepIds || stepIds.includes(step.id))
      .forEach((step) => {
        dispatch(resetStepExecutionStatus(step.id));
        Object.values(step.inputs).forEach((input) => {
          const {validationError, ...inputWithoutValidationError} = input;
          dispatch(updateStepInput({
            stepId: step.id,
            input: inputWithoutValidationError
          }))
        })
      });
  });


  return executeStepTrigger(request)
    .then((result) => {
        dispatch(updateFlowExecution({flowId, executionId: undefined, executedStepIds: undefined}));
        if ("data" in result) {
          const {data: {executionId}} = result;
          if (isUuid(executionId)) {
            dispatch(updateFlowExecution({flowId, executionId, executedStepIds: stepIds}));
          }
        } else {
          if ("data" in result.error && isExecuteActionErrorResult(result.error.data)) {
            const {
              errorsWithoutStepId,
              errorsByStep
            } = extractErrorsFromExecuteActionErrorResult(result.error.data, stepIds, steps);
            batch(() => {
              Object.entries(errorsByStep)
                .forEach(([stepId, errors]) => {
                  if (isId(stepId)) {
                    dispatch(updateStepById({
                      stepId: stepId,
                      update: {
                        lastExecutionError: errors.join("\n") //FIXME: better error parsing?
                      }
                    }));
                  }
                });
            });
            const generalErrorMessage = errorsWithoutStepId.length > 0
              ? `There were also some general issues: \n${errorsWithoutStepId.map((e) => `- ${e}`).join("\n")}`
              : undefined;
            generalErrorMessage
              ? errorCallback(
                `Validation errors prevent ${stepIds ? "step" : "flow"} from running.`,
                `Check "failed" steps for more information!\n${generalErrorMessage}`,
                "Click for details",
                MESSAGE_TYPE.WARNING
              )
              : errorCallback(
                `Validation errors prevent ${stepIds ? "step" : "flow"} from running.`,
                undefined,
                `Check "failed" steps for more information!`,
                MESSAGE_TYPE.WARNING
              );
          } else {
            errorCallback(
              "Execution failed unexpectedly!",
              "Raw message:\n" + JSON.stringify(result.error, null, 2)
            );
          }
        }
      }
    )
    .catch(() => {
      dispatch(updateFlowExecution({flowId, executionId: undefined, executedStepIds: undefined}));
    });
};
