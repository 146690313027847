import {isLessonStep, LessonsStep} from "./LessonsStep";

type Lesson = {
  steps: LessonsStep[];
}
export const isLesson = (maybeLesson: unknown): maybeLesson is Lesson => {
  return typeof maybeLesson === "object" && maybeLesson !== null
    && "steps" in maybeLesson && Array.isArray(maybeLesson.steps)
    && maybeLesson.steps.every(isLessonStep);
};
