"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExecuteRequest = exports.RunStepsAction = exports.RunAllAction = void 0;
const swagger_1 = require("@nestjs/swagger");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const Step_1 = require("../../common/domain/Step");
class RunAllAction {
}
__decorate([
    (0, swagger_1.ApiProperty)(),
    (0, class_validator_1.Equals)("runAll"),
    __metadata("design:type", String)
], RunAllAction.prototype, "type", void 0);
exports.RunAllAction = RunAllAction;
class RunStepsAction {
}
__decorate([
    (0, swagger_1.ApiProperty)(),
    (0, class_validator_1.Equals)("runSteps"),
    __metadata("design:type", String)
], RunStepsAction.prototype, "type", void 0);
__decorate([
    (0, swagger_1.ApiProperty)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", Array)
], RunStepsAction.prototype, "stepIds", void 0);
exports.RunStepsAction = RunStepsAction;
let ExecuteRequest = class ExecuteRequest {
};
__decorate([
    (0, swagger_1.ApiProperty)({ type: "string" }),
    (0, class_validator_1.IsDefined)(),
    __metadata("design:type", String)
], ExecuteRequest.prototype, "userId", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({ type: [Step_1.Step] }),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsDefined)(),
    __metadata("design:type", Array)
], ExecuteRequest.prototype, "flow", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({
        type: "object",
        additionalProperties: {
            $ref: "string"
        }
    }),
    (0, swagger_1.ApiProperty)(),
    (0, class_validator_1.IsDefined)(),
    __metadata("design:type", Object)
], ExecuteRequest.prototype, "env", void 0);
__decorate([
    (0, swagger_1.ApiProperty)({
        oneOf: [
            { $ref: (0, swagger_1.getSchemaPath)(RunStepsAction) },
            { $ref: (0, swagger_1.getSchemaPath)(RunAllAction) }
        ]
    }),
    (0, swagger_1.ApiProperty)(),
    (0, class_validator_1.IsDefined)(),
    (0, class_validator_1.IsNotEmptyObject)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => Object, {
        keepDiscriminatorProperty: true,
        discriminator: {
            property: "type",
            subTypes: [
                { value: RunStepsAction, name: "runSteps" },
                { value: RunAllAction, name: "runAll" }
            ]
        }
    }),
    __metadata("design:type", Object)
], ExecuteRequest.prototype, "action", void 0);
ExecuteRequest = __decorate([
    (0, swagger_1.ApiExtraModels)(RunAllAction),
    (0, swagger_1.ApiExtraModels)(RunStepsAction)
], ExecuteRequest);
exports.ExecuteRequest = ExecuteRequest;
