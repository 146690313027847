import {FallbackProps} from "react-error-boundary";
import {useNavigate} from "react-router-dom";
import {UiColor} from "../../../utils/constants/UiColor";
import Button, {ButtonType} from "../../../common/components/button/Button";
import React from "react";

export const ErrorFallback = (
  {
    error,
    resetErrorBoundary
  }: FallbackProps
) => {
  const navigate = useNavigate();
  return (
    <div role="alert" className={"w-screen h-screen fixed grid"}>
      <div
        className={`self-center justify-self-center p-6 border-2 ${UiColor.BorderColor.RED} flex flex-col gap-6`}>
        <h1 className={`${UiColor.TextColor.RED}`}>Something went wrong</h1>
        <div>
          <p>The error message was:</p>
          <pre className={`${UiColor.BackgroundColor.GRAY} p-2 text-sm`}>{error.message}</pre>
        </div>
        <Button title={"Go back to the starting page"} type={ButtonType.PRIMARY} onClick={() => {
          resetErrorBoundary();
          navigate("/")
        }}/>
      </div>
    </div>
  );
}
