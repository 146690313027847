import {UiColor} from "../../../utils/constants/UiColor";
import React, {Fragment} from "react";
import {LayoutValues} from "../../../utils/constants/LayoutValues";
import {classNames} from "../../../utils/classNames";

export type TimelineScrollableContainerProps = {
  backgroundTone?: string,
  children?: React.ReactNode,
}
const TimelineScrollableContainer = (
  {
    backgroundTone = UiColor.BackgroundColor.TRANSPARENT,
    children
  }: TimelineScrollableContainerProps
) => {
  return (
    <Fragment>
      <div
        className={classNames(`
        flex-grow flex-shrink overflow-y-auto overflow-x-hidden
        py-6 ${LayoutValues.COLUMN_PADDING_X_INVERSE} ${LayoutValues.COLUMN_PADDING_X}
        ${backgroundTone}
        `)}>
        {/*
                Negative margins and corresponding padding overrides default layout padding
                to make the div extend to the full layout column
                otherwise Timeline items' drop shadow and delete button would be clipped
             */}
        {children}
      </div>
      <div className={`w-full h-0 flex-shrink-0 border-t-[1px] pb-4 ${UiColor.BorderColor.GRAY}`}/>
    </Fragment>
  )
}

export default TimelineScrollableContainer;
