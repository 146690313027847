import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react";
import {
  AiModel,
  AiModels,
  Flow,
  GenerateCodeForStepRequest,
  GenerateCodeForStepResponse,
  GenerateExecutionErrorHintsRequest,
  GenerateExecutionErrorHintsResponse,
  GenerateFlowByInstructionHintsResponse,
  GenerateFlowByInstructionRequest,
  GeneratorErrorReason,
  QueryResponse,
  QueryResult,
  QueryStatusRequest
} from "@cranq-gpt-lowcode/contracts";
import {EndpointBuilder} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {ApiContext, getApiBaseUrl} from "./utils/getApiBaseUrl";

/**
 * API serializes enum as string in format "EnumName.EnumValue" (e.g. "GeneratorErrorReason.INSTRUCTION_LENGTH")
 * This function restores enum value from string
 */
const restoreGeneratorErrorReason = <T>(
  response: QueryResult<T>
): QueryResult<T> => {
  if (response.error) {
    const {reason: reasonStringified} = response.error;
    const [, reasonValue] = Object.entries(GeneratorErrorReason)
      .find(([key]) => `GeneratorErrorReason.${key}` === reasonStringified) ?? ["", GeneratorErrorReason.OTHER];
    return {
      ...response,
      error: {
        ...response.error,
        reason: reasonValue
      }
    }
  }
  return response;
}

export const editorApiRawEndpointBuilder = (
  builder: EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, "editorApi", "editorApi">
) => ({
  generateStepsAsync: builder.mutation<QueryResponse, GenerateFlowByInstructionRequest>({
    query: (request) => ({
      url: "flow/generateByInstruction/query",
      method: "POST",
      body: request
    })
  }),
  getGenerateStepsAsyncStatus: builder.query<QueryResult<Flow>, QueryStatusRequest["queryId"]>({
    query: (queryId) => ({
      url: `flow/generateByInstruction/query/${queryId}`,
      method: "GET"
    }),
    transformResponse: restoreGeneratorErrorReason<Flow>
  }),
  generateHintsForStepsAsync: builder.mutation<QueryResponse, GenerateFlowByInstructionRequest>({
    query: (request) => ({
      url: "flow/generateByInstruction/getHints/query",
      method: "POST",
      body: request
    })
  }),
  getGenerateHintsForStepAsyncStatus: builder.query<QueryResult<GenerateFlowByInstructionHintsResponse>, QueryStatusRequest["queryId"]>({
    query: (queryId) => ({
      url: `flow/generateByInstruction/getHints/query/${queryId}`,
      method: "GET"
    }),
    transformResponse: restoreGeneratorErrorReason<GenerateFlowByInstructionHintsResponse>
  }),
  generateCodeAsync: builder.mutation<QueryResponse, GenerateCodeForStepRequest>({
    query: (generateCodeRequest) => ({
      url: "step/generateCode/query",
      method: "POST",
      body: generateCodeRequest
    })
  }),
  getGenerateCodeAsyncStatus: builder.query<QueryResult<GenerateCodeForStepResponse>, QueryStatusRequest["queryId"]>({
    query: (queryId) => ({
      url: `step/generateCode/query/${queryId}`,
      method: "GET"
    }),
    transformResponse: restoreGeneratorErrorReason<GenerateCodeForStepResponse>
  }),
  createGenerateExecutionErrorHintsTask: builder.mutation<QueryResponse, GenerateExecutionErrorHintsRequest>({
    query: (request) => ({
      url: "task/generateExecutionErrorHints",
      method: "POST",
      body: request
    })
  }),
  getGenerateExecutionErrorHintsTaskStatus: builder.query<QueryResult<GenerateExecutionErrorHintsResponse>, QueryStatusRequest["queryId"]>({
    query: (taskId) => ({
      url: `task/generateExecutionErrorHints/${taskId}`,
      method: "GET"
    })
  }),
  getAiModels: builder.query<AiModels, void>({
    query: () => ({
      url: "aiModels",
      method: "GET"
    }),
    providesTags: [{type: "editorApi", id: "AiModels"}],
    transformResponse: (result: AiModel[]): AiModels => {
      return result.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.id]: curr
        }
      }, {} as AiModels)

    },
    keepUnusedDataFor: 15 * 60 /* in seconds */
  })
});

const editorApi = createApi({
  reducerPath: "editorApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getApiBaseUrl(ApiContext.EDITOR),
    credentials: "include",
    prepareHeaders: (headers) => {
      headers.append("Ga-Client-Id", window.__GLOBAL_VAR__.GA_ClIENT_ID || "unset");
    }
  }),
  endpoints: editorApiRawEndpointBuilder
});

export const {
  useGenerateStepsAsyncMutation,
  useGetGenerateStepsAsyncStatusQuery,
  useGenerateHintsForStepsAsyncMutation,
  useGetGenerateHintsForStepAsyncStatusQuery,
  useGenerateCodeAsyncMutation,
  useGetGenerateCodeAsyncStatusQuery,
  useCreateGenerateExecutionErrorHintsTaskMutation,
  useGetGenerateExecutionErrorHintsTaskStatusQuery,
  useGetAiModelsQuery
} = editorApi;
export default editorApi;
